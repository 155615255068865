import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Header, Text } from '../../components';
import { checkLoggedIn, validateAccess } from '../../session';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';

class BrandingHub extends Component {
  constructor(props) {
    super(props);

    this.shortcuts = [
      {
        key: 'appbranding',
        url: '/branding/edit',
        icon: 'mobile',
        label: 'App Branding',
        permission: 'master',
      },
      {
        key: 'applogin',
        url: '/branding/loginpicker',
        icon: 'sign-in',
        label: 'App Login',
        permission: 'master',
      },
      {
        key: 'adminlogin',
        url: '/branding/subdomain',
        icon: 'laptop',
        label: 'Community Manager Login Image',
        permission: 'master',
      },
    ];
  }

  componentDidMount() {
    checkLoggedIn(this, this.props.auth);
  }

  renderShortcut(shortcut) {
    if (shortcut.permission && !validateAccess(this.props.auth.site, shortcut.permission, this.props.auth)) {
      return null;
    }
    return (
      <Link to={shortcut.url} key={shortcut.key} className="dashboardButton">
        <div className="dashboardButton_inner">
          <div className="dashboardButton_iconContainer">
            <FontAwesome className="dashboardButton_icon" name={shortcut.icon} />
          </div>
          <p className="dashboardButton_text" style={{ fontSize: 16, height: 80, width: 150, marginBottom: -30 }}>
            {shortcut.label}
          </p>
        </div>
      </Link>
    );
  }

  renderContent() {
    return (
      <div style={{ minWidth: '100%' }}>
        <Text type="h3" className="marginBottom-20">
          Branding
        </Text>
        <div>{this.shortcuts.map((s) => this.renderShortcut(s))}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentWrapper">{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(BrandingHub);
