import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { DropdownInput, Button, GenericInput, CheckBox } from '../../components';
import { isTheBest, getApiError } from '../../session';
import { automationActions } from '../../webapi';

const REPO_APP = 'minuss-expo';
const REPO_WEB = 'minuss-admin';
const REPO_AWS = 'minuss-aws';
const REPO_ALL = 'all';
const CHECK_DEPLOYMENTS_DELAY = 30000;
const CHECK_BUILD_DELAY = 30000;

class Deployment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queueLoading: false,
      queueMessage: '',
      queueAdding: false,
      environmentLoading: [],
      environments: [],
      selected: [],
      runningAction: [],
      deployMessage: [],
      deployments: [],
      awsStacks: [],
      stackLoading: false,
      selectedStack: '',
      upgradeVersions: [],
      upgradeMessage: [],
      upgrading: [],
      mergeCode: [],
    };
    this.loadDeploymentHandle = [];
    this.loadBuildQueueHandle = null;
  }

  componentDidMount() {
    this.loadEnvironments(REPO_AWS);
    this.loadEnvironments(REPO_WEB);
    this.loadEnvironments(REPO_APP);

    this.startPolling();
  }

  componentWillUnmount() {
    this.stopPolling();
  }

  onEnvironmentChanged = (key, repo = '') => {
    const { selected, environments } = this.state;
    if (repo) {
      selected[repo] = environments[repo].find((item) => item.Key === key);

      if (repo === REPO_AWS) this.loadAwsStacks(repo);
    } else {
      selected[REPO_ALL] = environments[REPO_ALL].find((item) => item.Key === key);
      selected[REPO_APP] = environments[REPO_APP].find((item) => item.Key === key);
      selected[REPO_WEB] = environments[REPO_WEB].find((item) => item.Key === key);
      selected[REPO_AWS] = environments[REPO_AWS].find((item) => item.Key === key);

      this.loadAwsStacks(REPO_AWS);
    }
    this.setState({ selected });
  };

  onChangeVersion = (event, repo) => {
    const { upgradeVersions } = this.state;
    upgradeVersions[repo] = event.target.value;
    this.setState({ upgradeVersions });
  };

  onChangeMerge = (repo) => {
    const { mergeCode } = this.state;
    mergeCode[repo] = !mergeCode[repo];
    this.setState({ mergeCode });
  };

  onStartDeploymentAll = (environment, environmentName) => {
    if (!this.isSelectedEnvironmentValid(REPO_ALL)) return;
    if (!window.confirm(`Are you sure you want to deploy all branches for ${environment}?`)) return;

    const { deployMessage, mergeCode } = this.state;
    deployMessage[REPO_ALL] = `Starting ${environmentName} deploy all...`;
    this.setState({ deployMessage, queueAdding: true }, async () => {
      try {
        await automationActions.startDeployment({ environment, merge: mergeCode[REPO_ALL] });
        deployMessage[REPO_ALL] = '';
        this.setState({ deployMessage, queueAdding: false }, this.loadBuildQueue);
      } catch (error) {
        deployMessage[REPO_ALL] = getApiError(error).message;
        this.setState({ deployMessage, queueAdding: false });
        console.log('onStartDeploymentAll', environment, error);
      }
    });
  };

  onStartDeployment = (repo, environment, environmentName, stack = '') => {
    if (!this.isSelectedEnvironmentValid(repo)) return;
    if (
      !window.confirm(
        repo === REPO_AWS && stack
          ? `Are you sure you want to deploy ${repo} ${stack} stack for ${environment}?`
          : `Are you sure you want to deploy ${repo} for ${environment}?`,
      )
    )
      return;

    const { deployMessage, mergeCode } = this.state;
    deployMessage[repo] = `Starting ${environmentName} deploy...`;
    this.setState({ deployMessage, queueAdding: true }, async () => {
      try {
        if (repo !== REPO_AWS) stack = '';
        await automationActions.startDeployment({ environment, repo, stack, merge: mergeCode[repo] });
        // console.log('onStartDeployment', environment, repo, stack);
        deployMessage[repo] = '';
        this.setState({ deployMessage, queueAdding: false }, this.loadBuildQueue);
      } catch (error) {
        deployMessage[repo] = getApiError(error).message;
        this.setState({ deployMessage, queueAdding: false });
        console.log('onStartDeployment', repo, error);
      }
    });
  };

  onUpgradeVersion = (repo, version) => {
    if (!repo || _.isEmpty(version)) return;
    if (!window.confirm(`Are you sure you want to upgrade ${repo} branches with version ${version}`)) return;

    const { upgradeMessage, upgrading, environments, mergeCode } = this.state;
    upgrading[repo] = true;
    upgradeMessage[repo] = `Upgrading from ${version}...`;
    this.setState({ upgrading, upgradeMessage }, async () => {
      try {
        for (let i = 0; i < environments[repo].length; i++) {
          const env = environments[repo][i];
          upgradeMessage[repo] = `Checking ${env.Key} version...`;
          this.setState({ upgradeMessage });
          const { data } = await automationActions.getBranchConfig(repo, env.Key);
          if (data && data.version === version) {
            upgradeMessage[repo] = `Upgrading ${env.Key} from ${version}...`;
            this.setState({ upgradeMessage });
            await automationActions.startDeployment({ repo, environment: env.Key, merge: mergeCode[repo] });
          }
        }
        upgrading[repo] = false;
        upgradeMessage[repo] = '';
        this.setState({ upgrading, upgradeMessage }, this.loadBuildQueue);
      } catch (error) {
        upgrading[repo] = false;
        upgradeMessage[repo] = getApiError(error).message;
        this.setState({ upgrading, upgradeMessage });
        console.log('onUpgradeVersion', repo, version, error);
      }
    });
  };

  onRerunDeployment = (build) => {
    if (!build.RowId) return;

    const { runningAction } = this.state;
    runningAction[build.RowId] = true;
    this.setState({ runningAction }, async () => {
      try {
        await automationActions.restartDeployment(build.RowId);
        runningAction[build.RowId] = false;
        this.setState({ runningAction, queueMessage: '' }, this.loadBuildQueue);
      } catch (error) {
        runningAction[build.RowId] = false;
        this.setState({ runningAction, queueMessage: getApiError(error).message });
        console.log('onRerunDeployment', error);
      }
    });
  };

  onStopDeployment = async (build) => {
    if (!build.Repo) return;
    if (!window.confirm(`Are you sure you want to stop building ${build.Branch} branch in ${build.Repo}?`)) return;

    const { runningAction } = this.state;
    runningAction[build.RowId] = true;
    this.setState({ runningAction }, async () => {
      try {
        await automationActions.stopDeployment(build.Repo, build.RowId, build.PipelineId);
        runningAction[build.RowId] = false;
        this.setState({ runningAction, queueMessage: '' }, this.loadBuildQueue);
      } catch (error) {
        runningAction[build.RowId] = false;
        this.setState({ runningAction, queueMessage: getApiError(error).message });
        console.log('onStopDeployment', error);
      }
    });
  };

  startPolling = () => {
    this.pollDeployments(REPO_AWS);
    this.pollDeployments(REPO_WEB);
    this.pollDeployments(REPO_APP);
    this.pollBuildQueue();
  };

  pollDeployments = (repo) => {
    this.loadDeployments(repo);
    this.loadDeploymentHandle[repo] = setInterval(() => this.loadDeployments(repo), CHECK_DEPLOYMENTS_DELAY);
  };

  pollBuildQueue = () => {
    this.loadBuildQueue();
    this.loadBuildQueueHandle = setInterval(() => this.loadBuildQueue(), CHECK_BUILD_DELAY);
  };

  stopPolling = () => {
    clearInterval(this.loadDeploymentHandle[REPO_AWS]);
    clearInterval(this.loadDeploymentHandle[REPO_WEB]);
    clearInterval(this.loadDeploymentHandle[REPO_APP]);
    clearInterval(this.loadBuildQueueHandle);
  };

  loadEnvironments = (repo) => {
    const { environmentLoading, environments, deployMessage } = this.state;
    environmentLoading[repo] = true;
    deployMessage[repo] = 'Loading environments...';
    this.setState({ environmentLoading, deployMessage }, async () => {
      try {
        const { data } = await automationActions.getEnvironments(repo);
        const envs = data.map((env) => {
          return { Id: env.uuid, Title: env.name, Key: env.slug };
        });
        environments[repo] = envs;
        // console.log('loadEnvironments', repo, environments[repo]);
        environments[REPO_ALL] = environments[REPO_AWS];
        environmentLoading[repo] = false;
        deployMessage[repo] = '';
        this.setState({ environmentLoading, deployMessage, environments });
      } catch (error) {
        environmentLoading[repo] = false;
        deployMessage[repo] = getApiError(error).message;
        this.setState({ environmentLoading, deployMessage });
        console.log('loadEnvironments', repo, error);
      }
    });
  };

  loadDeployments = async (repo) => {
    const { deployments } = this.state;
    try {
      const { data } = await automationActions.getDeployments(repo);
      // console.log('loadDeployments', repo, data);
      deployments[repo] = data;
      this.setState({ deployments });
    } catch (error) {
      console.log('loadDeployments error', repo, error);
    }
  };

  loadAwsStacks = () => {
    const { deployMessage } = this.state;

    const environment = this.getSelected(REPO_AWS);
    deployMessage[REPO_AWS] = `Loading stacks for ${environment}...`;
    this.setState({ stackLoading: true, deployMessage }, async () => {
      try {
        const { data } = await automationActions.getAwsStacks(environment);
        const awsStacks = data.map((stack) => {
          return { Title: stack, Key: stack };
        });
        awsStacks.unshift({ Title: 'All', Key: '' });
        deployMessage[REPO_AWS] = '';
        this.setState({ stackLoading: false, deployMessage, awsStacks });
      } catch (error) {
        deployMessage[REPO_AWS] = getApiError(error).message;
        this.setState({ stackLoading: false, deployMessage });
        console.log('loadAwsStacks error', environment, error);
      }
    });
  };

  loadBuildQueue = () => {
    this.setState({ queueLoading: true }, async () => {
      try {
        const { data: buildQueue } = await automationActions.getBuildList();
        // console.log('loadBuildQueue', buildQueue);
        this.setState({ queueLoading: false, buildQueue });
      } catch (error) {
        this.setState({ queueLoading: false, queueMessage: getApiError(error).message });
        console.log('loadBuildQueue', error);
      }
    });
  };

  getSelected = (repo) => {
    const { selected } = this.state;
    return selected[repo] ? selected[repo].Key : '';
  };

  getSelectedName = (repo) => {
    const { selected } = this.state;
    return selected[repo] ? selected[repo].Title : '';
  };

  getSelectedStack = (stack) => {
    const { awsStacks } = this.state;
    const selected = awsStacks.find((s) => s.Key === stack);
    return selected ? selected.Title : 'All';
  };

  isSelectedEnvironmentValid = (repo) => {
    const { environmentLoading, selected } = this.state;
    return !environmentLoading[repo] && selected[repo] && selected[repo].Key ? true : false;
  };

  renderBuildQueue() {
    const { buildQueue, queueLoading, queueMessage, runningAction } = this.state;

    return (
      <div className="buildQueueContainer">
        <div className="flex-1" style={{ fontSize: 11 }}>
          {queueMessage}
        </div>
        <Table className="plussTable" striped bordered condensed hover>
          <thead>
            <tr>
              <th>Created</th>
              <th>Updated</th>
              <th>Repository</th>
              <th>Branch</th>
              <th>Stack</th>
              <th>Merge</th>
              <th>Status</th>
              <th>Result</th>
              <th>PR</th>
              <th>Build</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {buildQueue && buildQueue.length > 0 ? (
              buildQueue.map((build) => {
                const completed = build.Status === 'COMPLETED';
                const failed = ['FAILED', 'MERGE_FAILED'].includes(build.Result);
                const isRunning = runningAction[build.RowId] || queueLoading;
                const canStop = !completed || failed;
                const canRerun = failed;
                return (
                  <tr key={build.RowId} style={{ fontSize: 11 }}>
                    <td>{moment(build.Created).format('YYYY-MM-DD HH:mm')}</td>
                    <td>{build.Updated ? moment(build.Updated).format('YYYY-MM-DD HH:mm') : ''}</td>
                    <td>{completed ? build.Repo : <b>{build.Repo}</b>}</td>
                    <td>{completed ? build.Branch : <b>{build.Branch}</b>}</td>
                    <td>{completed ? build.Stack : <b>{build.Stack}</b>}</td>
                    <td>{build.Source}</td>
                    <td>{completed ? build.Status : <b>{build.Status}</b>}</td>
                    <td>{build.Result}</td>
                    <td>
                      {build.PullRequestUrl ? (
                        <a href={build.PullRequestUrl} target="_blank" rel="noopener noreferrer">
                          {build.PullRequestId ? `#${build.PullRequestId}` : ''}
                        </a>
                      ) : null}
                    </td>
                    <td>
                      {build.PipelineUrl ? (
                        <a href={build.PipelineUrl} target="_blank" rel="noopener noreferrer">
                          {build.BuildNumber ? `#${build.BuildNumber}` : ''}
                        </a>
                      ) : null}
                    </td>
                    <td>
                      {canStop ? (
                        <Button
                          inline
                          buttonType="outlined"
                          onClick={() => (isRunning ? null : this.onStopDeployment(build))}
                          isActive={!isRunning}
                          compact
                        >
                          Stop
                        </Button>
                      ) : null}
                      {/* {canRerun ? (
                        <Button
                          className="marginLeft-10"
                          inline
                          buttonType="outlined"
                          onClick={() => (isRunning ? null : this.onRerunDeployment(build))}
                          isActive={!isRunning}
                          compact
                        >
                          Rerun
                        </Button>
                      ) : null} */}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7}>Build queue is empty</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }

  renderSelectorAll() {
    const { environmentLoading, environments, deployMessage, mergeCode } = this.state;
    const selectedEnv = this.getSelected(REPO_ALL);
    const selectedEnvName = this.getSelectedName(REPO_ALL);
    const isLoading =
      environmentLoading[REPO_APP] || environmentLoading[REPO_WEB] || environmentLoading[REPO_AWS] || environmentLoading[REPO_ALL];
    return (
      <div>
        <p className="fontRegular fontSize-20 text-dark">Deploy Environment</p>
        <div className="flex flex-row">
          <DropdownInput
            id="allRepo"
            style={{ width: 250, marginBottom: 10, marginRight: 45 }}
            placeholder="Select Environment (All)"
            value={this.getSelectedName(REPO_ALL)}
            options={environments[REPO_ALL]}
            onSelect={(key) => this.onEnvironmentChanged(key)}
            disabled={isLoading}
          />
          <Button
            style={{ width: 120 }}
            inline
            buttonType="primary"
            onClick={() => this.onStartDeploymentAll(selectedEnv, selectedEnvName)}
            isActive={!_.isEmpty(selectedEnv) && !isLoading}
          >
            Deploy All
          </Button>
          <div className="marginTop-8 marginLeft-10" style={{ fontSize: 11 }}>
            {deployMessage[REPO_ALL] || (isLoading ? 'Loading environments...' : '')}
          </div>
        </div>
        <CheckBox label="Merge branch" isActive={mergeCode[REPO_ALL]} onChange={() => this.onChangeMerge(REPO_ALL)} />
      </div>
    );
  }

  renderRepoDeploy(repo, title) {
    const { environmentLoading, stackLoading, queueAdding, environments, awsStacks, selectedStack, deployMessage, mergeCode } = this.state;

    const isLoadingStacks = repo === REPO_AWS ? stackLoading : false;
    const isLoading = environmentLoading[repo] || isLoadingStacks || queueAdding;
    const canDeploy = !isLoading && this.isSelectedEnvironmentValid(repo);
    const selectedEnv = this.getSelected(repo);
    const selectedEnvName = this.getSelectedName(repo);
    return (
      <div>
        <div className="fontRegular fontSize-20 text-dark marginBottom-8">{title}</div>
        <div className="flex flex-column" style={{ height: 190 }}>
          <DropdownInput
            id={`dropdown_${repo}`}
            style={{ width: 250, marginBottom: 'unset' }}
            placeholder="Select Branch"
            value={selectedEnvName}
            options={environments[repo]}
            onSelect={(key) => this.onEnvironmentChanged(key, repo)}
            disabled={isLoading}
          />
          {repo === REPO_AWS ? (
            <DropdownInput
              id={'stacks'}
              style={{ width: 250, marginBottom: 10, marginTop: 10 }}
              value={this.getSelectedStack(selectedStack)}
              options={awsStacks}
              onSelect={(key) => this.setState({ selectedStack: key })}
              disabled={isLoading}
            />
          ) : (
            <div style={{ height: 45 }} />
          )}
          <CheckBox label="Merge branch" isActive={mergeCode[repo]} onChange={() => this.onChangeMerge(repo)} />
          <div className="flex flex-row flex-between marginTop-8">
            <Button
              style={{ width: 120 }}
              inline
              buttonType="primary"
              onClick={() => this.onStartDeployment(repo, selectedEnv, selectedEnvName, selectedStack)}
              isActive={canDeploy}
            >
              Deploy
            </Button>
          </div>
          <div className="flex-1 marginTop-8" style={{ fontSize: 11 }}>
            {deployMessage[repo]}
          </div>
        </div>
      </div>
    );
  }

  renderVersionDeploy(repo, title) {
    const { upgradeMessage, upgradeVersions, upgrading, environmentLoading, mergeCode } = this.state;

    const isValid = !_.isEmpty(upgradeVersions[repo]) && !upgrading[repo] && !environmentLoading[repo];
    const value = upgradeVersions[repo] || '';
    return (
      <div>
        <div className="fontRegular fontSize-20 text-dark">{title}</div>
        <div className="flex flex-column" style={{ height: 180 }}>
          <GenericInput
            id={`version_${repo}`}
            className="marginTop-10"
            style={{ width: 250 }}
            type="text"
            alwaysShowLabel
            label="Version to upgrade"
            placeholder="1.2.3"
            isValid={() => isValid}
            value={value}
            onChange={(e) => this.onChangeVersion(e, repo)}
          />
          <CheckBox label="Merge branch" isActive={mergeCode[repo]} onChange={() => this.onChangeMerge(repo)} />
          <div className="flex flex-row flex-between marginTop-8">
            <Button
              style={{ width: 120 }}
              inline
              buttonType="primary"
              onClick={() => this.onUpgradeVersion(repo, value)}
              isActive={isValid}
            >
              Upgrade
            </Button>
          </div>
          <div className="flex-1 marginTop-8" style={{ fontSize: 11 }}>
            {upgradeMessage[repo]}
          </div>
        </div>
      </div>
    );
  }

  renderHistory(repo) {
    const deployments = this.state.deployments[repo] || [];
    return (
      <div className="marginBottom-24">
        <div className="fontRegular fontSize-16 text-dark marginBottom-16">History</div>
        {deployments.map((dep) => {
          const completed = dep.state === 'COMPLETED';
          const failed = dep.result === 'FAILED';
          const status = `${dep.state}${dep.stage ? ` - ${dep.stage}` : ''}${dep.result ? ` - ${dep.result}` : ''}`;
          return (
            <div key={dep.uuid} className="marginBottom-8" style={{ fontSize: 11 }}>
              <div className="flex flex-row flex-between">
                <div>Branch: {completed ? dep.branch : <b>{dep.branch}</b>}</div>
                <a href={dep.url} target="_blank" rel="noopener noreferrer">
                  {`View Build - #${dep.build_number}`}
                </a>
              </div>
              <div className="flex flex-row flex-between">
                <div>
                  Status: <span className={failed ? 'text-tang' : ''}>{completed ? status : <b>{status}</b>}</span>
                </div>
                <div>{moment(dep.created_on).format('DD/MM/YYYY HH:mm')}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderStep1 = () => {
    return (
      <div className="flex-1 automation">
        <div className="flex flex-column marginBottom-20">
          {this.renderBuildQueue()}
          {this.renderSelectorAll()}
        </div>
        <div className="flex flex-row marginBottom-32">
          <div className="paddingRight-32" style={{ width: '33%' }}>
            {this.renderRepoDeploy(REPO_AWS, 'Deploy AWS Branch')}
            {this.renderVersionDeploy(REPO_AWS, 'Upgrade AWS')}
            {this.renderHistory(REPO_AWS)}
          </div>
          <div className="paddingRight-32" style={{ width: '33%' }}>
            {this.renderRepoDeploy(REPO_WEB, 'Deploy Web Branch')}
            {this.renderVersionDeploy(REPO_WEB, 'Upgrade Web')}
            {this.renderHistory(REPO_WEB)}
          </div>
          <div className="paddingRight-32" style={{ width: '33%' }}>
            {this.renderRepoDeploy(REPO_APP, 'Deploy App Branch')}
            {this.renderVersionDeploy(REPO_APP, 'Upgrade App')}
            {this.renderHistory(REPO_APP)}
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    return this.renderStep1();
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(Deployment);
