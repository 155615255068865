import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { refreshAuthUser } from '../../actions';
import { isTheBest, validateAccess } from '../../session';
import { Button, CheckBox, GenericInput, GlobalHider, ImageInput, Popup, SVGIcon, Tabs, Text } from '../../components';
import fontOptions from '../../json/featurePicker/fontOptions.json';
import headerPatterns from '../../json/featurePicker/headerPatterns.json';
import FontAwesome from 'react-fontawesome';
import tinycolor from 'tinycolor2';
import { ChromePicker } from 'react-color';
import Switch from 'react-switch';
import { COLOUR_BRANDING_APP, COLOUR_BRANDING_MAIN, COLOUR_DUSK_LIGHTER, pctBtwnColours, TEXT_LIGHT } from '../../js';
import { typeActions } from '../../webapi';

class EditBranding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...typeActions.getDefaultSiteSettings(),
      patternOptions: _.cloneDeep(headerPatterns),
      selectedQuickChoice: 'vibrant',
    };
  }

  componentDidMount() {
    if (!validateAccess(this.props.auth.site, 'featurePicker', this.props.auth)) {
      this.props.history.push('/mastermenu');
    }
    this.getSiteSettings();
  }

  getSiteSettings() {
    this.setState({
      loading: true,
    });
    typeActions.getSite(this.props.auth.site).then((res) => {
      this.setState({
        site: res.data,
      });
      this.loadSiteState(res.data);
    });
  }

  loadSiteState(data) {
    let newState = {
      ...typeActions.getDefaultSiteSettings(),
      loading: false,
    };
    if (data && data.Settings) {
      newState.headerType = data.Settings.HeaderType || (!!data.Settings.UseGradientHeader ? 'gradient' : 'white');
      newState.footerType = data.Settings.FooterType || 'white';
      newState.footerColour = data.Settings.FooterColour;
      newState.patternColour = data.Settings.PatternColour;
      newState.usePatternColour = newState.headerType === 'pattern' && !!data.Settings.PatternColour;
      newState.selectedPattern = data.Settings.HeaderPattern;

      if (data.Settings.TabTitles) {
        newState.tabTitle = { ...this.state.tabTitle, ...data.Settings.TabTitles };
      }
      if (data.Settings.TabSettings) {
        newState.tabSettings = [...data.Settings.TabSettings];
        newState.tabSettings.forEach((t) => {
          if (!t.widgetOptions) {
            t.widgetOptions = [];
          }
        });
      }
    }
    if (data && data.Branding && data.Branding) {
      if (data.Branding.MainBrandingColour) {
        newState.colour = data.Branding.MainBrandingColour;
      }
      if (data.Branding.Logo) {
        newState.logo = data.Branding.Logo;
      }

      newState.capitaliseSiteName = data.Branding.CapitaliseSiteName || false;
      newState.siteNameColour = data.Branding.SiteNameColour || null;
      newState.customColourSiteName = newState.siteNameColour !== null;
      newState.letterSpacingSiteName = data.Branding.LetterSpacingSiteName || false;
      newState.selectedFont = data.Branding.SiteNameFont || null;

      newState.originalBranding = data.Branding;
    }
    if (data && !_.isEmpty(data.siteName)) {
      newState.siteNameInput = data.siteName;
    }
    this.setColourCircle(newState.colour || COLOUR_BRANDING_APP);

    this.setState(_.cloneDeep(newState));
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  setColourCircle(colour) {
    const tc = tinycolor(colour);
    this.setState({
      colourCircleTop: 100 - (100 * tc.getBrightness()) / 255,
      colourCircleLeft: (100 * tc.toHsv().h) / 360,
    });
  }

  getQuickChoiceTabs(type) {
    if (type === 'header') {
      return [
        {
          value: 'vibrant',
          text: 'Vibrant',
        },
        {
          value: 'soft',
          text: 'Soft',
        },
      ];
    }
    return [
      {
        value: 'vibrant',
        text: 'Vibrant',
      },
      {
        value: 'soft',
        text: 'Soft',
      },
      {
        value: 'colourPicker',
        text: 'Picker',
      },
    ];
  }

  getHeaderStyle() {
    if (!this.state.usePatternColour) {
      return { backgroundImage: `url(${this.state.selectedPattern})` };
    }
    return {
      '-webkit-mask-image': `url(${this.state.selectedPattern})`,
      maskImage: `url(${this.state.selectedPattern})`,
      backgroundColor: this.state.patternColour || '#fff',
      // '-webkit-mask-size': 'cover',
      // maskSize: 'cover',
      // maskPosition: 'center',
    };
  }

  getTabIconFromWidget(widget) {
    switch (widget) {
      case 'people':
      case 'surveys':
        return 'survey';
      case 'facilities':
        return 'foryou';
      default:
        return widget; // when icon name matches
    }
  }

  getTabKeyFromPage(page) {
    if (page.icon) {
      return page.icon;
    }
    if (page.type === 'home') {
      return 'home';
    }
    if (page.type === 'menu') {
      return 'more';
    }
    if (_.isEmpty(page.widgets)) {
      return 'empty';
    }
    return this.getTabIconFromWidget(page.widgets[0]);
  }

  changeFooter(value) {
    const newState = {
      footerType: value,
      optionsChanged: true,
    };

    if (!this.state.footerColour) {
      newState.footerColour = this.state.colour;
    }

    this.setState(newState);
  }

  selectQuickChoice(tab) {
    this.setState({
      selectedQuickChoice: tab,
    });
  }

  onLogoUpdated(url) {
    this.setState({
      logo: url,
      optionsChanged: true,
    });
  }

  onPatternUpdated(url) {
    if (!_.isEmpty(url)) {
      this.setState({
        patternOptions: _.uniqBy([...this.state.patternOptions, { url, title: _.last(url.split('/')) }], (v) => v.url),
        selectedPattern: url,
        optionsChanged: true,
      });
    }
  }

  openPatternOptions = () => {
    this.setState({
      patternOptionsOpen: true,
    });
  };

  closePatternOptions = () => {
    this.setState({
      patternOptionsOpen: false,
    });
  };

  selectPatternOption = (url) => {
    this.setState({
      selectedPattern: url,
    });
  };

  changeHeader(value) {
    this.setState({
      headerType: value,
      optionsChanged: true,
    });
  }

  toggleLogoPopup(isOpen) {
    this.setState({
      logoPopupOpen: isOpen,
    });
  }

  toggleFontPopup(isOpen) {
    this.setState({
      fontPopupOpen: isOpen,
    });
  }

  selectFont = (fontKey) => {
    this.setState({
      selectedFont: fontKey,
    });
  };

  toggleFooterPopup(isOpen) {
    this.setState({
      footerPopupOpen: isOpen,
    });
  }

  handleColourChange = (color) => {
    let colour = color;
    if (typeof color === 'object') {
      colour = color.hex;
    }
    this.setState({ colour, optionsChanged: true });
    this.setColourCircle(colour);
  };

  handlePatternColourChange = (color) => {
    let patternColour = color;
    if (typeof color === 'object') {
      patternColour = color.hex;
    }
    this.setState({ patternColour, optionsChanged: true });
  };

  handleFooterColourChange = (color) => {
    let footerColour = color;
    if (typeof color === 'object') {
      footerColour = color.hex;
    }
    this.setState({ footerColour, optionsChanged: true });
  };

  handleSiteNameColourChange = (color) => {
    let siteNameColour = color;
    if (typeof color === 'object') {
      siteNameColour = color.hex;
    }
    this.setState({ siteNameColour, optionsChanged: true });
  };

  getFont = (fontKey) => {
    let fontKeyToUse = fontKey || 'bj';
    if (!fontOptions[fontKeyToUse]) {
      fontKeyToUse = _.find(Object.keys(fontOptions), (f) => fontOptions[f].default);
    }
    return fontKeyToUse;
  };

  getHeaderTileClasses(type) {
    let classes = `featurePicker_mainSection_tile featurePicker_mainSection_tile-header featurePicker_mainSection_tile-header-${type}`;
    if (type === this.state.headerType) {
      classes += ' featurePicker_mainSection_tile-selected';
    }
    return classes;
  }

  getFooterTileClasses(type) {
    let classes = `featurePicker_mainSection_tile featurePicker_mainSection_tile-footer featurePicker_mainSection_tile-footer-${type}`;
    if (type === this.state.footerType) {
      classes += ' featurePicker_mainSection_tile-selected';
    }
    return classes;
  }

  validateBranding() {
    if (_.isEmpty(this.state.siteNameInput)) {
      return false;
    }
    return true;
  }

  validateForm() {
    if (this.state.submitting) {
      return false;
    }
    if (!this.validateBranding()) {
      return false;
    }
    return true;
  }

  closeFailedPopup = () => {
    this.setState({
      failed: false,
    });
    window.location.reload();
  };

  closeSuccessPopup = () => {
    this.setState({
      success: false,
    });
    window.location.reload();
  };

  compileData() {
    const settings = {};
    const branding = this.state.originalBranding || {};
    // branding colours
    if (branding.MainBrandingColour !== this.state.colour) {
      branding.MainBrandingColour = this.state.colour;
      branding.LightBrandingColour = '#' + tinycolor(pctBtwnColours('#fff', this.state.colour, 0.6)).toHex();
      branding.DarkBrandingColour = '#' + tinycolor(pctBtwnColours(this.state.colour, '#000', 0.8)).toHex();
    }

    settings.UseGradientHeader = this.state.headerType === 'gradient';
    settings.HeaderType = this.state.headerType;
    if (this.state.headerType === 'pattern') {
      settings.HeaderPattern = this.state.selectedPattern;
    }
    settings.FooterType = this.state.footerType;
    settings.FooterColour = this.state.footerColour;
    settings.PatternColour = this.state.usePatternColour ? this.state.patternColour : null;

    // logo
    if (!_.isEmpty(this.state.logo)) {
      branding.Logo = this.state.logo;
      settings.UseHeaderLogo = true;
    } else {
      settings.UseHeaderLogo = false;
      branding.Logo = null;
    }

    branding.CapitaliseSiteName = this.state.capitaliseSiteName;
    branding.SiteNameColour = this.state.customColourSiteName ? this.state.siteNameColour : undefined;
    branding.LetterSpacingSiteName = this.state.letterSpacingSiteName;
    branding.SiteNameFont = this.state.selectedFont;
    const defSettings = this.state.site ? this.state.site.Settings || {} : {};
    return {
      Settings: {
        ...defSettings,
        UseHeaderLogo: settings.UseHeaderLogo,
        HeaderType: settings.HeaderType,
        HeaderPattern: settings.HeaderPattern,
        FooterType: settings.FooterType,
        FooterColour: settings.FooterColour,
        PatternColour: settings.PatternColour,
      },
      siteName: this.state.siteNameInput,
      Branding: branding,
    };
  }
  cancelAppOptions = () => {
    if (this.state.optionsChanged && !window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
      return;
    }
    window.history.back();
  };

  handleSaveOptions = () => {
    this.setState({
      submitting: true,
    });
    typeActions
      .editSiteSettings(this.props.auth.site, this.compileData())
      .then((res) => {
        this.setState({
          submitting: false,
          success: true,
        });
      })
      .catch((error) => {
        this.setState({
          submitting: false,
          failed: true,
        });
        console.log('error', error);
      });
  };

  renderFooterPicker() {
    if (!isTheBest(this.props.auth, true)) {
      return null;
    }
    return (
      <div className="marginTop-20">
        <Button buttonType="boxed" buttonClassName="button--boxed-dusk" onClick={this.toggleFooterPopup.bind(this, true)} isActive>
          Customise Footer
        </Button>
      </div>
    );
  }

  renderColourPicker() {
    return (
      <div className="colourPicker">
        <div
          className="colourPicker_button"
          onClick={() => {
            this.setState({ colourPickerOpen: !this.state.colourPickerOpen });
          }}
        >
          <p className="colourPicker_text">Choose from Colour Picker</p>
          <div className="colourPicker_rainbow">
            <div
              className="colourPicker_rainbow_circle"
              style={{
                top: `${this.state.colourCircleTop}%`,
                left: `${this.state.colourCircleLeft}%`,
              }}
            ></div>
          </div>
        </div>
        <div
          className="colourPicker_button"
          onClick={() => {
            this.setState({ colourQuickChoicesOpen: !this.state.colourQuickChoicesOpen, selectedQuickChoice: 'vibrant' });
          }}
        >
          <p className="colourPicker_text">Choose from Quick Choices</p>
          <div className="colourPicker_previewCircles">
            <div className="colourPicker_previewCircles_circle colourPicker_previewCircles_circle-1"></div>
            <div className="colourPicker_previewCircles_circle colourPicker_previewCircles_circle-2"></div>
            <div className="colourPicker_previewCircles_circle colourPicker_previewCircles_circle-3"></div>
          </div>
        </div>
      </div>
    );
  }

  renderLogoSection() {
    if (this.state.logo) {
      return (
        <div className="marginTop-20 flex-reverse">
          <Button buttonType="boxed" buttonClassName="button--boxed-dusk" onClick={this.onLogoUpdated.bind(this, null)} isActive>
            Remove logo
          </Button>
          <div className="featurePicker_logoPreview" style={{ backgroundImage: `url('${this.state.logo}')` }} />
        </div>
      );
    }
    return (
      <div className="marginTop-20 flex flex-center flex-between">
        <Button buttonType="boxed" buttonClassName="button--boxed-dusk" onClick={this.toggleLogoPopup.bind(this, true)} isActive>
          Upload logo
        </Button>
        <Text type="h5">or</Text>
        <Button buttonType="boxed" buttonClassName="button--boxed-dusk" onClick={this.toggleFontPopup.bind(this, true)} isActive>
          Choose font
        </Button>
      </div>
    );
  }

  renderFontOptionButton(fontKey) {
    const isSelected = this.state.selectedFont === fontKey;

    return (
      <Button
        buttonType="boxed"
        buttonClassName="button--boxed-dusk"
        className="marginTop-10"
        onClick={() => {
          this.selectFont(fontKey);
        }}
        isActive
        leftIcon={isSelected ? 'check' : undefined}
      >
        {this.renderSiteName(fontKey)}
      </Button>
    );
  }

  renderFontPopup() {
    if (!this.state.fontPopupOpen) {
      return null;
    }
    return (
      <Popup
        buttons={[
          {
            type: 'primary',
            onClick: this.toggleFontPopup.bind(this, false),
            isActive: true,
            text: 'Done',
          },
        ]}
        maxHeight="80vh"
        minWidth={800}
        maxWidth="80vw"
        title="Choose font"
        hasPadding
      >
        <div className="marginTop-20 flex">
          <div className="flex-1 flex flex-column flex-center">
            {Object.keys(fontOptions).map((fontKey) => {
              return this.renderFontOptionButton(fontKey);
            })}
          </div>
          <div className="flex-1 flex flex-column paddingLeft-20">
            <CheckBox
              label="Capitalise site name"
              className="marginTop-10"
              isActive={this.state.capitaliseSiteName}
              onChange={() => {
                this.setState({ capitaliseSiteName: !this.state.capitaliseSiteName });
              }}
            />
            <CheckBox
              label="Space out letters"
              className="marginTop-10"
              isActive={this.state.letterSpacingSiteName}
              onChange={() => {
                this.setState({ letterSpacingSiteName: !this.state.letterSpacingSiteName });
              }}
            />
            <CheckBox
              label="Custom colour"
              className="marginTop-10"
              isActive={this.state.customColourSiteName}
              onChange={() => {
                this.setState({ customColourSiteName: !this.state.customColourSiteName });
              }}
            />
            {this.state.customColourSiteName && <div className="marginTop-10">{this.renderColourQuickChoices('siteName')}</div>}
          </div>
        </div>
      </Popup>
    );
  }

  renderLogoPopup() {
    if (!this.state.logoPopupOpen) {
      return null;
    }
    return (
      <Popup
        buttons={[
          {
            type: 'primary',
            onClick: this.toggleLogoPopup.bind(this, false),
            isActive: true,
            text: 'Done',
          },
        ]}
        maxHeight={450}
        maxWidth={650}
        title="Upload logo"
        hasPadding
      >
        <div className="featurePicker_popup_inner" style={{ display: 'block' }}>
          <div className="marginTop-20 flex">
            <div className="marginRight-20">
              <ImageInput
                ref="imageInput"
                containerStyle={{ width: 300, height: 220 }}
                style={{ width: 300, height: 220 }}
                noCompress
                noMenu
                refreshCallback={this.onLogoUpdated.bind(this)}
                hasDefault={this.state.logo}
              />
            </div>
            <div className="flex-1">
              <p className="featurePickerLogoPopup_guidelinesTitle">Logo guidelines:</p>
              <p className="featurePickerLogoPopup_guidelinesText">
                &bull; Your logo works best in png format with a transparent background.
              </p>
              <p className="featurePickerLogoPopup_guidelinesText">
                &bull; If you use gradient header, a single coloured logo in white with a transparent background is recommended.
              </p>
            </div>
          </div>
        </div>
      </Popup>
    );
  }

  renderFooterPopup() {
    if (!this.state.footerPopupOpen) {
      return null;
    }
    return (
      <Popup
        buttons={[
          {
            type: 'primary',
            onClick: this.toggleFooterPopup.bind(this, false),
            isActive: true,
            text: 'Done',
          },
        ]}
        title="Customise footer"
        hasPadding
        maxHeight="95vh"
      >
        <div className="featurePicker_popup_inner featurePicker-scaled">
          {this.renderPreview()}
          <div className="featurePickerRight featurePickerRight-options">
            <div
              className={this.getFooterTileClasses('white')}
              onClick={() => {
                this.changeFooter('white');
              }}
            >
              <div className="featurePicker_mainSection_tile_inner">
                <div className="featurePicker_mainSection_tile_footer">
                  <div className="featurePicker_mainSection_tile_footer_inner">
                    <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
                    <p className="featurePicker_mainSection_tile_footer_text">White</p>
                  </div>
                </div>
                <div className="featurePicker_mainSection_tile_image">
                  <div className="featurePicker_mainSection_tile_image_inner"></div>
                </div>
              </div>
            </div>
            <div
              className={this.getFooterTileClasses('block')}
              onClick={() => {
                this.changeFooter('block');
              }}
            >
              <div className="featurePicker_mainSection_tile_inner">
                <div className="featurePicker_mainSection_tile_footer">
                  <div className="featurePicker_mainSection_tile_footer_inner">
                    <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
                    <p className="featurePicker_mainSection_tile_footer_text">Block</p>
                  </div>
                </div>
                <div className="featurePicker_mainSection_tile_image">
                  <div className="featurePicker_mainSection_tile_image_inner"></div>
                </div>
              </div>
            </div>

            {this.state.footerType !== 'white' && (
              <div className="flex flex-center flex-column marginTop-40">{this.renderColourQuickChoices('footer')}</div>
            )}
          </div>
        </div>
      </Popup>
    );
  }

  renderColourPickerPopup() {
    if (!this.state.colourPickerOpen) {
      return null;
    }
    return (
      <Popup
        title={`Colour Picker`}
        maxWidth={600}
        hasPadding
        buttons={[
          {
            type: 'primary',
            onClick: () => {
              this.setState({ colourPickerOpen: !this.state.colourPickerOpen });
            },
            isActive: true,
            text: 'Done',
          },
        ]}
      >
        <div className="flex flex-center">
          <ChromePicker
            color={this.state.colour}
            onChangeComplete={this.handleColourChange}
            disableAlpha
            className="chrome-picker-noShadow"
          />
        </div>
      </Popup>
    );
  }

  renderColourQuickChoicesCircle(colour, selectedColour, type) {
    return (
      <div
        className={`colourQuickChoices_circle${selectedColour === colour ? ' colourQuickChoices_circle-selected' : ''}`}
        style={{ backgroundColor: colour }}
        onClick={() => {
          if (type === 'footer') {
            this.handleFooterColourChange(colour);
          } else if (type === 'pattern') {
            this.handlePatternColourChange(colour);
          } else if (type === 'siteName') {
            this.handleSiteNameColourChange(colour);
          } else {
            this.handleColourChange(colour);
          }
        }}
      >
        <FontAwesome name="check" className="colourQuickChoices_circle_tick" />
      </div>
    );
  }

  renderColourQuickChoices(type) {
    let content;
    let selectedColour = this.state.colour;
    if (type === 'footer') {
      selectedColour = this.state.footerColour;
    } else if (type === 'pattern') {
      selectedColour = this.state.patternColour || '#fff';
    } else if (type === 'siteName') {
      selectedColour = this.state.siteNameColour;
    }
    switch (this.state.selectedQuickChoice) {
      case 'colourPicker':
        content = (
          <div className="flex flex-between colourQuickChoices">
            <ChromePicker
              color={selectedColour}
              onChangeComplete={(colour) => {
                if (type === 'footer') {
                  this.handleFooterColourChange(colour);
                } else if (type === 'pattern') {
                  this.handlePatternColourChange(colour);
                } else if (type === 'siteName') {
                  this.handleSiteNameColourChange(colour);
                }
              }}
              disableAlpha
              className="chrome-picker-noShadow"
            />
          </div>
        );
        break;
      case 'vibrant':
        content = (
          <div className="flex flex-between colourQuickChoices">
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#c44569', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#f1a459', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#38ada9', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#7064a7', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#ea4c88', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#e8bb67', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#60a3bc', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#546de5', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#e66767', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#94d0c5', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#5badda', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#dfad8c', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#ff6c5c', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#7abf9e', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#9fcfd9', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#dd91a7', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#f19066', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#a1ab94', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#a67db7', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#596275', selectedColour, type)}
            </div>
          </div>
        );
        break;
      case 'soft':
        content = (
          <div className="flex flex-between colourQuickChoices">
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#eacdd0', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#a4c9d7', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#c6b9c0', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#cdc4af', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#d6b7b1', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#eaf2f4', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#cce0db', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#b9bee4', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#9bd3cb', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#f1d7bb', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#fcbc7b', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#caeff5', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#e0dceb', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#b1becd', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#e6cfc5', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#cfd6df', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#f39ca4', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#c0adc5', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#fcbca4', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#c5ceb9', selectedColour, type)}
            </div>
          </div>
        );
        break;
      default:
        content = null;
        break;
    }
    return (
      <div>
        <Tabs
          onSelectTab={this.selectQuickChoice.bind(this)}
          selectedTab={this.state.selectedQuickChoice}
          tabs={this.getQuickChoiceTabs(type)}
          containerStyle={type === 'header' ? { justifyContent: 'center', paddingLeft: 30 } : {}}
        />
        {content}
      </div>
    );
  }

  renderColourQuickChoicesPopup() {
    if (!this.state.colourQuickChoicesOpen) {
      return null;
    }
    return (
      <Popup
        title={`Quick Choices`}
        maxWidth={500}
        hasPadding
        buttons={[
          {
            type: 'primary',
            onClick: () => {
              this.setState({ colourQuickChoicesOpen: !this.state.colourQuickChoicesOpen });
            },
            isActive: true,
            text: 'Done',
          },
        ]}
      >
        {this.renderColourQuickChoices('header')}
      </Popup>
    );
  }

  renderPatternsOption(url, title, i) {
    return (
      <div
        className={`featurePicker_option featurePicker_option-pattern${
          this.state.selectedPattern === url ? ' featurePicker_option-selected' : ''
        }`}
        onClick={() => {
          this.selectPatternOption(url);
        }}
        key={i}
      >
        <div className="featurePicker_mainSection_tile_inner">
          <div className="featurePicker_mainSection_tile_footer">
            <div className="featurePicker_mainSection_tile_footer_inner">
              <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
              <p className="featurePicker_mainSection_tile_footer_text truncate">{title}</p>
            </div>
          </div>
          <div className="featurePicker_mainSection_tile_image">
            <div
              className="featurePicker_mainSection_tile_image_inner"
              style={{ backgroundImage: `url(${url})`, backgroundColor: this.state.colour }}
            ></div>
          </div>
        </div>
      </div>
    );
  }

  renderPatternOptionsPopup() {
    if (!this.state.patternOptionsOpen) {
      return null;
    }
    return (
      <Popup
        buttons={[
          {
            type: 'primary',
            onClick: this.closePatternOptions,
            isActive: true,
            text: 'Done',
          },
        ]}
        onClose={this.closePatternOptions}
        hasPadding
      >
        <div className="featurePicker_popup_inner">
          {this.renderPreview()}
          <div className="featurePickerRight featurePickerRight-options">
            <div className="featurePicker_popup_titleContainer" style={{ paddingLeft: 10 }}>
              <span className="popup_title featurePicker_popup_title-faded">App Options</span>
              <FontAwesome name="angle-right" className="featurePicker_popup_titleIcon featurePicker_popup_title-faded" />
              <span className="popup_title">Patterns</span>
            </div>
            <div className="featurePicker_mainSection featurePicker_mainSection-grid">
              <div className="featurePicker_mainSection_widgetContainer">
                <div className="featurePicker_mainSection_empty">
                  <div className="flex">
                    <Switch
                      className="featurePicker_gridToggle_toggle"
                      checked={this.state.usePatternColour}
                      onChange={(shouldUse) => {
                        this.setState({ usePatternColour: shouldUse });
                      }}
                      onColor={COLOUR_BRANDING_MAIN}
                      offColor={COLOUR_DUSK_LIGHTER}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      height={26}
                      width={50}
                    />
                    <p className="featurePicker_gridToggle_label">Custom Pattern Colour</p>
                  </div>
                  {this.state.usePatternColour && <div className="marginTop-16">{this.renderColourQuickChoices('pattern')}</div>}
                </div>
                {_.map(this.state.patternOptions, (p, i) => {
                  return this.renderPatternsOption(p.url, p.title || `Pattern ${i + 1}`, i);
                })}
                {isTheBest(this.props.auth, true) && (
                  <ImageInput
                    ref="patternInput"
                    style={{ width: 250, height: 250 }}
                    containerStyle={{ display: 'inline-block', width: 250, height: 250 }}
                    noCompress
                    refreshCallback={this.onPatternUpdated.bind(this)}
                    simpleStyle
                    noMenu
                    disableRemove
                    onlyAllowUpload
                    noDownload
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }

  renderHeader() {
    return (
      <div className="featurePicker_headerTray">
        <FontAwesome className="featurePicker_back" name="angle-left" onClick={this.cancelAppOptions} />
        <Text type="formTitleLarge" className="featurePicker_pageTitle">
          App Options
        </Text>
        <div className="flex flex-1 flex-center flex-reverse">
          <Button inline buttonType="primary" onClick={this.handleSaveOptions} isActive>
            Save Options
          </Button>
        </div>
      </div>
    );
  }

  renderPage() {
    return (
      <div className="featurePickerPage">
        <div className="flex">
          <div className="featurePicker_appOptionsLeft">
            <div className="featurePicker_titleContainer">
              <div className="featurePicker_sectionTitle">
                <img
                  className="featurePicker_sectionTitle_image"
                  alt="features"
                  src="https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/3cc483f6441cb0395c6a9526e8/mainitem.png"
                />
                <p className="featurePicker_sectionTitle_text">App Information</p>
              </div>
            </div>
            <div className="featurePicker_nameLogoContainer">
              <GenericInput
                className="genericInputContainer-largeLabel"
                id="siteNameInput"
                type="text"
                value={this.state.siteNameInput}
                onChange={(e) => this.handleChange(e)}
                placeholder="Your Community Name here"
                label="Name"
                alwaysShowLabel
              />
              {this.renderLogoSection()}
            </div>
            {this.renderColourPicker()}
            {this.renderFooterPicker()}
          </div>
          <div className="flex-1 paddingLeft-40">
            <div className="featurePicker_titleContainer">
              <div className="featurePicker_sectionTitle">
                <img
                  className="featurePicker_sectionTitle_image"
                  alt="style"
                  src="https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/5d92f926440cb4df438d52996d/paintbrush.png"
                />
                <p className="featurePicker_sectionTitle_text">Header Style</p>
              </div>
            </div>
            <div>
              <div
                className={this.getHeaderTileClasses('white')}
                onClick={() => {
                  this.changeHeader('white');
                }}
              >
                <div className="featurePicker_mainSection_tile_inner">
                  <div className="featurePicker_mainSection_tile_footer">
                    <div className="featurePicker_mainSection_tile_footer_inner">
                      <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
                      <p className="featurePicker_mainSection_tile_footer_text">White</p>
                    </div>
                  </div>
                  <div className="featurePicker_mainSection_tile_image">
                    <div className="featurePicker_mainSection_tile_image_inner"></div>
                  </div>
                </div>
              </div>
              <div
                className={this.getHeaderTileClasses('block')}
                onClick={() => {
                  this.changeHeader('block');
                }}
              >
                <div className="featurePicker_mainSection_tile_inner">
                  <div className="featurePicker_mainSection_tile_footer">
                    <div className="featurePicker_mainSection_tile_footer_inner">
                      <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
                      <p className="featurePicker_mainSection_tile_footer_text">Block</p>
                    </div>
                  </div>
                  <div className="featurePicker_mainSection_tile_image">
                    <div className="featurePicker_mainSection_tile_image_inner"></div>
                  </div>
                </div>
              </div>
              <div
                className={this.getHeaderTileClasses('gradient')}
                onClick={() => {
                  this.changeHeader('gradient');
                }}
              >
                <div className="featurePicker_mainSection_tile_inner">
                  <div className="featurePicker_mainSection_tile_footer">
                    <div className="featurePicker_mainSection_tile_footer_inner">
                      <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
                      <p className="featurePicker_mainSection_tile_footer_text">Gradient</p>
                    </div>
                  </div>
                  <div className="featurePicker_mainSection_tile_image">
                    <div className="featurePicker_mainSection_tile_image_inner"></div>
                  </div>
                </div>
              </div>
              <div
                className={this.getHeaderTileClasses('pattern')}
                onClick={() => {
                  this.changeHeader('pattern');
                }}
              >
                <div className="featurePicker_mainSection_tile_inner">
                  <div className="featurePicker_mainSection_tile_footer">
                    <div className="featurePicker_mainSection_tile_footer_inner">
                      <Button
                        inline
                        buttonType="primary"
                        className="featurePicker_mainSection_tile_footer_settingsButton"
                        onClick={this.openPatternOptions}
                        isActive={true}
                      >
                        Settings
                      </Button>
                      <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
                      <p className="featurePicker_mainSection_tile_footer_text">Pattern</p>
                    </div>
                  </div>
                  <div className="featurePicker_mainSection_tile_image">
                    <div className="featurePicker_mainSection_tile_image_inner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSiteName(fontKey) {
    const fontKeyToUse = this.getFont(fontKey);
    return (
      <span
        className={`${fontOptions[fontKeyToUse].className}${this.state.capitaliseSiteName ? ' text-upper' : ''}${
          this.state.letterSpacingSiteName ? ' letterSpacing-1' : ''
        }`}
        style={
          this.state.customColourSiteName
            ? {
                color: this.state.siteNameColour,
              }
            : null
        }
      >
        {this.state.siteNameInput}
      </span>
    );
  }

  renderPreviewFooter() {
    if (
      (this.state.tabSettings.length === 1 ||
        _.filter(this.state.tabSettings, (t) => {
          return t.isEnabled;
        }).length === 1) &&
      !this.state.footerPopupOpen
    ) {
      return null;
    }
    const textColour = this.state.footerType !== 'white' ? '#fff' : TEXT_LIGHT;
    const selectedColour = this.state.footerType !== 'white' ? '#fff' : this.state.colour;
    // const selectedBGColour = this.state.footerType !== 'white' ? 'rgba(255,255,255,0.2)' : '#fff';
    return (
      <div className={`featurePickerPreview_footer featurePickerPreview_footer-${this.state.footerType}`}>
        {this.state.footerType === 'block' && (
          <div className="featurePickerPreview_footer_background" style={{ backgroundColor: this.state.footerColour }}></div>
        )}
        {this.state.footerType === 'gradient' && (
          <div className="featurePickerPreview_footer_background" style={{ backgroundColor: this.state.footerColour }}></div>
        )}
        {this.state.tabSettings.map((t) => {
          if (!t.isEnabled) {
            return null;
          }
          return (
            <div
              className={`featurePickerPreview_footer_icon${
                this.state.selectedTab === t.key ? ' featurePickerPreview_footer_icon-selected' : ''
              }`}
              key={t.key}
              // style={{ backgroundColor: this.state.selectedTab === t.key ? selectedBGColour : COLOUR_TRANSPARENT }}
            >
              <div className="featurePickerPreview_footer_icon_svg">
                <SVGIcon icon={this.getTabKeyFromPage(t)} colour={this.state.selectedTab === t.key ? selectedColour : textColour} />
              </div>
              <p
                className="featurePickerPreview_footer_icon_title"
                style={{ color: this.state.selectedTab === t.key ? selectedColour : textColour }}
              >
                {t.tabTitle}
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  renderPreview() {
    const header = (
      <div
        className={`featurePickerPreview_header featurePickerPreview_header-${this.state.headerType} featurePickerPreview_header-editBranding`}
        style={{
          backgroundColor: this.state.colour,
          borderBottomWidth: 1,
        }}
      >
        {this.state.headerType === 'pattern' && (
          <div className="featurePickerPreview_header_background" style={this.getHeaderStyle()}></div>
        )}
        <div className="featurePickerPreview_header_image"></div>
        {this.state.logo ? (
          <div className="featurePickerPreview_header_logoContainer">
            <div className="featurePickerPreview_header_logo" style={{ backgroundImage: `url('${this.state.logo}')` }} />
          </div>
        ) : (
          <p
            className="featurePickerPreview_header_text"
            style={{
              color: this.state.headerType === 'white' ? this.state.colour : '#fff',
              fontSize: this.state.siteNameInput.length < 12 ? 22 : 16,
            }}
          >
            {this.renderSiteName(this.state.selectedFont)}
          </p>
        )}
      </div>
    );
    return (
      <div className="featurePickerPreview">
        <div className="featurePickerPreview_inner">
          {header}
          <div className="featurePickerPreview_main featurePickerPreview_main-branding">
            {!_.isEmpty(this.state.logo) ? (
              <img src={this.state.logo} alt={this.state.siteNameInput} className="featurePickerPreview_main_logo" />
            ) : (
              <p className="featurePickerPreview_main_title" style={{ color: this.state.colour }}>
                {this.renderSiteName(this.state.selectedFont)}
              </p>
            )}
          </div>
          {this.renderPreviewFooter()}
        </div>
      </div>
    );
  }

  renderSuccessPopup() {
    if (this.state.failed) {
      return (
        <Popup
          title="Something went wrong"
          hasPadding
          minWidth={400}
          minHeight={200}
          buttons={[
            {
              type: 'primary',
              onClick: this.closeFailedPopup,
              isActive: true,
              text: 'Close',
            },
          ]}
        />
      );
    }
    if (this.state.submitting) {
      return <Popup title="Saving..." hasPadding minWidth={400} minHeight={200} />;
    }
    if (!this.state.success) {
      return null;
    }
    return (
      <Popup
        title="Saved"
        subtitle="Your branding has been saved!"
        hasPadding
        minWidth={400}
        minHeight={200}
        buttons={[
          {
            type: 'primary',
            onClick: this.closeSuccessPopup,
            isActive: true,
            text: 'Done',
          },
        ]}
      />
    );
  }

  render() {
    return (
      <div className="pageContainer relative flex flex-column featurePicker-appOptions">
        <GlobalHider chatbot sideMenu />
        {this.renderFontPopup()}
        {this.renderLogoPopup()}
        {this.renderFooterPopup()}
        {this.renderColourPickerPopup()}
        {this.renderColourQuickChoicesPopup()}
        {this.renderPatternOptionsPopup()}
        {this.renderSuccessPopup()}
        {this.renderHeader()}
        <div className="featurePicker" style={{ visibility: this.state.loading ? 'hidden' : 'visible' }}>
          <div className="featurePickerLeft">{this.renderPreview()}</div>
          <div className="featurePickerRight paddingBottom-20">
            <div className="featurePickerRight_appOptionsCaret"></div>
            {this.renderPage()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { refreshAuthUser })(withRouter(EditBranding));
