import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Text, ExportCsvPopup, StatBox } from '..';
import { faAt, faEye, faImage, faLink, faPhone, faUser, faUserTie, faUsers } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { analyticsActions } from '../../webapi';
import { countActivities } from '../../js';
import { validateAccess } from '../../session';

const getInitialState = () => ({
  uploads: 0,
  prevUploads: 0,
  staffUploads: 0,
  prevStaffUploads: 0,
  residentUploads: 0,
  prevResidentUploads: 0,
  isLoading: true,
});

// GalleryAnalytics Component
const GalleryAnalytics = ({ startTime, endTime, auth, prevText, dayCount }) => {
  const [analyticsData, setAnalyticsData] = useState(getInitialState());
  const [isExportOpen, setIsExportOpen] = useState(false);

  const hasAccess = validateAccess(auth.site, 'manageImageLibrary', auth);
  if (!hasAccess) {
    return null;
  }

  const exportColumns = [
    { label: 'Select All', key: '' },
    { label: 'Start Date', key: 'startDate' },
    { label: 'End Date', key: 'endDate' },
    { label: 'Uploads', key: 'uploads' },
    { label: 'Staff Uploads', key: 'staffUploads' },
    { label: 'Resident Uploads', key: 'residentUploads' },
  ];

  useEffect(() => {
    getData();
  }, [startTime, endTime]);

  const getData = async () => {
    setAnalyticsData(getInitialState());
    // Load analytics data here using startTime and endTime
    const timeDifference = endTime - startTime;
    const [currentStatsResponse, prevStatsResponse] = await Promise.all([
      analyticsActions.getAggregateEntityStats(auth.site, 'gallery', startTime, endTime, true),
      analyticsActions.getAggregateEntityStats(auth.site, 'gallery', startTime - timeDifference, startTime, true),
    ]);

    const data = {
      uploads: countActivities(currentStatsResponse.data, 'GalleryUpload', 'total'),
      prevUploads: countActivities(prevStatsResponse.data, 'GalleryUpload', 'total'),
      residentUploads: countActivities(currentStatsResponse.data, 'ResidentGalleryUpload', 'total'),
      prevResidentUploads: countActivities(prevStatsResponse.data, 'ResidentGalleryUpload', 'total'),
      staffUploads: countActivities(currentStatsResponse.data, 'StaffGalleryUpload', 'total'),
      prevStaffUploads: countActivities(prevStatsResponse.data, 'StaffGalleryUpload', 'total'),
      isLoading: false,
    };
    setAnalyticsData(data);
  };

  const isReadyToOpenCSV = () => {
    return !analyticsData.isLoading;
  };

  const getExportSource = () => {
    return [
      {
        startDate: moment(startTime + 1).format('D-MM-YYYY'),
        endDate: moment(endTime).format('D-MM-YYYY'),
        uploads: analyticsData.uploads,
        staffUploads: analyticsData.staffUploads,
        residentUploads: analyticsData.residentUploads,
      },
    ];
  };

  const csvPopup = () => {
    if (!isExportOpen) {
      return null;
    }
    const source = getExportSource();
    return (
      <ExportCsvPopup
        onClose={() => {
          setIsExportOpen(false);
        }}
        columns={exportColumns}
        source={source}
        filename={`galleryanalytics_${source[0].startDate}_${source[0].endDate}.csv`}
      />
    );
  };

  return (
    <div className="dashboardSection">
      {csvPopup()}
      <div>
        <Text type="h4" className="inlineBlock marginRight-40">
          Gallery
        </Text>
        <Button
          inline
          buttonType="primaryAction"
          onClick={() => {
            if (!isReadyToOpenCSV()) return;
            setIsExportOpen(true);
          }}
          isActive={isReadyToOpenCSV()}
          leftIcon="file-code-o"
        >
          Export CSV
        </Button>
      </div>
      <div className="analyticsSection dashboardSection_content">
        <StatBox
          title="Uploads"
          icon={faImage}
          value={analyticsData.uploads}
          previousValue={analyticsData.prevUploads}
          prevText={prevText}
          viewGraphLink={`/chart?entity=gallery&startTime=${startTime}&endTime=${endTime}&key=GalleryUpload&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Staff Uploads"
          icon={faUserTie}
          value={analyticsData.staffUploads}
          previousValue={analyticsData.prevStaffUploads}
          prevText={prevText}
          viewGraphLink={`/chart?entity=gallery&startTime=${startTime}&endTime=${endTime}&key=StaffGalleryUpload&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Primary User Uploads"
          icon={faUsers}
          value={analyticsData.residentUploads}
          previousValue={analyticsData.prevResidentUploads}
          prevText={prevText}
          viewGraphLink={`/chart?entity=gallery&startTime=${startTime}&endTime=${endTime}&key=ResidentGalleryUpload&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

const toExport = connect(mapStateToProps, {})(GalleryAnalytics);
export { toExport as GalleryAnalytics };
