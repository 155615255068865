import _ from 'lodash';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { extensionMoreSections, extensionAliases, PlussCore } from '../config/features';
import { getUrlParams } from '../helper';

export const getSessionTokenAWS = (prefix) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((data) => {
        const token = data.getAccessToken().getJwtToken();
        if (_.isUndefined(prefix)) {
          resolve(token);
        } else {
          resolve(`${prefix} ${token}`);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getRefreshTokenAWS = async () => {
  const data = await Auth.currentSession();
  return data.getRefreshToken().token;
};

export const getCurrentUser = async () => {
  return await Auth.currentAuthenticatedUser();
};

export const generateSoftwareToken = async (user) => {
  const code = await Auth.setupTOTP(user);
  return code;
};

export const authedFunction = async (request) => {
  return new Promise((resolve, reject) => {
    getSessionTokenAWS()
      .then((authkey) => {
        if (!request.headers) {
          request.headers = {};
        }
        request.headers.authkey = authkey;
        // if (process.env.NODE_ENV !== 'production') console.log('Request', request);
        axios(request)
          .then((res) => {
            // if (process.env.NODE_ENV !== 'production') console.log('Request', request, 'Response', res);
            resolve(res);
          })
          .catch((err) => {
            // if (process.env.NODE_ENV !== 'production') console.log('Error - Request', request);
            reject(err);
          });
      })
      .catch((err) => {
        console.log('authedFunction error', err, request);
        //attempt unauthed request
        axios(request)
          .then((res) => {
            // if (process.env.NODE_ENV !== 'production') console.log('Request', request, 'Response', res);
            resolve(res);
          })
          .catch((err) => {
            // if (process.env.NODE_ENV !== 'production') console.log('Error - Request', request);
            reject(err);
          });
      });
  });
};

export const unauthedFunction = (request) => {
  return axios(request);
};

export const getCurrentUserSub = () => {
  return new Promise((resolve, reject) => {
    Auth.currentUserInfo()
      .then((user) => {
        resolve(user.id);
      })
      .catch((err) => {
        resolve(null);
      });
  });
};

export const checkLoggedIn = (self, auth) => {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      if (_.isUndefined(user) || user == null || user.username == null) {
        console.log('redirecting to login');
        self && self.props && self.props.history && self.props.history.push('/login');
      }
    })
    .catch((err) => {
      if (err === 'not authenticated') {
        console.log('redirecting to logout');
        self && self.props && self.props.history && self.props.history.push('/logout');
      }
    });
};

export const checkLogInScreen = (self, auth) => {
  if (auth.auth) {
    const urlParams = getUrlParams();
    if (urlParams && urlParams.redirect) {
      self.props.history.push(decodeURIComponent(urlParams.redirect));
    } else if (auth.auth === 'KIOSK') {
      console.log('redirecting to tv');
      self.props.history.push('/tv');
    } else {
      console.log('redirecting to mastermenu');
      self.props.history.push('/mastermenu');
    }
  }
};

export const getAdminTypes = (additionalTypes) => {
  if (!additionalTypes) {
    additionalTypes = [];
  }
  return ['admin', 'master', 'villageStaff', ...additionalTypes];
};

export const isAdmin = (auth) => {
  return isAuthed(auth, getAdminTypes(null));
};

export const isAdminMaster = (auth) => {
  return auth.auth === 'master';
};

export const isAuthed = (auth, validTypes) => {
  return _.some(validTypes, (type) => {
    return type === auth.auth;
  });
};

export const isTheBest = (auth, isPlussSpaceMaster) => {
  if (auth && auth.user) {
    if (isPlussSpaceMaster) {
      // only plussSpace master
      return validateAccess('plussSpace', 'master', auth);
    }
    // any master
    return validateAccess('hq', 'master', auth);
  }
  return false;
};

export const getRolesWithAccess = (roles, type) => {
  return _.filter(roles, (role) => {
    return role.type === 'master' || (!_.isEmpty(role.Permission) && _.includes(role.Permissions, type));
  });
};

export const mapRolesToSites = (roles) => {
  return _.sortBy(
    roles.map((r) => {
      return {
        Id: r.site,
        Key: r.site,
        siteName: (r.siteInfo && r.siteInfo.siteName) || r.site,
        Title: (r.siteInfo && r.siteInfo.siteName) || r.site,
      };
    }),
    (r) => {
      return r.siteName.toLowerCase();
    },
  );
};

export const getCurrentUserType = (auth) => {
  if (!auth) {
    return {};
  }
  const roles = auth && auth.user ? auth.user.Roles : [];
  const role = _.find(roles, (r) => {
    return r.site === auth.site;
  });
  if (!role) {
    // catch for no role
    return { typeName: auth.auth, displayName: auth.auth, site: auth.site };
  }
  const userType = _.find(auth.userTypes || [], (ut) => {
    return ut.site === role.site && ut.typeName === role.type;
  });
  if (!userType) {
    return { typeName: role.type, displayName: role.type, site: role.site };
  }
  return userType;
};

export const validateAccess = (site, type, auth, noRole) => {
  const roles = auth && auth.user ? auth.user.Roles : [];
  if (_.isEmpty(roles)) {
    // catch for no roles
    return noRole;
  }
  if (site === 'plussSpace' && type === 'master') {
    // must be plussSpace master
    return _.some(roles, (r) => {
      return r.site === 'plussSpace' && r.type === 'master';
    });
  }
  if (
    _.some(roles, (r) => {
      return r.type === 'master';
    })
  ) {
    // accept any master
    return true;
  }
  const role = _.find(roles, (r) => {
    return r.site === site;
  });
  if (!role) {
    // user does not have a role in that site
    return false;
  }
  if (type === 'any') {
    // access to that site is valid
    return true;
  }
  // validate role has permission
  return !_.isEmpty(role.Permissions) && _.includes(role.Permissions, type);
};

export const getApiError = (error) => {
  // console.log('getApiError', error.response.data);
  try {
    const errorData = error.response.data;
    const errorMessage = errorData && errorData.error;
    return errorMessage || errorData || error;
  } catch (e) {
    return error;
  }
};

export const getEnabledFeatures = (siteInfo, interfaces) => {
  const interfaceSettings = !_.isEmpty(interfaces)
    ? interfaces.map((t) => {
        if (t.Type === 'TV') {
          return t;
        }
        return t.Settings;
      })
    : [];

  const siteSettings = siteInfo && siteInfo.Settings ? siteInfo.Settings : {};
  const hidden = siteInfo && siteInfo.Hidden ? siteInfo.Hidden : [];
  const notHidden = siteInfo && siteInfo.NotHidden ? siteInfo.NotHidden : [];
  const defaultFeatures = ['users', 'alerts'];
  let features = [...defaultFeatures];
  if (siteSettings.TabSettings) {
    interfaceSettings.push(siteSettings.TabSettings);
  } else {
    features = [...features, ...(siteSettings.HomeWidgets || ['events', 'news'])];
    if (!_.includes(hidden, 'whatsOn')) {
      // second tab is enabled - add features
      features.push(siteSettings.MainWidget1 || 'events');
    }
    if (!_.includes(hidden, 'marketplace')) {
      // third tab is enabled - add features
      features = [...features, ...(siteSettings.Widgets2 || ['services', 'offers', 'facilities'])];
    }
    if (!_.includes(hidden, 'people')) {
      // fourth tab is enabled - add features
      features.push(siteSettings.MainWidget3 || 'people');
    }
  }

  interfaceSettings.forEach((s) => {
    if (!Array.isArray(s) && s.Type && s.Type === 'TV') {
      // TV Views
      features = [
        ...features,
        ...s.Settings.widgets.map((w) => {
          return w.key;
        }),
      ];
    } else {
      // App Views
      (s || []).forEach((t) => {
        if (t.isEnabled) {
          features = [...features, ...t.widgets];
        }
      });
    }
  });

  if (
    (siteSettings.TabSettings &&
      _.some(siteSettings.TabSettings, (t) => {
        return t.type === 'menu' && t.isEnabled;
      })) ||
    (!siteSettings.TabSettings && !_.includes(hidden, 'more'))
  ) {
    // more tab is enabled - add features
    extensionMoreSections.forEach((m) => {
      if (m && m.hiddenKey && m.featureKey && !_.includes(hidden, m.hiddenKey)) features.push(m.featureKey);
    });
    if (!_.includes(hidden, 'infoPages')) {
      features.push('infoPages');
    }
    if (!_.includes(hidden, 'maps')) {
      features.push('maps');
    }
    if (!_.includes(hidden, 'importantContacts')) {
      features.push('importantContacts');
    }
    if (!_.includes(hidden, 'feedback')) {
      features.push('feedback');
    }
    if (!_.includes(hidden, 'surveys')) {
      features.push('surveys');
    }
  }
  if (_.includes(notHidden, 'visitors')) {
    features.push('visitors');
  }
  if (_.includes(features, 'services')) {
    features.push('sponsors');
  }
  return _.uniq(features);
};

export const isFeatureEnabled = (features, key) => {
  if (_.includes(features, key)) return true;
  const alias = extensionAliases.find((a) => a.aliases.includes(key));
  // console.log(`isFeatureEnabled - key: ${key}, alias:`, alias);
  if (alias) return _.includes(features, alias.key);

  switch (key) {
    case 'userManagement':
      return _.includes(features, 'users');
    case 'polls':
      return _.includes(features, 'surveys');
    case 'terms':
      return true;
    default:
      return false;
  }
};

export const isTVEnabled = PlussCore.Session.isTVEnabled;
