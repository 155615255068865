import React, { Component } from 'react';
import { isIOS, isAndroid, isMacOs, deviceDetect } from 'react-device-detect';
import _ from 'lodash';
import { automationActions } from '../../../webapi';

const LINK_NOT_SET = 'STORE URL NOT SET';

class Install extends Component {
  constructor(props) {
    super(props);

    this.state = { message: 'Please wait...' };
  }

  componentDidMount() {
    this.getAppInfo();
  }

  getAppInfo = async () => {
    const url = new URL(window.location.href);
    const clientCode = url.searchParams.get('c') || url.searchParams.get('code') || url.hostname;
    // console.log('getAppInfo', { clientCode, isIOS, isAndroid, isMacOs, device: deviceDetect() });
    try {
      const { data } = await automationActions.getAppInfo(clientCode);
      // console.log('getAppInfo', data);
      let message = '';
      if (data) {
        if (isIOS || isMacOs) {
          if (data.IOSUrl) window.location.href = data.IOSUrl;
          message = `Redirecting to App Store - ${data.IOSUrl || LINK_NOT_SET}`;
        } else if (isAndroid) {
          if (data.AndroidUrl) window.location.href = data.AndroidUrl;
          message = `Redirecting to Google Play Store - ${data.AndroidUrl || LINK_NOT_SET}`;
        } else {
          const device = deviceDetect();
          message = `${device.osName} is not supported`;
        }
      } else {
        message = `${clientCode} does not exist`;
      }
      this.setState({ message });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return <div className="flex flex-center flex-center-row height-100p">{this.state.message}</div>;
  }
}

export default Install;
