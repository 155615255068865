import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Switch from 'react-switch';
import { Button, GlobalHider, Popup, SVGIcon, Tabs, Text } from '../../../components';
import { withRouter } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DraggableWidget from './DraggableWidget';
import tabIcons from '../../../json/featurePicker/tabIcons.json';
import { extensionMoreSections, extensionWidgetKeys } from '../../../config/features';
import { typeActions } from '../../../webapi';
import {
  COLOUR_BRANDING_MAIN,
  COLOUR_DUSK,
  COLOUR_DUSK_LIGHTER,
  COLOUR_GREEN,
  getTabKeyFromPage,
  getTabTitleFromPage,
  getWidgetInfo,
} from '../../../js';
import FontAwesome from 'react-fontawesome';
import { CONST_STRINGS } from '../../../config';
import { getFeatureInfo, getPluralS } from '../../../helper';
import { refreshAuthUser } from '../../../actions';
import PhoneFooter from './Preview/PhoneFooter';
import PhoneHeader from './Preview/PhoneHeader';
import featurePickerTemplates from '../../../json/featurePicker/featurePickerTemplates.json';

class FeaturePickerSimple extends Component {
  constructor(props) {
    super(props);

    this.availableWidgetKeys = ['news', 'events', 'gallery', 'groups', 'people'];
    this.widgetKeys = _.filter(
      [...extensionWidgetKeys, 'events', 'offers', 'surveys', 'people', 'services', 'facilities', 'gallery'],
      (w) => {
        // return true;
        return _.includes(this.availableWidgetKeys, w);
      },
    );

    this.moreOptions = [
      ...extensionMoreSections,
      {
        displayName: 'Profile',
        optionKey: 'profile',
        inverseOption: true,
      },
      {
        displayName: CONST_STRINGS.FAQS,
        optionKey: 'infoPages',
        inverseOption: true,
      },
      {
        displayName: 'Surveys',
        optionKey: 'surveys',
        inverseOption: true,
      },
      {
        displayName: 'Maps',
        optionKey: 'maps',
        inverseOption: true,
      },
      {
        displayName: 'Contacts',
        optionKey: 'importantContacts',
        inverseOption: true,
      },
      {
        displayName: 'Give Feedback',
        optionKey: 'feedback',
        inverseOption: true,
      },
    ];

    this.state = {
      ...typeActions.getDefaultSiteSettings(true, false, true),
      selectedSidebarTab: 'features',
    };

    this.iconOptions = _.filter(tabIcons, (t) => t.tag !== 'more').map((t) => {
      return t.key;
    });
    this.moreIconOptions = _.filter(tabIcons, (t) => t.tag === 'more').map((t) => {
      return t.key;
    });
  }

  componentDidMount = () => {
    this.load();
  };

  load = () => {
    console.log('loading');
    typeActions.getSite(this.props.auth.site).then((res) => {
      console.log('loaded', res.data);
      this.loadSiteState(res.data);
    });
  };

  loadSiteState = (site) => {
    const newState = { site };
    if (site && site.Settings) {
      if (site.Settings.TabSettings) {
        newState.tabSettings = [...site.Settings.TabSettings];
        newState.tabSettings.forEach((t) => {
          if (!t.widgetOptions) {
            t.widgetOptions = [];
          }
        });
      }
      newState.headerType = site.Settings.HeaderType || (!!site.Settings.UseGradientHeader ? 'gradient' : 'white');
      newState.footerType = site.Settings.FooterType || 'white';
      newState.footerColour = site.Settings.FooterColour;
      newState.patternColour = site.Settings.PatternColour;
      newState.usePatternColour = newState.headerType === 'pattern' && !!site.Settings.PatternColour;
      newState.selectedPattern = site.Settings.HeaderPattern;
    }
    if (site && site.Branding && site.Branding) {
      if (site.Branding.MainBrandingColour) {
        newState.colour = site.Branding.MainBrandingColour;
      }
      if (site.Branding.Logo) {
        newState.logo = site.Branding.Logo;
      }
    }
    this.setState(newState);
  };

  selectSidebarTab = (tab) => {
    this.setState({
      selectedSidebarTab: tab,
    });
  };

  selectTab(tab) {
    if (tab === this.state.selectedTab) {
      return;
    }
    this.setState({
      selectedTab: tab,
      iconSelectorOpen: false,
    });
  }

  openIconSelector = () => {
    this.setState({
      iconSelectorOpen: !this.state.iconSelectorOpen,
    });
  };

  selectIcon(page, icon) {
    page.icon = icon;
    this.setState({
      tabSettings: [...this.state.tabSettings],
      iconSelectorOpen: false,
      anyChanges: true,
    });
  }

  getSelectedTab() {
    const page = _.find(this.state.tabSettings, (t) => {
      return t.key === this.state.selectedTab;
    });
    return page;
  }

  getSelectedWidgets() {
    return this.getSelectedTab().widgets;
  }

  getAvailableWidgets() {
    const selectedWidgets = this.getSelectedWidgets();
    if (this.getSelectedTab().type === 'menu') {
      return _.filter(
        this.moreOptions.map((o) => {
          return o.featureKey || o.optionKey;
        }),
        (w) => {
          return !_.includes(selectedWidgets, w);
        },
      );
    }
    return _.filter(this.widgetKeys, (w) => {
      return !_.includes(selectedWidgets, w);
    });
  }

  getWidgetTitleFromPage(page, index, widget) {
    if (page && page.widgetOptions && page.widgetOptions[index] && !_.isEmpty(page.widgetOptions[index].Title)) {
      return page.widgetOptions[index].Title;
    }
    return this.getWidgetTitle(widget);
  }

  getWidgetTitle(w) {
    return getFeatureInfo(w).text;
  }

  getWidgetContent(w) {
    if (this.getSelectedTab().type === 'menu') {
      return () => {
        return null;
      };
    }
    return getFeatureInfo(w).featurePickerContent;
  }

  setWidgetInfo = (w) => {
    const widgetInfo = getWidgetInfo(w);
    if (!widgetInfo) {
      this.setState({
        widgetInfo: null,
        showWidgetInfo: false,
      });
      return;
    }
    this.setState({
      widgetInfo: {
        ...widgetInfo,
        title: this.getWidgetTitle(w),
      },
      showWidgetInfo: true,
    });
  };

  selectTemplate = (t) => {
    if (!t) {
      this.setState({
        tabSettings: [...this.state.oldTabSettings],
        selectedTemplate: null,
      });
      return;
    }
    const newState = {};
    if (!this.state.selectedTemplate) {
      newState.oldTabSettings = [...this.state.tabSettings];
    }

    newState.tabSettings = [...t.value.Settings.TabSettings];
    newState.selectedTemplate = t.key;

    this.setState(newState);
  };

  handleTabTitleChange = (key, value) => {
    _.find(this.state.tabSettings, (t) => {
      return t.key === key;
    }).tabTitle = value.replace(/\\n/g, ' ').substr(0, 15);
    this.setState({
      tabSettings: [...this.state.tabSettings],
      anyChanges: true,
    });
  };

  handleWidgetTitleChange = (page, widgetIndex, value) => {
    if (!page.widgetOptions[widgetIndex]) {
      page.widgetOptions[widgetIndex] = {};
    }
    page.widgetOptions[widgetIndex].Title = value;
    this.setState({
      tabSettings: [...this.state.tabSettings],
      anyChanges: true,
    });
  };

  onDeletePage = (page) => {
    const newState = {
      anyChanges: true,
      tabSettings: _.filter(this.state.tabSettings, (t) => {
        return t.key !== page.key;
      }),
    };
    if (this.state.selectedTab === page.key) {
      newState.selectedTab = newState.tabSettings[0].key;
    }

    this.setState(newState);
  };

  isMoreEnabled = () => {
    const more = _.find(this.state.tabSettings, (t) => {
      return t.type === 'menu';
    });
    if (!more) {
      return false;
    }
    return more.isEnabled;
  };

  onToggleMore = (moreHidden) => {
    const more = _.find(this.state.tabSettings, (t) => {
      return t.type === 'menu';
    });
    if (moreHidden && !more) {
      this.addNewPage('menu');
    } else if (more) {
      more.isEnabled = !moreHidden;
      const newTabSettings = [...this.state.tabSettings];
      this.setState({
        tabSettings: newTabSettings,
        anyChanges: true,
      });
    }
  };

  isReadyToSave = () => {
    if (_.isEmpty(this.state.tabSettings)) {
      return false;
    }
    if (this.state.submitting) {
      return false;
    }
    let anyInvalid = false;
    this.state.tabSettings.forEach((p) => {
      if (!this.validatePage(p)) {
        anyInvalid = true;
      }
    });
    return !anyInvalid;
  };

  compileData() {
    const settings = this.state.site && this.state.site.Settings ? { ...this.state.site.Settings } : {};

    // new tab settings flow
    const tabSettings = [...this.state.tabSettings];
    tabSettings.forEach((t) => {
      t.icon = getTabKeyFromPage(t);
      t.tabTitle = getTabTitleFromPage(t);

      // if (t.type === 'menu') {
      //   this.moreOptions.forEach((o) => {
      //     const isSelected = t.widgets.indexOf(o.featureKey || o.optionKey);
      //     settings[o.optionKey] = o.inverseOption === !isSelected;
      //   });
      // }
    });
    settings.TabSettings = tabSettings;

    return { Settings: settings };
  }

  handleCancel = () => {
    if (this.state.anyChanges && !window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
      return;
    }
    window.history.back();
  };

  handleSave = () => {
    if (!this.isReadyToSave()) {
      this.setState({
        showWarnings: true,
      });
      return;
    }
    this.setState({
      submitting: true,
    });
    typeActions
      .editSiteSettings(this.props.auth.site, this.compileData())
      .then((res) => {
        this.props.refreshAuthUser(this.props.auth.site);

        this.setState({
          submitting: false,
          success: true,
          anyChanges: false,
        });
      })
      .catch((error) => {
        console.log('error', error);
        this.setState({
          submitting: false,
          success: false,
        });
      });
  };

  closeSuccessPopup = () => {
    this.setState({
      success: false,
    });
    window.location.assign('/');
  };

  canAddNewPage = () => {
    return (
      _.filter(this.state.tabSettings, (t) => {
        return t.type !== 'menu';
      }).length < 4
    );
  };

  addNewPage = (type) => {
    let tabKey = null;
    if (type === 'menu') {
      tabKey = 'tab4';
      this.state.tabSettings.push({
        key: tabKey,
        tabTitle: 'More',
        type: 'menu',
        isEnabled: true,
        widgets: [],
        widgetOptions: [],
      });
    } else if (type === 'blank') {
      const pageNum =
        this.state.tabSettings.length -
        (_.some(this.state.tabSettings, (t) => {
          return t.type === 'menu';
        })
          ? 1
          : 0);
      tabKey = `tab${pageNum}`;
      const newTab = {
        key: tabKey,
        tabTitle: '',
        type: 'blank',
        isEnabled: true,
        widgets: [],
        widgetOptions: [],
      };
      if (
        _.some(this.state.tabSettings, (t) => {
          return t.type === 'menu';
        })
      ) {
        const pageNum = this.state.tabSettings.length - 1;
        this.state.tabSettings.splice(pageNum, 0, newTab);
      } else {
        this.state.tabSettings.push(newTab);
      }
      setTimeout(() => {
        const input = document.getElementById(`tabTitleInput_${tabKey}`);
        if (input) input.select();
      }, 500);
    }
    this.setState({
      tabSettings: [...this.state.tabSettings],
      showNewPage: false,
      selectedTab: tabKey,
      anyChanges: true,
    });
  };

  removeWidget = (index) => {
    const newSelection = [...this.getSelectedWidgets()];
    newSelection.splice(index, 1);

    const selectedTab = this.getSelectedTab();
    selectedTab.widgets = newSelection;
    if (selectedTab.widgetOptions && selectedTab.widgetOptions.length > index) {
      const newOptions = selectedTab.widgetOptions;
      newOptions.splice(index, 1);
      selectedTab.widgetOptions = newOptions;
    }

    this.setState({
      tabSettings: [...this.state.tabSettings],
      anyChanges: true,
    });
  };

  updateWidgets = (result) => {
    const newSelection = [...this.getSelectedWidgets()];
    const widgetOptions = [...(this.getSelectedTab().widgetOptions || [])];
    while (widgetOptions.length < newSelection.length) {
      widgetOptions.push({});
    }
    if (result.destination && result.destination.droppableId === 'selection') {
      const indexSourced = result.source && result.source.droppableId === 'selection' ? result.source.index : 999999;
      const indexToAdd = result.destination.index + (indexSourced < result.destination.index && 1);
      newSelection.splice(indexToAdd, 0, result.draggableId);
      widgetOptions.splice(indexToAdd, 0, widgetOptions[indexSourced]);

      if (result.source.droppableId === 'selection') {
        const indexAdded = result.destination ? result.destination.index : 999999;
        const indexToDelete = result.source.index + (indexAdded < result.source.index && 1);
        newSelection.splice(indexToDelete, 1);
        widgetOptions.splice(indexToDelete, 1);
      }
    }

    const selectedTab = this.getSelectedTab();
    selectedTab.widgets = newSelection;
    selectedTab.widgetOptions = widgetOptions;

    this.setState({
      tabSettings: [...this.state.tabSettings],
      anyChanges: true,
      draggingWidget: false,
    });
  };

  updateTabs = (result) => {
    if (!result.destination || result.destination.droppableId !== 'footertabs') {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const increasedIndex = result.destination.index > result.source.index;
    const draggedId = result.draggableId.substr(4);

    const newTabSettings = _.sortBy(this.state.tabSettings, (t) => {
      const i = this.state.tabSettings.indexOf(t);
      if (t.key === draggedId) {
        return result.destination.index;
      }
      if (increasedIndex && i <= result.destination.index) {
        return i - 1;
      }
      if (!increasedIndex && i >= result.destination.index) {
        return i + 1;
      }
      return i;
    });
    this.setState({
      tabSettings: newTabSettings,
      anyChanges: true,
    });
  };

  validateHomePage(page) {
    return !_.isEmpty(page.widgets);
  }

  validateBlankPage(page) {
    if (!page.isEnabled) {
      return true;
    }
    if (_.isEmpty(page.widgets)) {
      return false;
    }
    return true;
  }

  validateMenuPage(page) {
    if (!page.isEnabled) {
      return true;
    }
    if (_.isEmpty(page.widgets)) {
      return false;
    }
    return true;
  }

  validatePage(page) {
    switch (page.type) {
      case 'home':
        return this.validateHomePage(page);
      case 'blank':
        return this.validateBlankPage(page);
      case 'menu':
        return this.validateMenuPage(page);
      default:
        break;
    }
    return false;
  }

  startDraggingWidgets = (result) => {
    this.setState({
      draggingWidget: true,
    });
  };

  onDragStart = (result) => {
    this.setWidgetInfo(null);
    switch (result.type) {
      case 'WIDGET':
        return this.startDraggingWidgets(result);
      case 'TAB':
      // return this.updateTabs(result);
      default:
        return;
    }
  };

  onDragEnd = (result) => {
    switch (result.type) {
      case 'WIDGET':
        return this.updateWidgets(result);
      case 'TAB':
        return this.updateTabs(result);
      default:
        return;
    }
  };

  renderTemplate(t, i) {
    return (
      <div
        className={`fp2_template${i % 2 === 1 ? ' fp2_template-odd' : ''}${
          t.key === this.state.selectedTemplate ? ' fp2_template-selected' : ''
        }`}
        onClick={() => {
          this.selectTemplate(t);
        }}
      >
        <div className="fp2_template_button">
          <img src={t.image} className="fp2_template_icon" />
        </div>
        <div className="fp2_template_bottom">
          <div
            className="fp2_template_remove"
            onClick={(e) => {
              e.stopPropagation();
              this.selectTemplate();
            }}
          >
            <SVGIcon className="fp2_template_remove_icon fp2_template_remove_icon-remove" icon="close" colour={COLOUR_DUSK} />
            <SVGIcon className="fp2_template_remove_icon fp2_template_remove_icon-check" icon="check" colour={COLOUR_GREEN} />
          </div>
          <div className="flex-1">
            <Text type="h6" className="fp2_template_title">
              {t.title}
            </Text>
            <Text type="help" className="fp2_template_subtitle">{`${t.value.Settings.TabSettings.length} page${getPluralS(
              t.value.Settings.TabSettings.length,
            )}`}</Text>
          </div>
        </div>
      </div>
    );
  }

  renderTemplates() {
    return (
      <div className="fp2_sidebar_content">
        {_.values(featurePickerTemplates).map((t, i) => {
          return this.renderTemplate(t, i);
        })}
      </div>
    );
  }

  renderFeatureOptions() {
    return (
      <Droppable droppableId="available" type="WIDGET" isDropDisabled={true}>
        {(provided) => (
          <div className="fp2_sidebar_content" {...provided.droppableProps} ref={provided.innerRef}>
            {this.getAvailableWidgets().map((widget, index) => {
              return (
                <DraggableWidget
                  widget={widget}
                  key={widget}
                  index={index}
                  title={this.getWidgetTitle(widget)}
                  content={this.getWidgetContent(widget)}
                  onMouseEnter={
                    this.getSelectedTab().type === 'menu'
                      ? null
                      : () => {
                          this.setWidgetInfo(widget);
                        }
                  }
                  onMouseLeave={() => {
                    this.setWidgetInfo(null);
                  }}
                />
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  renderSelectedTab() {
    const sTab = this.getSelectedTab();
    const selectedWidgets = this.getSelectedWidgets();
    return (
      <Droppable droppableId="selection" type="WIDGET">
        {(provided, snapshot) => (
          <div
            className={`fp2_phone_inner${snapshot.isDraggingOver ? ' fp2_phone_inner-draggedOver' : ''}${
              this.state.draggingWidget ? ' fp2_phone_inner-dragging' : ''
            }`}
          >
            <PhoneHeader
              headerType={this.state.headerType}
              headerPattern={this.state.selectedPattern}
              usePatternColour={this.state.usePatternColour}
              patternColour={this.state.patternColour}
              tabSettings={this.state.tabSettings}
              selectedTab={this.state.selectedTab}
              mainBrandingColour={this.state.colour}
              siteName={this.state.site ? this.state.site.siteName : ''}
              logo={this.state.logo}
            />
            <div className="fp2_phone_droparea" {...provided.droppableProps} ref={provided.innerRef}>
              {_.isEmpty(selectedWidgets) && (
                <div className="fp2_phone_empty">
                  <img
                    src="https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/982ce9284459a2bb31ba61769a/dnd.png"
                    className="fp2_phone_empty_graphics"
                  />
                  <Text type="bodyLarge" className="fp2_phone_empty_text">
                    Drag and drop items from the Features panel to add them to your page.
                  </Text>
                </div>
              )}
              {selectedWidgets.map((widget, index) => {
                return (
                  <DraggableWidget
                    widget={widget}
                    key={widget}
                    index={index}
                    selected
                    title={this.getWidgetTitleFromPage(sTab, index, widget)}
                    content={this.getWidgetContent(widget)}
                    onChangeTitle={
                      sTab.type !== 'menu'
                        ? (value) => {
                            this.handleWidgetTitleChange(sTab, index, value);
                          }
                        : undefined
                    }
                    onRemove={() => {
                      this.removeWidget(index);
                    }}
                    large={index === selectedWidgets.length - 1}
                  />
                );
              })}
              {provided.placeholder}
            </div>
            {
              <PhoneFooter
                footerType={this.state.footerType}
                footerColour={this.state.footerColour}
                tabSettings={this.state.tabSettings}
                selectedTab={this.state.selectedTab}
                mainBrandingColour={this.state.colour}
              />
            }
          </div>
        )}
      </Droppable>
    );
  }

  renderSideBar() {
    return (
      <div className="fp2_sidebar">
        <Tabs
          onSelectTab={this.selectSidebarTab}
          selectedTab={this.state.selectedSidebarTab}
          className="fp2_sidebar_tabs"
          tabs={[
            {
              value: 'features',
              text: 'Features',
            },
            {
              value: 'templates',
              text: 'Templates',
            },
          ]}
        />
        {this.state.selectedSidebarTab === 'features' ? this.renderFeatureOptions() : this.renderTemplates()}
      </div>
    );
  }

  renderHeader() {
    return (
      <div className="featurePicker_headerTray featurePicker_headerTray-fp2">
        <FontAwesome className="featurePicker_back" name="angle-left" onClick={this.handleCancel} />
        <Text type="formTitleLarge" className="featurePicker_pageTitle">
          Feature Picker
        </Text>
        <div className="flex flex-1 flex-center flex-reverse">
          <Button inline buttonType="primary" onClick={this.handleSave} isActive={this.isReadyToSave()} style={{ width: 150 }}>
            Save
          </Button>
        </div>
      </div>
    );
  }

  renderPhone() {
    return (
      <div className="fp2_phone">
        <div className="fp2_phone_notch"></div>
        {this.renderSelectedTab()}
      </div>
    );
  }

  renderIconSelector(t) {
    const largeMenu = t.type !== 'menu';
    const iconOptions = t.type === 'menu' ? this.moreIconOptions : this.iconOptions;
    const tabIcon = getTabKeyFromPage(t);
    return (
      <div className={`fp2_iconSection${largeMenu ? ' fp2_iconSection-large' : ''}`}>
        {iconOptions.map((key, index) => {
          return (
            <div
              className={`fp2_iconOption${tabIcon === key ? ' fp2_iconOption-selected' : ''}`}
              key={key}
              onClick={() => {
                this.selectIcon(t, key);
              }}
            >
              <SVGIcon icon={key} colour={COLOUR_BRANDING_MAIN} />
            </div>
          );
        })}
      </div>
    );
  }

  renderTab(t, i) {
    const isSelected = this.state.selectedTab === t.key;
    const isHidden = !t.isEnabled;
    const isInvalid = !this.validatePage(t);
    return (
      <Draggable draggableId={`tab_${t.key}`} index={i} key={t.key}>
        {(provided, snapshot) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            onClick={() => {
              this.selectTab(t.key);
            }}
            className={`fp2_tab 
            ${isSelected ? ' fp2_tab-selected' : ''}
            ${isHidden ? ' fp2_tab-hidden' : ''}
            ${isInvalid ? ' fp2_tab-isInvalid' : ''}
            ${snapshot.isDragging ? ' fp2_tab-dragging' : ''}`}
          >
            {this.state.iconSelectorOpen && isSelected && this.renderIconSelector(t)}
            {isSelected ? (
              <div className="fp2_tab_iconButton" onClick={this.openIconSelector}>
                <div className="fp2_tab_iconButton_icon">
                  <SVGIcon icon={getTabKeyFromPage(t)} colour={COLOUR_BRANDING_MAIN} />
                </div>
                <FontAwesome name="caret-down" className="fp2_tab_iconButton_caret" />
              </div>
            ) : (
              <div className="fp2_icon fp2_tab_icon">
                <SVGIcon icon={getTabKeyFromPage(t)} colour={COLOUR_BRANDING_MAIN} />
              </div>
            )}
            {isSelected ? (
              <input
                id={`tabTitleInput_${t.key}`}
                type="text"
                className="fp2_tab_title fp2_tab_title-input"
                value={getTabTitleFromPage(t, i)}
                onChange={(e) => this.handleTabTitleChange(t.key, e.target.value)}
              />
            ) : (
              <p className="fp2_tab_title">{getTabTitleFromPage(t, i)}</p>
            )}
          </div>
        )}
      </Draggable>
    );
  }

  renderAddPageButton() {
    const canAdd = this.canAddNewPage();
    if (!canAdd) {
      return null;
    }
    return (
      <div className="fp2_main_footer_addPage">
        <Button
          inline
          buttonType="tealAction"
          onClick={() => {
            if (canAdd) this.addNewPage('blank');
          }}
          isActive={canAdd}
          style={{ width: 150 }}
        >
          Add Page
        </Button>
      </div>
    );
  }

  renderMore() {
    const t = _.find(this.state.tabSettings, (t) => {
      return t.type === 'menu';
    }) || { type: 'menu', tabTitle: 'More' };
    const isSelected = t && this.state.selectedTab === t.key;
    const isHidden = !t.isEnabled;
    const isInvalid = !this.validatePage(t);
    return (
      <div
        onClick={() => {
          if (!t.key) {
            this.addNewPage('menu');
          } else {
            this.selectTab(t.key);
          }
        }}
        className={`fp2_tab fp2_tab-more 
        ${isSelected ? ' fp2_tab-selected' : ''}
        ${isHidden ? ' fp2_tab-hidden' : ''}
        ${isInvalid ? ' fp2_tab-isInvalid' : ''}`}
      >
        {this.state.iconSelectorOpen && isSelected && this.renderIconSelector(t)}
        {isSelected ? (
          <div className="fp2_tab_iconButton" onClick={this.openIconSelector}>
            <div className="fp2_tab_iconButton_icon">
              <SVGIcon icon={getTabKeyFromPage(t)} colour={COLOUR_BRANDING_MAIN} />
            </div>
            <FontAwesome name="caret-down" className="fp2_tab_iconButton_caret" />
          </div>
        ) : (
          <div className="fp2_icon fp2_tab_icon">
            <SVGIcon icon={getTabKeyFromPage(t)} colour={COLOUR_BRANDING_MAIN} />
          </div>
        )}
        {isSelected ? (
          <input
            id={`tabTitleInput_${t.key}`}
            type="text"
            className="fp2_tab_title fp2_tab_title-input"
            value={t.tabTitle}
            onChange={(e) => this.handleTabTitleChange(t.key, e.target.value)}
          />
        ) : (
          <p className="fp2_tab_title">{t.tabTitle}</p>
        )}
      </div>
    );
  }

  renderFooter() {
    const tabs = _.filter(this.state.tabSettings, (t) => {
      return t.type !== 'menu';
    });
    return (
      <div className="fp2_main_footer">
        <div className="fp2_main_footer_right">{this.renderMore()}</div>
        <Droppable droppableId="footertabs" direction="horizontal" type="TAB">
          {(provided) => (
            <div className="fp2_main_footer_tabs" {...provided.droppableProps} ref={provided.innerRef}>
              {tabs.map((t, i) => {
                return this.renderTab(t, i);
              })}
              {provided.placeholder}
              {this.renderAddPageButton()}
            </div>
          )}
        </Droppable>
      </div>
    );
  }

  renderHideDelete() {
    const t = this.getSelectedTab();
    switch (t.type) {
      case 'home':
        return null;
      case 'menu':
        return (
          <div className="fp2_pageToggle">
            <p className="fp2_pageToggle_label">Hide Page</p>
            <Switch
              className="fp2_pageToggle_toggle"
              checked={!this.isMoreEnabled()}
              onChange={this.onToggleMore}
              onClick={this.onToggleMore}
              onColor={COLOUR_BRANDING_MAIN}
              offColor={COLOUR_DUSK_LIGHTER}
              checkedIcon={false}
              uncheckedIcon={false}
              height={26}
              width={50}
            />
          </div>
        );
      case 'blank':
        return (
          <Button
            buttonType="outlined"
            onClick={() => {
              this.onDeletePage(t);
            }}
            isActive
          >
            Delete page
          </Button>
        );

      default:
        return null;
    }
  }

  renderMainHeader() {
    const t = this.getSelectedTab();
    const i = this.state.tabSettings.indexOf(t);
    return (
      <div className="fp2_main_header">
        <div className="fp2_main_header_titleSection">
          <div className="fp2_icon fp2_main_header_icon">
            <SVGIcon icon={getTabKeyFromPage(t)} colour={COLOUR_BRANDING_MAIN} />
          </div>
          <p className="fp2_main_header_title">{getTabTitleFromPage(t, i)}</p>
        </div>
        {this.renderHideDelete()}
      </div>
    );
  }

  renderWidgetInfo() {
    if (!this.state.showWidgetInfo) {
      return null;
    }
    const widgetInfo = this.state.widgetInfo || {};
    return (
      <div className="fp2_widgetInfo">
        {/* <div
          className="fp2_widgetInfo_close"
          onClick={() => {
            this.setWidgetInfo();
          }}
        >
          <SVGIcon className="fp2_widgetInfo_close_icon" icon="close" colour={COLOUR_DUSK} />
        </div> */}
        {!_.isEmpty(widgetInfo.image) && (
          <div style={{ backgroundImage: `url(${widgetInfo.image})` }} className="fp2_widgetInfo_image"></div>
        )}
        <div className="fp2_widgetInfo_bottom">
          {!_.isEmpty(widgetInfo.title) && (
            <Text type="h6" className="fp2_widgetInfo_title">
              {widgetInfo.title}
            </Text>
          )}
          {!_.isEmpty(widgetInfo.text) && (
            <Text type="body" className="fp2_widgetInfo_text">
              {widgetInfo.text}
            </Text>
          )}
          {widgetInfo.layoutDisclaimer && (
            <div className="fp2_widgetInfo_disclaimer">
              <Text type="body">This layout will change depending on position on the page.</Text>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderMain() {
    return (
      <div className="fp2_main">
        <div className="fp2_main_top">
          {_.isEmpty(this.getSelectedTab().widgets) && (
            <img
              src="https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/80b1b2154c2e9a4975cfdf61b5/arrow.png"
              className="fp2_main_arrow"
            />
          )}
          {this.renderWidgetInfo()}
          {this.renderMainHeader()}
          {this.renderPhone()}
        </div>
        {this.renderFooter()}
      </div>
    );
  }

  renderSuccessPopup() {
    if (!this.state.success) {
      return null;
    }
    return (
      <Popup
        title="Saved"
        subtitle="Your App is now ready!"
        hasPadding
        minWidth={400}
        minHeight={200}
        buttons={[
          {
            type: 'primary',
            onClick: this.closeSuccessPopup,
            isActive: true,
            text: 'Done',
          },
        ]}
      />
    );
  }

  render() {
    return (
      <div className={`pageContainer flex flex-column fp2${this.state.showWarnings ? ' fp2-showWarnings' : ''}`}>
        <GlobalHider chatbot sideMenu />
        {this.renderHeader()}
        <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
          <div className="fp2_page">
            {this.renderSideBar()}
            {this.renderMain()}
          </div>
        </DragDropContext>
        {this.renderSuccessPopup()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { refreshAuthUser })(withRouter(FeaturePickerSimple));
