import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Button, OverlayPage, OverlayPageContents, OverlayPageSection, Text, UserListing } from '../../components';
import { bookingActions, paymentActions } from '../../webapi';
import { hasTimeframeOverlap, minutesToString, safeReadParams, timepickerToMinutes } from '../../helper';
import { checkLoggedIn, getApiError } from '../../session';
import FontAwesome from 'react-fontawesome';

class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: safeReadParams(props, 'id'),
      booking: {},
      loadingBooking: true,
    };
  }

  componentDidMount() {
    checkLoggedIn(this, this.props.auth);
    this.getData();
  }

  getData() {
    bookingActions
      .getBooking(this.state.id)
      .then((res) => {
        this.setState({
          booking: res.data.booking,
          loadingBooking: false,
        });
      })
      .catch((err) => {
        this.setState({
          loadingBooking: false,
          error: true,
        });
      });
  }

  onBack = () => {
    window.history.back();
  };

  onCancelBooking = async () => {
    try {
      if (window.confirm('Are you sure you want to cancel this booking?')) {
        this.setState({
          cancelling: true,
        });
        let refundId = null;
        if (this.state.booking && this.state.booking.TransactionId) {
          const { data } = await paymentActions.refund(this.state.booking.TransactionId);
          refundId = data && data.RowId;
        }
        const res = await bookingActions.cancelBooking(this.state.id, refundId);
        this.setState({
          cancelling: false,
          booking: res.data.booking,
        });
      }
    } catch (error) {
      const message = getApiError(error);
      console.error(message);
    }
  };

  renderForm = () => {
    if (this.state.loadingBooking) {
      return null;
    }
    return (
      <div>
        <div className="padding-60 paddingVertical-40">
          <div className="flex flex-center">
            <FontAwesome className="featurePicker_back" name="angle-left" onClick={this.onBack} />
            <Text type="formTitleLarge" className="featurePicker_pageTitle">
              Booking {this.state.booking.Id}
            </Text>
          </div>
          {this.state.booking.Cancelled && (
            <>
              <Text type="h3" className="marginTop-16 text-red">
                Cancelled
              </Text>
              <Text type="formText">
                This booking was cancelled {moment(this.state.booking.CancelledTime).local().format('D MMMM YYYY h:mma')}
              </Text>
            </>
          )}
          <div style={{ opacity: this.state.booking.Cancelled ? 0.5 : 1 }}>
            <Text type="formLabel" className="marginTop-16">
              Time
            </Text>
            <Text type="formText">
              {moment.parseZone(this.state.booking.TimeString || this.state.booking.Time).format('dddd D MMMM YYYY')}
            </Text>
            <Text type="formText">
              {moment.parseZone(this.state.booking.TimeString || this.state.booking.Time).format('h:mma')} -{' '}
              {moment.parseZone(this.state.booking.EndTimeString || this.state.booking.EndTime).format('h:mma')}
            </Text>
            <Text type="formLabel" className="marginTop-16">
              Calendar
            </Text>
            <Text type="formText">{this.state.booking.Calendar.Title}</Text>
            <Text type="formLabel" className="marginTop-16">
              Booking Type
            </Text>
            <Text type="formText">{this.state.booking.BookingType.Title}</Text>
            {!_.isEmpty(this.state.booking.Notes) && (
              <>
                <Text type="formLabel" className="marginTop-16">
                  Notes
                </Text>
                <Text type="bodyLarge">{this.state.booking.Notes}</Text>
              </>
            )}
            <Text type="formLabel" className="marginTop-16">
              User
            </Text>
            <UserListing size={40} user={this.state.booking.User} />
            {this.state.booking.Entity && (
              <>
                <Text type="formLabel" className="marginTop-16">
                  Booked via
                </Text>
                <UserListing size={40} squareImage image={this.state.booking.Entity.Image} title={this.state.booking.Entity.Title} />
              </>
            )}
            <Text type="formLabel" className="marginTop-16">
              Booking made
            </Text>
            <Text type="formText">{moment(this.state.booking.CreatedTime).local().format('D MMMM YYYY h:mma')}</Text>
            {!this.state.booking.Cancelled && (
              <>
                <Text type="formLabel" className="marginTop-16">
                  Options
                </Text>
                <Button inline buttonType="outlined" onClick={this.onCancelBooking} isActive leftIcon="ban" className="marginTop-4">
                  Cancel Booking
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons>
          <OverlayPageSection className="pageSectionWrapper--fixedPopupSize">{this.renderForm()}</OverlayPageSection>
        </OverlayPageContents>
      </OverlayPage>
    );
  };
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(Booking);
