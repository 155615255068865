import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { isTheBest, getApiError } from '../../session';
import { automationActions, fileActions } from '../../webapi';
import { whiteLabelLoaded, whiteLabelReset } from '../../actions';
import { FileInput, GenericInput, Button, DropdownInput } from '../../components';
import {
  renderTitle,
  renderDescription,
  renderOpenLinkStep,
  renderTextStep,
  renderImageStep,
  renderEmbed,
  renderCopyToClipboardText,
  getWhiteLabelAppUser,
} from './helper';
import { automationConfig } from '../../config';

const REVIEW_USER_PREFIX = 'google';
const REVIEW_USER_MISSING = '[MISSING REVIEW USER]';
const STEP_SELECT = 0;
const STEP_ENABLE_API = 1;
const STEP_FCM = 2;
const STEP_FCM_GOOGLE = 2.1;
const STEP_FCM_TOKEN = 2.2;
const STEP_BUNDLE = 3;
const STEP_USER = 4;
const STEP_APP = 5;
const STEP_APP_INIT = 5.1;
const GRAPHIC_WIDTH = 200;
const GRAPHIC_HEIGHT = 200;
const GRAPHIC_TYPE_ICON = 'icon';
const GRAPHIC_TYPE_FEATURE = 'featureGraphic';
const GRAPHIC_TYPE_PHONE = 'phoneScreenshots';
const GRAPHIC_TYPE_TABLET_7 = 'sevenInchScreenshots';
const GRAPHIC_TYPE_TABLET_10 = 'tenInchScreenshots';

class AppSetupAndroid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteLabelInfo: _.cloneDeep(props.activeWhiteLabel),
      selectedApp: '',
      cloudMessageToken: '',
      releaseUrl: '',
      loading: false,
      loadingMessage: '',
      resetMessage: '',
      message: {},
      localGraphics: {},
      title: '',
      shortDescription: '',
      fullDescription: '',
    };
    this.steps = [
      { label: 'Select App', name: STEP_SELECT, render: this.renderStepSelectApp },
      { label: 'Enable API', name: STEP_ENABLE_API, render: this.renderStepEnableApi },
      { label: 'Set up FCM', name: STEP_FCM, render: this.renderStepSetUpFcm },
      { label: 'Build App Bundle', name: STEP_BUNDLE, render: this.renderStepBuildAppBundle },
      { label: 'Set up Review User', name: STEP_USER, render: this.renderStepSetupReviewUser },
      { label: 'Set up Android App', name: STEP_APP, render: this.renderStepSetupAndroidApp },
    ];
  }

  componentDidMount() {
    this.loadLocalGraphics();
    // TEST: Preset environment
    // this.setState({ whiteLabelInfo: { ClientCode: 'unittesting' } }, this.onLoadWhiteLabel);
  }

  componentDidUpdate(prevProps) {
    const { activeWhiteLabel } = this.props;
    if (!_.isEqual(prevProps.activeWhiteLabel, activeWhiteLabel)) {
      this.setState({ whiteLabelInfo: activeWhiteLabel }, this.setAppInfo);
    }
  }

  getAppBranchName = (defaultName = '{MISSING_NAME}') => {
    const { selectedApp, whiteLabelInfo } = this.state;
    return selectedApp || whiteLabelInfo.ClientCode || defaultName;
  };

  getAppBundleId = () => this.getAppBranchName().replace('-', '');

  getAppInfo = () => {
    const { selectedApp, whiteLabelInfo } = this.state;
    if (!selectedApp && !(whiteLabelInfo && whiteLabelInfo.Apps)) return {};

    return (whiteLabelInfo.Apps && whiteLabelInfo.Apps.find((a) => a.ClientCode === (selectedApp || whiteLabelInfo.ClientCode))) || {};
  };

  getAppProperty = (key) => {
    const app = this.getAppInfo();
    return app ? app[key] : null;
  };

  setAppInfo = () => {
    const app = this.getAppInfo();
    // console.log('setAppInfo', app);
    const { CloudMessageToken, PlayStoreTitle, ClientName, PlayStoreShortDescription, PlayStoreFullDescription } = app;
    this.setState(
      {
        cloudMessageToken: CloudMessageToken,
        title: PlayStoreTitle || ClientName,
        shortDescription: PlayStoreShortDescription || '',
        fullDescription: PlayStoreFullDescription || '',
      },
      this.loadLocalGraphics,
    );
  };

  getGraphicMessage = (type, index = null) => {
    return _.clone(this.state[`${type}${index !== null ? `_${index}` : ''}`]);
  };

  setGraphicMessage = (type, index, message) => {
    const update = {};
    update[`${type}${index !== null ? `_${index}` : ''}`] = message;
    this.setState(update);
  };

  loadLocalGraphics = () => {
    const graphics = this.getAppProperty('PlayStoreGraphics') || {};
    const updatedGraphics = _.cloneDeep(graphics);
    const initGraphicType = (type) => {
      if (!updatedGraphics[type]) updatedGraphics[type] = {};
      let count = 0;
      switch (type) {
        case GRAPHIC_TYPE_ICON:
        case GRAPHIC_TYPE_FEATURE:
          count = 1;
          break;
        case GRAPHIC_TYPE_PHONE:
        case GRAPHIC_TYPE_TABLET_7:
        case GRAPHIC_TYPE_TABLET_10:
          count = 4;
          break;
        default:
          count = 1;
      }
      for (let i = 0; i < count; i++) {
        if (!updatedGraphics[type][i]) updatedGraphics[type][i] = { url: null };
      }
    };

    initGraphicType(GRAPHIC_TYPE_ICON);
    initGraphicType(GRAPHIC_TYPE_FEATURE);
    initGraphicType(GRAPHIC_TYPE_PHONE);
    initGraphicType(GRAPHIC_TYPE_TABLET_7);
    initGraphicType(GRAPHIC_TYPE_TABLET_10);

    // console.log('loadLocalGraphics', { updatedGraphics });
    this.setState({ localGraphics: updatedGraphics });
  };

  hasApiInfo = () => {
    const hasAccountEmail = !_.isEmpty(this.getAppProperty('GoogleServiceAccountEmail'));
    const hasAccountKey = !_.isEmpty(this.getAppProperty('GoogleServiceAccountKey'));
    return hasAccountEmail && hasAccountKey;
  };

  onMoveStep = (stepIndex) => {
    if (this.props.onMoveStep) this.props.onMoveStep(stepIndex);
  };

  onAddGoogleServiceAccountFile = (file) => {
    return new Promise((resolve, reject) => {
      const { message, whiteLabelInfo } = this.state;

      var reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        message[STEP_ENABLE_API] = 'Processing...';
        this.setState({ loading: true, message }, async () => {
          try {
            const googleServiceAccount = e.target.result;
            const { data } = await automationActions.setupGoogleServiceAccount(
              whiteLabelInfo.RowId,
              this.getAppBranchName(''),
              googleServiceAccount,
            );
            message[STEP_ENABLE_API] = 'Saved successfully';
            this.props.whiteLabelLoaded(data);
            this.setState({ loading: false, message }, resolve());
          } catch (error) {
            message[STEP_ENABLE_API] = getApiError(error).message;
            this.setState({ loading: false, message }, reject(error));
          }
        });
      };
      reader.onerror = (error) => {
        message[STEP_ENABLE_API] = error.message;
        reject(error);
      };
    });
  };

  onAddGoogleServicesFile = (file) => {
    return new Promise((resolve, reject) => {
      const { message, whiteLabelInfo } = this.state;

      var reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        message[STEP_FCM_GOOGLE] = 'Processing...';
        this.setState({ loading: true, message }, async () => {
          try {
            const googleService = e.target.result;
            const { data } = await automationActions.setupGoogleService(whiteLabelInfo.RowId, this.getAppBranchName(''), googleService);
            message[STEP_FCM_GOOGLE] = 'Saved successfully';
            this.props.whiteLabelLoaded(data);
            this.setState({ loading: false, message }, resolve());
          } catch (error) {
            message[STEP_FCM_GOOGLE] = getApiError(error).message;
            this.setState({ loading: false, message }, reject(error));
          }
        });
      };
      reader.onerror = (error) => {
        message[STEP_FCM_GOOGLE] = error.message;
        reject(error);
      };
    });
  };

  onChangeText = (event, fieldName) => {
    const { whiteLabelInfo } = this.state;
    whiteLabelInfo[fieldName] = event.target.value;
    this.setState({ whiteLabelInfo });
  };

  onSaveCloudMessageToken = () => {
    const { whiteLabelInfo, cloudMessageToken, message } = this.state;
    message[STEP_FCM_TOKEN] = 'Saving...';
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.setupCloudMessage(whiteLabelInfo.RowId, this.getAppBranchName(''), cloudMessageToken);
        message[STEP_FCM_TOKEN] = 'Saved successfully';
        this.props.whiteLabelLoaded(data);
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_FCM_TOKEN] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  onSaveReleaseUrl = () => {
    const { whiteLabelInfo, releaseUrl, message } = this.state;
    message[STEP_APP] = 'Saving...';
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.saveReleaseUrl(whiteLabelInfo.RowId, this.getAppBranchName(''), releaseUrl);
        message[STEP_APP] = 'Saved successfully';
        this.props.whiteLabelLoaded(data);
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_APP] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  onBranchNameChanged = (event) => {
    const { whiteLabelInfo } = this.state;
    const newInfo = _.cloneDeep(whiteLabelInfo);
    newInfo.ClientCode = event.target.value.replace(' ', '-');
    this.setState({ whiteLabelInfo: newInfo });
  };

  onBranchChanged = (key) => {
    this.setState({ selectedApp: key, resetMessage: '' }, this.setAppInfo);
  };

  onClearClientCode = () => {
    this.props.whiteLabelReset();
    this.setState({ loading: false, loadingMessage: '', resetMessage: '', selectedApp: '' }, this.setAppInfo);
  };

  onLoadWhiteLabel = () => {
    const { whiteLabelInfo } = this.state;
    const { activeWhiteLabel } = this.props;
    const clientCode = whiteLabelInfo.ClientCode;
    if (clientCode === activeWhiteLabel.ClientCode) return;
    if (!clientCode) {
      this.onClearClientCode();
      return;
    }

    let loadingMessage = `Loading ${clientCode} details...`;
    this.setState({ loading: true, loadingMessage, cloudMessageToken: '' }, async () => {
      try {
        const { data } = await automationActions.getWhiteLabel(clientCode);
        const selectedApp = data ? data.ClientCode : '';
        // console.log('onLoadWhiteLabel', data, 'selectedApp', selectedApp);
        loadingMessage = data ? `Details for ${data.ClientName} has been loaded` : `${clientCode} does not exist`;
        this.setState({ loading: false, loadingMessage, selectedApp }, () => {
          if (data) {
            this.props.whiteLabelLoaded(data);
          } else {
            this.props.whiteLabelReset();
          }
        });
      } catch (error) {
        this.setState({ loading: false, loadingMessage: getApiError(error).message });
      }
    });
  };

  onResetUser = () => {
    const { whiteLabelInfo, selectedApp } = this.state;
    this.setState({ loading: true, resetMessage: 'Setting up review user...' }, async () => {
      try {
        const { data } = await automationActions.resetTestUser(
          whiteLabelInfo.RowId,
          selectedApp || whiteLabelInfo.ClientCode,
          REVIEW_USER_PREFIX,
        );
        // console.log('onResetUser', data);
        this.setState({ loading: false, resetMessage: 'Review user has been set up successfully' }, () => {
          if (data) this.props.whiteLabelLoaded(data);
        });
      } catch (error) {
        this.setState({ loading: false, resetMessage: getApiError(error).message });
      }
    });
  };

  onSetGraphic = async (type, index, file) => {
    const { localGraphics } = this.state;
    // console.log('onSetGraphic', type, index, file);

    try {
      const fileDataUrl = await fileActions.readBase64(file);
      let updatedLocalGraphics = _.cloneDeep(localGraphics);
      if (!updatedLocalGraphics) updatedLocalGraphics = {};
      if (!updatedLocalGraphics[type]) updatedLocalGraphics[type] = {};
      updatedLocalGraphics[type][index] = {
        url: fileDataUrl,
        type: file.type,
        name: file.name,
      };
      this.setState({ localGraphics: updatedLocalGraphics });
    } catch (error) {
      const apiError = getApiError(error);
      console.error('onSetGraphic', apiError);
      const message = apiError.message || apiError;
      this.setGraphicMessage(type, index, `Error - ${message}`);
    }
  };

  onUploadGraphics = async (type) => {
    const { localGraphics, whiteLabelInfo } = this.state;
    if (!localGraphics || !localGraphics[type]) return;

    this.setState({ loading: true }, async () => {
      const appClientCode = this.getAppBranchName('');
      let res = null;
      const setSize = Object.keys(localGraphics[type]).length;
      for (let index = 0; index < setSize; index++) {
        try {
          const cachedData = localGraphics[type][index] || null;
          if (cachedData && cachedData.type) {
            const fileData = cachedData.url.split(',')[1].trim();
            // console.log('onUploadGraphics', { fileData, fileType: cachedData.type, fileName: cachedData.name });
            this.setGraphicMessage(type, index, `Uploading ${cachedData.name}...`);
            res = await automationActions.uploadAndroidGraphic(whiteLabelInfo.RowId, appClientCode, type, index, fileData, cachedData.type);
            // console.log('onUploadGraphics', res.data);
            this.setGraphicMessage(type, index, '');
          }
        } catch (error) {
          const apiError = getApiError(error);
          console.error('onUploadGraphics', apiError);
          const message = apiError.message || apiError;
          this.setGraphicMessage(type, index, `Error - ${message}`);
        }
      }
      if (res) this.props.whiteLabelLoaded(res.data);
      this.setState({ loading: false });
    });
  };

  onDeleteGraphics = async (type) => {
    const { whiteLabelInfo } = this.state;

    this.setState({ loading: true }, async () => {
      const appClientCode = this.getAppBranchName('');
      let res = null;
      try {
        this.setGraphicMessage(type, null, `Deleting ${type} graphics...`);
        res = await automationActions.deleteAndroidGraphic(whiteLabelInfo.RowId, appClientCode, type);
        // console.log('onDeleteGraphics', res.data);
        this.setGraphicMessage(type, null, '');
      } catch (error) {
        const apiError = getApiError(error);
        console.error('onDeleteGraphics', apiError);
        const message = apiError.message || apiError;
        this.setGraphicMessage(type, null, `Error - ${message}`);
      }
      if (res) this.props.whiteLabelLoaded(res.data);
      this.setState({ loading: false });
    });
  };

  onGraphicError = (type, index) => {
    this.setGraphicMessage(type, index, `Upload is successful but the image is not accessible from this site`);
    setTimeout(() => {
      this.setGraphicMessage(type, index, '');
      // const { localGraphics } = this.state;
      // const updatedLocalGraphics = _.cloneDeep(localGraphics);
      // const graphic = updatedLocalGraphics[type][index];
      // if (graphic && graphic.url.startsWith('https:')) {
      //   const originalUrl = graphic.url.split('?d=')[0];
      //   updatedLocalGraphics[type][index].url = `${originalUrl}?d=${moment().valueOf()}`;
      //   this.setState({ localGraphics: updatedLocalGraphics });
      // }
    }, 5000);
  };

  onInitialisePlayStore = () => {
    const { whiteLabelInfo, message, title, shortDescription, fullDescription } = this.state;
    message[STEP_APP_INIT] = 'Initialising play store...';
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.initPlayStore(
          whiteLabelInfo.RowId,
          this.getAppBranchName(''),
          title,
          shortDescription,
          fullDescription,
        );
        message[STEP_APP_INIT] = 'Initialised successfully';
        this.props.whiteLabelLoaded(data);
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_APP_INIT] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  renderBranchInput = () => {
    const { whiteLabelInfo, loading, loadingMessage } = this.state;
    const { activeWhiteLabel } = this.props;
    const branchName = whiteLabelInfo ? whiteLabelInfo.ClientCode : '';
    const isBranchValid = !_.isEmpty(branchName);
    const canClear = isBranchValid && activeWhiteLabel && activeWhiteLabel.ClientCode === branchName;

    return (
      <div className="flex flex-row flex-center">
        <GenericInput
          className="marginTop-10"
          id="branch"
          style={{ width: 300 }}
          type="text"
          placeholder="Client code"
          isValid={() => isBranchValid}
          value={branchName}
          onChange={this.onBranchNameChanged}
          onBlur={this.onLoadWhiteLabel}
          isRequired
        />
        <Button
          style={{ width: 90 }}
          className="marginLeft-24"
          inline
          buttonType="primary"
          onClick={canClear ? this.onClearClientCode : null}
          isActive={branchName && !loading}
        >
          {canClear ? 'Clear' : 'Load'}
        </Button>
        <div className="marginLeft-16">{loadingMessage}</div>
      </div>
    );
  };

  renderSelectApp = () => {
    const { whiteLabelInfo, loading } = this.state;
    const branches = whiteLabelInfo.Apps
      ? whiteLabelInfo.Apps.map((b) => {
          return { Title: b.ClientCode, Key: b.ClientCode };
        })
      : [];

    return (
      <DropdownInput
        id={`dropdown_app`}
        style={{ width: 250, marginBottom: 'unset', marginTop: 10 }}
        placeholder="Select Sub App"
        value={this.getAppBranchName('')}
        options={branches}
        onSelect={this.onBranchChanged}
        disabled={loading}
      />
    );
  };

  renderStepSelectApp = () => {
    return (
      <div>
        {renderTitle('Select App')}
        {renderDescription('Select Android app to build.')}
        <ol>
          {renderTextStep(
            <div>
              Enter client code (e.g. aveo)
              {this.renderBranchInput()}
            </div>,
          )}
          {renderTextStep(
            <div>
              Select app
              {this.renderSelectApp()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStepEnableApi = () => {
    const { message } = this.state;
    const hasGoogleServiceAccount = !_.isEmpty(this.getAppProperty('GoogleServiceAccountKey'));

    return (
      <div>
        {renderTitle('Enable Google Play Publishing API - Optional')}
        {renderDescription(
          'In this step, we will create a Google Service Account to enable automation. If not enabled, some automation features will not be available and will require manual intervention.',
        )}
        <ol>
          {renderTextStep(
            <div>
              If a Google Service Account has already been set up previously,{' '}
              <a href="#uploadgoogleserviceaccount">upload the service account key</a>
            </div>,
          )}
          {renderOpenLinkStep(
            'https://accounts.google.com/ServiceLogin?service=androiddeveloper&passive=true&continue=https%3A%2F%2Fplay.google.com%2Fconsole%2Fdeveloper%2F&_ga=2.4515891.1482629686.1660270642-1937964670.1660270642',
          )}
          {renderImageStep(
            <div>
              Sign in to Google
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Email</b> <b>{renderCopyToClipboardText(automationConfig.devAccountEmail)}</b>
                </li>
                <li>
                  Enter <b>Password</b>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/1+-+Sign+In.png',
            '50%',
            '20%',
          )}
          {renderImageStep(
            <div>
              Expand <b>Setup</b> on the left menu pane and choose <b>API access</b>
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-google-service-account/01-open-google-play-console.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              If API access is not enabed, click <b>Choose a project to link</b>
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-google-service-account/02-api-access.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Select <b>Create new project</b>, then click <b>Link project</b>
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-google-service-account/03-create-new-project.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click <b>Create new service account</b> link
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-google-service-account/04-create-new-service-account.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click <b>Google Cloud Platform</b> link in the dialog
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-google-service-account/05-service-account-dialog.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click <b>CREATE SERVICE ACCOUNT</b>
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-google-service-account/06-google-cloud-platform.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Enter <b>Service account name</b> field <b>{renderCopyToClipboardText('pluss-automation')}</b>
              <br />
              Enter <b>Service account description</b> field <b>{renderCopyToClipboardText('Pluss Automation')}</b>
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-google-service-account/07-service-account-name.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click <b>Select a role</b> and choose <b>Service Accounts / Service Account User</b>
              <br />
              Click <b>Done</b>
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-google-service-account/08-service-account-permissions.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Select <b>Manage keys</b> from the <b>Actions</b> button
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-google-service-account/09-a-create-key.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click <b>Create new key</b>
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-google-service-account/09-b-create-key.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Choose <b>JSON</b> and then click <b>CREATE</b> button
              <br />
              Download the <b>.json</b> file and store the file in a secure place
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-google-service-account/10-create-json-key.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Return to the <b>API access</b> page on the Google Play Console and ensure it shows the new service account
              <br />
              Click <b>Grant permissions</b> for the service account
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-google-service-account/11-grant-access.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click <b>Invite user</b>
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-google-service-account/12-invite-user.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click <b>Send invitation</b>
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-google-service-account/13-send-invitation.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Navigate to <b>Google Play Android Developer API</b> at{' '}
              <b>{renderCopyToClipboardText('https://console.cloud.google.com/apis/library/androidpublisher.googleapis.com')}</b>
              <ul>
                <li>Ensure the API is enabled</li>
              </ul>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/enablegoogleplayapi.png',
            '50%',
            '40%',
          )}
          {renderTextStep(
            <div id="uploadgoogleserviceaccount">
              Drop and upload the .json file downloaded
              <div className="flex flex-row flex-center marginTop-10">
                <FileInput
                  ref={(ref) => {
                    this.keyFileInput = ref;
                  }}
                  style={{ height: 120, width: 240 }}
                  handleFile={this.onAddGoogleServiceAccountFile}
                  accept="application/json"
                  simpleStyle
                  multiple={false}
                />
                <div className="marginLeft-16">{message[STEP_ENABLE_API]}</div>
                {hasGoogleServiceAccount ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
              </div>
            </div>,
          )}
          {renderTextStep('Close the Incognito window')}
        </ol>
      </div>
    );
  };

  renderStepSetUpFcm = () => {
    const { message, loading, cloudMessageToken } = this.state;
    const hasGoogleServiceKey = this.getAppProperty('HasGoogleServices');
    const cloudMessageTokenValid = !_.isEmpty(cloudMessageToken);

    return (
      <div>
        {renderTitle('Set up Firebase Cloud Messaging')}
        {renderDescription('In this step, we will set up the Firebase Cloud Messaging to enable push notification for the Android app.')}
        <ol>
          {renderOpenLinkStep('https://console.firebase.google.com')}
          {renderImageStep(
            <div>
              Sign in to Google
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Email</b> <b>{renderCopyToClipboardText(automationConfig.devAccountEmail)}</b>
                </li>
                <li>
                  Enter <b>Password</b>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/1+-+Sign+In.png',
            '50%',
            '20%',
          )}
          {renderImageStep(
            <div>
              Click <b>Add project</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Add+Project.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Enter the project name <b>{renderCopyToClipboardText(`pluss-${this.getAppBranchName()}`)}</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Project+Name.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Click <b>Select parent resource</b>
              <ul>
                <li>
                  Select <b>joinpluss.com</b> and then click <b>Done</b>
                </li>
                <li>
                  Click <b>Continue</b>
                </li>
              </ul>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Parent+Resource.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Uncheck <b>Enable Google Analytis for this project</b> then click <b>Create project</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Disable+Google+Analytics.png',
            '50%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Once new project is ready, click <b>Continue</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Project+Is+Ready.png',
            '50%',
            '30%',
          )}
          {renderImageStep(
            <div>
              Next to the <b>Project Overview</b>, click the cog symbol and click <b>Project settings</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Open+Project+Settings.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Scroll down to <b>Your apps</b> section and click the <b>Android</b> button
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Add+Android+App.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Under <b>Register app</b>,
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Android package name</b> <b>{renderCopyToClipboardText(`com.pluss.${this.getAppBundleId()}`)}</b> (OR the actual
                  package name saved during the configuration steps)
                </li>
                <li>
                  Enter <b>App nickname</b> <b>{renderCopyToClipboardText(this.getAppProperty('ClientName'))}</b>
                </li>
              </ol>
              Then click <b>Register app</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/4+-+Register+App.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Click <b>Download google-services.json</b> button to download the configuration file
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/5+-+Download+Config.png',
            '50%',
            '40%',
          )}
          {renderTextStep(
            <div>
              Drop and upload <b>google-services.json</b> file downloaded
              <div className="flex flex-row flex-center marginTop-10">
                <FileInput
                  ref={(ref) => {
                    this.servicesFileInput = ref;
                  }}
                  style={{ height: 120, width: 240 }}
                  handleFile={this.onAddGoogleServicesFile}
                  accept="application/json"
                  simpleStyle
                  multiple={false}
                />
                <div className="marginLeft-16">{message[STEP_FCM_GOOGLE]}</div>
                {hasGoogleServiceKey ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
              </div>
            </div>,
          )}
          {renderImageStep(
            <div>
              Click <b>Cloud Messaging</b> tab at the top
              <ul>
                <li>
                  To enable <b>Cloud Messaging API (Legacy)</b>, expand the section and click <b>Manage API in Google Cloud Console</b>
                </li>
                <li>
                  Enable <b>Cloud Messaging</b> service
                </li>
              </ul>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/enablecloudmessaging.png',
            '50%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Return to the <b>Cloud Messaging</b> tab in <b>Project settings</b>
              <ul>
                <li>
                  Under <b>Cloud Messaging API (Legacy)</b>, copy the <b>Token</b> next to the <b>Server key</b>
                </li>
                <li>
                  Paste the <b>Token</b> below
                  <GenericInput
                    className="marginTop-10"
                    id="cloudMessageToken"
                    style={{ width: 400 }}
                    type="text"
                    isValid={() => cloudMessageTokenValid}
                    value={cloudMessageToken}
                    onChange={(e) => this.setState({ cloudMessageToken: e.target.value })}
                    isRequired
                  />
                  <div className="flex flex-row flex-center">
                    <Button
                      inline
                      buttonType="primary"
                      onClick={this.onSaveCloudMessageToken}
                      isActive={cloudMessageTokenValid && !loading}
                    >
                      Save
                    </Button>
                    <div className="marginLeft-16">{message[STEP_FCM_TOKEN]}</div>
                  </div>
                </li>
              </ul>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/getmessagingserverkey.png',
            '50%',
            '50%',
          )}
          {renderTextStep('Close the Incognito window')}
        </ol>
      </div>
    );
  };

  renderStepBuildAppBundle = () => {
    return (
      <div>
        {renderTitle('Build Android App Bundle')}
        {renderDescription(
          'In this step, we will build an App Bundle file to upload. The following steps assume that you have Docker Desktop installed and have the plussdev/default image.',
        )}
        <ol>
          {renderTextStep(
            <div>
              For detailed instructions on how to set up the Docker Desktop for building the app, refer to{' '}
              <a href="https://docs.google.com/document/d/1bxv7Xvy_K4Xk16L0bolRzIsLj6EtXC8O6sHgXt5Bxr4/edit?usp=sharing" target="_blank">
                Building and Uploading Apps
              </a>
            </div>,
          )}
          {renderImageStep(
            <div>
              Ensure you are running a docker container from the <b>plussdev/default</b> image then click <b>Run</b> button without changing
              any settings.
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Run+Docker+Image.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click on the <b>Containers/Apps</b> menu on the left and then click the CLI button to open the command prompt
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Open+Command+Prompt.png',
            '40%',
            '60%',
          )}
          {renderTextStep(
            <div>
              Ensure you are in <b>minuss-expo</b> folder. If not, switch to the folder by typing,
              {renderEmbed('cd minuss-expo')}
            </div>,
          )}
          {renderTextStep(
            <div>
              Start building the android app bundle by typing,
              {renderEmbed(`npm run build:android ${this.getAppBranchName()}`)}
            </div>,
          )}
          {renderTextStep(
            <div>
              When prompted to generate a new <b>keystore</b>, type <b>Y</b> to allow Expo to generate
            </div>,
          )}
          {renderTextStep(<div>Wait for the build to complete</div>)}
          {renderImageStep(
            <div>
              Download App Bundle file
              <ol className="marginBottom-16">
                <li>
                  Right click on the url to the standalone app built (e.g.{' '}
                  <b>https://expo.io/artifacts/94e075f9-852d-48a6-bd17-e8228a9ca618</b>)
                </li>
                <li>Open the url to start downloading the App Bundle file</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Build+Complete.png',
            '40%',
            '60%',
          )}
          {renderTextStep(
            <div>
              Upload cloud messaging server key written down from previous steps to the Expo server by typing,
              {renderEmbed(`expo push:android:upload --api-key ${this.getAppProperty('CloudMessageToken') || '{MISSING_TOKEN}'}`)}
            </div>,
          )}
          {renderTextStep('Close the terminal window')}
        </ol>
      </div>
    );
  };

  renderResetUser = () => {
    const { loading, resetMessage, whiteLabelInfo, selectedApp } = this.state;
    const appUser = getWhiteLabelAppUser(whiteLabelInfo, selectedApp, REVIEW_USER_PREFIX);
    const canReset = !loading;

    return (
      <div className="marginTop-10">
        {appUser ? (
          <div>
            <div>
              {`User: ${appUser.user}`}
              {appUser.user ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
            </div>
            <div>
              {`Email: ${appUser.email}`}
              {appUser.email ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
            </div>
            <div>
              {`Password: ${appUser.password}`}
              {appUser.password ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
            </div>
          </div>
        ) : null}
        <div className="flex flex-row flex-center marginTop-10">
          <Button style={{ width: 90 }} inline buttonType="primary" onClick={() => canReset && this.onResetUser()} isActive={canReset}>
            Start
          </Button>
          <div className="marginLeft-16">{resetMessage}</div>
        </div>
      </div>
    );
  };

  renderStepSetupReviewUser = () => {
    return (
      <div>
        {renderTitle('Set up Review User')}
        {renderDescription(
          'In this step, we will ensure an app review user is set up and/or have the password reset for a fresh review process.',
        )}
        <ol>
          {renderTextStep(
            <div>
              Create or reset app review user
              {this.renderResetUser()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderGraphicInput = (type, index, graphic, enabled) => {
    const message = this.getGraphicMessage(type, index);
    // console.log('renderGraphicInput', { type, index, enabled, graphic, message });
    const graphicSet = !_.isEmpty(graphic);
    const processing = !_.isEmpty(message);

    return (
      <div
        key={`${type}_${index}`}
        className="marginRight-10"
        style={{ width: GRAPHIC_WIDTH, height: GRAPHIC_HEIGHT, position: 'relative' }}
      >
        {graphicSet ? (
          <img
            style={{ width: GRAPHIC_WIDTH, height: GRAPHIC_HEIGHT, objectFit: 'contain' }}
            src={graphic}
            onError={() => this.onGraphicError(type, index)}
          />
        ) : null}
        {enabled ? (
          <FileInput
            ref={(ref) => {
              this.graphicInput = ref;
            }}
            containerStyle={{
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: graphicSet && !processing ? 0.2 : 0.9,
            }}
            style={{ width: GRAPHIC_WIDTH, height: GRAPHIC_HEIGHT }}
            handleFile={(file) => this.onSetGraphic(type, index, file)}
            simpleStyle
            multiple={false}
            accept="image/png"
          />
        ) : null}
        {processing ? (
          <div
            className={`padding-8 ${message.startsWith('Error') ? 'text-error' : 'text-body'}`}
            style={{ width: GRAPHIC_WIDTH, height: GRAPHIC_HEIGHT / 4, position: 'absolute', top: 0, left: 0 }}
          >
            {message}
          </div>
        ) : null}
      </div>
    );
  };

  renderGraphics = (type, title, helpText, enabled) => {
    const { localGraphics, loading } = this.state;
    if (!localGraphics[type]) return null;

    const typeMessage = this.getGraphicMessage(type, null);
    const graphicInputs = Object.values(localGraphics[type]);
    const canUpload = graphicInputs.some((g) => g.type);
    const canDelete = graphicInputs.some((g) => !_.isNil(g.id));
    // console.log('renderGraphics', { type, canUpload, graphicInputs, canDelete });

    return (
      <div className="flex flex-row flex-center marginTop-10">
        <div className="marginRight-20" style={{ width: 300 }}>
          <div className="font-bold">{title}</div>
          {!_.isEmpty(helpText) ? <div>{helpText}</div> : null}
        </div>
        {graphicInputs.map((g, i) => this.renderGraphicInput(type, i, g.url, enabled))}
        <div className="flex flex-column">
          <Button
            style={{ width: 100 }}
            inline
            buttonType="primary"
            onClick={() => this.onUploadGraphics(type)}
            isActive={canUpload && !loading}
          >
            Upload
          </Button>
          <Button
            style={{ width: 100, marginTop: 8 }}
            inline
            buttonType="primary"
            onClick={() => this.onDeleteGraphics(type)}
            isActive={canDelete && !loading}
          >
            Delete
          </Button>
          {typeMessage ? (
            <div className={`padding-8 ${typeMessage.startsWith('Error') ? 'text-error' : 'text-body'}`}>{typeMessage}</div>
          ) : null}
        </div>
      </div>
    );
  };

  renderInitialisePlayStore = () => {
    const { message, loading, title, shortDescription, fullDescription } = this.state;
    const apiEnabled = this.hasApiInfo();
    const playStoreIntialised = this.getAppProperty('PlayStoreInitialised');
    const isTitleValid = !_.isEmpty(title);
    const isShortDescriptionValid = !_.isEmpty(shortDescription);
    const isFullDescriptionValid = !_.isEmpty(fullDescription);

    return (
      <div className="marginTop-10">
        <ul className="marginTop-10">
          <li>
            App name
            <div className="flex flex-row flex-center">
              <GenericInput
                className="marginTop-10"
                id="title"
                style={{ width: 800 }}
                type="text"
                isValid={isTitleValid}
                value={title}
                onChange={(e) => this.setState({ title: e.target.value })}
                maxLength={30}
                help={`${30 - title.length} characters`}
              />
            </div>
          </li>
          <li>
            Short description
            <div className="flex flex-row flex-center">
              <GenericInput
                className="marginTop-10"
                id="shortDescription"
                style={{ width: 800 }}
                type="text"
                isValid={isShortDescriptionValid}
                value={shortDescription}
                onChange={(e) => this.setState({ shortDescription: e.target.value })}
                maxLength={80}
                help={`${80 - shortDescription.length} characters`}
              />
            </div>
          </li>
          <li>
            Full description
            <div className="flex flex-row flex-center">
              <GenericInput
                className="marginTop-10"
                id="fullDescription"
                style={{ width: 800 }}
                type="textarea"
                componentClass="textarea"
                isValid={isFullDescriptionValid}
                value={fullDescription}
                onChange={(e) => this.setState({ fullDescription: e.target.value })}
                maxLength={4000}
                help={`${4000 - fullDescription.length} characters`}
              />
            </div>
          </li>
        </ul>
        <div className="flex flex-row flex-center marginTop-10">
          {apiEnabled ? (
            <Button style={{ width: 90 }} inline buttonType="primary" onClick={this.onInitialisePlayStore} isActive={!loading}>
              Save
            </Button>
          ) : null}
          <div className="marginLeft-10">{message[STEP_APP_INIT]}</div>
          {playStoreIntialised ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
        </div>
      </div>
    );
  };

  renderStepSetupAndroidApp = () => {
    const { whiteLabelInfo, selectedApp, message, loading, releaseUrl } = this.state;
    const appUser = getWhiteLabelAppUser(whiteLabelInfo, selectedApp, REVIEW_USER_PREFIX);
    const userName = appUser ? appUser.email : REVIEW_USER_MISSING;
    const password = appUser ? appUser.password : REVIEW_USER_MISSING;
    const hasGoogleServiceKey = !_.isEmpty(this.getAppProperty('GoogleServiceAccountKey'));
    const releaseUrlValid = !_.isEmpty(releaseUrl);
    const apiEnabled = this.hasApiInfo();

    return (
      <div>
        {renderTitle('Set up new app')}
        {renderDescription(
          `In this step, we will set up a new app in Google Play Console.${
            !apiEnabled ? ' Because API is not enabled, there are some extra steps that needs manual intervention.' : ''
          }`,
        )}
        <ol>
          {renderOpenLinkStep(
            'https://accounts.google.com/ServiceLogin?service=androiddeveloper&passive=true&continue=https%3A%2F%2Fplay.google.com%2Fconsole%2Fdeveloper%2F&_ga=2.4515891.1482629686.1660270642-1937964670.1660270642',
          )}
          {renderImageStep(
            <div>
              Sign in to Google
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Email</b> <b>{renderCopyToClipboardText(automationConfig.devAccountEmail)}</b>
                </li>
                <li>
                  Enter <b>Password</b>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/1+-+Sign+In.png',
            '50%',
            '20%',
          )}
          {renderImageStep(
            <div>
              Click <b>Create app</b>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/2+-+All+Apps.png',
            '30%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>App details</b>,
              <ol className="marginBottom-16">
                <li>
                  Enter <b>App name</b> <b>{renderCopyToClipboardText(this.getAppProperty('ClientName'))}</b>
                </li>
                <li>
                  Select <b>Default language</b> - <b>English (Australia) - en-AU</b>
                </li>
                <li>
                  Select <b>App or game</b> - <b>App</b>
                </li>
                <li>
                  Select <b>Free or paid</b> - <b>Free</b>
                </li>
              </ol>
              Under <b>Declarations</b>,
              <ol className="marginBottom-16">
                <li>
                  For <b>Developer Program Policies</b>, check <b>Confirm app meets the Developer Program Policies</b>
                </li>
                <li>
                  For <b>US export laws</b>, check <b>Accept US export laws</b>
                </li>
              </ol>
              Then click <b>Create app</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/3+-+Create+App.png',
            '40%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Under <b>Release</b> on the left menu, click <b>Production</b>
              <ol className="marginBottom-16">
                <li>
                  Copy the <b>URL</b> of this page in the address bar. (This will later be used to redirect the user to future releases)
                </li>
                <li>
                  Paste the <b>URL</b> below
                  <GenericInput
                    className="marginTop-10"
                    id="releaseUrl"
                    style={{ width: 400 }}
                    type="text"
                    isValid={() => releaseUrlValid}
                    value={releaseUrl}
                    onChange={(e) => this.setState({ releaseUrl: e.target.value })}
                    isRequired
                    placeholder="e.g. https://play.google.com/console/u/0/developers/8994129238190593164/app/4972307585907085579/tracks/production"
                  />
                  <div className="flex flex-row flex-center">
                    <Button inline buttonType="primary" onClick={this.onSaveReleaseUrl} isActive={releaseUrlValid && !loading}>
                      Save
                    </Button>
                    <div className="marginLeft-16">{message[STEP_APP]}</div>
                  </div>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/playstoreReleaseUrl.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Select countries where the app will be made available by,
              <ol className="marginBottom-16">
                <li>
                  Click <b>Countries / regions</b> tab
                </li>
                <li>
                  Click <b>Add countires / regions</b>
                </li>
                <li>
                  Select <b>Australia</b> from the list (and other countries as required)
                </li>
                <li>
                  Click <b>Add countries / regions</b> button
                </li>
              </ol>
              Then click <b>Create new release</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/8+-+Production.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>App bundles and APKs</b>
              <ol className="marginBottom-16">
                <li>
                  Click <b>Upload</b> and select the App Bundle file downloaded from previous steps
                </li>
                <li>Wait until the upload completes</li>
                <li>You should see the App Bundle file uploaded in the list</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/10+-+App+Bundles.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>Release details</b>,
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Release notes</b>
                  {renderEmbed('<en-AU>\nInitial release\n</en-AU>')}
                </li>
                <li>
                  Click <b>Save</b>
                </li>
                <li>
                  Click <b>Review release</b> button
                </li>
              </ol>
              Then click <b>Start rollout to Production</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/11+-+Release+Details.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>Grow/Store presence</b> on the left menu, click <b>Store settings</b>
              <ol className="marginBottom-16">
                <li>
                  Select <b>Category</b> - <b>Lifestyle</b>
                </li>
                <li>
                  Enter <b>Email address</b> <b>{renderCopyToClipboardText(automationConfig.helpDeskEmail)}</b>
                </li>
                <li>
                  Enter <b>Website</b> <b>{renderCopyToClipboardText(automationConfig.websiteUrl)}</b>
                </li>
              </ol>
              Then click <b>Save</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/6+-+Store+Settings.png',
            '40%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Under <b>Policy</b> on the left menu, click <b>App content</b>
              <ol className="marginBottom-16">
                <li>
                  For <b>Privacy policy</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Enter <b>{renderCopyToClipboardText(automationConfig.privacyUrl)}</b>
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>Ads</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Select <b>No, my app does not contain ads</b>
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>App access</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Select <b>All or some functionality is restricted</b>
                    </li>
                    <li>
                      Click <b>+ Add new instructions</b>
                      <ol>
                        <li>
                          Enter <b>Name</b> (<b>Login Credentials</b>)
                        </li>
                        <li>
                          Enter <b>Username / phone number</b> <b>{renderCopyToClipboardText(userName)}</b>
                        </li>
                        <li>
                          Enter <b>Password</b> <b>{renderCopyToClipboardText(password)}</b>
                        </li>
                        <li>
                          Enter <b>Any other instructions</b> (
                          <b>
                            Use the app as if you might be a member of a community - explore What's On and invite friends to an event you
                            might be attending, check the News for announcements or stories about your neighbours.
                          </b>
                          )
                        </li>
                        <li>
                          Click <b>Apply</b> button
                        </li>
                      </ol>
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>Content ratings</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Click <b>Start questionnaire</b> button
                    </li>
                    <li>
                      For <b>Category</b>,
                      <ol>
                        <li>
                          Enter <b>Email address</b> <b>{renderCopyToClipboardText(automationConfig.devAccountEmail)}</b>
                        </li>
                        <li>
                          Select <b>Category</b> (<b>All Other App Types</b>)
                        </li>
                        <li>
                          Click <b>Next</b> button
                        </li>
                      </ol>
                      For <b>All Other App Types</b>,
                      <ol>
                        <li>
                          Answer <b>No</b> to{' '}
                          <b>
                            Does the app contain any ratings-relevant content (e.g., sex, violence, language) downloaded as part of the app
                            package (code, assets)?
                          </b>
                        </li>
                        <li>
                          Answer <b>Yes</b> to{' '}
                          <b>
                            Does the app natively allow users to interact or exchange content with other users through voice communication,
                            text, or sharing images or audio?
                          </b>
                        </li>
                        <li>
                          Answer <b>No</b> to <b>Is shared, user-generated content the primary source of content in the app?</b>
                        </li>
                        <li>
                          Answer <b>No</b> to <b>Does the app permit the public sharing of nudity?</b>
                        </li>
                        <li>
                          Answer <b>No</b> to{' '}
                          <b>Does the app permit the public sharing of real-world, graphic violence outside of a newsworthy context?</b>
                        </li>
                        <li>
                          Answer <b>Yes</b> to <b>Does the app include the ability to block users or user-generated content?</b>
                        </li>
                        <li>
                          Answer <b>Yes</b> to <b>Does the app include the ability to report users or user-generated content?</b>
                        </li>
                        <li>
                          Answer <b>Yes</b> to <b>Can interactions in the app be limited to invited friends only?</b>
                        </li>
                        <li>
                          Answer <b>Yes</b> to <b>Does the app include chat moderation?</b>
                        </li>
                        <li>
                          Select <b>Online Content</b> (<b>Yes</b>)
                        </li>
                        <li>
                          Select <b>Violence</b> (<b>No</b>)
                        </li>
                        <li>
                          Select <b>Sexuality</b> (<b>No</b>)
                        </li>
                        <li>
                          Select <b>Language</b> (<b>No</b>)
                        </li>
                        <li>
                          Select <b>Controlled Substance</b> (<b>No</b>)
                        </li>
                        <li>
                          Select <b>Promotion Of Age-Restricted Products Or Activities</b> (<b>No</b>)
                        </li>
                        <li>
                          Answer <b>No</b> to <b>Does the app share the user's current physical location with other users?</b>
                        </li>
                        <li>
                          Answer <b>No</b> to <b>Does the app allow users to purchase digital goods?</b>
                        </li>
                        {/* <li>
                          Answer <b>No</b> to{' '}
                          <b>Does the app contain any swastikas, other Nazi symbols or propaganda deemed unconstitutional in Germany?</b>
                        </li> */}
                        <li>
                          Answer <b>No</b> to <b>Is the app a web browser or search engine?</b>
                        </li>
                        <li>
                          Answer <b>No</b> to <b>Is the app primarily a news or educational product?</b>
                        </li>
                        <li>
                          Click <b>Save</b>
                        </li>
                        <li>
                          Click <b>Next</b> button
                        </li>
                      </ol>
                    </li>
                    <li>
                      Click <b>Submit</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>Target audience and content</b>, click <b>Start</b>
                  <ol>
                    <li>
                      For <b>Target age</b>,
                      <ol>
                        <li>
                          Check <b>18 and over</b>
                        </li>
                        <li>
                          Click <b>Next</b> button
                        </li>
                      </ol>
                    </li>
                    <li>
                      For <b>Store presence</b>,
                      <ol>
                        <li>
                          Answer <b>No</b> to <b>Appeal to children</b>
                        </li>
                        <li>
                          Click <b>Next</b> button
                        </li>
                      </ol>
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>News apps</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Select <b>News apps</b> (<b>No</b>)
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>COVID-19 contact tracing and status apps</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Select <b>My app is not a publicly available COVID-19 contact tracing or status app</b>
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>Data safety</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Download{' '}
                      <a href="https://dev-dev-media.s3.ap-southeast-2.amazonaws.com/whitelabel/data_safety_export.csv" target="_blank">
                        Data Safety Export
                      </a>
                    </li>
                    <li>
                      Click <b>Import from CSV</b> and drop the data safety export file downloaded
                    </li>
                    <li>
                      Click <b>Import</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>Advertising ID</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Select <b>Does your app use advertising ID?</b> (<b>No</b>)
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>Government apps</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Select <b>Is your app developed by or on behalf of a government?</b> (<b>No</b>)
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/appcontent.png',
            '50%',
            '50%',
          )}
          {apiEnabled
            ? renderTextStep(
                <div>
                  Initialise Play Store
                  {this.renderInitialisePlayStore()}
                </div>,
              )
            : renderImageStep(
                <div>
                  Under <b>Grow/Store presence</b> on the left menu, click <b>Main store listing</b>
                  <ol className="marginBottom-16">
                    <li>
                      Enter <b>App name</b> <b>{renderCopyToClipboardText(this.getAppProperty('ClientName'))}</b>
                    </li>
                    <li>
                      Enter <b>Short description</b>
                    </li>
                    <li>
                      Enter <b>Full description</b>
                    </li>
                  </ol>
                </div>,
                'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/4+-+App+Details.png',
                '40%',
                '50%',
              )}
          {apiEnabled
            ? renderTextStep(
                <div>
                  Drop and click <b>Upload</b> to upload graphics
                  {this.renderGraphics(GRAPHIC_TYPE_ICON, 'App icon', 'Transparent PNG, 512 x 512 pixels', hasGoogleServiceKey)}
                  {this.renderGraphics(GRAPHIC_TYPE_FEATURE, 'Feature graphic', 'PNG, 1024 x 500 pixels', hasGoogleServiceKey)}
                  {this.renderGraphics(GRAPHIC_TYPE_PHONE, 'Phone screenshots', 'PNG, 1284 x 2778 pixels', hasGoogleServiceKey)}
                  {this.renderGraphics(GRAPHIC_TYPE_TABLET_7, '7-inch tablet screenshots', 'PNG, 2048 x 2732 pixels', hasGoogleServiceKey)}
                  {this.renderGraphics(
                    GRAPHIC_TYPE_TABLET_10,
                    '10-inch tablet screenshots',
                    'PNG, 2048 x 2732 pixels',
                    hasGoogleServiceKey,
                  )}
                </div>,
              )
            : renderImageStep(
                <div>
                  Under <b>Graphics</b>,
                  <ol className="marginBottom-16">
                    <li>
                      Upload <b>App Icon</b>
                    </li>
                    <li>
                      Upload <b>Feature graphic</b>
                    </li>
                    <li>
                      Upload <b>Phone screenshots</b>
                    </li>
                    <li>
                      Upload <b>7-inch tablet screenshots</b>
                    </li>
                    <li>
                      Upload <b>10-inch tablet screenshots</b>
                    </li>
                  </ol>
                  Then click <b>Save</b> button
                </div>,
                'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/5+-+Graphics.png',
                '40%',
                '50%',
              )}
          {renderTextStep('Close the Incognito window once completed')}
        </ol>
      </div>
    );
  };

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    const { stepIndex } = this.props;
    const step = stepIndex && stepIndex < this.steps.length ? stepIndex : 0;
    return (
      <div className="flex-1 automation">
        <StepProgressBar
          startingStep={step}
          progressClass="progressBar"
          primaryBtnClass="primaryBtn"
          secondaryBtnClass="secondaryBtn"
          submitBtnName="Done"
          onPrevious={() => this.onMoveStep(step - 1)}
          onNext={() => this.onMoveStep(step + 1)}
          onSubmit={() => (this.props.onDone ? this.props.onDone() : null)}
          steps={this.steps}
        />
        {this.steps[step].render()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded, whiteLabelReset })(AppSetupAndroid);
