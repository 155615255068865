import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { offersLoaded, removeOffer } from '../../actions';
import { marketActions } from '../../webapi';
import { checkLoggedIn, validateAccess } from '../../session';
import { Text } from '../../components/text';

class ListOffer extends Component {
  state = {
    offers: [],
    sortColumn: 'ExpiryUnixTimestamp', // column to sort by
    sortDesc: true, // if true, sort descending rather than ascending
    now: moment.utc().valueOf(),
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    this.updateProps(this.props);
  }

  componentDidMount() {
    this.getData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    this.setState({
      offers: props.offers,
    });
  }

  getData() {
    marketActions.getOffers(this.props.auth.site).then((res) => {
      if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
        this.props.offersLoaded(res.data);
      }
    });
  }

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  removeOffer(offer) {
    if (window.confirm(`Are you sure you want to delete that offer?`)) {
      this.props.removeOffer(offer.Id);
      marketActions
        .deleteOffer(this.props.auth.site, offer.Id)
        .then((res) => {
          this.getData();
        })
        .catch((res) => {
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  renderOffer(source) {
    return source.map((ev) => {
      if (ev != null) {
        return (
          <tr key={ev.Id}>
            <td className="table-TitleColumn">
              {validateAccess(this.props.auth.site, 'offers', this.props.auth) || ev.ShowResults ? (
                <Link to={`/offers/offer/${ev.Id}`}>{ev.Title}</Link>
              ) : (
                ev.Title
              )}
            </td>
            <td>{moment.utc(ev.StartUnixTimestamp).local().format('D MMM YYYY')}</td>
            <td>{moment.utc(ev.ExpiryUnixTimestamp).local().format('D MMM YYYY')}</td>
            <td className="table-options">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {validateAccess(this.props.auth.site, 'offers', this.props.auth) && (
                  <Link to={`/offers/offer/${ev.Id}`}>
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8 }} name="pencil" />
                  </Link>
                )}
                {validateAccess(this.props.auth.site, 'offers', this.props.auth) && (
                  <a
                    onClick={() => {
                      this.removeOffer(ev);
                    }}
                  >
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="minus-circle" />
                  </a>
                )}
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  renderSort(col) {
    if (col !== this.state.sortColumn) {
      return null;
    }
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  sortIsActive(col) {
    if (col !== this.state.sortColumn) {
      return '';
    }
    return ' table--columnActive';
  }

  renderView(source) {
    return (
      <Table className="plussTable" striped bordered condensed hover>
        <thead>
          <tr>
            <th
              className={`${this.sortIsActive('Title')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.sortByCol('Title');
              }}
            >
              Title{this.renderSort('Title')}
            </th>
            <th
              className={`${this.sortIsActive('StartUnixTimestamp')}`}
              style={{ cursor: 'pointer', width: 120 }}
              onClick={() => {
                this.sortByCol('StartUnixTimestamp');
              }}
            >
              Start time{this.renderSort('StartUnixTimestamp')}
            </th>
            <th
              className={`${this.sortIsActive('ExpiryUnixTimestamp')}`}
              style={{ cursor: 'pointer', width: 120 }}
              onClick={() => {
                this.sortByCol('ExpiryUnixTimestamp');
              }}
            >
              End time{this.renderSort('ExpiryUnixTimestamp')}
            </th>
            <th style={{ width: 70 }} />
          </tr>
        </thead>
        <tbody>{this.renderOffer(source)}</tbody>
      </Table>
    );
  }

  renderEmpty() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <Text type="h3">There are no offers</Text>
        </div>
      </div>
    );
  }

  renderContent() {
    let source = _.sortBy(this.state.offers, this.state.sortColumn);
    if (this.state.sortDesc) {
      source.reverse();
    }
    source = _.filter(source, (ev) => {
      if (!ev) {
        return false;
      }
      return true;
    });

    if (this.props.filterOption === 'current') {
      source = _.filter(source, (ev) => {
        return this.state.now > ev.StartUnixTimestamp && this.state.now < ev.ExpiryUnixTimestamp;
      });
    } else if (this.props.filterOption === 'past') {
      source = _.filter(source, (ev) => {
        return this.state.now > ev.ExpiryUnixTimestamp;
      });
    }
    // source = []
    if (_.isEmpty(source)) {
      return this.renderEmpty();
    }
    return this.renderView(source);
  }

  render() {
    return <div style={{ minWidth: '100%' }}>{this.renderContent()}</div>;
  }
}

const mapStateToProps = (state) => {
  const { offers } = state.market;
  const { auth } = state;
  return {
    offers,
    auth,
  };
};

export default connect(mapStateToProps, { offersLoaded, removeOffer })(ListOffer);
