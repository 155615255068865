import React, { useState, useEffect } from 'react';
import { Button, ExportCsvPopup, Text, StatBox } from '../';
import { faMobileAlt, faDesktop, faChartPie, faReply } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { analyticsActions } from '../../webapi';
import { countActivities } from '../../js';
import { validateAccess } from '../../session';
import moment from 'moment';

const getInitialState = () => ({
  polls: 0,
  prevPolls: 0,
  pollAnswers: 0,
  prevPollAnswers: 0,
  isLoading: true,
});

// PollAnalytics Component
const PollAnalytics = ({ startTime, endTime, auth, prevText, dayCount }) => {
  const [analyticsData, setAnalyticsData] = useState(getInitialState());
  const [isExportOpen, setIsExportOpen] = useState(false);

  const hasAccess = validateAccess(auth.site, 'polls', auth);
  if (!hasAccess) {
    return null;
  }

  const exportColumns = [
    { label: 'Select All', key: '' },
    { label: 'Start Date', key: 'startDate' },
    { label: 'End Date', key: 'endDate' },
    { label: 'Polls', key: 'polls' },
    { label: 'Poll Answers', key: 'pollAnswers' },
  ];

  useEffect(() => {
    getData();
  }, [startTime, endTime]);

  const getData = async () => {
    setAnalyticsData(getInitialState());
    // Load analytics data here using startTime and endTime
    const timeDifference = endTime - startTime;
    const [currentStatsResponse, prevStatsResponse] = await Promise.all([
      analyticsActions.getAggregateEntityStats(auth.site, 'poll', startTime, endTime, true),
      analyticsActions.getAggregateEntityStats(auth.site, 'poll', startTime - timeDifference, startTime, true),
    ]);

    const data = {
      polls: countActivities(currentStatsResponse.data, 'Poll', 'unique'),
      prevPolls: countActivities(prevStatsResponse.data, 'Poll', 'unique'),
      pollAnswers: countActivities(currentStatsResponse.data, 'PollAnswer', 'total'),
      prevPollAnswers: countActivities(prevStatsResponse.data, 'PollAnswer', 'total'),
      isLoading: false,
    };
    setAnalyticsData(data);
  };

  const isReadyToOpenCSV = () => {
    return !analyticsData.isLoading;
  };

  const getExportSource = () => {
    return [
      {
        startDate: moment(startTime + 1).format('D-MM-YYYY'),
        endDate: moment(endTime).format('D-MM-YYYY'),
        polls: analyticsData.polls,
        pollAnswers: analyticsData.pollAnswers,
      },
    ];
  };

  const csvPopup = () => {
    if (!isExportOpen) {
      return null;
    }
    const source = getExportSource();
    return (
      <ExportCsvPopup
        onClose={() => {
          setIsExportOpen(false);
        }}
        columns={exportColumns}
        source={source}
        filename={`pollanalytics_${source[0].startDate}_${source[0].endDate}.csv`}
      />
    );
  };

  return (
    <div className="dashboardSection">
      {csvPopup()}
      <div>
        <Text type="h4" className="inlineBlock marginRight-40">
          Surveys
        </Text>

        <Button
          inline
          buttonType="primaryAction"
          onClick={() => {
            if (!isReadyToOpenCSV()) return;
            setIsExportOpen(true);
          }}
          isActive={isReadyToOpenCSV()}
          leftIcon="file-code-o"
        >
          Export CSV
        </Button>
      </div>
      <div className="analyticsSection dashboardSection_content">
        <StatBox
          title="Surveys"
          icon={faChartPie}
          value={analyticsData.polls}
          previousValue={analyticsData.prevPolls}
          prevText={prevText}
          viewGraphLink={`/chart?entity=poll&startTime=${startTime}&endTime=${endTime}&key=Poll&countType=unique&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Survey Answers"
          icon={faReply}
          value={analyticsData.pollAnswers}
          previousValue={analyticsData.prevPollAnswers}
          prevText={prevText}
          viewGraphLink={`/chart?entity=poll&startTime=${startTime}&endTime=${endTime}&key=PollAnswer&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

const toExport = connect(mapStateToProps, {})(PollAnalytics);
export { toExport as PollAnalytics };
