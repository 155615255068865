import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { eventsLoaded } from '../../actions';
import {
  RadioButton,
  DropdownInput,
  GenericInput,
  ImageInput,
  Button,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  CheckBox,
  Tag,
} from '../../components';
import { eventActions } from '../../webapi';
import { safeReadParams, getThumb300, get1400, isEmail } from '../../helper';
import categories from '../../json/eventCategories.json';
import bookingTypes from '../../json/eventBookingTypes.json';
import { COLOUR_BRANDING_MAIN, COLOUR_BRANDING_OFF } from '../../js';

class EditSubmission extends Component {
  initialState = {
    updateId: safeReadParams(this.props, 'updateId'),
    success: false,
    submitting: false,
    update: {},
    newUpdate: {},
    action: '',
    eventRepetitions: [],
  };

  state = { ...this.initialState };

  UNSAFE_componentWillMount() {
    const newUpdate = { ...this.props.newUpdate };
    if (_.isUndefined(newUpdate.HasBookingOptions)) {
      newUpdate.HasBookingOptions = false;
      newUpdate.BookingData = { type: '' };
    }
    if (_.isUndefined(newUpdate.IsFeatured)) {
      newUpdate.IsFeatured = false;
    }

    const eventRepetitions = [];
    let maxId = 0;
    newUpdate.RepeatedTimes.forEach((repetition) => {
      if (typeof repetition === 'string') {
        eventRepetitions.push({
          Id: maxId++,
          Date: moment.utc(repetition).local().format('YYYY-MM-DD'),
          AllDay: false,
          Time: moment.utc(repetition).local().format('HH:mm'),
          Location: '',
          TicketCount: '',
        });
      } else {
        eventRepetitions.push({
          Id: repetition.Id != null ? repetition.Id : maxId++,
          Date: moment.utc(repetition.Time).local().format('YYYY-MM-DD'),
          AllDay: repetition.AllDay,
          Time: moment.utc(repetition.Time).local().format('HH:mm'),
          Location: repetition.Location ? repetition.Location : '',
          TicketCount: '' + repetition.TicketCount,
          AttendanceCount: repetition.AttendanceCount,
        });
      }
    });

    if (!_.isEmpty(newUpdate.Category)) {
      const tags = this.selectCategory(newUpdate.Category);
      if (tags && !_.isEmpty(newUpdate.Tags)) {
        _.values(tags).forEach((tag) => {
          tag.Active = newUpdate.Tags.indexOf(tag.Key) > -1;
        });
      }
    }

    this.setState({ newUpdate, eventRepetitions });
    console.log(newUpdate);
  }

  componentDidMount() {
    this.checkSetImage();
  }

  checkSetImage() {
    if (this.refs.imageInput) {
      this.refs.imageInput.getWrappedInstance().setValue(this.state.newUpdate.Image);
    } else {
      setTimeout(() => {
        this.checkSetImage();
      }, 100);
    }
  }

  handleChange(event) {
    var stateChange = { newUpdate: { ...this.state.newUpdate } };
    stateChange.newUpdate[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  onChangeBookingInfo(event) {
    var stateChange = { newUpdate: { ...this.state.newUpdate } };
    stateChange.newUpdate.BookingData[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleRepetitionChange(Id, column, event) {
    var stateChange = {
      eventRepetitions: this.state.eventRepetitions,
    };
    const index = _.findIndex(stateChange.eventRepetitions, (rep) => {
      return rep.Id === Id;
    });
    stateChange.eventRepetitions[index][column] = event.target.value;
    this.setState(stateChange);
  }

  handleRepetitionAllDayChange(id, isAllDay) {
    var stateChange = {
      eventRepetitions: this.state.eventRepetitions,
    };
    const index = _.findIndex(stateChange.eventRepetitions, (rep) => {
      return rep.Id === id;
    });
    stateChange.eventRepetitions[index].AllDay = isAllDay;
    // All day events default to 12:00 AM
    stateChange.eventRepetitions[index].Time = isAllDay ? '00:00' : '12:00';
    this.setState(stateChange);
  }

  isValidLink() {
    const regexp = /(http:\/\/|https:\/\/)/;
    if (regexp.test(this.state.newUpdate.BookingData.ButtonLink)) {
      return true;
    }
    return false;
  }

  inputsDisabled() {
    return false;
  }

  validateRepetitionTime(repetition) {
    if (!_.isUndefined(repetition.Time)) {
      return repetition.Time.trim() !== '';
    }
    return false;
  }

  validateRepetitionDate(repetition) {
    if (!_.isUndefined(repetition.Date)) {
      const date = new Date(repetition.Date.trim());
      return repetition.Date.trim() !== '' && !isNaN(date);
    }
    return false;
  }

  removeRepetition(rep, e) {
    e.stopPropagation();
    rep.Removed = true;
    const newReps = [...this.state.eventRepetitions];

    const newState = { eventRepetitions: newReps };

    if (this.state.selectedTimeSlot === rep.Id) {
      newState.selectedTimeSlot = null;
    }

    this.setState(newState);
  }

  getDate() {
    if (!this.state.update.Timestamp) {
      return '';
    }
    return moment.utc(this.state.update.Timestamp).local().format('D MMM');
  }

  getTime(input) {
    var timeSplit = input.split(':');
    return {
      hours: timeSplit[0],
      minutes: timeSplit[1],
    };
  }

  setRepetitions(input) {
    input.RepeatedTimes = [];

    this.state.eventRepetitions.forEach((repetition) => {
      if (!repetition.Removed) {
        const repTime = new Date(repetition.Date.trim());
        var time = this.getTime(repetition.Time.trim());
        repTime.setHours(time.hours);
        repTime.setMinutes(time.minutes);
        let ticketCount = 0;
        if (
          !_.isEmpty(repetition.TicketCount) &&
          !isNaN(parseInt(repetition.TicketCount, 10)) &&
          parseInt(repetition.TicketCount, 10) > 0
        ) {
          ticketCount = parseInt(repetition.TicketCount, 10);
        }
        input.RepeatedTimes.push({
          Id: repetition.Id,
          AllDay: repetition.AllDay,
          Time: repTime,
          Location: repetition.Location,
          TicketCount: ticketCount,
          AttendanceCount: repetition.AttendanceCount,
        });
      }
    });
    input.RepeatedTimes = _.sortBy(input.RepeatedTimes, (repetition) => {
      return moment(repetition.Time).valueOf();
    });
  }

  setTags(input) {
    input.Tags = _.filter(_.values(this.state.selectableTags), (tag) => {
      return tag.Active;
    }).map((tag) => {
      return tag.Key;
    });
  }

  validateReps() {
    if (this.state.isEveryday) {
      return true;
    }
    let allValid = true;
    this.state.eventRepetitions.forEach((rep) => {
      if (!rep.Removed) {
        if (!this.validateRepetitionTime(rep) || !this.validateRepetitionDate(rep)) {
          allValid = false;
        }
      }
    });
    return allValid;
  }

  validateImage() {
    return (
      this.refs.imageInput &&
      this.refs.imageInput
        .getWrappedInstance()
        .getValue()
        .match(/\.(jpeg|jpg|gif|png|ashx)/) != null
    );
  }

  validateEmailRequestField() {
    if (
      this.state.newUpdate.HasBookingOptions &&
      this.state.newUpdate.BookingData.type === 'EmailRequest' &&
      (_.isEmpty(this.state.newUpdate.BookingData.RequestEmail) || !isEmail(this.state.newUpdate.BookingData.RequestEmail))
    ) {
      return false;
    }
    return true;
  }

  validateButtonLink() {
    if (
      this.state.newUpdate.HasBookingOptions &&
      this.state.newUpdate.BookingData.type === 'External' &&
      (_.isEmpty(this.state.newUpdate.BookingData.ButtonText) || _.isEmpty(this.state.newUpdate.BookingData.ButtonLink))
    ) {
      return false;
    }
    return true;
  }

  validateForm() {
    if (!this.validateReps()) {
      return false;
    }
    if (_.isEmpty(this.state.newUpdate.Title)) {
      return false;
    }
    if (!this.validateImage()) {
      return false;
    }
    if (this.state.submitting) {
      return false;
    }
    if (!this.validateOrganiser()) {
      return false;
    }
    if (!this.validateButtonLink()) {
      return false;
    }
    if (!this.validateEmailRequestField()) {
      return false;
    }
    return true;
  }

  compileJson() {
    const result = { ...this.state.newUpdate };
    result.Image = this.refs.imageInput.getWrappedInstance().getValue();

    if (result.HasBookingOptions && result.BookingData.type === 'External' && !this.isValidLink(result.BookingData.ButtonLink)) {
      result.BookingData.ButtonLink = 'http://' + result.BookingData.ButtonLin;
    }

    this.setRepetitions(result);
    // this.setAttachments(result);
    this.setTags(result);

    result.Thumbnail = getThumb300(result.Image);
    result.Image = get1400(result.Image);
    if (result.Category && result.Category.indexOf(',') !== -1) {
      result.Category = result.Category.split(',')[0];
    }
    return result;
  }

  handleEditSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true });
    const data = this.compileJson();

    console.log(data);
    eventActions
      .editSubmission(data)
      .then((res) => {
        // this.setState({
        //     update: { ...data },
        //     edit: false,
        //     submitting: false
        // });
        // this.getData();
        // this.props.eventsLoaded([data]);
        this.props.onSuccess();
      })
      .catch((res) => {
        this.setState({ submitting: false });
        alert('Something went wrong with the submission. Please try again.');
      });
  }

  renderEditSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving changes...</Button>;
    }
    return (
      <div>
        <Button inline buttonType="tertiary" onClick={this.props.onCancel} isActive style={{ marginRight: 16 }}>
          Cancel
        </Button>
        <Button inline buttonType="primary" onClick={this.handleEditSubmit.bind(this)} isActive={this.validateForm()}>
          Save changes
        </Button>
      </div>
    );
  }

  renderTimeSlots() {
    if (!this.state.update || !this.state.update.RepeatedTimes) {
      return null;
    }
    return (
      <div>
        <p className="fontHeavy">Time Slots</p>
        {this.state.update.RepeatedTimes.map((event) => {
          return (
            <div style={{ padding: 15, backgroundColor: '#f1f1f19e', marginBottom: 5 }}>
              <p style={{ marginBottom: 0 }}>{moment.utc(event.Time).local().format('D MMM YY - h:mma')}</p>
              {event.Location && <p style={{ marginBottom: 0 }}>{event.Location}</p>}
              {event.TicketCount > 0 && <p style={{ marginBottom: 0 }}>Limit: {event.TicketCount}</p>}
            </div>
          );
        })}
      </div>
    );
  }

  renderAttachments() {
    if (!this.state.update || _.isEmpty(this.state.update.Attachments)) {
      return null;
    }
    return (
      <div style={{ marginTop: 10 }}>
        <p style={{ marginBottom: 0, fontWeight: 'bold' }}>Attachments</p>
        <div className="clearfix">
          {this.state.update.Attachments.map((att) => {
            return (
              <a
                href={att.Source}
                target="_blank"
                style={{
                  width: 100,
                  height: 100,
                  padding: 5,
                  borderRadius: 4,
                  backgroundColor: COLOUR_BRANDING_MAIN,
                  marginBottom: 5,
                  marginRight: 5,
                  float: 'left',
                }}
              >
                <p
                  style={{
                    marginBottom: 0,
                    fontWeight: 'bold',
                    color: '#fff',
                    textAlign: 'center',
                    width: 90,
                    wordBreak: 'break-all',
                    lineHeight: '20px',
                    maxHeight: 40,
                    overflow: 'hidden',
                  }}
                >
                  {att.Title}
                </p>
                <p style={{ marginBottom: 0, color: '#fff', textAlign: 'center' }}>PDF</p>
              </a>
            );
          })}
        </div>
      </div>
    );
  }

  renderRepeats() {
    let source = this.state.eventRepetitions;
    source = _.filter(source, (ev) => {
      return !ev.Removed;
    });

    return _.map(source, (repetition, index) => {
      return (
        <div key={repetition.Id} className="eventTimeRep eventTimeRep--submissionEdit">
          <div className="flex flex-between flex-center">
            <GenericInput
              id={`repetitionTicketCount${repetition.Id}`}
              type="text"
              label="Attendee limit"
              placeholder="Attendee Limit (Leave blank or 0 if no limit)"
              value={repetition.TicketCount}
              onChange={(e) => this.handleRepetitionChange(repetition.Id, 'TicketCount', e)}
              style={{ marginBottom: 0, width: '100%' }}
              textWrapperStyle={{ marginBottom: 0 }}
              inputStyle={{ padding: 0, fontSize: 13, height: 20 }}
              disabled={this.inputsDisabled()}
            />
            <div className="flex">
              {this.state.eventId != null && !repetition.New && (
                <div onClick={this.editRepetition.bind(this, repetition)}>
                  <FontAwesome className="fontSize-16 lineHeight-22 text-brandingOff pointer marginLeft-32" name="pencil" />
                </div>
              )}
              {source.length > 1 && (
                <div onClick={this.removeRepetition.bind(this, repetition)}>
                  <FontAwesome className="marginLeft-16 fontSize-16 lineHeight-22 text-brandingAction pointer" name="minus-circle" />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-center marginTop-16" style={{ position: 'relative', minHeight: 72 }}>
            {/* <div className='fontMedium text-dark marginRight-24' style={{ fontSize: 72, lineHeight: '72px' }}>
                {this.validateRepetitionDate(repetition) ? moment(repetition.Date, 'YYYY-MM-DD').format('DD') : moment().format('DD')}
            </div> */}
            <div style={{ width: '-webkit-fill-available' }}>
              <div>
                <CheckBox
                  label={'All day'}
                  isActive={repetition.AllDay}
                  onChange={this.handleRepetitionAllDayChange.bind(this, repetition.Id, !repetition.AllDay)}
                />
                {!repetition.AllDay && (
                  <GenericInput
                    id={`repetitionTime${repetition.Id}`}
                    type="time"
                    label="Time"
                    value={repetition.Time}
                    isValid={() => {
                      return this.validateRepetitionTime(repetition);
                    }}
                    showError={() => {
                      return this.state.showWarnings && !this.validateRepetitionTime(repetition);
                    }}
                    onChange={(e) => this.handleRepetitionChange(repetition.Id, 'Time', e)}
                    isRequired
                    style={{ marginBottom: 0 }}
                    textWrapperStyle={{ marginBottom: 0 }}
                    inputStyle={{ padding: 0 }}
                    disabled={this.inputsDisabled()}
                  />
                )}
              </div>
              <div className="fontRegular text-dark fontSize-26 marginTop-8">
                {/* Test Input Date Selector */}
                <GenericInput
                  id={`repetitionDate${repetition.Id}`}
                  type="date"
                  label="Date"
                  value={repetition.Date}
                  isValid={() => {
                    return this.validateRepetitionDate(repetition);
                  }}
                  showError={() => {
                    return this.state.showWarnings && !this.validateRepetitionDate(repetition);
                  }}
                  onChange={(e) => this.handleRepetitionChange(repetition.Id, 'Date', e)}
                  isRequired
                  textWrapperStyle={{ marginBottom: 0 }}
                  inputStyle={{ padding: 0, fontSize: 26 }}
                  disabled={this.inputsDisabled()}
                />
              </div>
            </div>
          </div>
          <GenericInput
            id={`repetitionLocation${repetition.Id}`}
            type="text"
            label="Location"
            placeholder="Location"
            value={repetition.Location}
            onChange={(e) => this.handleRepetitionChange(repetition.Id, 'Location', e)}
            style={{ marginBottom: 0 }}
            textWrapperStyle={{ marginBottom: 0 }}
            inputStyle={{ padding: 0 }}
            disabled={this.inputsDisabled()}
          />
        </div>
      );
    });
  }

  validateOrganiser() {
    if (!_.isEmpty(this.state.newUpdate.Phone)) {
      return !_.isEmpty(this.state.newUpdate.Organiser);
    }
    return true;
  }

  getSelectedBookingType() {
    if (bookingTypes[this.state.newUpdate.BookingData.type]) {
      return bookingTypes[this.state.newUpdate.BookingData.type];
    }
    return {
      Title: 'Select',
    };
  }

  toggleTag(tag) {
    if (this.inputsDisabled()) {
      return;
    }
    const newTags = { ...this.state.selectableTags };
    newTags[tag].Active = !newTags[tag].Active;
    this.setState({
      selectableTags: newTags,
    });
  }

  selectBookingType(tag) {
    if (!bookingTypes[tag]) {
      return null;
    }
    const newUpdate = { ...this.state.newUpdate };
    newUpdate.BookingData.type = tag;

    if (newUpdate.BookingData.type === 'EmailRequest' && _.isUndefined(newUpdate.BookingData.RequestEmail)) {
      newUpdate.BookingData.RequestEmail = '';
    }
    if (newUpdate.BookingData.type === 'External' && _.isUndefined(newUpdate.BookingData.ButtonLink)) {
      newUpdate.BookingData.ButtonLink = '';
    }
    if (newUpdate.BookingData.type === 'External' && _.isUndefined(newUpdate.BookingData.ButtonText)) {
      newUpdate.BookingData.ButtonText = '';
    }

    this.setState({
      newUpdate,
    });
  }

  addRepetition() {
    const newReps = [...this.state.eventRepetitions];
    const id = _.isEmpty(this.state.eventRepetitions) ? 0 : _.maxBy(this.state.eventRepetitions, 'Id').Id + 1;

    newReps.push({
      Edit: true,
      Id: id,
      Date: moment().format('YYYY-MM-DD'),
      AllDay: false,
      Time: '12:00',
      Location: '',
      TicketCount: '',
    });
    this.setState({
      eventRepetitions: newReps,
    });
  }

  selectCategory(tag) {
    if (!categories[tag]) {
      return null;
    }
    const tags = categories[tag].Sub;
    const newUpdate = { ...this.state.newUpdate, Category: tag };
    this.setState({
      newUpdate,
      selectableTags: tags,
    });
    return tags;
  }

  validateCategory() {
    if (categories[this.state.newUpdate.Category]) {
      return true;
    }
    return false;
  }

  getSelectedCategory() {
    if (categories[this.state.newUpdate.Category]) {
      return categories[this.state.newUpdate.Category];
    }
    return {
      Title: 'Category',
    };
  }

  renderSubCategories() {
    if (_.isEmpty(this.state.newUpdate.Category) || !categories[this.state.newUpdate.Category] || _.isEmpty(this.state.selectableTags)) {
      return null;
    }
    return (
      <div>
        <div className="fieldLabel marginBottom-8">Tag/s</div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {_.values(this.state.selectableTags).map((tag) => {
            return (
              <Tag
                key={tag.Key}
                className="marginTop-8 marginRight-8"
                text={tag.Title}
                onClick={this.toggleTag.bind(this, tag.Key)}
                leftIcon={tag.Active ? 'check' : null}
              />
            );
          })}
        </div>
      </div>
    );
  }

  renderEditForm() {
    if (_.isEmpty(this.state.newUpdate)) {
      return (
        <div className="padding-60 bottomPadding-40" style={{ textAlign: 'center' }}>
          <FontAwesome style={{ fontSize: 32, color: COLOUR_BRANDING_OFF }} name="spinner fa-pulse fa-fw" />
        </div>
      );
    }
    return (
      <div>
        <div className="padding-60 paddingBottom-20 bottomDivideBorder">
          <div className="newTopBar clearfix">
            <p className="newTopBarTitle text-sectionTitle">EVENT SUBMISSION</p>
          </div>
          <div className="newTopBar clearfix">
            <span className="newTopBarTitle text-sectionTitle">
              Submitted by <span className="text-brandingColour">{this.state.newUpdate.SubmittedBy.displayName}</span>
            </span>
          </div>
          {!_.isUndefined(this.state.update.EditedBy) && (
            <div className="newTopBar clearfix">
              <span className="newTopBarTitle text-sectionTitle">
                Last edited by <span className="text-brandingColour">{this.state.newUpdate.EditedBy.displayName}</span>
              </span>
            </div>
          )}
        </div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          {/* title */}
          <GenericInput
            id="Title"
            type="text"
            label="Event title"
            placeholder="Event title"
            showError={() => {
              return this.state.showWarnings && _.isEmpty(this.state.newUpdate.Title);
            }}
            isValid={() => {
              return !_.isEmpty(this.state.newUpdate.Title);
            }}
            value={this.state.newUpdate.Title}
            onChange={(e) => this.handleChange(e)}
            large
            isRequired
            disabled={this.inputsDisabled()}
          />
          <div className="marginTop-24">
            <ImageInput
              ref="imageInput"
              refreshCallback={() => {
                this.setState({ imageUpdate: true });
              }}
              showError={() => {
                return this.state.showWarnings && !this.validateImage();
              }}
              disableRemove={this.inputsDisabled()}
            />
          </div>
        </div>
        {/* Time Slots */}
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <div className="newTopBar clearfix">
            <p className="newTopBarTitle text-sectionTitle">EVENT TIME SLOTS</p>
            <div className="marginTop-16">
              <RadioButton
                label="Is this a featured event?"
                isActive={this.state.newUpdate.IsFeatured}
                options={[
                  {
                    Label: 'Yes',
                    Value: true,
                    onChange: () => {
                      var stateChange = { newUpdate: { ...this.state.newUpdate } };
                      stateChange.newUpdate.IsFeatured = true;
                      this.setState(stateChange);
                    },
                  },
                  {
                    Label: 'No',
                    Value: false,
                    onChange: () => {
                      var stateChange = { newUpdate: { ...this.state.newUpdate } };
                      stateChange.newUpdate.IsFeatured = true;
                      this.setState(stateChange);
                    },
                  },
                ]}
                disabled={this.inputsDisabled()}
              />
              <div className="genericInput-help" style={{ marginTop: 4 }}>
                Featured events will be highlighted on the What's On page.
              </div>
            </div>
            {this.renderRepeats()}
            <div className="">
              <Button buttonType="outlined" isActive onClick={() => this.addRepetition()}>
                Add time slot
              </Button>
            </div>
          </div>
        </div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <div className="newTopBar clearfix">
            <p className="newTopBarTitle text-sectionTitle">EVENT DETAILS</p>
          </div>
          {/* description */}
          <GenericInput
            id="Description"
            label="Description"
            type="textarea"
            componentClass="textarea"
            placeholder="Description"
            value={this.state.newUpdate.Description}
            onChange={(e) => this.handleChange(e)}
            inputStyle={{
              height: 120,
            }}
            disabled={this.inputsDisabled()}
          />
          <div style={{ width: '100%', maxWidth: 325, marginRight: 24 }}>
            <DropdownInput
              id="Category"
              label="Category"
              placeholder="Category"
              ignoreValue="Category"
              value={this.getSelectedCategory().Title}
              options={categories}
              onSelect={this.selectCategory.bind(this)}
              disabled={this.inputsDisabled()}
            />
            {this.renderSubCategories()}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 8 }}>
            {/* organiser */}
            <GenericInput
              id="Organiser"
              type="text"
              label="Organiser"
              placeholder="Organiser"
              value={this.state.newUpdate.Organiser}
              onChange={(e) => this.handleChange(e)}
              isValid={() => {
                return this.validateOrganiser();
              }}
              showError={() => {
                return this.state.showWarnings && !this.validateOrganiser();
              }}
              isRequired={!_.isEmpty(this.state.newUpdate.Phone)}
              style={{ width: '100%', marginRight: 24 }}
              disabled={this.inputsDisabled()}
            />
            {/* phone */}
            <GenericInput
              id="Phone"
              type="text"
              label="Contact number"
              placeholder="Contact number"
              value={this.state.newUpdate.Phone}
              onChange={(e) => this.handleChange(e)}
              style={{ width: '100%', marginLeft: 24 }}
              disabled={this.inputsDisabled()}
            />
          </div>
          {/* Ticket */}
          {/* <GenericInput
            id="Tickets"
            type="text"
            label="Ticket price"
            placeholder="Ticket price"
            value={this.state.newUpdate.Tickets}
            onChange={(e) => this.handleChange(e)}
            disabled={this.inputsDisabled()}
          /> */}
        </div>
        <div className="padding-60 paddingVertical-40">
          <div className="newTopBar clearfix">
            <p className="newTopBarTitle text-sectionTitle">BOOKING OPTIONS</p>
          </div>
          <RadioButton
            label="Does this event have booking options?"
            isActive={this.state.newUpdate.HasBookingOptions}
            options={[
              {
                Label: 'Yes',
                Value: true,
                onChange: () => {
                  var stateChange = { newUpdate: { ...this.state.newUpdate } };
                  stateChange.newUpdate.HasBookingOptions = true;
                  this.setState(stateChange);
                },
              },
              {
                Label: 'No',
                Value: false,
                onChange: () => {
                  var stateChange = { newUpdate: { ...this.state.newUpdate } };
                  stateChange.newUpdate.HasBookingOptions = false;
                  this.setState(stateChange);
                },
              },
            ]}
          />
          {this.state.newUpdate.HasBookingOptions && (
            <div style={{ marginTop: 8 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '100%', maxWidth: '50%', marginRight: 24 }}>
                  <DropdownInput
                    id="bookingType"
                    label="Booking Type"
                    placeholder="Booking Type"
                    value={this.getSelectedBookingType().Title}
                    options={bookingTypes}
                    onSelect={this.selectBookingType.bind(this)}
                    disabled={this.inputsDisabled()}
                  />
                </div>
                {this.state.newUpdate.BookingData.type === 'External' && (
                  <GenericInput
                    id="ButtonText"
                    type="text"
                    label="Button Text"
                    placeholder="Button Text"
                    showError={() => {
                      return this.state.showWarnings && _.isEmpty(this.state.newUpdate.BookingData.ButtonText);
                    }}
                    value={this.state.newUpdate.BookingData.ButtonText}
                    onChange={(e) => this.onChangeBookingInfo(e)}
                    style={{ width: '100%', marginLeft: 24 }}
                    disabled={this.inputsDisabled()}
                  />
                )}
                {this.state.newUpdate.BookingData.type === 'EmailRequest' && (
                  <GenericInput
                    id="RequestEmail"
                    type="text"
                    label="Email address"
                    placeholder="Email address"
                    help="Enter an email address that receives these requests"
                    showError={() => {
                      return this.state.showWarnings && _.isEmpty(this.state.newUpdate.BookingData.RequestEmail);
                    }}
                    value={this.state.newUpdate.BookingData.RequestEmail}
                    onChange={(e) => this.onChangeBookingInfo(e)}
                    style={{ width: '100%', marginLeft: 24 }}
                    disabled={this.inputsDisabled()}
                  />
                )}
              </div>
              {this.state.newUpdate.BookingData.type === 'External' && (
                <GenericInput
                  id="ButtonLink"
                  type="text"
                  label="Button Link"
                  placeholder="External link (https://...)"
                  showError={() => {
                    return this.state.showWarnings && _.isEmpty(this.state.newUpdate.BookingData.ButtonLink);
                  }}
                  value={this.state.newUpdate.BookingData.ButtonLink}
                  onChange={(e) => this.onChangeBookingInfo(e)}
                  disabled={this.inputsDisabled()}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">{this.renderEditForm()}</OverlayPageSection>
        </OverlayPageContents>
        {!this.state.success && <OverlayPageBottomButtons>{this.renderEditSubmit()}</OverlayPageBottomButtons>}
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { eventsLoaded })(EditSubmission);
