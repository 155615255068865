import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Header, HubSidebar } from '../../components';
import { validateAccess, isTheBest } from '../../session';
import { usersLoaded, setAuth } from '../../actions';
import { userActions, typeActions } from '../../webapi';
import { COLOUR_BRANDING_OFF } from '../../js';
import EditAppTerms from '../Terms/EditAppTerms';
import SignUpQuestions from './SignUpQuestions';
import Users from './Users';
import UserAnalytics from './UserAnalytics';
import { isKiosk } from '../../helper';
import { PlussCore } from '../../config/core'

class UsersHub extends Component {
  state = {
    selectedSection: 'all',
    location: '',

    loadingAll: false,
    loadingSubmissions: false,

    submissionEntries: [],

    allList: [],
    residents: [],
    staff: [],
    family: [],
    kiosk: [],
    now: moment.utc(),
    onlyFuture: true,
    search: '',

    showDropDown: false,
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
  }

  componentDidMount() {
    this.getData();
    this.getUserTypes();
    // this.getSubmissions()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.site && this.props.auth.site !== nextProps.auth.site) {
      this.getData();
    }
    this.updateProps(nextProps);
  }

  updateProps(props) {
    const residentTypes = [];
    const staffTypes = [];
    const familyTypes = [];
    if (!_.isEmpty(this.props.auth.userTypes) && Array.isArray(this.props.auth.userTypes)) {
      this.props.auth.userTypes.forEach((ev) => {
        if (!_.isUndefined(ev.category) && ev.category === 'resident') {
          residentTypes.push({ typeName: ev.typeName, site: ev.site });
        }
        if (!_.isUndefined(ev.category) && ev.category === 'staff') {
          staffTypes.push({ typeName: ev.typeName, site: ev.site });
        }
        if (!_.isUndefined(ev.category) && ev.category === 'family') {
          familyTypes.push({ typeName: ev.typeName, site: ev.site });
        }
      });
    }

    const allUsers = _.filter(props.users, (ev) => {
      if (!ev) {
        return false;
      }
      return ev.site !== 'plussSpace' || this.props.auth.site === 'plussSpace';
    });

    const residents = _.filter(allUsers, (ev) => {
      if (!ev) {
        return false;
      }
      return _.some(residentTypes, (t) => {
        return t.site === ev.site && t.typeName === ev.type;
      });
    });
    const staff = _.filter(allUsers, (ev) => {
      if (!ev) {
        return false;
      }
      return _.some(staffTypes, (t) => {
        return t.site === ev.site && t.typeName === ev.type;
      });
    });
    const family = _.filter(allUsers, (ev) => {
      if (!ev) {
        return false;
      }
      return _.some(familyTypes, (t) => {
        return t.site === ev.site && t.typeName === ev.type;
      });
    });
    const kiosk = _.filter(allUsers, (ev) => {
      if (!ev) {
        return false;
      }
      return isKiosk(ev.type);
    });

    this.setState({
      allList: allUsers,
      residents,
      staff,
      family,
      kiosk,
    });
  }

  getData() {
    this.setState({
      loadingAll: true,
    });

    userActions
      .getSiteUsersRecursive(this.props.auth.site)
      .then((users) => {
        this.setState({
          loadingAll: false,
        });
        if (!_.isEmpty(users) && (users[0].site === this.props.auth.site || this.props.auth.site === 'hq')) {
          this.buildData(users);
        }
      })
      .catch((error) => {
        this.setState({ loadingAll: false });
      });
  }

  buildData(users) {
    if (!_.isEmpty(users)) {
      this.props.usersLoaded([...users]);
    }
  }

  addNew = (action) => {
    if (validateAccess(this.props.auth.site, 'userManagement', this.props.auth)) {
      if (action === 'single') {
        this.props.history.push(`/usershub/adduser`);
      }
      if (action === 'kiosk') {
        this.props.history.push(`/usershub/addkiosk`);
      }
      if (action === 'bulk') {
        this.props.history.push(`/usershub/bulkadduser`);
      }
      if (action === 'uploads') {
        this.props.history.push(`/usershub/bulkuploads`);
      }
    }
  };

  canAddNew(isClass) {
    if (validateAccess(this.props.auth.site, 'userManagement', this.props.auth)) {
      return isClass ? '' : true;
    }
    return isClass ? ' hub-sideContent-topButton--hide' : false;
  }

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? { backgroundColor: '#fff' } : {};
  }

  renderStats(gweg, loading) {
    if (loading) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    return gweg;
  }

  getUserTypes() {
    typeActions
      .getUserTypes(this.props.auth.site === 'hq' ? 'all' : this.props.auth.site)
      .then((res) => {
        this.props.setAuth({ userTypes: res.data });
      })
      .catch((error) => {});
  }

  getTypeTitle(user) {
    const typeObject = _.find(this.props.auth.userTypes, (t) => {
      return t.typeName === user.type;
    });
    if (typeObject) {
      return typeObject.displayName;
    }
    return '';
  }

  getUsers() {
    switch (this.state.selectedSection) {
      case 'all':
        return this.state.allList;
      case 'Residents':
        return this.state.residents;
      case 'Staff':
        return this.state.staff;
      case 'Family':
        return this.state.family;
      case 'Kiosk':
        return this.state.kiosk;
      default:
        break;
    }
    return [];
  }

  renderLeftBar() {
    if (!validateAccess(this.props.auth.site, 'userManagement', this.props.auth, true)) {
      return null;
    }

    const sectionItems = [];

    if (this.canAddNew()) {
      sectionItems.push({
        type: 'newButton',
        text: 'New User',
        onClick: () => {
          this.addNew('single');
        },
      });
    }

    sectionItems.push({
      type: 'navItem',
      text: 'View Users',
      icon: 'eye',
      isFontAwesome: true,
      selected: this.state.selectedSection === 'all',
      onClick: () => {
        this.setState({ selectedSection: 'all' });
      },
    });

    sectionItems.push({
      type: 'navItem',
      text: 'Analytics',
      icon: 'line-chart',
      isFontAwesome: true,
      selected: this.state.selectedSection === 'Analytics',
      onClick: () => {
        this.setState({ selectedSection: 'Analytics' });
      },
    });

    if (validateAccess(this.props.auth.site, 'inviteCode', this.props.auth)) {
      sectionItems.push({
        type: 'navItem',
        text: 'Invite Codes',
        // icon: 'user-plus',
        // isFontAwesome: true,
        onClick: () => {
          this.props.history.push('/inviteCodes');
        },
      });
    }

    if (validateAccess(this.props.auth.site, 'permissionManagement', this.props.auth)) {
      sectionItems.push({
        type: 'navItem',
        text: 'User Types',
        // icon: 'userType',
        // isSVG: true,
        onClick: () => {
          this.props.history.push('/permissions');
        },
      });
    }

    if (validateAccess(this.props.auth.site, 'terms', this.props.auth)) {
      sectionItems.push({
        type: 'navItem',
        text: 'Terms & Conditions',
        // icon: 'gavel',
        // isFontAwesome: true,
        selected: this.state.selectedSection === 'terms',
        onClick: () => {
          this.setState({ selectedSection: 'terms' });
        },
      });
    }

    sectionItems.push({
      type: 'navItem',
      text: 'Bulk Upload',
      // icon: 'file-code-o',
      // isFontAwesome: true,
      onClick: () => {
        this.addNew('bulk');
      },
    });

    sectionItems.push({
      type: 'navItem',
      text: 'Check Uploads',
      // icon: 'cloud-upload',
      // isFontAwesome: true,
      onClick: () => {
        this.addNew('uploads');
      },
    });

    sectionItems.push({
      type: 'navItem',
      text: 'User Tags',
      // icon: 'file-code-o',
      // isFontAwesome: true,
      onClick: () => {
        this.props.history.push(`/tagging`);
      },
    });

    if (isTheBest(this.props.auth, true)) {
      sectionItems.push({
        type: 'navItem',
        text: 'Sign Up Questions',
        onClick: () => {
          this.props.history.push(`/usershub/signupform`);
        },
      });
    }

    return (
      <HubSidebar
        sections={[
          {
            title: 'Users',
            items: sectionItems,
          },
        ]}
        helpGuide={{
          text:  `Help with Users`,
          url: PlussCore.Urls.HelpGuide.Users,
        }}
      />
    );
  }

  renderRight() {
    if (this.state.selectedSection === 'Analytics') {
      return <UserAnalytics userTypes={this.props.auth.userTypes} />;
    }
    if (this.state.selectedSection === 'terms') {
      return <EditAppTerms />;
    }
    return <Users userTypes={this.props.auth.userTypes} filter={this.state.selectedSection} users={this.getUsers()} />;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '40px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
    paddingRight: 24,
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { users } = state.users;
  const { auth } = state;
  return {
    users,
    auth,
  };
};

export default connect(mapStateToProps, { usersLoaded, setAuth })(UsersHub);
