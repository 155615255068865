import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
import { getUrlParams } from '../../helper';
import { setAuthUser } from '../../actions';
import { fileActions, formActions, typeActions } from '../../webapi';
import { Button, Popup, SVGIcon } from '../../components';
import { communityConfig, logo } from '../../config';
import { COLOUR_BRANDING_APP, hexToRGBAstring } from '../../js';
import SingleSignInForm from './SingleSignInForm';

class SignInForm extends Component {
  constructor(props) {
    super(props);

    const params = getUrlParams();
    const signOutCode = Cookies.get('signOutCode') || '';

    this.state = {
      forms: [],
      site: params.site || props.site,
      failureText: '',
      loading: true,
      loadingBranding: true,
      submittingSignIn: false,
      signingOut: false,
      answers: {},
      formPage: 0,
      signOutCode,
      message: '',
    };
  }

  UNSAFE_componentWillMount() {
    this.getFormData();
    this.getSiteBranding();
  }

  getSiteBranding() {
    typeActions.getSiteBranding(this.state.site).then((res) => {
      this.setState({
        loadingBranding: false,
        siteName: res.data.Name,
        colour: res.data.MainBrandingColour || COLOUR_BRANDING_APP,
        logo: res.data.Logo || logo,
      });
    });
  }

  getFormData() {
    this.setState({
      loading: true,
    });
    formActions.getForms(this.state.site).then((res) => {
      console.log(res.data);
      this.setState({
        loading: false,
        forms: _.sortBy(res.data, 'Order'),
      });
    });
  }

  selectFormOption = (f) => {
    this.setState({
      form: f,
      formPages: _.sortBy(_.values(_.groupBy(f.Fields, 'page')), (fields) => {
        return fields[0].page;
      }),
    });
  };

  onSignInSuccess = (signOutCode) => {
    if (this.props.onSubmit) {
      this.props.onSubmit();
    } else {
      Cookies.set('signOutCode', signOutCode, { path: '/', expires: 7 });
      this.setState({ submittingSignIn: false, signOutCode, message: '' });
    }
  };

  updateEmptySignature = () => {
    this.setState({
      signatureEmpty: false,
    });
  };

  onClearSignature = () => {
    this.setState({
      signatureEmpty: true,
    });
    this.sigPad_signout.clear();
  };

  onSignOutFail = (response) => {
    if (response.requireSignature) {
      this.setState({
        requireSignoutSignature: true,
        message: null,
        signingOut: false,
        signatureEmpty: true,
      });
    } else {
      this.setState({
        signOutFail: true,
      });
    }
  };

  onClearSignOut = () => {
    Cookies.remove('signOutCode');
    this.setState({ signingOut: false }, () => {
      setTimeout(this.resetForm, 1000);
    });
  };

  uploadSignOutSignature = async () => {
    const fileRes = await fileActions.putCanvas(this.sigPad_signout, 'signature');
    return fileRes.key;
  };

  onSignOut = async () => {
    this.setState({ signingOut: true, message: 'Signing out...' }, async () => {
      const { site, signOutCode } = this.state;
      try {
        let extras = null;
        if (this.state.requireSignoutSignature) {
          extras = { Signature: await this.uploadSignOutSignature() };
        }
        const signOutResponse = await formActions.performFormAction(site, 'signIn', 'signOut', signOutCode, extras);
        if (!signOutResponse.data.success) return this.onSignOutFail(signOutResponse.data);
        this.setState({ message: 'Signed out successfully' });
        this.onClearSignOut();
      } catch (error) {
        console.log('performFormAction error', error);
        this.setState({ signingOut: false, message: '' });
        return this.onSignOutFail({});
      }
    });
  };

  resetForm = () => {
    if (this.props.onCancel) {
      this.setState({ form: null, formPages: [] }, () => this.props.onCancel());
    } else {
      window.location.reload();
    }
  };

  renderSignOutFail = () => {
    if (!this.state.signOutFail) return null;
    return (
      <Popup
        subtitle={'That sign out code is no longer valid. You may have already signed out on another device.'}
        maxWidth="90%"
        maxHeight="90%"
        hasPadding
        buttons={[
          {
            type: 'tertiary',
            onClick: this.onClearSignOut,
            isActive: true,
            text: 'Okay',
          },
        ]}
      />
    );
  };

  renderSignOutSignature() {
    const isActive = !this.state.signingOut && !this.state.signatureEmpty;
    return (
      <div className="visitorSignIn">
        {this.renderSignOutFail()}
        {this.renderHeader()}

        {this.renderBanner(<p className="visitorSignIn_banner_title">Signature required</p>)}
        <div className="padding-10">
          <p className="visitorSignIn_header_subtitle">Please sign below</p>
          <div className="signature">
            <SignatureCanvas
              penColor="black"
              canvasProps={{ width: 600, className: 'signature_canvas' }}
              // backgroundColor="#fff"
              ref={(ref) => {
                this.sigPad_signout = ref;
              }}
              onEnd={() => this.updateEmptySignature()}
            />
            <Button
              buttonType="secondary"
              // className="button--signup"
              onClick={this.onClearSignature}
              isActive
              // style={{ borderColor: this.state.colour, backgroundColor: this.state.colour }}
              // textStyle={{ color: '#fff' }}
            >
              Clear signature
            </Button>
          </div>
        </div>
        <div className="flex flex-center-row marginTop-10">
          {_.isEmpty(this.state.message) ? (
            <Button
              buttonType="primary"
              // className="button--signup"
              onClick={this.onSignOut}
              isActive={isActive}
              style={isActive && { borderColor: this.state.colour, backgroundColor: this.state.colour }}
              textStyle={isActive && { color: '#fff' }}
            >
              Sign Out
            </Button>
          ) : (
            <p>{this.state.message}</p>
          )}
        </div>
      </div>
    );
  }

  renderSignOut() {
    if (this.state.requireSignoutSignature) {
      return this.renderSignOutSignature();
    }
    return (
      <div className="visitorSignIn">
        {this.renderSignOutFail()}
        {this.renderHeader()}

        {this.renderBanner(<p className="visitorSignIn_banner_title">Thank you for signing in!</p>)}
        <div className="padding-10">
          <p className="visitorSignIn_header_subtitle">{`Your sign out code is ${this.state.signOutCode}`}</p>
          <p className="visitorSignIn_header_subtitle">Please remember to sign out when you leave this facility</p>
          <p className="visitorSignIn_header_subtitle">
            If your mobile number was provided, this code will also be sent to you via text message.
          </p>
        </div>
        <div className="flex flex-center-row marginTop-10">
          {_.isEmpty(this.state.message) ? (
            <Button
              buttonType="primary"
              // className="button--signup"
              onClick={this.onSignOut}
              isActive={!this.state.signingOut}
              style={{ borderColor: this.state.colour, backgroundColor: this.state.colour }}
              textStyle={{ color: '#fff' }}
            >
              Sign Out
            </Button>
          ) : (
            <p>{this.state.message}</p>
          )}
        </div>
      </div>
    );
  }

  renderHeader() {
    return (
      <div className="visitorSignIn_header">
        <img className="visitorSignIn_header_logo" src={this.state.logo} alt="logo" />
      </div>
    );
  }

  renderBanner(content) {
    return (
      <div className="visitorSignIn_banner" style={{ backgroundColor: this.state.colour }}>
        <div className="visitorSignIn_banner_image" style={{ backgroundImage: `url('${communityConfig.banner}')` }}></div>
        <div className="visitorSignIn_banner_gradient">{content}</div>
      </div>
    );
  }

  renderFormOption(f) {
    return (
      <div
        className="visitorSignIn_formOption"
        key={f.RowId}
        onClick={() => {
          this.selectFormOption(f);
        }}
        style={this.props.optionStyle}
      >
        <SVGIcon colour={hexToRGBAstring(this.state.colour, 0.2)} icon={f.Icon || 'users'} width="50px" height="50px" />
        <p className="visitorSignIn_formOption_title">{f.Name}</p>
      </div>
    );
  }

  renderFormSelector() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderBanner(
          <div>
            <p className="visitorSignIn_banner_title">Welcome to {this.state.siteName}!</p>
            <p className="visitorSignIn_banner_subtitle">Please select an option to sign in</p>
          </div>,
        )}
        <div className="visitorSignIn_formSelector">
          <div className="flex-1 paddingRight-5">
            {this.state.forms.map((f, i) => {
              if (i % 2 === 1) {
                return null;
              }
              return this.renderFormOption(f);
            })}
          </div>
          <div className="flex-1 paddingLeft-5">
            {this.state.forms.map((f, i) => {
              if (i % 2 === 0) {
                return null;
              }
              return this.renderFormOption(f);
            })}
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading || this.state.loadingBranding) {
      return null;
    }
    if (!_.isEmpty(this.state.signOutCode)) {
      return this.renderSignOut();
    }
    if (this.state.form) {
      return (
        <SingleSignInForm
          form={this.state.form}
          colour={this.state.colour}
          logo={this.state.logo}
          resetForm={this.resetForm}
          onSignInSuccess={this.onSignInSuccess}
          disableAutoFill={this.props.disableAutoFill}
        />
      );
    }
    return this.renderFormSelector();
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { setAuthUser })(SignInForm);
