import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { alertsUpdate } from '../../actions';
import {
  Button,
  DropdownInput,
  GenericInput,
  DatePicker,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  SuccessPopup,
} from '../../components';
import { typeActions, alertActions, profileActions } from '../../webapi';
import { formatDuration, getSiteLevelFromState, safeReadParams } from '../../helper';
import { COLOUR_BRANDING_OFF } from '../../js';
import AlertUser from './AlertUser';
import { Text } from '../../components/text';
import { isTVEnabled } from '../../session';

class AddService extends Component {
  initialState = {
    locations: [],
    categories: [
      {
        Title: 'All Primary User Types',
        Key: 'resident',
      },
      {
        Title: 'All Staff User Types',
        Key: 'staff',
      },
      {
        Title: 'All Linked User Types',
        Key: 'family',
      },
    ],
    types: [],
    tags: [],

    infoId: safeReadParams(this.props, 'infoId'),
    loadingAll: true,
    loadSuccess: false,

    Deleted: false,

    Title: '',
    Description: '',
    Site: '',
    Type: '',
    Category: '',
    AudienceType: 'Category',
    Important: false,
    TVMode: false,

    showDate: false,
    Expiry: moment().add(7, 'days').format('YYYY-MM-DD'),
    ExpiryText: moment().add(7, 'days').format('DD/MM/YYYY'),

    Users: [],
    Read: [],

    showWarnings: false,
    success: false,
    submitting: false,

    Times: [],

    alertObj: {},
  };

  state = { ...this.initialState };

  componentDidMount() {
    this.getUserTypes();
    this.setState({
      loadingAll: true,
    });
    alertActions
      .getAlert(this.props.auth.site, this.state.infoId)
      .then((res) => {
        this.setState({
          loadingAll: false,
        });
        if (res.data != null) {
          this.applyReturn(res.data);
        }
        // if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
        //     this.setState({ alerts: res.data });
        // }
      })
      .catch((res2) => {
        this.setState({ loadingAll: false });
        console.log('fail from getAlerts');
        console.log(res2);
      });
  }

  applyReturn(res) {
    const newState = {
      RowId: res.RowId,
      Title: res.Title,
      Description: res.Description,
      Site: res.Site,
      AudienceType: res.AudienceType,
      Important: res.IsImportant,
      TVMode: res.TVMode,
      Expiry: moment(res.Expiry, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      ExpiryText: moment(res.Expiry, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      loadSuccess: true,
      alertObj: res,
      createdTime: res.CreatedUnix * 1000, // adjust to milliseconds
    };

    if (res.AudienceType === 'Category') {
      newState.Category = res.AudienceTypeSelection;
    } else if (res.AudienceType === 'UserTags') {
      newState.Tag = res.AudienceTypeSelection;
      this.getUserTags();
    } else {
      newState.Type = res.AudienceTypeSelection;
    }

    newState.Read = res.Read || [];
    newState.ReadTime = res.ReadTime || [];
    if (!_.isEmpty(newState.ReadTime)) {
      // calculate average time
      newState.AverageReadTime =
        _.sumBy(newState.ReadTime, (t) => {
          return t.timestamp - newState.createdTime;
        }) / newState.ReadTime.length;
      newState.ReadTimeDisplay = formatDuration(newState.AverageReadTime);
    }
    if (!_.isUndefined(res.Recipients) && !_.isEmpty(res.Recipients)) {
      newState.Users = res.Recipients;
    }

    this.setState(newState);
  }

  selectSite(key, e) {
    this.setState({
      Site: key,
    });
  }

  getSites() {
    var self = this;
    typeActions
      .getSites()
      .then((res) => {
        res.data.forEach((e) => {
          e.Title = e.siteName;
          e.Key = e.Id;
        });
        self.setState({
          locations: res.data,
        });
      })
      .catch((error) => {});
  }

  getUserTags() {
    profileActions.getUserTagsBySite(this.props.auth.site).then((res) => {
      this.setState({
        tags: res.data,
      });
    });
  }

  handleDateTextChange(value) {
    const newU = { ExpiryText: value };
    const m = moment(value, 'DD/MM/YYYY');

    if (m.isValid() && m.year() > 1900) {
      newU.Expiry = m.format('YYYY-MM-DD');
    }
    this.setState(newU);
  }

  handleDateChange(date) {
    var stateChange = {
      Expiry: date,
      ExpiryText: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      showDate: false,
    };
    this.setState(stateChange);
  }

  selectCat(key, e) {
    this.setState({
      Category: key,
    });
  }
  getCatTitle() {
    const typeObject = _.find(this.state.categories, (t) => {
      return t.Key === this.state.Category;
    });
    if (typeObject) {
      return typeObject.Title;
    }
    return '';
  }

  selectType(key, e) {
    this.setState({
      Type: key,
    });
  }

  getTypeTitle() {
    const typeObject = _.find(this.state.types, (t) => {
      return t.typeName === this.state.Type;
    });
    if (typeObject) {
      return typeObject.displayName;
    }
    return '';
  }

  getUserTagTitle() {
    const tag = _.find(this.state.tags, (t) => {
      return t.Id === this.state.Tag;
    });
    if (tag) {
      return tag.Title;
    }
    return '';
  }

  getUserTypes() {
    var self = this;
    typeActions
      .getUserTypes(this.props.auth.site)
      .then((res) => {
        res.data.forEach((e) => {
          e.Title = e.displayName;
          e.Key = e.typeName;

          if (e.category) {
            e.Title = `(${e.category[0].toUpperCase() + e.category.substring(1)}) ${e.Title}`;
          }
        });
        self.setState({
          types: res.data,
        });
      })
      .catch((error) => {});
  }

  inputsDisabled() {
    if (this.state.submitting) {
      return true;
    }
    // if (this.canManageEvent()) {
    //     return false;
    // }
    // if (this.canSubmitEvent()) {
    //     return false;
    // }
    return false;
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  validateCompulsoryText() {
    // if (_.isEmpty(this.state.Title)) {
    //     return false;
    // }
    // if (_.isEmpty(this.state.Description)) {
    //     return false;
    // }
    // if (this.state.AudienceType === 'Category' && !this.validateCategory()) {
    //     return false;
    // }
    // if (this.state.AudienceType === 'UserType' && !this.validateType()) {
    //     return false;
    // }
    return true;
  }

  validateImage() {
    return this.refs.imageInput && this.refs.imageInput.getValue().match(/\.(jpeg|jpg|gif|png|ashx)/) != null;
  }

  validateForm() {
    if (!this.validateCompulsoryText()) {
      return false;
    }
    if (this.state.submitting || this.state.loadingAll || !this.state.loadSuccess) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }

    this.setState({ submitting: true, showWarnings: false });

    const obj = {
      Title: this.state.Title,
      RowId: this.state.RowId,
      Expiry: this.state.Expiry,
      Site: this.props.auth.site,
    };

    alertActions
      .addAlert(obj)
      .then((res) => {
        this.setState({
          success: true,
          submitting: false,
        });
        // console.log(res.data)
        this.props.alertsUpdate(this.props.auth.site);
      })
      .catch((res) => {
        this.setState({ submitting: false });
        console.log(res);
        alert('Something went wrong with the request. Please try again.');
      });
  }

  validateCategory() {
    return !_.isUndefined(this.state.Category) && !_.isEmpty(this.state.Category);
  }

  validateType() {
    return !_.isUndefined(this.state.Type) && !_.isEmpty(this.state.Type);
  }

  getTarget() {
    if (this.state.AudienceType === 'All') {
      return 'All Users';
    }
    if (this.state.AudienceType === 'Category') {
      return this.getCatTitle();
    }
    if (this.state.AudienceType === 'User') {
      return 'Single Users';
    }
    if (this.state.AudienceType === 'UserTags') {
      return `User Tag: ${this.getUserTagTitle()}`;
    }
    return `User Type:   ${this.getTypeTitle()}`;
  }

  renderTVMode() {
    if (!isTVEnabled(this.props.auth.interfaces, 'announcements')) {
      return null;
    }
    return (
      <p className="fontMedium fontSize-16">
        {this.state.TVMode ? (
          <span>
            This alert <span className="text-brandingColour fontHeavy">is displayed</span> in TV Mode
          </span>
        ) : (
          <span>
            This alert is <span className="text-brandingColour fontHeavy">not</span> displayed in TV Mode
          </span>
        )}
      </p>
    );
  }

  renderForm() {
    if (this.state.success || (!this.state.loadingAll && !this.state.loadSuccess)) {
      return null;
    }
    if (this.state.loadingAll) {
      return (
        <div style={{ padding: 60, width: '100%', display: 'flex', justifyContent: 'center' }}>
          <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />
        </div>
      );
    }
    return (
      <div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          {/* title */}
          <GenericInput
            id="Title"
            label="Title"
            type="text"
            placeholder="Alert title"
            value={this.state.Title}
            onChange={(e) => this.handleChange(e)}
            large
            disabled
            alwaysShowLabel
          />
          <GenericInput
            id="Description"
            label="Description"
            type="textarea"
            componentClass="textarea"
            placeholder="Alert Description"
            value={this.state.Description}
            onChange={(e) => this.handleChange(e)}
            inputStyle={{
              height: 120,
            }}
            disabled
            alwaysShowLabel
          />

          <div className="flex" style={{ marginTop: 32 }}>
            <Text type="formTitleSmall">
              Audience Targeted:<span className="text-brandingColour text-cap">{` ${this.props.auth.site}`}</span>
            </Text>
          </div>
          <DropdownInput
            id="typeSelect"
            // label='User Category'
            alwaysShowLabel
            placeholder="User Category"
            value={this.getTarget()}
            options={this.state.categories}
            onSelect={this.selectCat.bind(this)}
            style={{ width: 325 }}
            disabled
          />

          <Text type="formTitleSmall" className="marginBottom-8">
            Settings
          </Text>

          <div>
            <p className="fontMedium fontSize-16">
              {this.state.Important ? (
                <span>
                  This is an <span className="text-brandingColour fontHeavy">important</span> alert
                </span>
              ) : (
                <span>
                  This is <span className="text-brandingColour fontHeavy">not</span> an important alert
                </span>
              )}
            </p>
            {this.renderTVMode()}
          </div>
        </div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <GenericInput
            id="ExpiryText"
            label="Expiry Date"
            alwaysShowLabel
            placeholder={this.state.ExpiryText}
            value={this.state.ExpiryText}
            onChange={(e) => this.handleDateTextChange(e.target.value)}
            onClick={(e) => this.setState({ showDate: !this.state.showDate })}
            style={{ marginTop: 24, width: 325 }}
          />
          {this.state.showDate && (
            <DatePicker
              selectedDate={this.state.Expiry}
              // selectedDate={moment(this.state.newUser.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')}
              selectDate={this.handleDateChange.bind(this)}
            />
          )}
        </div>
      </div>
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          {this.state.infoId == null ? 'Cancel' : 'Back'}
        </Button>
        {!this.inputsDisabled() && (
          <Button inline buttonType="primary" onClick={() => this.handleSubmit()} isActive={this.validateForm()}>
            Save
          </Button>
        )}
      </div>
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text="Alert has been edited"
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  renderUsers() {
    let source = [...this.state.Users];

    if (!_.isUndefined(this.state.alertObj.Include) && !_.isEmpty(this.state.alertObj.Include)) {
      let goo = [...this.state.alertObj.Include];
      goo.forEach((element) => {
        element.id = element.Id;
      });
      source = [...source, ...goo];
    }

    if (!_.isUndefined(this.state.alertObj.Exclude) && !_.isEmpty(this.state.alertObj.Exclude)) {
      source = _.reject(source, (user) =>
        _.find(this.state.alertObj.Exclude, (t) => {
          return t.Id === user.id;
        }),
      );
    }

    let seen = [];
    let notSeen = [];
    source.forEach((element) => {
      if (element != null) {
        element.HasSeen = this.state.Read.indexOf(element.id) > -1;
        if (element.HasSeen) {
          seen.push(element);
        } else {
          notSeen.push(element);
        }
      }
    });
    return [..._.sortBy(seen, 'displayName'), ..._.sortBy(notSeen, 'displayName')].map((ev, index) => {
      if (ev != null) {
        return (
          <AlertUser
            key={ev.id}
            user={ev}
            hasTick
            readList={this.state.Read}
            readTime={this.state.ReadTime}
            created={this.state.createdTime}
          />
        );
      }
      return null;
    });
  }

  renderThem() {
    return (
      <div
        className={`padding-60 paddingVertical-40${
          !_.isUndefined(this.state.alertObj.Exclude) && !_.isEmpty(this.state.alertObj.Exclude) && ' bottomDivideBorder'
        }`}
      >
        <div className="flex flex-between" style={{ marginBottom: 16 }}>
          <Text type="formTitleSmall" className="text-white">
            Recipients
          </Text>
          <Text type="formTitleSmall" className="text-white">
            Delivered
          </Text>
        </div>
        {this.renderUsers()}
      </div>
    );
  }

  renderExclude() {
    if (!_.isUndefined(this.state.alertObj.Exclude) && !_.isEmpty(this.state.alertObj.Exclude)) {
      return (
        <div className="padding-60 paddingVertical-40">
          <Text type="formTitleSmall" className="text-white marginBottom-16">
            Excluded From Alert
          </Text>
          {_.sortBy(this.state.alertObj.Exclude, 'displayName').map((ev, index) => {
            if (ev != null) {
              return <AlertUser key={ev.Id} user={ev} />;
            }
            return null;
          })}
        </div>
      );
    }
  }

  renderAverageRead() {
    if (!this.state.ReadTimeDisplay) {
      return null;
    }
    return (
      <div className={`padding-60 paddingVertical-40 bottomDivideBorder`}>
        <Text type="formTitleSmall" className="marginBottom-16 text-white">
          Average Read Time
          <br />
          {this.state.ReadTimeDisplay}
        </Text>
      </div>
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
          {!this.state.loadingAll && this.state.loadSuccess && !this.state.success && (
            <OverlayPageSection className="pageSectionWrapper--newPopupSide pageSectionWrapper--newPopupSide--purple">
              {this.renderAverageRead()}
              {this.renderThem()}
              {this.renderExclude()}
            </OverlayPageSection>
          )}
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const styles = {
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { alertsUpdate })(AddService);
