import _ from 'lodash';
import { authedFunction } from '../session';
import { getUrl } from './helper';
import { decrypt } from '../helper';

export const userActions = {
  createNewUser: (user, userExtra) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'add'),
      data: {
        user,
        userExtra,
      },
    });
  },
  bulkNewUser: (users, site, sendWelcome) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'addbulk'),
      data: {
        site,
        users,
        sendWelcome,
      },
    });
  },
  getbulks: (site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'getbulks'),
      data: {
        site,
      },
    });
  },
  fetchUsers: (site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'get'),
      data: {
        site,
      },
    });
  },
  getSiteUsers: (site, lastKey) => {
    const query = { site };
    if (!_.isEmpty(lastKey)) {
      query.lastKey = JSON.stringify(lastKey);
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('users', 'users/site', query),
    });
  },
  getSiteUsersRecursive: (site, lastKey, users = []) => {
    return new Promise((resolve) => {
      userActions.getSiteUsers(site, lastKey).then((userRes) => {
        const newUsers = [...users, ...userRes.data.Items];
        if (!userRes.data.LastKey) {
          return resolve(newUsers);
        }
        return resolve(userActions.getSiteUsersRecursive(site, userRes.data.LastKey, newUsers));
      });
    });
  },
  fetchInitialPasswords: async (site) => {
    return new Promise(async (resolve) => {
      const response = await authedFunction({
        method: 'GET',
        url: getUrl('users', 'initialpasswords', { site }),
      });

      const ekRes = await authedFunction({
        method: 'get',
        url: getUrl('strings', 'getstring/usekey', { key: response.data.ek }),
      });
      const ivRes = await authedFunction({
        method: 'get',
        url: getUrl('strings', 'getstring/usekey', { key: response.data.iv }),
      });
      return resolve(
        response.data.Items.map((pr) => {
          if (!pr.initialPassword) {
            return pr;
          }
          const decryptedPW = decrypt(pr.initialPassword, ekRes.data, ivRes.data);
          return { ...pr, initialPassword: decryptedPW };
        }),
      );
    });
  },
  fetchUser: (site, userId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'getsingle'),
      data: {
        site,
        userId,
      },
    });
  },
  updateUser: (site, userId, user) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'manageUser'),
      data: {
        site,
        userId,
        user,
      },
    });
  },
  getSiteResidentsCount: (site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'getexp'),
      data: { site, isCount: true },
    });
  },
  sendWelcomeEmail: (site, userId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'welcome/email'),
      data: { site, userId },
    });
  },
  sendWelcomeText: (site, userId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'welcome/text'),
      data: { site, userId },
    });
  },
  getInviteCode: (userId, type, site) => {
    const query = {};
    if (!_.isEmpty(userId)) {
      query.userId = userId;
    }
    if (!_.isEmpty(type)) {
      query.type = type;
    }
    if (!_.isEmpty(site)) {
      query.site = site;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('users', 'invite/get', query),
    });
  },
  generateInviteCode: (userId, type, site) => {
    const data = {};
    if (!_.isEmpty(userId)) {
      data.userId = userId;
    }
    if (!_.isEmpty(type)) {
      data.type = type;
    }
    if (!_.isEmpty(site)) {
      data.site = site;
    }
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'invite/generate'),
      data,
    });
  },
  createInviteCode: (userId, site, userType, expiry, settings) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'invite/update/add'),
      data: { userId, site, userType, expiry, settings },
    });
  },
  editInviteCode: (code, userType, expiry, settings) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'invite/update/edit'),
      data: { code, userType, expiry, settings },
    });
  },
  deleteInviteCode: (code) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'invite/update/delete'),
      data: { code },
    });
  },
  getInviteCodes: (site, excludeFamily = false) => {
    const action = excludeFamily ? 'excludeFamily' : 'all';
    return authedFunction({
      method: 'GET',
      url: getUrl('users', `invite/get/${action}`, { site }),
    });
  },
  getInviteCodeByCode: (code) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('users', 'invite/get/code', { code }),
    });
  },
  updateProfile: async (input) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'updateProfile'),
      data: {
        details: input,
      },
    });
  },
};
