import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';
import _ from 'lodash';
import { servicesLoaded, removeService } from '../../actions';
import { checkLoggedIn } from '../../session';
import { serviceActions } from '../../webapi';
import { Text } from '../../components/text';

class SponsorsList extends Component {
  state = {
    InApp: [],
    External: [],
    sortColumn: 'Title', // column to sort by
    sortDesc: false, // if true, sort descending rather than ascending
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    this.updateProps(this.props);
  }

  componentDidMount() {
    this.getJobs();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    const InApp = [];
    const External = [];
    const Sponsored = [];

    props.services.forEach((rep) => {
      if (_.isUndefined(rep.Internal) || rep.Internal) {
        InApp.push(rep);
        if (rep.Sponsored) {
          Sponsored.push(rep);
        }
      } else {
        External.push(rep);
      }
    });

    this.setState({
      InApp,
      External,
      Sponsored,
    });
  }

  getJobs() {
    serviceActions
      .getServices(this.props.auth.site)
      .then((res) => {
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.servicesLoaded(res.data);
        }
      })
      .catch((error) => {});
  }

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  removeService(event) {
    if (window.confirm(`Are you sure you want to delete ${event.Title}?`)) {
      this.props.removeService(event.RowId);
      serviceActions
        .addService(this.props.auth.site, { ...event, Deleted: true })
        .then((res) => {
          // action
        })
        .catch((res) => {
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  handleCheckChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.checked;
    this.setState(stateChange);
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  renderVisTest(source) {
    source = _.filter(source, (ev) => {
      if (!ev || ev.Deleted) {
        return false;
      }
      return true;
    });

    return source.map((ev, index) => {
      if (ev != null) {
        return (
          <tr key={index}>
            <td className="table-TitleColumn">
              {(_.isUndefined(ev.Internal) || ev.Internal) && (
                <Link to={`/services/service/${ev.RowId}`}>
                  <span>{ev.Title}</span>
                </Link>
              )}
              {!_.isUndefined(ev.Internal) && !ev.Internal && (
                <Link to={`/sponsors/sponsor/${ev.RowId}`}>
                  <span>{ev.Title}</span>
                </Link>
              )}
            </td>
            <td>{moment.unix(ev.Changed).local().format('D MMM YY')}</td>
            <td className="text-brandingColour">
              {this.props.page === 'services' && ev.Sponsored && 'Sponsored'}
              {this.props.page !== 'services' && (_.isUndefined(ev.Internal) || ev.Internal) && 'Sponsored Service'}
            </td>
            <td className="table-options">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {(_.isUndefined(ev.Internal) || ev.Internal) && (
                  <Link to={`/services/analytics/${ev.RowId}`}>
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name="bar-chart" />
                  </Link>
                )}
                {(_.isUndefined(ev.Internal) || ev.Internal) && (
                  <Link to={`/services/service/${ev.RowId}`}>
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name="pencil" />
                  </Link>
                )}
                {!_.isUndefined(ev.Internal) && !ev.Internal && (
                  <Link to={`/sponsors/sponsor/${ev.RowId}`}>
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name="pencil" />
                  </Link>
                )}
                <a
                  onClick={() => {
                    this.removeService(ev);
                  }}
                >
                  <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="minus-circle" />
                </a>
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  renderSort(col) {
    if (col !== this.state.sortColumn) {
      return null;
    }
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  sortIsActive(col) {
    if (col !== this.state.sortColumn) {
      return '';
    }
    return ' table--columnActive';
  }

  renderView(source) {
    return (
      <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
        <thead>
          <tr>
            <th
              className={`${this.sortIsActive('Title')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.sortByCol('Title');
              }}
            >
              {this.props.page === 'services' ? 'Service' : 'Sponsor'} title{this.renderSort('Title')}
            </th>
            <th
              className={`${this.sortIsActive('Changed')}`}
              style={{ cursor: 'pointer', width: 150 }}
              onClick={() => {
                this.sortByCol('Changed');
              }}
            >
              Last updated{this.renderSort('Changed')}
            </th>
            <th style={{ minWidth: 80 }} />
            <th style={{ width: 50 }} />
          </tr>
        </thead>
        <tbody>
          {/* Render Current Visitors here! */}
          {this.renderVisTest(source)}
        </tbody>
      </Table>
    );
  }

  renderEmpty() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <Text type="h3">There are no sponsors</Text>
        </div>
      </div>
    );
  }

  renderContent() {
    let source = [];

    // If viewed from Services Hub
    if (this.props.page === 'services') {
      source = [...this.state.InApp];
      if (this.props.filter === 'sponsored') {
        source = _.filter(source, (ev) => {
          return ev.Sponsored;
        });
      }
    } else {
      // Viewed from Sponsored Hub
      source = [...this.state.External, ...this.state.Sponsored];
      if (this.props.filter === 'ext') {
        source = [...this.state.External];
      }
      if (this.props.filter === 'sponsored') {
        source = [...this.state.Sponsored];
      }
    }

    source = _.sortBy(source, (event) => {
      if (this.state.sortColumn !== 'Changed') {
        return event[this.state.sortColumn];
      }
      return event.Changed;
    });

    if (this.state.sortDesc) {
      source.reverse();
    }
    // source = []
    if (_.isEmpty(source)) {
      return this.renderEmpty();
    }
    return this.renderView(source);
  }

  render() {
    return <div style={{ minWidth: '100%' }}>{this.renderContent()}</div>;
  }
}

const mapStateToProps = (state) => {
  const { services } = state.services;
  const { auth } = state;
  return {
    services,
    auth,
    site: auth.site,
  };
};

export default connect(mapStateToProps, { servicesLoaded, removeService })(SponsorsList);
