import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { contactsLoaded, removeContact } from '../../actions';
import { checkLoggedIn, getSessionTokenAWS, validateAccess } from '../../session';
import { contactActions } from '../../webapi';
import { COLOUR_BRANDING_OFF } from '../../js';
import { AddButton } from '../../components';
import { Text } from '../../components/text';

class ImportantContacts extends Component {
  state = {
    contacts: [],
    showCompleted: false,
    sortColumn: 'order',
    sortDesc: false,

    loading: true,
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    this.getData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    this.setState({
      contacts: props.contacts,
    });
  }

  moveDown(ev) {
    if (this.state.savingOrder) {
      return;
    }
    let source = _.sortBy(this.state.contacts, 'order');
    source = _.filter(source, (ev) => {
      if (!ev) {
        return false;
      }
      return true;
    });
    const index = source.indexOf(ev);
    if (index === source.length - 1) {
      return;
    }
    source.splice(index, 1);
    source.splice(index + 1, 0, ev);

    this.saveOrder(source);
  }

  moveUp(ev) {
    if (this.state.savingOrder) {
      return;
    }
    let source = _.sortBy(this.state.contacts, 'order');
    source = _.filter(source, (ev) => {
      if (!ev) {
        return false;
      }
      return true;
    });
    const index = source.indexOf(ev);
    if (index === 0) {
      return;
    }
    source.splice(index, 1);
    source.splice(index - 1, 0, ev);

    this.saveOrder(source);
  }

  saveOrder(source) {
    const changes = [];
    source.forEach((item, index) => {
      if (item.order !== index) {
        changes.push({
          id: item.RowId,
          order: index,
        });
        item.order = index;
      }
    });
    this.setState({
      savingOrder: true,
    });
    contactActions.changeContactOrder(this.props.auth.site, changes).then((res) => {
      console.log('success');
      this.setState({
        savingOrder: false,
      });
    });
  }

  getUpStyle(ev) {
    if (this.state.savingOrder || ev.order === 0) {
      return { visibility: 'hidden' };
    }
    return null;
  }

  getDownStyle(ev) {
    if (this.state.savingOrder || ev.order === this.state.contacts.length - 1) {
      return { visibility: 'hidden' };
    }
    return null;
  }

  getData() {
    contactActions.getContacts(this.props.auth.site).then((res) => {
      if (res.data != null) {
        this.props.contactsLoaded(res.data);
      }
      this.setState({ loading: false });
    });
  }

  removeImportantContact(ev) {
    if (window.confirm(`Are you sure you want to delete ${ev.title}?`)) {
      getSessionTokenAWS().then((idToken) => {
        contactActions
          .deleteContact(this.props.auth.site, ev.RowId, this.props.auth.user.uid, idToken)
          .then((res) => {
            // action
            console.log('success');
            this.props.removeContact(ev.RowId);
          })
          .catch((res) => {
            alert('Something went wrong with the request. Please try again.');
          });
      });
    }
  }

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  renderList() {
    let source = _.sortBy(this.state.contacts, this.state.sortColumn);
    if (this.state.sortDesc) {
      source.reverse();
    }
    source = _.filter(source, (ev) => {
      if (!ev) {
        return false;
      }
      return true;
    });

    return source.map((ev, index) => {
      if (ev != null) {
        return (
          <tr key={index}>
            <td className="table-TitleColumn">
              <Link to={`/info/importantcontact/${ev.RowId}`}>{ev.title}</Link>
            </td>
            <td>{ev.email}</td>
            <td>{ev.phone}</td>
            {validateAccess(this.props.auth.site, 'importantContacts', this.props.auth) && (
              <td className="table-options">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <a
                    onClick={() => {
                      this.moveUp(ev);
                    }}
                    style={this.getUpStyle(ev)}
                  >
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="arrow-up" />
                  </a>
                  <a
                    onClick={() => {
                      this.moveDown(ev);
                    }}
                    style={this.getDownStyle(ev)}
                  >
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="arrow-down" />
                  </a>
                  <Link to={`/info/importantcontact/${ev.RowId}`}>
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name="pencil" />
                  </Link>
                  <a
                    onClick={() => {
                      this.removeImportantContact(ev);
                    }}
                  >
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="minus-circle" />
                  </a>
                </div>
              </td>
            )}
          </tr>
        );
      }
      return null;
    });
  }

  renderSort(col) {
    if (col !== this.state.sortColumn) {
      return null;
    }
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  sortIsActive(col) {
    if (col !== this.state.sortColumn) {
      return '';
    }
    return ' table--columnActive';
  }

  addNew() {
    this.props.history.push(`/info/importantcontact`);
  }

  renderView() {
    return (
      <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
        <thead>
          <tr>
            <th
              className={`${this.sortIsActive('title')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.sortByCol('title');
              }}
            >
              Name{this.renderSort('title')}
            </th>
            <th
              className={`${this.sortIsActive('email')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.sortByCol('email');
              }}
            >
              Email{this.renderSort('email')}
            </th>
            <th
              className={`${this.sortIsActive('phone')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.sortByCol('phone');
              }}
            >
              Phone{this.renderSort('phone')}
            </th>
            <th style={{ width: 70 }} />
          </tr>
        </thead>
        <tbody>{this.renderList()}</tbody>
      </Table>
    );
  }

  renderEmpty() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <Text type="h3">There are no contacts</Text>
        </div>
      </div>
    );
  }

  renderContent() {
    if (_.isEmpty(this.state.contacts)) {
      return this.renderEmpty();
    }
    return this.renderView();
  }

  render() {
    if (this.state.contacts.length === 0 && this.state.loading) {
      return (
        <div style={{ minWidth: '100%' }}>
          <div className="padding-60 paddingVertical-40" style={{ textAlign: 'center' }}>
            <FontAwesome style={{ fontSize: 30, color: COLOUR_BRANDING_OFF }} name="spinner fa-pulse fa-fw" />
          </div>
        </div>
      );
    }
    return <div style={{ minWidth: '100%' }}>{this.renderContent()}</div>;
  }
}

const mapStateToProps = (state) => {
  const { contacts } = state.contacts;
  const { auth } = state;
  return { contacts, auth };
};

export default connect(mapStateToProps, { contactsLoaded, removeContact })(withRouter(ImportantContacts));
