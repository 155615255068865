import { getUrl } from './helper';
import { authedFunction, getRefreshTokenAWS } from '../session';
import { baseClient, baseStage, baseAPIUrl } from '../config';

export const deviceActions = {
  activateDevice: async (code, site) => {
    const refreshToken = await getRefreshTokenAWS();
    return authedFunction({
      method: 'POST',
      url: getUrl('devices', 'activateDevice'),
      data: {
        code,
        site,
        apiUrl: baseAPIUrl,
        client: baseClient,
        stage: baseStage,
        refreshToken,
      },
    });
  },
  getDevices: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('devices', 'getDevices', { site }),
    });
  },
  saveSettings: (rowId, site, nickname, view) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('devices', 'update/save'),
      data: {
        rowId,
        site,
        nickname,
        view,
      },
    });
  },
  restartDevice: (rowId, site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('devices', 'update/command'),
      data: {
        rowId,
        site,
        command: 'RESTART',
      },
    });
  },
  deleteDevice: (deviceId, site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('devices', 'deleteDevice'),
      data: {
        deviceId,
        site,
      },
    });
  },
};
