import _ from 'lodash';
import {
  EVENTS_LOADED,
  EVENTS_UPDATED,
  EVENT_REMOVED,
  EVENT_SUBMISSIONS_LOADED,
  EVENT_SUBMISSION_REMOVED,
  EVENTS_PURGE,
  EVENT_DASHBOARD_LOADING,
  EVENT_SUBMISSIONS_DASHBOARD_LOADING,
  EVENTS_MANAGED_LOADED,
  AVAILABLE_EVENTS_LOADED,
} from './types';
import { eventActions } from '../webapi';
import { readStorageWithCookie } from '../helper';

export const eventsUpdate = (site, time, isdashboard) => {
  return (dispatch) => {
    if (isdashboard) {
      dispatch({
        type: EVENT_DASHBOARD_LOADING,
      });
    }
    eventActions.getEvents(site, time, true).then((res) => {
      const currentSite = readStorageWithCookie('site');
      if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === currentSite) {
        dispatch({
          type: EVENTS_UPDATED,
          payload: res.data,
        });
      } else {
        dispatch({
          type: EVENTS_UPDATED,
          payload: [],
        });
      }
    });
  };
};

export const eventsLoaded = (events) => {
  return {
    type: EVENTS_LOADED,
    payload: events,
  };
};

export const removeEvent = (id) => {
  return {
    type: EVENT_REMOVED,
    payload: id,
  };
};

export const availableEventsLoaded = (type, list) => {
  return {
    type: AVAILABLE_EVENTS_LOADED,
    payload: {
      type,
      list,
    },
  };
};

export const eventSubmissionsUpdate = (site, isdashboard) => {
  return (dispatch) => {
    if (isdashboard) {
      dispatch({
        type: EVENT_SUBMISSIONS_DASHBOARD_LOADING,
      });
    }
    eventActions.getEventSubmissions(site).then((res) => {
      if (res.data != null) {
        dispatch({
          type: EVENT_SUBMISSIONS_LOADED,
          payload: res.data,
        });
      } else {
        dispatch({
          type: EVENT_SUBMISSIONS_LOADED,
          payload: [],
        });
      }
    });
  };
};

export const eventSubmissionsLoaded = (events) => {
  return {
    type: EVENT_SUBMISSIONS_LOADED,
    payload: events,
  };
};

export const removeEventSubmission = (id) => {
  return {
    type: EVENT_SUBMISSION_REMOVED,
    payload: id,
  };
};

export const clearEvents = () => {
  return {
    type: EVENTS_PURGE,
  };
};
