import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import 'react-step-progress/dist/index.css';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button } from '../../components';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded } from '../../actions';
import { isTheBest, getApiError } from '../../session';

const STEP_AUTO_VERIFY = 'setupAutoVerify';
const STEP_USER_POOL = 'setupCognitoUserPool';
const STEP_IDENTITY_POOL = 'setupCognitoIdentityPool';
const STEP_API_DOMAIN = 'setupApiDomain';
const STEP_API_GATEWAY = 'setupApiGatewayDomain';
const STEP_DEPLOYMENT_BUCKET = 'setupDeploymentBucket';
const STEP_AWS_BRANCH = 'setupAWSBranch';
const STEP_LAMBDA_LAYERS = 'setupLambdaLayers';
const STEP_LAMBDA_LAYERS_COMPLETE = 'setupLambdaLayersComplete';
const STEP_LAMBDA_CONCURRENCY = 'ensureLambdaConcurrencyLimit';
const STEP_DEFAULT_AWS_CONFIG = 'defaultAWSConfig';
const STEP_DEFAULT_AWS_INVITEEMAIL = 'defaultAWSInviteEmail';
const STEP_DEFAULT_AWS_SERVERLESS = 'defaultAWSServerless';
const STEP_WEB_BRANCH = 'setupWebBranch';
const STEP_DEFAULT_WEB_CONFIG = 'defaultWebConfig';
const STEP_DEFAULT_WEB_PACKAGE = 'defaultWebPackage';
const STEP_DEFAULT_WEB_COLOURS = 'defaultWebColours';
const STEP_APP_BRANCH = 'setupAppBranch';
const STEP_DEFAULT_APP_EXPO = 'defaultAppExpo';
const STEP_DEFAULT_APP_CONFIG = 'defaultAppConfig';
const STEP_DEFAULT_APP_COLOURS = 'defaultAppColours';
const STEP_HOSTING = 'setupHosting';
const STEP_DEPLOY_SERVICES = 'deployServices';
const STEP_DEPLOY_WEBSITE = 'deployWebsite';
const STEP_INIT_SITE = 'initialiseSite';
const STEP_SETUP_TC = 'setupTC';
const STEP_SUBDOMAIN = 'setupSubdomain';

class CommunityManagerSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingMessage: '',
      message: {},
    };
  }

  canContinue = () => {
    const { activeWhiteLabel } = this.props;
    const { loading } = this.state;
    return !loading && activeWhiteLabel.AWSAccessKey && activeWhiteLabel.AWSSecretKey;
  };

  onMoveStep = (stepIndex) => {
    if (this.props.onMoveStep) this.props.onMoveStep(stepIndex);
  };

  onRefresh = () => {
    if (!this.canContinue()) return;
    const { activeWhiteLabel } = this.props;

    this.setState({ loading: true, loadingMessage: 'Processing...' }, async () => {
      try {
        const { data } = await automationActions.getWhiteLabel(null, activeWhiteLabel.RowId);
        this.props.whiteLabelLoaded(data);
        this.setState({ loading: false, loadingMessage: '' });
      } catch (error) {
        this.setState({ loading: false, loadingMessage: getApiError(error).message });
      }
    });
  };

  onRestoreLayers = () => {
    if (!this.canContinue()) return;

    const { message } = this.state;
    const { activeWhiteLabel } = this.props;

    message[STEP_LAMBDA_LAYERS_COMPLETE] = 'Restoring...';
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.restoreLambdaLayers(activeWhiteLabel.RowId);
        message[STEP_LAMBDA_LAYERS_COMPLETE] = '';
        this.props.whiteLabelLoaded(data);
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_LAMBDA_LAYERS_COMPLETE] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  onCheckLambdaLimit = () => {
    if (!this.canContinue()) return;

    const { message } = this.state;
    const { activeWhiteLabel } = this.props;

    message[STEP_LAMBDA_CONCURRENCY] = 'Checking...';
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.ensureLambdaConcurrencyQuota(activeWhiteLabel.RowId);
        message[STEP_LAMBDA_CONCURRENCY] = '';
        this.props.whiteLabelLoaded(data);
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_LAMBDA_CONCURRENCY] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  renderStep = (step, title, exists, content, progress = null) => {
    const { message } = this.state;
    const { activeWhiteLabel } = this.props;
    const text = message[step] || (activeWhiteLabel.Errors || {})[step];
    // console.log('renderStep', message);

    return (
      <tr key={step} style={{ fontSize: 11 }}>
        <td>{title}</td>
        <td>{exists ? content : progress}</td>
        <td>{text}</td>
        <td>{exists ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}</td>
      </tr>
    );
  };

  renderRefresh = () => {
    const { loadingMessage } = this.state;
    return (
      <div className="flex flex-row flex-center">
        <Button style={{ width: 90, marginRight: 10 }} inline buttonType="primary" onClick={this.onRefresh} isActive={this.canContinue()}>
          Refresh
        </Button>
        <div>{loadingMessage}</div>
      </div>
    );
  };

  renderProgress = () => {
    const { activeWhiteLabel } = this.props;
    const { loading } = this.state;
    const {
      AutoVerifyArn,
      UserPoolId,
      UserPoolClientId,
      IdentityPoolId,
      ApiHostedZoneId,
      ApiCertificateArn,
      ApiDomainName,
      ApiGatewayName,
      DeploymentBucket,
      EnvironmentIdAWS,
      LayerPipelineId,
      LayerPipelineUrl,
      LayerPipelineStatus,
      Layers,
      LambdaConcurrencyLimit,
      DefaultAWSConfig,
      DefaultAWSInviteEmail,
      DefaultAWSServerless,
      DefaultAWSServerlessList,
      EnvironmentIdWeb,
      DefaultWebConfig,
      DefaultWebPackage,
      DefaultWebColours,
      EnvironmentIdApp,
      DefaultAppExpo,
      DefaultAppConfig,
      DefaultAppColours,
      HostingBucket,
      HostingDomainId,
      HostingDomainName,
      DeployAWSServicesStarted,
      DeployAWSServicesStatus,
      DeployAWSServicesUrl,
      DeployWebsiteStarted,
      DeployWebsiteStatus,
      DeployWebsiteUrl,
      AdminIntialised,
      TermsSet,
      HostingDomainUrl,
    } = activeWhiteLabel;

    return (
      <div className="flex-1 automation">
        {this.renderRefresh()}
        <Table className="plussTable" striped bordered condensed hover>
          <thead>
            <tr>
              <th>Step</th>
              <th style={{ width: '100%', maxWidth: '60%' }}>Values</th>
              <th>Message</th>
              <th>Completed</th>
            </tr>
          </thead>
          <tbody>
            {this.renderStep(
              STEP_AUTO_VERIFY,
              'Create auto-verify function',
              !_.isEmpty(AutoVerifyArn),
              <div>
                Auto Verify Function ARN
                <br />
                <b>{AutoVerifyArn}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_USER_POOL,
              'Create user pool',
              !_.isEmpty(UserPoolId) && !_.isEmpty(UserPoolClientId),
              <div>
                <div>
                  User Pool Id
                  <br />
                  <b>{UserPoolId}</b>
                </div>
                <div>
                  User Pool Client Id
                  <br />
                  <b>{UserPoolClientId}</b>
                </div>
              </div>,
            )}
            {this.renderStep(
              STEP_IDENTITY_POOL,
              'Create identity pool',
              !_.isEmpty(IdentityPoolId),
              <div>
                Identity Pool Id
                <br />
                <b>{IdentityPoolId}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_API_DOMAIN,
              'Create domain and certificate',
              !_.isEmpty(ApiHostedZoneId) && !_.isEmpty(ApiCertificateArn),
              <div>
                <div>
                  Hosted Zone Id
                  <br />
                  <b>{ApiHostedZoneId}</b>
                </div>
                <div>
                  Certificate ARN
                  <br />
                  <b>{ApiCertificateArn}</b>
                </div>
              </div>,
            )}
            {this.renderStep(
              STEP_API_GATEWAY,
              'Create domain name for API gateway',
              !_.isEmpty(ApiDomainName) && !_.isEmpty(ApiGatewayName),
              <div>
                <div>
                  API Domain Name
                  <br />
                  <b>{ApiDomainName}</b>
                </div>
                <div>
                  API Gateway Name
                  <br />
                  <b>{ApiGatewayName}</b>
                </div>
              </div>,
            )}
            {this.renderStep(
              STEP_DEPLOYMENT_BUCKET,
              'Create deployment bucket',
              !_.isEmpty(DeploymentBucket),
              <div>
                Deployment Bucket
                <br />
                <b>{DeploymentBucket}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_AWS_BRANCH,
              'Create AWS branch',
              !_.isEmpty(EnvironmentIdAWS),
              <div>
                Environment ID
                <br />
                <b>{EnvironmentIdAWS}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_LAMBDA_LAYERS,
              'Deploy lambda layers',
              !_.isEmpty(LayerPipelineId) && !_.isEmpty(LayerPipelineUrl),
              <div>
                <div>
                  Deploy Pipeline Id
                  <br />
                  <b>{LayerPipelineId}</b>
                </div>
                <div>
                  <a href={LayerPipelineUrl} target="_blank" rel="noopener noreferrer">
                    See Deploy Pipeline
                  </a>
                  {` - ${LayerPipelineStatus}`}
                </div>
              </div>,
            )}
            {this.renderStep(
              STEP_LAMBDA_LAYERS_COMPLETE,
              'Complete lambda layers setup',
              !_.isEmpty(Layers),
              <div className="flex flex-row flex-center">
                <div className="marginRight-20">
                  <div>Lambda Layers:</div>
                  {Layers &&
                    Layers.map((layer) => {
                      return (
                        <div key={layer.LayerName}>
                          <b>{layer.LayerArn}</b>
                        </div>
                      );
                    })}
                </div>
                <Button style={{ width: 120 }} inline buttonType="primary" onClick={this.onRestoreLayers} isActive={!loading}>
                  Restore
                </Button>
              </div>,
            )}
            {this.renderStep(
              STEP_LAMBDA_CONCURRENCY,
              'Ensure lambda concurrency limit',
              !_.isNil(LambdaConcurrencyLimit),
              <div className="flex flex-row flex-center">
                <div className="marginRight-20">
                  Concurrent executions
                  <br />
                  <b>{LambdaConcurrencyLimit}</b>
                </div>
                <Button style={{ width: 120 }} inline buttonType="primary" onClick={this.onCheckLambdaLimit} isActive={!loading}>
                  Check Limit
                </Button>
              </div>,
            )}
            {this.renderStep(
              STEP_DEFAULT_AWS_CONFIG,
              'Default AWS configurations',
              DefaultAWSConfig,
              <div>
                <b>{DefaultAWSConfig ? 'COMPLETE' : 'NOT COMPLETE'}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_DEFAULT_AWS_INVITEEMAIL,
              'Default invite email configurations',
              DefaultAWSInviteEmail,
              <div>
                <b>{DefaultAWSInviteEmail ? 'COMPLETE' : 'NOT COMPLETE'}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_DEFAULT_AWS_SERVERLESS,
              'Default serverless configurations',
              DefaultAWSServerless,
              <div>
                <b>{DefaultAWSServerless ? 'COMPLETE' : 'NOT COMPLETE'}</b>
              </div>,
              <div>
                <div>Stacks to update:</div>
                <div style={{ wordBreak: 'break-word' }}>
                  <b>{DefaultAWSServerlessList && DefaultAWSServerlessList.length > 0 ? DefaultAWSServerlessList.join(',') : 'NONE'}</b>
                </div>
              </div>,
            )}
            {this.renderStep(
              STEP_WEB_BRANCH,
              'Create web branch',
              !_.isEmpty(EnvironmentIdWeb),
              <div>
                Environment ID
                <br />
                <b>{EnvironmentIdWeb}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_DEFAULT_WEB_CONFIG,
              'Default web configurations',
              DefaultWebConfig,
              <div>
                <b>{DefaultWebConfig ? 'COMPLETE' : 'NOT COMPLETE'}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_DEFAULT_WEB_PACKAGE,
              'Default web packages',
              DefaultWebPackage,
              <div>
                <b>{DefaultWebPackage ? 'COMPLETE' : 'NOT COMPLETE'}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_DEFAULT_WEB_COLOURS,
              'Default web colours',
              DefaultWebColours,
              <div>
                <b>{DefaultWebColours ? 'COMPLETE' : 'NOT COMPLETE'}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_APP_BRANCH,
              'Create app branch',
              !_.isEmpty(EnvironmentIdApp),
              <div>
                Environment ID
                <br />
                <b>{EnvironmentIdApp}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_DEFAULT_APP_EXPO,
              'Default expo configurations',
              DefaultAppExpo,
              <div>
                <b>{DefaultAppExpo ? 'COMPLETE' : 'NOT COMPLETE'}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_DEFAULT_APP_CONFIG,
              'Default app configurations',
              DefaultAppConfig,
              <div>
                <b>{DefaultAppConfig ? 'COMPLETE' : 'NOT COMPLETE'}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_DEFAULT_APP_COLOURS,
              'Default app colours',
              DefaultAppColours,
              <div>
                <b>{DefaultAppColours ? 'COMPLETE' : 'NOT COMPLETE'}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_HOSTING,
              'Set up hosting',
              !_.isEmpty(HostingBucket) && !_.isEmpty(HostingDomainId) && !_.isEmpty(HostingDomainName),
              <div>
                <div>
                  Hosting Bucket
                  <br />
                  <b>{HostingBucket}</b>
                </div>
                <div>
                  Hosting Domain Id
                  <br />
                  <b>{HostingDomainId}</b>
                </div>
                <div>
                  Hosting Domain Url
                  <br />
                  <a href={`https://${HostingDomainName}`} target="_blank" rel="noopener noreferrer">
                    {HostingDomainName}
                  </a>
                </div>
              </div>,
            )}
            {this.renderStep(
              STEP_DEPLOY_SERVICES,
              'Deploy AWS services',
              DeployAWSServicesStatus === 'SUCCESSFUL' && DeployAWSServicesUrl,
              <div>
                <b>{`${DeployAWSServicesStatus} - `} </b>
                <a href={DeployAWSServicesUrl} target="_blank" rel="noopener noreferrer">
                  See Deploy Pipeline
                </a>
              </div>,
              DeployAWSServicesStatus ? (
                <div>
                  <b>{`${DeployAWSServicesStatus}${DeployAWSServicesUrl ? ' - ' : ''}`} </b>
                  {DeployAWSServicesUrl ? (
                    <a href={DeployAWSServicesUrl} target="_blank" rel="noopener noreferrer">
                      See Deploy Pipeline
                    </a>
                  ) : null}
                </div>
              ) : (
                <b>{DeployAWSServicesStarted ? 'QUEUED' : ''}</b>
              ),
            )}
            {this.renderStep(
              STEP_DEPLOY_WEBSITE,
              'Deploy Website',
              DeployWebsiteStatus === 'SUCCESSFUL' && DeployWebsiteUrl,
              <div>
                <b>{`${DeployWebsiteStatus} - `} </b>
                <a href={DeployWebsiteUrl} target="_blank" rel="noopener noreferrer">
                  See Deploy Pipeline
                </a>
              </div>,
              DeployWebsiteStatus ? (
                <div>
                  <b>{`${DeployWebsiteStatus}${DeployWebsiteUrl ? ' - ' : ''}`} </b>
                  {DeployWebsiteUrl ? (
                    <a href={DeployWebsiteUrl} target="_blank" rel="noopener noreferrer">
                      See Deploy Pipeline
                    </a>
                  ) : null}
                </div>
              ) : (
                <b>{DeployWebsiteStarted ? 'QUEUED' : ''}</b>
              ),
            )}
            {this.renderStep(
              STEP_INIT_SITE,
              'Initialise site',
              AdminIntialised,
              <div>
                <b>{AdminIntialised ? 'COMPLETE' : 'NOT COMPLETE'}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_SETUP_TC,
              'Set up T&C',
              TermsSet,
              <div>
                <b>{TermsSet ? 'COMPLETE' : 'NOT COMPLETE'}</b>
              </div>,
            )}
            {this.renderStep(
              STEP_SUBDOMAIN,
              'Set up sub domain',
              HostingDomainUrl,
              <div>
                Sub domain url
                <br />
                <a href={`https://${HostingDomainUrl}`} target="_blank" rel="noopener noreferrer">
                  {HostingDomainUrl}
                </a>
              </div>,
            )}
          </tbody>
        </Table>
        {this.renderRefresh()}
      </div>
    );
  };

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    return this.renderProgress();
  }
}

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded })(CommunityManagerSetup);
