import { baseStage, baseAPIUrl } from '../config';
import _ from 'lodash';

export const getUrl = (baseAction, action, query, { url, stage } = { url: null, stage: null }) => {
  let queryPart = '';
  if (query) {
    _.keys(query).forEach((key, index) => {
      queryPart += `${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(query[key])}`;
    });
  }
  return `${url || baseAPIUrl}/${baseAction}-${stage || baseStage}/${action}${queryPart}`;
};
