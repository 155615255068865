import React, { Component } from 'react';
import { Table, Form, Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { InputGroup, Button } from '../../components';
import { getApiError } from '../../session';
import { automationActions } from '../../webapi';
import { renderTitle } from './helper';

class AppDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: '',
      messages: {},
      appStatusList: [],
      sortColumn: 'AppCode',
      sortDesc: false,
      filterText: '',
      filteredList: [],
    };
  }

  componentDidMount() {
    this.onLoadAppStatus();
  }

  componentWillUnmount() {}

  sortIsActive(col) {
    if (col !== this.state.sortColumn) return '';
    return ' table--columnActive';
  }

  sortByCol = (col) => {
    const { sortColumn, sortDesc } = this.state;
    if (sortColumn === col) {
      this.setState({ sortDesc: !sortDesc });
    } else {
      this.setState({ sortColumn: col, sortDesc: false });
    }
  };

  filterList = (list) => {
    const { appStatusList, filterText, sortColumn, sortDesc } = this.state;
    const source = _.orderBy(list || appStatusList, sortColumn, sortDesc ? 'desc' : 'asc');

    const matchText = (item) => {
      const text = filterText.toLowerCase();
      return (
        _.isEmpty(text) ||
        (item.AppName && item.AppName.toLowerCase().includes(text)) ||
        (item.AppCode && item.AppCode.toLowerCase().includes(text))
      );
    };

    const filteredList = _.filter(source, (item) => {
      if (!item) return false;
      return matchText(item);
    });
    // console.log('filteredList', filteredList);

    this.setState({ filteredList });
  };

  onSearchChanged = (event) => {
    const filterText = event && event.target.value ? event.target.value : '';
    this.setState({ filterText }, this.filterList);
  };

  onCheckAppStatus = async () => {
    const { loading } = this.state;
    if (loading) return;

    this.setState({ loading: true, message: 'Processing...' }, async () => {
      try {
        await automationActions.processAppStatus();
        this.setState({ loading: false, message: '' }, () => setTimeout(this.onLoadAppStatus, 2000));
      } catch (error) {
        const message = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  onLoadAppStatus = async () => {
    const { loading } = this.state;
    if (loading) return;

    this.setState({ loading: true, message: 'Loading...' }, async () => {
      try {
        const { data } = await automationActions.getAppStatusList();
        // console.log('onLoadAppStatus', data);
        this.setState({ loading: false, appStatusList: data, message: '' }, this.filterList);
      } catch (error) {
        const message = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  getMessage = (rowId) => this.state.messages[rowId];

  setMessage = (rowId, message) => {
    const messages = _.cloneDeep(this.state.messages);
    messages[rowId] = message;
    this.setState({ messages });
  };

  onResetAppStatus = async (rowId) => {
    let message = this.getMessage(rowId);
    if (message) return;

    this.setMessage(rowId, 'Refreshing...');
    this.setState({ loading: true }, async () => {
      try {
        const { data } = await automationActions.resetAppStatus(rowId);
        // console.log('onResetAppStatus', data);
        this.setMessage(rowId, '');
        this.setState({ loading: false }, this.onLoadAppStatus);
      } catch (error) {
        const message = getApiError(error).message;
        this.setMessage(rowId, message);
        this.setState({ loading: false });
      }
    });
  };

  renderSort(col) {
    const { sortColumn, sortDesc } = this.state;
    if (col !== sortColumn) return null;
    return <FontAwesome style={{ marginLeft: 5 }} name={sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  renderEmpty() {
    const { loading } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <span className="fontRegular fontSize-13">{loading ? 'Loading app status...' : 'There are no app status information'}</span>
        </div>
      </div>
    );
  }

  renderHeaders() {
    return (
      <tr>
        <th
          className={`${this.sortIsActive('AppName')}`}
          style={{ cursor: 'pointer', width: 200 }}
          onClick={() => this.sortByCol('AppName')}
        >
          App Name{this.renderSort('AppName')}
        </th>
        <th style={{ width: 400 }}>App</th>
        <th style={{ width: 400 }}>Community Manager</th>
        <th>Messages</th>
        <th className={`${this.sortIsActive('Updated')}`} style={{ cursor: 'pointer' }} onClick={() => this.sortByCol('Updated')}>
          Updated{this.renderSort('Updated')}
        </th>
        <th style={{ width: 50 }}>Options</th>
      </tr>
    );
  }

  renderAppName(app) {
    const { AppCode, AppName } = app;
    return <div style={{ fontSize: 14 }}>{`${AppName || ''}${AppCode ? ` (${AppCode})` : ''}`.trim()}</div>;
  }

  renderCheck(checked) {
    return checked && checked !== 'NOT FOUND' ? (
      <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
    ) : (
      <FontAwesome className="marginLeft-10 text-plussRed" name={'times'} />
    );
  }

  renderAppSection(app) {
    const {
      AppStoreAppId,
      AppStoreIssuerId,
      AppStoreKeyId,
      AppStoreVersion,
      AppStoreState,
      EASProjectId,
      AppVersion,
      AppVersionIOS,
      AppVersionAndroid,
      PlayStoreVersion,
      PlayStoreState,
    } = app;
    return (
      <div style={{ fontSize: 12 }}>
        <div>
          <b>App Version: </b>
          {AppVersion}
          {this.renderCheck(AppVersion)}
        </div>
        <div>
          <b>App Version (iOS): </b>
          {AppVersionIOS}
          {this.renderCheck(AppVersionIOS)}
        </div>
        <div>
          <b>App Version (Android): </b>
          {AppVersionAndroid}
          {this.renderCheck(AppVersionAndroid)}
        </div>
        <div>
          <b>Expo ID: </b>
          {EASProjectId}
          {this.renderCheck(EASProjectId)}
        </div>
        <div className="marginTop-8">
          <b>App Store ID: </b>
          {AppStoreAppId}
          {this.renderCheck(AppStoreAppId)}
        </div>
        <div>
          <b>App Store Issuer ID: </b>
          {AppStoreIssuerId}
          {this.renderCheck(AppStoreIssuerId)}
        </div>
        <div>
          <b>App Store Key ID: </b>
          {AppStoreKeyId}
          {this.renderCheck(AppStoreKeyId)}
        </div>
        <div>
          <b>App Store Version: </b>
          {AppStoreVersion}
          {this.renderCheck(AppStoreVersion)}
        </div>
        <div>
          <b>App Store Status: </b>
          {AppStoreState}
          {this.renderCheck(AppStoreState && AppStoreState !== 'REJECTED')}
        </div>
        <div className="marginTop-8">
          <b>Play Store Version: </b>
          {PlayStoreVersion}
          {this.renderCheck(PlayStoreVersion)}
        </div>
        <div>
          <b>Play Store Status: </b>
          {PlayStoreState}
          {this.renderCheck(PlayStoreState)}
        </div>
      </div>
    );
  }

  renderWebSection(app) {
    const {
      AWSAccessKey,
      AWSSecretKey,
      EmailServiceEnabled,
      EmailServiceStatus,
      DeployAWSServicesStatus,
      DeployWebsiteStatus,
      HostingDomainUrl,
      AdminIntialised,
      TermsSet,
    } = app;
    return (
      <div style={{ fontSize: 12 }}>
        <div>
          <b>Serverless Key: </b>
          {AWSAccessKey}
          {this.renderCheck(AWSAccessKey)}
        </div>
        <div>
          <b>Serverless Secret: </b>
          {AWSSecretKey}
          {this.renderCheck(AWSSecretKey)}
        </div>
        <div>
          <b>SES Email Enabled: </b>
          {EmailServiceEnabled}
          {this.renderCheck(EmailServiceEnabled)}
        </div>
        <div>
          <b>SES Email Status: </b>
          {EmailServiceStatus}
          {this.renderCheck(EmailServiceStatus && EmailServiceStatus !== 'DENIED')}
        </div>
        <div>
          <b>AWS Services Deployed: </b>
          {DeployAWSServicesStatus}
          {this.renderCheck(DeployAWSServicesStatus && DeployAWSServicesStatus === 'SUCCESSFUL')}
        </div>
        <div>
          <b>Community Manager Deployed: </b>
          {DeployWebsiteStatus}
          {this.renderCheck(DeployWebsiteStatus && DeployWebsiteStatus === 'SUCCESSFUL')}
        </div>
        <div>
          <b>Community Manager Url: </b>
          {HostingDomainUrl}
          {this.renderCheck(HostingDomainUrl)}
        </div>
        <div>
          <b>Community Manager Initialised: </b>
          {AdminIntialised}
          {this.renderCheck(AdminIntialised)}
        </div>
        <div>
          <b>Community Manager T&C Set: </b>
          {TermsSet}
          {this.renderCheck(TermsSet)}
        </div>
      </div>
    );
  }

  renderContent() {
    const { filteredList, sortColumn, sortDesc } = this.state;

    const source = _.orderBy(
      filteredList,
      (app) => {
        if (sortColumn) return app[sortColumn];
        return app.Start;
      },
      sortDesc ? 'desc' : 'asc',
    );

    return source.map((app, index) => {
      const { RowId, Errors, Updated } = app;
      const message = this.getMessage(RowId);
      return (
        <tr key={index}>
          <td className="table-TitleColumn">{this.renderAppName(app)}</td>
          <td>{this.renderAppSection(app)}</td>
          <td>{this.renderWebSection(app)}</td>
          <td>
            {Errors && Errors.length > 0
              ? Errors.map((e, index) => (
                  <div style={{ fontSize: 11 }} key={index}>
                    {e}
                  </div>
                ))
              : ''}
          </td>
          <td>{moment.utc(Updated).local().format('DD MMM YY - hh:mma')}</td>
          <td className="table-options">
            {message || (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <a onClick={() => this.onResetAppStatus(RowId)}>
                  <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="refresh" />
                </a>
              </div>
            )}
          </td>
        </tr>
      );
    });
  }

  renderFilters() {
    const { filterText, loading, message } = this.state;

    return (
      <Form>
        <Row>
          <Col xs={6}>
            <InputGroup
              id="filterText"
              type="text"
              label="Search"
              placeholder="Search by App name or App code"
              value={filterText}
              onChange={this.onSearchChanged}
            />
          </Col>
          <Col xs={6} className="flex flex-row flex-center" style={{ marginTop: 26 }}>
            {/* <Button className="marginRight-16" inline buttonType="primary" onClick={this.onCheckAppStatus} isActive={!loading}>
              Process
            </Button> */}
            <Button className="marginRight-16" inline buttonType="primary" onClick={this.onLoadAppStatus} isActive={!loading}>
              Refresh
            </Button>
            <div>{message}</div>
          </Col>
        </Row>
      </Form>
    );
  }

  renderTable() {
    if (_.isEmpty(this.state.appStatusList)) return this.renderEmpty();

    return (
      <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
        <thead>{this.renderHeaders()}</thead>
        <tbody>{this.renderContent()}</tbody>
      </Table>
    );
  }

  render() {
    return (
      <div style={{ minWidth: '100%' }}>
        {renderTitle('App Status Dashboard')}
        {this.renderFilters()}
        {this.renderTable()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(AppDashboard);
