import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Header, AddButton, HubSidebar } from '../../components';
import SignInFormList from './SignInFormList';
import SignInList from './SignInList';
import ContractorList from './ContractorList';
import EditTerms from './EditTerms';
import VisitorList from './VisitorList';
import Keys from '../Keys/Keys';
import { COLOUR_BRANDING_OFF } from '../../js';
import { isAdminMaster, validateAccess } from '../../session';
import FormLockoutSettings from './FormLockoutSettings';
import FormLockoutLog from './FormLockoutLog';

class SignInHub extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSection: 'SignIns',
    };
  }

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    this.setState({});
  }

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? 'fontHeavy' : 'fontLight ';
  }

  onAddNewForm = () => {
    if (validateAccess(this.props.auth.site, 'forms', this.props.auth)) {
      this.props.history.push('/signinHub/form');
    }
  };

  renderStats(gweg, loading) {
    if (loading) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    return gweg;
  }

  renderLeftBar() {
    const sectionItems = [];

    sectionItems.push({
      type: 'navItem',
      text: 'View Sign Ins',
      icon: 'eye',
      isFontAwesome: true,
      selected: this.state.selectedSection === 'SignIns',
      onClick: () => {
        this.setState({ selectedSection: 'SignIns' });
      },
    });

    if (validateAccess(this.props.auth.site, 'forms', this.props.auth)) {
      sectionItems.push({
        type: 'navItem',
        text: 'Forms',
        // icon: 'check-square-o',
        // isFontAwesome: true,
        selected: this.state.selectedSection === 'Forms',
        onClick: () => {
          this.setState({ selectedSection: 'Forms' });
        },
      });
    }

    if (validateAccess(this.props.auth.site, 'keysCards', this.props.auth)) {
      sectionItems.push({
        type: 'navItem',
        text: 'Keys & Cards',
        // icon: 'key',
        // isFontAwesome: true,
        selected: this.state.selectedSection === 'Keys',
        onClick: () => {
          this.setState({ selectedSection: 'Keys' });
        },
      });
    }

    return (
      <HubSidebar
        sections={[
          {
            title: 'Sign Ins',
            items: sectionItems,
          },
        ]}
      />
    );
  }

  renderRight() {
    if (this.state.selectedSection === 'Forms') {
      return <SignInFormList onNew={this.onAddNewForm} />;
    }
    if (this.state.selectedSection === 'SignIns') {
      return <SignInList />;
    }
    if (this.state.selectedSection === 'Contractors') {
      return <ContractorList />;
    }
    if (this.state.selectedSection === 'Visitors') {
      return <VisitorList />;
    }
    if (this.state.selectedSection === 'Terms' && isAdminMaster(this.props.auth)) {
      return <EditTerms />;
    }
    if (this.state.selectedSection === 'Keys' && validateAccess(this.props.auth.site, 'keysCards', this.props.auth)) {
      return <Keys />;
    }
    if (this.state.selectedSection === 'FormLockout' && isAdminMaster(this.props.auth)) {
      return <FormLockoutSettings />;
    }
    if (this.state.selectedSection === 'FormLockoutLog' && validateAccess(this.props.auth.site, 'visitors', this.props.auth)) {
      return <FormLockoutLog />;
    }
    return null;
  }

  addNew() {}

  getButtonText() {
    if (this.state.selectedSection === 'Contractors') {
      return 'NEW CONTRACTOR SIGN IN';
    }
    if (this.state.selectedSection === 'Visitors') {
      return 'NEW VISITOR SIGN IN';
    }
    if (this.state.selectedSection === 'Keys' && validateAccess(this.props.auth.site, 'keysCards', this.props.auth)) {
      return 'NEW KEY';
    }
    return '';
  }

  renderNewButton() {
    if (this.state.selectedSection === 'Terms') {
      return null;
    }
    if (this.state.selectedSection === 'Keys' && !validateAccess(this.props.auth.site, 'keysCards', this.props.auth)) {
      return null;
    }
    return <AddButton onClick={this.addNew.bind(this)} text={this.getButtonText()} />;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
    paddingRight: 32,
    whiteSpace: 'nowrap',
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(SignInHub);
