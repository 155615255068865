import { authedFunction, unauthedFunction } from '../session';
import _ from 'lodash';
import { getUrl } from './helper';

export const formActions = {
  getForm: (formId) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('forms', 'get/single', { formId }),
    });
  },
  getForms: (site, includeInactive) => {
    return unauthedFunction({
      method: 'GET',
      url: getUrl('forms', 'getForms', { site, includeInactive }),
    });
  },
  saveForm: (entry, site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('forms', 'saveForm'),
      data: { entry, site },
    });
  },
  deleteForm: (site, id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('forms', 'deleteForm'),
      data: { site, id },
    });
  },
  manageStatus: (site, id, action) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('forms', 'status'),
      data: { site, id, action },
    });
  },
  getSubmissions: (site, type, from, to) => {
    const query = { site, type };
    if (!_.isUndefined(from)) query.from = from;
    if (!_.isUndefined(to)) query.to = to;
    return authedFunction({
      method: 'GET',
      url: getUrl('forms', 'getSubmissions', query),
    });
  },
  submitForm: (submission) => {
    return unauthedFunction({
      method: 'POST',
      url: getUrl('forms', 'submitForm'),
      data: { submission },
    });
  },
  performFormAction: (site, type, action, value, extras) => {
    return unauthedFunction({
      method: 'POST',
      url: getUrl('forms', 'performFormAction'),
      data: { site, type, action, value, extras },
    });
  },
  changeFormOrder: (site, changes) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('forms', 'changeFormOrder'),
      data: {
        site,
        changes,
      },
    });
  },
  editSubmission: (id, changes) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('forms', 'submissions/edit'),
      data: {
        id,
        changes,
      },
    });
  },
};
