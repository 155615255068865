import React, { Component } from 'react';
import tinycolor from 'tinycolor2';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getApiError } from '../../session';
import { Button, ColourPicker } from '../../components';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded } from '../../actions';
import { renderTitle, renderTextStep } from './helper';
import { pctBtwnColours, COLOUR_NAV_GREY } from '../../js';
import AppSelector from './common/AppSelector';

const MESSAGE_CONFIG = 'configs';
const MESSAGE_APP = 'app';
const REPO_APP = 'minuss-expo';
const CONFIG_TYPE_COLOURS = 'file';
const CONFIG_KEY_COLOURS = 'src/js/Colors.js';
const COLOUR_MAIN = 'COLOUR_BRANDING_MAIN';
const COLOUR_LIGHT = 'COLOUR_BRANDING_LIGHT';
const COLOUR_DARK = 'COLOUR_BRANDING_DARK';

class ReplaceAppColours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: null,
      loading: false,
      selectedApp: null,
      colourMain: '',
      colourDark: '',
      colourLight: '',
    };
  }

  componentDidMount() {
    // this.onAppSelected({
    //   Id: 'unittesting-sub4',
    //   Key: 'unittesting-sub4',
    //   Title: 'Unit Testing 4',
    // });
  }

  setMessage = (key, message, extraStates = {}) => {
    return new Promise((resolve) => {
      const newState = { ...extraStates };
      newState[`message_${key}`] = message;
      // console.log('setMessage', newState);
      this.setState(newState, resolve());
    });
  };

  getMessage = (key) => this.state[`message_${key}`];

  onAppSelected = async (selectedApp) => {
    await this.setMessage(MESSAGE_CONFIG, 'Loading app colours...', { selectedApp, loading: true });
    try {
      const branch = this.state.selectedApp.Key;
      const { data } = await automationActions.getEnvironmentConfigs(REPO_APP, branch);
      const config = data.find((c) => c.type === CONFIG_TYPE_COLOURS && c.key === CONFIG_KEY_COLOURS);
      const colourMain = config.values.find((v) => v.key === COLOUR_MAIN).value;
      const colourLight = config.values.find((v) => v.key === COLOUR_LIGHT).value;
      const colourDark = config.values.find((v) => v.key === COLOUR_DARK).value;
      //   console.log('onAppSelected', config.values, { colourMain, colourDark, colourLight });
      await this.setMessage(MESSAGE_CONFIG, '', { loading: false, config, colourMain, colourDark, colourLight });
    } catch (error) {
      const message = getApiError(error).message;
      await this.setMessage(MESSAGE_CONFIG, `Error: ${message}`, { loading: false });
    }
  };

  onColourSelected = (colour, field = null) => {
    const newState = {};
    if (field) {
      newState[field] = colour;
    } else {
      newState.colourMain = colour;
      newState.colourDark = '#' + tinycolor(pctBtwnColours(colour, '#000', 0.8)).toHex();
      newState.colourLight = '#' + tinycolor(pctBtwnColours('#fff', colour, 0.6)).toHex();
    }
    this.setState(newState);
  };

  onSaveColours = async () => {
    const { loading, selectedApp, config, colourMain, colourDark, colourLight } = this.state;
    if (loading) return;

    try {
      await this.setMessage(MESSAGE_APP, 'Saving app colours...', { loading: true });
      const branch = selectedApp.Key;
      const newConfig = _.cloneDeep(config);
      newConfig.values.find((v) => v.key === COLOUR_MAIN).value = colourMain && colourMain.toUpperCase();
      newConfig.values.find((v) => v.key === COLOUR_LIGHT).value = colourLight && colourLight.toUpperCase();
      newConfig.values.find((v) => v.key === COLOUR_DARK).value = colourDark && colourDark.toUpperCase();
      //   console.log('onSaveColours', newConfig.values, { colourMain, colourDark, colourLight });
      await automationActions.updateEnvironmentConfigs(REPO_APP, branch, [newConfig]);
      await this.setMessage(MESSAGE_APP, 'Saved successfully', { loading: false });
      setTimeout(() => this.setMessage(MESSAGE_APP, ''), 5000);
    } catch (error) {
      const message = getApiError(error).message;
      await this.setMessage(MESSAGE_APP, `Error: ${message}`, { loading: false });
    }
  };

  renderColourAjdust(value, field) {
    const { loading } = this.state;
    return (
      <ColourPicker
        options="picker"
        defaultTab="picker"
        value={value}
        showHex
        onColourSelected={(colour) => this.onColourSelected(colour, field)}
        disabled={loading}
      >
        <div
          className="colourPicker_button"
          style={{ width: 80, height: 30, marginBottom: 'unset', backgroundColor: value || COLOUR_NAV_GREY }}
        />
      </ColourPicker>
    );
  }

  renderColours() {
    const { colourMain, colourDark, colourLight, loading } = this.state;
    const messageConfig = this.getMessage(MESSAGE_CONFIG);
    if (messageConfig) return <div className="marginTop-16">{messageConfig ? <div>{messageConfig}</div> : null}</div>;

    const messageApp = this.getMessage(MESSAGE_APP);
    return (
      <div className="marginTop-16">
        <div className="flex flex-row flex-center">
          <div style={{ width: 120 }}>Pick a colour</div>
          <ColourPicker
            options="picker,soft,vibrant"
            defaultTab="vibrant"
            value={colourMain}
            showHex
            onColourSelected={this.onColourSelected}
            disabled={loading}
          />
        </div>
        <div className="marginTop-32 marginBottom-20">And/or manually adjust the brand colours</div>
        <div className="flex flex-row flex-center marginTop-10">
          <div style={{ width: 120 }}>Main colour</div>
          {this.renderColourAjdust(colourMain, 'colourMain')}
        </div>
        <div className="flex flex-row flex-center marginTop-10">
          <div style={{ width: 120 }}>Dark colour</div>
          {this.renderColourAjdust(colourDark, 'colourDark')}
        </div>
        <div className="flex flex-row flex-center marginTop-10">
          <div style={{ width: 120 }}>Light colour</div>
          {this.renderColourAjdust(colourLight, 'colourLight')}
        </div>
        <div className="flex flex-row flex-center marginTop-24">
          <Button inline buttonType="primary" onClick={this.onSaveColours} isActive={!loading}>
            Save
          </Button>
          <div className="marginLeft-16">{messageApp}</div>
        </div>
      </div>
    );
  }

  render() {
    const { selectedApp, loading } = this.state;
    return (
      <div>
        {renderTitle('Replace App Colours')}
        <ol>
          {renderTextStep(
            <div>
              <AppSelector onSelected={this.onAppSelected} selected={selectedApp} includeSubApps disabled={loading} />
            </div>,
          )}
          {selectedApp
            ? renderTextStep(
                <div>
                  Set brand colours
                  {this.renderColours()}
                </div>,
              )
            : null}
        </ol>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { automation } = state;
  return { activeWhiteLabel: automation.active };
};

export default connect(mapStateToProps, { whiteLabelLoaded })(ReplaceAppColours);
