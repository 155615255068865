import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { AnalyticsFilter, Header, Text } from '../../components';
import { getVisibleFeatures } from '../../helper';
import { setNavData } from '../../actions';
import { extensionReducers, extensionAnalytics } from '../../config/features';
import {
  UserAnalytics,
  EventAnalytics,
  FacilityAnalytics,
  OfferAnalytics,
  ServiceAnalytics,
  GalleryAnalytics,
  PollAnalytics,
  AlertAnalytics,
  UserRetentionAnalytics,
} from '../../components/analytics';
import { getAnalyticsFilterOptions } from '../../js';
import { UTC_OFFSET } from '../../config';

const analyticsComponents = {
  alerts: AlertAnalytics,
  events: EventAnalytics,
  facilities: FacilityAnalytics,
  gallery: GalleryAnalytics,
  offers: OfferAnalytics,
  surveys: PollAnalytics,
  services: ServiceAnalytics,
  users: [UserRetentionAnalytics, UserAnalytics],
};

class AnalyticsHub extends Component {
  constructor(props) {
    super(props);

    const filterOptions = getAnalyticsFilterOptions();
    let selectedFilter =
      this.props.nav &&
      !_.isUndefined(this.props.nav.analyticsFilter) &&
      _.find(filterOptions, (d) => {
        return d.dayCount === this.props.nav.analyticsFilter;
      });
    if (!selectedFilter) {
      selectedFilter = filterOptions[4];
    }
    this.state = { selectedFilter };
  }

  componentDidMount() {
    this.setTimeframe();
  }

  setTimeframe(min, max) {
    this.setState({
      loading: true,
    });
    const lastDay = max ? moment(max).utc() : moment.utc();
    const dayCount = min ? moment(max).diff(moment(min), 'days') : this.state.selectedFilter.dayCount;

    const startOfLastDay = lastDay.utcOffset(UTC_OFFSET).startOf('d');
    const minTime = moment(startOfLastDay).add(-dayCount, 'd').valueOf() - 1;
    const maxTime = moment(startOfLastDay).valueOf() - 1;

    const prevText = this.state.selectedFilter.prevText.replace('*', dayCount);

    this.setState({
      minTime,
      maxTime,
      timeframeReady: true,
      prevText,
      dayCount,
    });
  }

  filterChanged = (selectedFilter) => {
    this.props.setNavData({
      analyticsFilter: selectedFilter.dayCount,
    });
    this.setState({ selectedFilter }, () => {
      this.setTimeframe();
    });
  };

  filterDateRangeChanged = (startDate, endDate) => {
    this.setTimeframe(startDate, endDate);
  };

  renderFeatureComponent = (FeatureComponent, key) => {
    if (!FeatureComponent) {
      return null;
    }
    return (
      <FeatureComponent
        key={key}
        startTime={this.state.minTime}
        endTime={this.state.maxTime}
        prevText={this.state.prevText}
        dayCount={this.state.dayCount}
      />
    );
  };

  renderFeature = (key) => {
    let FeatureComponent;
    if (extensionAnalytics[key]) {
      FeatureComponent = extensionAnalytics[key];
    }
    if (analyticsComponents[key]) {
      FeatureComponent = analyticsComponents[key];
    }
    if (!FeatureComponent) {
      return null;
    }
    if (!this.state.timeframeReady) {
      return null;
    }
    if (Array.isArray(FeatureComponent)) {
      return FeatureComponent.map((f, index) => {
        return this.renderFeatureComponent(f, `${key}${index}`);
      });
    }
    return this.renderFeatureComponent(FeatureComponent, key);
  };

  renderFeatures() {
    const ordered = getVisibleFeatures(this.props.auth, this.state, this.props);
    return ordered.map((f, i) => this.renderFeature(f.key, i));
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentCenter">
            <div className="hub-content dashboardContent hub-content-analytics">
              <Text type="h1" className="marginTop-40">
                Analytics
              </Text>

              <AnalyticsFilter
                defaultFilter={this.state.selectedFilter}
                filterChanged={this.filterChanged}
                filterDateRangeChanged={this.filterDateRangeChanged}
              />
              {this.renderFeatures()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, nav } = state;
  const props = { auth, nav, strings: (state.strings && state.strings.config) || {} };
  if (extensionReducers) {
    const reducerKeys = Object.keys(extensionReducers);
    reducerKeys.forEach((key) => {
      props[key] = state[key];
    });
  }
  return props;
};

export default connect(mapStateToProps, { setNavData })(AnalyticsHub);
