import _ from 'lodash';
import { CALENDARS_LOADED, CALENDAR_ADDED, CALENDAR_REMOVED, BOOKINGTYPES_LOADED, BOOKINGTYPE_ADDED, BOOKINGTYPE_REMOVED } from './types';

export const calendarsLoaded = (calendars) => {
  return {
    type: CALENDARS_LOADED,
    payload: calendars,
  };
};

export const addCalendar = (calendar) => {
  return {
    type: CALENDAR_ADDED,
    payload: calendar,
  };
};

export const removeCalendar = (id) => {
  return {
    type: CALENDAR_REMOVED,
    payload: id,
  };
};
export const bookingTypesLoaded = (bookingTypes) => {
  return {
    type: BOOKINGTYPES_LOADED,
    payload: bookingTypes,
  };
};

export const addBookingType = (bookingType) => {
  return {
    type: BOOKINGTYPE_ADDED,
    payload: bookingType,
  };
};

export const removeBookingType = (id) => {
  return {
    type: BOOKINGTYPE_REMOVED,
    payload: id,
  };
};
