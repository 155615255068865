import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Header, HubSidebar } from '../../components';
import { withRouter } from 'react-router-dom';
import BookingCalendars from './BookingCalendars';
import BookingTypes from './BookingTypes';
import Bookings from './Bookings';

class BookingsHub extends Component {
  state = {
    selectedSection: 'Bookings',
  };

  renderLeftBar() {
    const sections = [];

    const bookingItems = [];
    bookingItems.push({
      type: 'newButton',
      text: 'New Booking',
      buttonType: 'primary',
      onClick: () => {
        this.props.history.push(`/bookings/booking`);
      },
    });
    bookingItems.push({
      type: 'newButton',
      text: 'Block Time',
      buttonType: 'outlined',
      onClick: () => {
        this.props.history.push(`/bookings/blockout`);
      },
    });
    bookingItems.push({
      type: 'navItem',
      text: 'View Bookings',
      icon: 'eye',
      isFontAwesome: true,
      selected: this.state.selectedSection === 'Bookings',
      onClick: () => {
        this.setState({ selectedSection: 'Bookings' });
      },
    });
    sections.push({
      title: 'Bookings',
      items: bookingItems,
    });

    const calendarItems = [];
    calendarItems.push({
      type: 'newButton',
      text: 'New Calendar',
      buttonType: 'outlined',
      onClick: () => {
        this.props.history.push(`/bookings/calendar`);
      },
    });
    calendarItems.push({
      type: 'navItem',
      text: 'View Calendars',
      icon: 'eye',
      isFontAwesome: true,
      selected: this.state.selectedSection === 'Calendars',
      onClick: () => {
        this.setState({ selectedSection: 'Calendars' });
      },
    });
    sections.push({
      title: 'Calendars',
      items: calendarItems,
    });

    const typeItems = [];
    typeItems.push({
      type: 'newButton',
      text: 'New Booking Type',
      buttonType: 'outlined',
      onClick: () => {
        this.props.history.push(`/bookings/type`);
      },
    });
    typeItems.push({
      type: 'navItem',
      text: 'View Booking Types',
      icon: 'eye',
      isFontAwesome: true,
      selected: this.state.selectedSection === 'BookingTypes',
      onClick: () => {
        this.setState({ selectedSection: 'BookingTypes' });
      },
    });
    sections.push({
      title: 'Booking Types',
      items: typeItems,
    });

    return <HubSidebar sections={sections} />;
  }

  renderRight() {
    if (this.state.selectedSection === 'Bookings') {
      return <Bookings />;
    }
    if (this.state.selectedSection === 'Calendars') {
      return <BookingCalendars />;
    }
    if (this.state.selectedSection === 'BookingTypes') {
      return <BookingTypes />;
    }
    return null;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(withRouter(BookingsHub));
