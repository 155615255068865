import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, GenericInput, Text } from '../../../components';
import { COLOUR_GREEN } from '../../../js';
import { stringActions } from '../../../webapi';

class SetWelcome extends Component {
  state = {
    loaded: false,
    showWarnings: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    stringActions.getString(this.props.auth.site, 'welcomeEmail').then((res) => {
      this.setState({
        welcomeEmail: res.data,
        loaded: true,
      });
    });
    stringActions.getString(this.props.auth.site, 'welcomeEmailTitle').then((res) => {
      this.setState({
        welcomeEmailTitle: res.data,
      });
    });
    stringActions.getString(this.props.auth.site, 'welcomeText').then((res) => {
      this.setState({
        welcomeText: res.data,
      });
    });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true, showWarnings: false });
    stringActions
      .setString(this.props.auth.site, 'welcomeEmailTitle', this.state.welcomeEmailTitle)
      .then((res) => {})
      .catch((res) => {
        alert('Something went wrong with saving the welcome email title. Please try again.');
      });
    stringActions
      .setString(this.props.auth.site, 'welcomeText', this.state.welcomeText)
      .then((res) => {})
      .catch((res) => {
        alert('Something went wrong with saving the welcome text. Please try again.');
      });
    stringActions
      .setString(this.props.auth.site, 'welcomeEmail', this.state.welcomeEmail)
      .then((res) => {
        this.setState({
          success: true,
          submitting: false,
        });
      })
      .catch((res) => {
        this.setState({ submitting: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  onSuccess() {
    this.setState({ submitting: false, success: true });
  }

  validateForm() {
    return true;
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <Button inline buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateForm()}>
        Save
      </Button>
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return <span style={{ color: COLOUR_GREEN, fontSize: 14, lineHeight: '33px', marginLeft: 30 }}>Saved</span>;
  }

  render() {
    return (
      <div style={{ width: '100%', maxWidth: 600 }}>
        <Text type="h3">Welcome Email Settings</Text>
        <GenericInput
          id="welcomeEmailTitle"
          label="Welcome Email Subject"
          type="text"
          placeholder="Enter subject"
          value={this.state.welcomeEmailTitle}
          onChange={(e) => this.handleChange(e)}
        />
        <GenericInput
          id="welcomeEmail"
          label="Welcome email content"
          type="textarea"
          placeholder="Enter email content"
          value={this.state.welcomeEmail}
          onChange={(e) => this.handleChange(e)}
          inputStyle={{
            height: 500,
          }}
          help={
            <div>
              <i>___PASSWORD___</i> will be replaced with the user's actual password to help them log in. This supports HTML mark up.
            </div>
          }
        />
        <Text type="h3" className="marginTop-40">
          Welcome Text Message Settings
        </Text>
        <GenericInput
          id="welcomeText"
          label="Welcome text message"
          type="textarea"
          placeholder="Enter text message"
          value={this.state.welcomeText}
          onChange={(e) => this.handleChange(e)}
          inputStyle={{
            height: 500,
          }}
          help={
            <div>
              <i>___PASSWORD___</i> will be replaced with the user's actual password to help them log in.
            </div>
          }
        />
        {this.renderSubmit()}
        {this.renderSuccess()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(SetWelcome);
