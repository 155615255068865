import React, { Component } from 'react';
import _ from 'lodash';
import { GlobalHider } from '../../components';
import { feedbackActions, typeActions } from '../../webapi';
import { safeReadParams, getPluralS, getPercentage } from '../../helper';
import { COLOUR_BRANDING_APP } from '../../js';
import { logo } from '../../config';

class PollEmbed extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      pollId: safeReadParams(this.props, 'pollId'),
      poll: {},
      colour: COLOUR_BRANDING_APP,
      logo: logo,
    };

    this.state = { ...this.initialState };
  }

  componentDidMount() {
    this.loadResults();

    this.resultInterval = setInterval(() => {
      this.loadResults();
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.resultInterval);
  }

  loadResults() {
    const promises = [];

    promises.push(feedbackActions.getPoll(this.state.pollId));
    promises.push(feedbackActions.getPollResults(this.state.pollId));

    Promise.all(promises).then((result) => {
      this.getSiteBranding(result[0].data.Site);
      this.setState({
        poll: result[0].data,
        pollLoaded: true,
        results: result[1].data,
      });
    });
  }

  getSiteBranding(site) {
    if (this.state.loadedBranding) return;
    typeActions.getSiteBranding(site).then((res) => {
      this.setState({
        loadedBranding: true,
        siteName: res.data.Name,
        colour: res.data.MainBrandingColour || COLOUR_BRANDING_APP,
        logo: res.data.Logo || logo,
      });
    });
  }

  getResponseCount(qid, oid) {
    if (!this.state.results || !this.state.results[qid] || !this.state.results[qid][oid]) {
      return 0;
    }
    return this.state.results[qid][oid];
  }

  getPercentage(count) {
    if (!this.state.loadedBranding) return '0%';
    return getPercentage(count, this.state.poll.ResponseCount);
  }

  getOptionText(o) {
    if (!_.isUndefined(o.Value) && o.Value !== o.Text) {
      return `${o.Value}: ${o.Text}`;
    }
    return o.Text;
  }

  renderMultiChoiceOption(q, o) {
    const optionId = _.isUndefined(o.Id) ? o.Value : o.Id;
    const count = this.getResponseCount(q.Id, optionId);
    const percentage = this.getPercentage(count);
    return (
      <div className="multichoiceresult" key={optionId}>
        <div className="multichoiceresult_bar">
          <p className="multichoiceresult_innerText">{this.getOptionText(o)}</p>
          <div
            className="multichoiceresult_innerBar"
            style={{ backgroundColor: this.state.colour, transform: `scaleX(${percentage})` }}
          ></div>
          <div className="flex flex-center relative">
            <div className="multichoiceresult_responses">
              {count} response{getPluralS(count)}
            </div>
            <div className="multichoiceresult_percentage">{percentage}</div>
          </div>
        </div>
      </div>
    );
  }

  renderMultiChoiceResults(question) {
    return question.Options.map((o) => {
      return this.renderMultiChoiceOption(question, o);
    });
  }

  renderBranding() {
    if (!this.state.loadedBranding) {
      return null;
    }
    return (
      <div className="question_logo">
        <img src={this.state.logo} alt="logo" />
      </div>
    );
  }

  renderQuestion() {
    if (!this.state.poll.Questions) {
      return null;
    }
    const question = this.state.poll.Questions[0];
    return (
      <div className="question_resultsContainer">
        <p className="question_title">{question.Text}</p>
        {this.renderMultiChoiceResults(question)}
      </div>
    );
  }

  render() {
    return (
      <div>
        <GlobalHider chatbot sideMenu />
        <div className="question question-results question-embed">
          {this.renderBranding()}
          {this.renderQuestion()}
        </div>
      </div>
    );
  }
}

export default PollEmbed;
