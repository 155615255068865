import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';
import _ from 'lodash';
import { removeAlert } from '../../actions';
import { checkLoggedIn } from '../../session';
import { alertActions } from '../../webapi';
import { Text } from '../../components/text';

class AlertsList extends Component {
  state = {
    categories: [
      {
        Title: 'All primary users',
        Key: 'resident',
      },
      {
        Title: 'All staff',
        Key: 'staff',
      },
      {
        Title: 'All linked users',
        Key: 'family',
      },
    ],
    jobList: [],
    showCompleted: false,
    sortColumn: 'CreatedUnix', // column to sort by
    sortDesc: true, // if true, sort descending rather than ascending
    now: moment.utc().format('YYYY-MM-DD'),
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    this.updateProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    this.setState({
      jobList: props.alerts,
    });
  }

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  removeAlert(alert) {
    if (window.confirm(`Are you sure you want to delete the alert "${alert.Title}"?`)) {
      this.props.removeAlert(alert.RowId);
      alertActions
        .addAlert({ RowId: alert.RowId, Site: alert.Site, Deleted: true })
        .then((res) => {})
        .catch((res) => {
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  handleCheckChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.checked;
    this.setState(stateChange);
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  getCatTitle(v) {
    const typeObject = _.find(this.state.categories, (t) => {
      return t.Key === v;
    });
    if (typeObject) {
      return typeObject.Title;
    }
    return '';
  }

  getTagTitle(v) {
    const tag = _.find(this.props.tags, (t) => {
      return t.Id === v;
    });
    if (tag) {
      return tag.Title;
    }
    return 'User Tag';
  }

  getTarget(ev) {
    if (ev.AudienceType === 'All') {
      return 'All users';
    }
    if (ev.AudienceType === 'Category') {
      return this.getCatTitle(ev.AudienceTypeSelection);
    }
    if (ev.AudienceType === 'UserTags') {
      return this.getTagTitle(ev.AudienceTypeSelection);
    }
    if (ev.AudienceType === 'User') {
      let string = '';
      ev.Include.forEach((u, index) => {
        string = string + `${index !== 0 ? ', ' : ''}${u.displayName}`;
      });
      if (string.length > 50) {
        return string.substring(0, 50) + '...';
      } else {
        return string;
      }
    }
    return ev.AudienceTypeSelection;
  }

  renderVisTest(source) {
    return source.map((ev, index) => {
      if (ev != null) {
        return (
          <tr key={index}>
            {/* <td style={{ minWidth: 120 }} className="lineClamp-2 table-id">{ev.RowId}</td> */}
            <td className="table-TitleColumn">
              <Link className="lineClamp-2" to={`/alerts/alert/${ev.RowId}`}>
                {ev.Title}
              </Link>
            </td>
            {/* <td>{ev.Description}</td> */}
            <td>{moment.unix(ev.CreatedUnix).local().format('D MMM YY')}</td>
            <td>{moment.unix(ev.ExpiryUnix).local().format('D MMM YY')}</td>
            <td className={'text-cap'}>{this.getTarget(ev)}</td>
            <td>{ev.IsImportant && <span className="fontHeavy text-brandingColour">Important</span>}</td>
            <td className="table-options">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to={`/alerts/alert/${ev.RowId}`}>
                  <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name="pencil" />
                </Link>
                <a
                  onClick={() => {
                    this.removeAlert(ev);
                  }}
                >
                  <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="minus-circle" />
                </a>
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  renderSort(col) {
    if (col !== this.state.sortColumn) {
      return null;
    }
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  sortIsActive(col) {
    if (col !== this.state.sortColumn) {
      return '';
    }
    return ' table--columnActive';
  }

  renderView(source) {
    return (
      <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
        <thead>
          <tr>
            <th
              className={`${this.sortIsActive('Title')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.sortByCol('Title');
              }}
            >
              Title{this.renderSort('Title')}
            </th>
            <th
              className={`${this.sortIsActive('CreatedUnix')}`}
              style={{ cursor: 'pointer', width: 150 }}
              onClick={() => {
                this.sortByCol('CreatedUnix');
              }}
            >
              Created{this.renderSort('CreatedUnix')}
            </th>
            <th
              className={`${this.sortIsActive('ExpiryUnix')}`}
              style={{ cursor: 'pointer', width: 150 }}
              onClick={() => {
                this.sortByCol('ExpiryUnix');
              }}
            >
              Expiry date{this.renderSort('ExpiryUnix')}
            </th>
            <th style={{ width: 150 }}>Audience</th>
            <th style={{ width: 120 }}></th>
            <th style={{ width: 50 }} />
          </tr>
        </thead>
        <tbody>
          {/* Render Current Visitors here! */}
          {this.renderVisTest(source)}
        </tbody>
      </Table>
    );
  }

  renderEmpty() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <Text type="h3">There are no alerts</Text>
        </div>
      </div>
    );
  }

  renderContent() {
    let source = _.sortBy(this.state.jobList, (event) => {
      if (this.state.sortColumn !== 'Changed') {
        return event[this.state.sortColumn];
      }
      return event.Changed;
    });

    if (this.state.sortDesc) {
      source.reverse();
    }

    source = _.filter(source, (ev) => {
      if (!ev || ev.Deleted) {
        return false;
      }
      if (!this.props.showInActive && !moment.utc(ev.Expiry).isSameOrAfter(this.state.now)) {
        return false;
      }
      // if (!this.state.showPast) {
      //     return moment.utc(ev.Date).isSameOrAfter(this.state.now);
      // }
      return true;
    });
    // source = []

    if (_.isEmpty(source)) {
      return this.renderEmpty();
    }
    return this.renderView(source);
  }

  render() {
    return <div style={{ minWidth: '100%' }}>{this.renderContent()}</div>;
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
    site: auth.site,
  };
};

export default connect(mapStateToProps, { removeAlert })(AlertsList);
