import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getApiError } from '../../session';
import { ImageInput } from '../../components';
import { automationActions } from '../../webapi';
import { renderTitle, renderTextStep } from './helper';
import { COLOUR_NAV_GREY } from '../../js';
import AppSelector from './common/AppSelector';

const MESSAGE_CONFIG = 'configs';
const REPO_APP = 'minuss-expo';
const IMAGE_NOT_FOUND = 'NOTFOUND';
const CONFIG_TYPE_ASSETS = 'assets';
const IMAGE_WIDTH = 200;

class ReplaceAppAssets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedApp: null,
      config: {},
      assets: [],
    };
  }

  componentDidMount() {
    // this.setState(
    //   {
    //     selectedApp: {
    //       Id: 'unittesting-sub4',
    //       Key: 'unittesting-sub4',
    //       Title: 'Unit Testing 4',
    //     },
    //     config: {
    //       type: 'assets',
    //       key: 'assets',
    //       path: 'assets/images',
    //       regex: '',
    //       values: [],
    //     },
    //     assets: [
    //       {
    //         label: 'App Icon',
    //         key: 'ImageUrlAppIcon',
    //         value: '',
    //         default: 'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/assets/appicon.png',
    //         path: 'assets/images/unittesting/appicon.png',
    //         index: 0,
    //       },
    //       {
    //         label: 'App Logo',
    //         key: 'ImageUrlLogo',
    //         value: '',
    //         default: 'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/assets/logo.png',
    //         path: 'assets/images/unittesting/logo.png',
    //         index: 0,
    //       },
    //       {
    //         label: 'App Splash',
    //         key: 'ImageUrlAppSplash',
    //         value: '',
    //         default: 'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/assets/splash.png',
    //         path: 'assets/images/unittesting/splash.png',
    //         index: 0,
    //       },
    //     ],
    //   },
    //   () => this.checkConfigImages(this.state.selectedApp.Key),
    // );
  }

  setMessage = (key, message, extraStates = {}) => {
    return new Promise((resolve) => {
      const newState = { ...extraStates };
      newState[`message_${key}`] = message;
      // console.log('setMessage', newState);
      this.setState(newState, resolve());
    });
  };

  getMessage = (key) => this.state[`message_${key}`];

  setImageData = (key, data, extraStates = {}) => {
    return new Promise((resolve) => {
      const newState = { ...extraStates };
      newState[`imageData_${key}`] = data;
      // console.log('setImageData', newState);
      this.setState(newState, resolve());
    });
  };

  getImageData = (key) => this.state[`imageData_${key}`];

  onAppSelected = async (selectedApp) => {
    await this.setMessage(MESSAGE_CONFIG, 'Loading app images information...', { selectedApp, loading: true, assets: [] });
    try {
      const branch = this.state.selectedApp.Key;
      const { data } = await automationActions.getEnvironmentConfigs(REPO_APP, branch);
      const config = data.find((c) => c.type === CONFIG_TYPE_ASSETS);
      const assets = config ? config.values : [];
      // console.log('onAppSelected', assets);
      await this.setMessage(MESSAGE_CONFIG, '', { loading: false, config, assets });
      this.checkConfigImages(branch);
    } catch (error) {
      const message = getApiError(error).message;
      console.log('onAppSelected', message, error);
      await this.setMessage(MESSAGE_CONFIG, `Error: ${message}`, { loading: false });
    }
  };

  getSourceImage = async (branch, path) => {
    try {
      this.setMessage(path, 'Loading source image...');
      const { data } = await automationActions.getSourceImage(REPO_APP, branch, path);
      this.setMessage(path, '');
      return data;
    } catch (error) {
      // console.log('getSourceImage', { path: a.path, error });
      const message = getApiError(error).message;
      this.setMessage(path, `Error: ${message}`);
      return 'ERROR';
    }
  };

  checkConfigImages = async (branch, path = null) => {
    if (path) {
      this.setImageData(path, null);
      const imageData = await this.getSourceImage(branch, path);
      this.setImageData(path, imageData);
    } else {
      const promises = this.state.assets.map(async (a) => {
        this.setImageData(a.path, null);
        const imageData = await this.getSourceImage(branch, a.path);
        this.setImageData(a.path, imageData);
      });
      await Promise.all(promises);
    }
  };

  onImageUpdated = async (url, key) => {
    const asset = this.state.assets.find((c) => c.key === key);
    if (!asset) return;

    if (!url) {
      // console.log('onImageUpdated - start', { url, key, asset });
      await this.setImageData(asset.path, null);
      await this.setMessage(asset.path, 'Uploading image...');
    } else {
      // console.log('onImageUpdated - finish', { url, key, asset });
      await this.setMessage(asset.path, '');
      this.saveAsset(asset, url);
    }
  };

  saveAsset = async (asset, url) => {
    const { selectedApp, config } = this.state;
    const branch = selectedApp.Key;

    const performSave = async () => {
      // Save assets
      const newConfigs = [{ ...config, values: [{ ...asset, value: url }] }];
      // console.log('saving asset...', newConfigs);
      await this.setMessage(asset.path, `Updating ${asset.label}...`);
      await automationActions.updateEnvironmentConfigs(REPO_APP, branch, newConfigs);
      await this.setMessage(asset.path, ``);
      this.checkConfigImages(branch, asset.path);
    };

    try {
      await performSave();
    } catch (assetError) {
      const message = getApiError(assetError).message;
      console.log('saveAsset error, retrying...', message);
      try {
        await performSave();
      } catch (retryError) {
        const message = getApiError(assetError).message;
        await this.setMessage(asset.path, `Error: ${message}`);
      }
    }
  };

  renderAsset(asset) {
    const { loading } = this.state;
    const imagePreview = this.getImageData(asset.path);
    const imageExists = imagePreview && imagePreview !== IMAGE_NOT_FOUND;
    const assetMessage = this.getMessage(asset.path);
    const uploadDisabled = loading || !_.isEmpty(assetMessage);
    // console.log('renderAsset', { loading, path: asset.path, imageExists, assetMessage, uploadDisabled });

    return (
      <div key={asset.key} className="flex flex-row marginBottom-16" style={{ position: 'relative' }}>
        <div style={{ width: 100 }}>{asset.label}</div>
        <div className="flex marginRight-16" style={{ width: IMAGE_WIDTH, height: IMAGE_WIDTH }}>
          {imageExists ? (
            <img
              style={{ width: IMAGE_WIDTH, height: IMAGE_WIDTH, objectFit: 'contain', backgroundColor: COLOUR_NAV_GREY }}
              src={`data:image/png;base64,${imagePreview}`}
            />
          ) : null}
          <ImageInput
            ref={`image_${asset.key}`}
            containerStyle={{ width: IMAGE_WIDTH, height: IMAGE_WIDTH, position: 'absolute', opacity: imageExists ? 0.2 : 1 }}
            style={{ width: IMAGE_WIDTH, height: IMAGE_WIDTH }}
            disabled={uploadDisabled}
            refreshCallback={(url) => this.onImageUpdated(url, asset.key)}
            simpleStyle
            noCompress
            noMenu
            noDownload
            disableRemove
            onlyAllowUpload
          />
        </div>
        <div>{assetMessage}</div>
      </div>
    );
  }

  renderAssets() {
    const { assets } = this.state;
    const message = this.getMessage(MESSAGE_CONFIG);
    // console.log('renderConfigs', assets);

    return (
      <div className="marginTop-10">
        {message ? <div>{message}</div> : null}
        {assets.map((asset) => this.renderAsset(asset))}
      </div>
    );
  }

  render() {
    const { selectedApp, loading } = this.state;

    return (
      <div>
        {renderTitle('Replace App Images')}
        <ol>
          {renderTextStep(
            <div>
              <AppSelector onSelected={this.onAppSelected} selected={selectedApp} includeSubApps disabled={loading} />
            </div>,
          )}
          {selectedApp
            ? renderTextStep(
                <div>
                  Drag and drop or click to upload images to replace
                  {this.renderAssets()}
                </div>,
              )
            : null}
        </ol>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(ReplaceAppAssets);
