import React from 'react';
import _ from 'lodash';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Text } from './text';
import { COLOUR_DUSK, COLOUR_BRANDING_ACTION } from '../js';

const PlussLineChart = ({ data = [], lineColor = COLOUR_BRANDING_ACTION, strokeWidth = 2 }) => {
  const RenderTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="genericBox padding-4">
          <Text type="h6">
            {label}: {payload[0].value}
          </Text>
        </div>
      );
    }
    return null;
  };

  const getYAxisTicks = () => {
    let yAxisTicks;
    const minValue = (
      _.minBy(data, (d) => {
        return d.value;
      }) || { value: 0 }
    ).value;
    const maxValue = (
      _.maxBy(data, (d) => {
        return d.value;
      }) || { value: 0 }
    ).value;
    if (maxValue - minValue <= 4) {
      yAxisTicks = [minValue];
      let iterValue = minValue;
      while (iterValue < maxValue) {
        yAxisTicks.push(++iterValue);
      }
    }
    return yAxisTicks;
  };

  const getXAxisInterval = () => {
    if (data.length <= 15) {
      return 0;
    }
    return Math.floor(data.length / 15);
  };

  const CustomizedXAxisTick = (props) => {
    const { x, y, payload, tickStyle } = props;

    return (
      <g transform={`translate(${x - 5},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill={tickStyle.color} style={tickStyle} transform="rotate(-45)">
          {payload.value}
        </text>
      </g>
    );
  };

  const tickStyle = { color: COLOUR_DUSK, fontSize: 14, fontFamily: `'Source Sans Pro', sans-serif`, fontWeight: 600 };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" height={90} interval={getXAxisInterval()} tick={<CustomizedXAxisTick tickStyle={tickStyle} />} />
        <YAxis tick={{ ...tickStyle }} ticks={getYAxisTicks()} />
        <Tooltip content={<RenderTooltip />} animationDuration={0} />
        <Line type="monotone" dataKey="value" stroke={lineColor} strokeWidth={strokeWidth} animationDuration={0} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export { PlussLineChart as LineChart };
