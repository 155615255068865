import React from 'react';
import { connect } from 'react-redux';
import { usePay } from './usePay';

export const withPay = (WrappedComponent) => {
  const NonPayableComponent = (props) => {
    const payment = usePay(props);
    return <WrappedComponent {...props} {...{ payment }} />;
  };

  const mapStateToProps = (state) => ({ state });
  return connect(mapStateToProps)(NonPayableComponent);
};
