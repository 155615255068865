import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Button, Header, RadioButton, GenericInput, Popup } from '../../components';
import { deviceActions, typeActions } from '../../webapi';
import { devicesLoaded, removeDevice, viewsLoaded } from '../../actions';
import { validateAccess } from '../../session';
import { Text } from '../../components/text';
import { COLOUR_RED, COLOUR_CITRON, COLOUR_TANGERINE, COLOUR_BLUE } from '../../js';

const CODE_MAX_LENGTH = 6;
const ACTIVE_LIMIT_MINUTES = 10;

class ActivateTV extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      loading: false,
      activating: false,
      deactivating: false,
      saving: false,
      restarting: false,
      status: '',
      settingStatus: '',
      sortColumn: 'DeviceName',
      sortDesc: false,
      selectedDevice: null,
      selectedView: null,
      nickname: '',
      selectedStatus: null,
      isStatusOpen: true,
    };
  }

  componentDidMount() {
    if (this.props.auth.siteType === 'free') {
      this.props.history.push('/mastermenu');
    }
    this.getDevices();
    this.getInterfaces();
  }

  getDevices = () => {
    this.setState({ loading: true }, async () => {
      try {
        const res = await deviceActions.getDevices(this.props.auth.site);
        // console.table('getDevices', res.data);
        this.props.devicesLoaded(res.data);
      } catch (error) {
        console.log('getDevices error', error);
      } finally {
        this.setState({ loading: false });
      }
    });
  };

  getInterfaces = async () => {
    try {
      const res = await typeActions.getInterfaces(this.props.auth.site, 'TV');
      // console.table('getInterfaces', res.data);
      this.props.viewsLoaded(res.data);
    } catch (error) {
      console.log('getInterfaces error', error);
    }
  };

  onChangeCode = (event) => {
    const code = event.target.value;
    if (code.length > CODE_MAX_LENGTH) return;

    this.setState({ code });
  };

  onActivate = async () => {
    const { code } = this.state;
    if (!code) return;

    const { auth } = this.props;
    this.setState({ activating: true });
    try {
      const res = await deviceActions.activateDevice(code, auth.site);
      // console.log('activate', res.data);
      let status = '';
      let selectedDevice = null;
      switch (res.data.Result) {
        case 'INVALID_USER':
          status = 'Invalid user';
          break;
        case 'ACTIVE':
          status = '';
          selectedDevice = res.data;
          break;
        case 'INVALID_CODE':
        default:
          status = 'Invalid code';
          break;
      }
      this.setState({ activating: false, status, code: selectedDevice ? '' : code, selectedDevice });
      this.getDevices();
    } catch (error) {
      console.log('activateDevice error', error);
      this.setState({ activating: false, status: 'Activation failed' });
    }
  };

  onOpenSettings = (selectedDevice) => {
    this.setState({ selectedDevice, selectedView: selectedDevice.View, nickname: selectedDevice.Nickname });
  };

  onCloseSettings = () => {
    this.setState({ selectedDevice: null, settingStatus: '' });
  };

  onOpenStatus = (selectedStatus) => {
    this.setState({ selectedStatus, isStatusOpen: true });
  };

  onCloseStatus = () => {
    this.setState({ selectedStatus: null, isStatusOpen: false });
  };

  onChangeView = (selectedView) => {
    this.setState({ selectedView });
  };

  onDeactivate = () => {
    const { auth } = this.props;
    const { selectedDevice } = this.state;
    if (!selectedDevice) return;

    if (!window.confirm(`Are you sure you want to deactivate ${selectedDevice.DeviceName}?`)) return;

    this.setState({ deactivating: true, settingStatus: '' }, async () => {
      try {
        await deviceActions.deleteDevice(selectedDevice.DeviceId, auth.site);

        this.onCloseSettings();
        this.getDevices();
      } catch (error) {
        console.log('onDeactivate error', error);
        this.setState({ settingStatus: 'There was a problem deactivating' });
      } finally {
        this.setState({ deactivating: false });
      }
    });
  };

  onSave = () => {
    const { auth } = this.props;
    const { selectedDevice, selectedView, nickname } = this.state;
    if (!selectedDevice) return;
    if (!selectedView) {
      this.setState({ settingStatus: 'Please select a view' });
      return;
    }

    this.setState({ saving: true, settingStatus: '' }, async () => {
      try {
        await deviceActions.saveSettings(selectedDevice.RowId, auth.site, nickname, selectedView);

        this.onCloseSettings();
        this.getDevices();
      } catch (error) {
        console.log('onSave error', error);
        this.setState({ settingStatus: 'There was a problem saving' });
      } finally {
        this.setState({ saving: false });
      }
    });
  };

  onRestart = () => {
    const { auth } = this.props;
    const { selectedDevice } = this.state;
    if (!selectedDevice) return;

    if (!window.confirm(`This will force ${selectedDevice.DeviceName} to restart. Are you sure you want to continue?`)) return;

    this.setState({ restarting: true, settingStatus: '' }, async () => {
      try {
        await deviceActions.restartDevice(selectedDevice.RowId, auth.site);

        this.onCloseSettings();
        this.getDevices();
      } catch (error) {
        console.log('onRestart error', error);
        this.setState({ settingStatus: 'There was a problem restarting' });
      } finally {
        this.setState({ restarting: false });
      }
    });
  };

  sortIsActive(col) {
    return col !== this.state.sortColumn ? '' : ' table--columnActive';
  }

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({ sortDesc: !this.state.sortDesc });
    } else {
      this.setState({ sortColumn: col, sortDesc: false });
    }
  }

  getAppStateColor = (device) => {
    const lastConnected = moment(device.Active);
    const isActive = moment().diff(lastConnected, 'minutes') <= ACTIVE_LIMIT_MINUTES;

    switch (_.toLower(device.AppStateCode)) {
      case 'error':
        return COLOUR_RED;
      case 'background':
      case 'inactive':
        return COLOUR_CITRON;
      default:
        return isActive ? COLOUR_BLUE : COLOUR_TANGERINE;
    }
  };

  renderSort(col) {
    if (col !== this.state.sortColumn) return null;
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  renderDevice(source) {
    return source.map((device) => {
      if (device != null) {
        const appStateColor = this.getAppStateColor(device);

        return (
          <tr key={device.DeviceId}>
            <td className="table-TitleColumn">
              <span className="deviceViewCell">{device.Nickname}</span>
            </td>
            <td className="table-TitleColumn">
              <span className="deviceNameCell">{device.DeviceName}</span>
            </td>
            <td className="table-TitleColumn">
              <span className="deviceViewCell">{device.DeviceId}</span>
            </td>
            <td className="table-TitleColumn">
              <span className="deviceViewCell">{device.OS}</span>
            </td>
            <td className="table-TitleColumn">
              <span className="deviceViewCell">{device.Version}</span>
            </td>
            <td className="table-TitleColumn">
              <span className="deviceViewCell">{device.ViewName}</span>
            </td>
            <td className="table-TitleColumn">
              <a onClick={() => this.onOpenStatus(device)}>
                <span className="deviceStatusCell">{device.Status}</span>
              </a>
            </td>
            <td className="table-TitleColumn">
              <a onClick={() => this.onOpenStatus(device)}>
                <span className="deviceViewCell" style={{ color: appStateColor }}>
                  {moment(device.Active).format('DD/MM/YY hh:mma')}
                </span>
              </a>
            </td>
            <td className="table-TitleColumn">
              <a onClick={() => this.onOpenStatus(device)}>
                <span className="deviceViewCell" style={{ color: appStateColor }}>
                  {device.ActivePercentage ? `${device.ActivePercentage}%` : ''}
                </span>
              </a>
            </td>
            <td className="table-options">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button buttonType="primaryAction" buttonClassName="settings-button" onClick={() => this.onOpenSettings(device)} isActive>
                  Settings
                </Button>
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  renderList() {
    const { devices } = this.props;
    const { sortColumn, sortDesc } = this.state;
    const source = _.orderBy(
      devices,
      (device) => {
        return sortColumn !== 'DeviceName' ? device[sortColumn] : device.DeviceName;
      },
      sortDesc ? 'desc' : 'asc',
    );

    return (
      <Table className="plussTable" striped bordered condensed hover>
        <thead>
          <tr>
            <th
              className={`${this.sortIsActive('Nickname')}`}
              style={{ cursor: 'pointer', minWidth: 100 }}
              onClick={() => this.sortByCol('Nickname')}
            >
              Device Nickname{this.renderSort('Nickname')}
            </th>
            <th
              className={`${this.sortIsActive('DeviceName')}`}
              style={{ cursor: 'pointer', minWidth: 100 }}
              onClick={() => this.sortByCol('DeviceName')}
            >
              Device Name{this.renderSort('DeviceName')}
            </th>
            <th
              className={`${this.sortIsActive('DeviceId')}`}
              style={{ cursor: 'pointer', minWidth: 280 }}
              onClick={() => this.sortByCol('DeviceId')}
            >
              Device ID{this.renderSort('DeviceId')}
            </th>
            <th className={`${this.sortIsActive('OS')}`} style={{ cursor: 'pointer', width: 70 }} onClick={() => this.sortByCol('OS')}>
              OS{this.renderSort('OS')}
            </th>
            <th
              className={`${this.sortIsActive('Version')}`}
              style={{ cursor: 'pointer', width: 80 }}
              onClick={() => this.sortByCol('Version')}
            >
              Version{this.renderSort('Version')}
            </th>
            <th
              className={`${this.sortIsActive('ViewName')}`}
              style={{ cursor: 'pointer', minWidth: 120 }}
              onClick={() => this.sortByCol('ViewName')}
            >
              View{this.renderSort('ViewName')}
            </th>
            <th
              className={`${this.sortIsActive('Status')}`}
              style={{ cursor: 'pointer', width: 100 }}
              onClick={() => this.sortByCol('Status')}
            >
              Status{this.renderSort('Status')}
            </th>
            <th
              className={`${this.sortIsActive('Active')}`}
              style={{ cursor: 'pointer', width: 130 }}
              onClick={() => this.sortByCol('Active')}
            >
              Last Connected{this.renderSort('Active')}
            </th>
            <th
              className={`${this.sortIsActive('ActivePercentage')}`}
              style={{ cursor: 'pointer', width: 80 }}
              onClick={() => this.sortByCol('ActivePercentage')}
            >
              Connection{this.renderSort('ActivePercentage')}
            </th>
            <th style={{ width: 100 }} />
          </tr>
        </thead>
        <tbody>{this.renderDevice(source)}</tbody>
      </Table>
    );
  }

  renderSettingsPopup() {
    const { selectedDevice, selectedView, settingStatus, deactivating, saving, restarting, nickname } = this.state;
    if (!selectedDevice) return null;

    const viewOptions = this.props.views.map((view) => {
      return {
        Label: view.Title,
        Value: view.Id,
        onChange: () => this.onChangeView(view),
      };
    });

    return (
      <div className="popup">
        <div className="popup_box settingsPopup">
          <FontAwesome className="closeButton" name="times" onClick={this.onCloseSettings} />
          <p className="heading">TV Settings</p>
          <p className="subHeading">{selectedDevice.DeviceName}</p>
          <GenericInput
            id="nickname"
            type="text"
            label="Nickname"
            placeholder="Enter a nickname for this TV - optional"
            value={nickname}
            onChange={(e) => this.setState({ nickname: e.target.value })}
            alwaysShowLabel
          />
          <p className="help">
            Please select the corresponding app views to be displayed in your TV. You can use the same views in more than one TVs.
          </p>
          <p className="linkedViewsHeading">LINKED APP VIEWS</p>
          <RadioButton
            isActive={selectedView && selectedView.Id}
            rowStyle={styles.deviceViewRow}
            buttonStyle={styles.deviceViewButton}
            options={viewOptions}
            className="viewList"
          />
          <div className="status">{settingStatus}</div>
          <div className="buttonsContainer">
            {deactivating ? (
              <Button buttonType="secondary" buttonClassName="button">
                Deactivating...
              </Button>
            ) : (
              <Button inline buttonType="outlinedAction" buttonClassName="button" onClick={this.onDeactivate} isActive>
                Deactivate TV
              </Button>
            )}
            {restarting ? (
              <Button buttonType="secondary" buttonClassName="button">
                Restarting...
              </Button>
            ) : (
              <Button inline buttonType="outlinedAction" buttonClassName="button" onClick={this.onRestart} isActive>
                Restart TV
              </Button>
            )}
            {saving ? (
              <Button buttonType="secondary" buttonClassName="button">
                Saving...
              </Button>
            ) : (
              <Button inline buttonType="primaryAction" buttonClassName="button" onClick={this.onSave} isActive>
                Save
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderStatusPopup() {
    const { isStatusOpen, selectedStatus } = this.state;
    if (!isStatusOpen || !selectedStatus) return null;

    const appState = selectedStatus.AppState
      ? selectedStatus.AppState.split('\n').map((m, i) => (
          <span key={i}>
            {m}
            <br />
          </span>
        ))
      : '';
    const messages = selectedStatus.Message
      ? selectedStatus.Message.map((m, i) => (
          <span key={i}>
            {m}
            <br />
          </span>
        ))
      : '';
    return (
      <Popup
        title={selectedStatus.Nickname || selectedStatus.DeviceName}
        hasPadding
        buttons={[
          {
            type: 'primaryAction',
            onClick: this.onCloseStatus,
            isActive: true,
            text: 'Okay',
          },
        ]}
        onClose={this.onCloseStatus}
      >
        <div>
          <p>{appState}</p>
          <p>{messages}</p>
        </div>
      </Popup>
    );
  }

  render() {
    const { code, status, activating, loading } = this.state;
    const { auth } = this.props;
    if (!validateAccess(auth.site, 'activateDevices', auth)) return null;

    return (
      <div className="pageContainer relative flex flex-column">
        <Header />
        <div className="hub-contentWrapper">
          <div style={{ minWidth: '100%' }}>
            <div className="activate-container">
              <div className="topSection">
                <div className="activate-device">
                  <div className="heading">Activate TV</div>
                  <div className="activate-field">
                    <div className="label">Enter Activation Code</div>
                    <input
                      className="genericInput"
                      type="text"
                      placeholder="000000"
                      id="cardholder-name"
                      title="activation code"
                      aria-label="enter activation code"
                      value={code}
                      onChange={this.onChangeCode}
                    />
                    <div className="help">Please enter the 6 digit activation code that appears on your TV screen</div>
                    <div className="status">{status}</div>
                  </div>
                  {activating ? (
                    <Button buttonType="secondary" buttonClassName="activate-button">
                      Activating...
                    </Button>
                  ) : (
                    <Button
                      buttonType="primaryAction"
                      buttonClassName="activate-button"
                      onClick={this.onActivate}
                      isActive={code && code.length === CODE_MAX_LENGTH}
                    >
                      Save
                    </Button>
                  )}
                </div>
                <div className="activate-guide-image" />
              </div>
              {validateAccess(auth.site, 'featurePicker', auth) && (
                <div className="paddingTop-40">
                  <Text type="formTitleMedium">TV views</Text>
                  <Button
                    buttonType="primary"
                    className="marginTop-8"
                    onClick={() => {
                      this.props.history.push('/tvpicker');
                    }}
                    isActive
                  >
                    Configure TV Views
                  </Button>
                </div>
              )}
              <div className="bottomSection">
                <div className="flex flex-row flex-center">
                  <Text type="formTitleMedium">Connected TVs</Text>
                  <Button className="marginLeft-10" buttonType="tertiary" onClick={this.getDevices} isActive={!loading}>
                    <FontAwesome name="refresh" />
                  </Button>
                </div>
                {/* <div className="heading">Connected TVs</div> */}
                {this.renderList()}
              </div>
              {this.renderSettingsPopup()}
            </div>
          </div>
        </div>
        {this.renderStatusPopup()}
      </div>
    );
  }
}

const styles = {
  deviceViewRow: {
    flexDirection: 'column',
  },
  deviceViewButton: {
    boxShadow: '0 3px 30px 0 rgba(103, 125, 169, 0.07)',
    margin: '3px 0px',
    padding: '10px 25px',
  },
};

const mapStateToProps = (state) => {
  const { auth, devices } = state;
  return {
    auth,
    devices: devices.list,
    views: devices.views,
  };
};

export default connect(mapStateToProps, { devicesLoaded, removeDevice, viewsLoaded })(withRouter(ActivateTV));
