import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { addBookingType } from '../../actions';
import {
  Button,
  GenericInput,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  SuccessPopup,
  Text,
  DurationInput,
  OptionsSection,
  DropdownInput,
  RadioButton,
} from '../../components';
import { bookingActions, paymentActions } from '../../webapi';
import { getMerchantsFromState, minutesToString, safeReadParams } from '../../helper';
import { withPay } from '../../payment';
import { checkLoggedIn } from '../../session';
import { faBan, faBell, faDollar, faEnvelopeCircleCheck } from '@fortawesome/free-solid-svg-icons';

class AddBookingType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: safeReadParams(props, 'id'),
      titleInput: '',
      descriptionInput: '',

      length: 0,
      bufferBefore: 0,
      bufferAfter: 0,

      enabledPayments: false,
      price: '',
      onchargeFees: false,

      selectedOption: 'payment',

      showWarnings: false,
      success: false,
      submitting: false,
    };
  }

  componentDidMount() {
    checkLoggedIn(this, this.props.auth);
    if (this.state.id) {
      this.getData();
    }
  }

  getData() {
    bookingActions.getBookingType(this.state.id).then((res) => {
      this.parseUpdate(res.data.bookingType);
    });
  }

  parseUpdate(bookingType) {
    // TODO: Consolidate fix
    const payInfo = bookingType.PaymentInfo;
    const defaultCategory = payInfo.categories ? payInfo.categories[0] : payInfo;
    const defaultMerchant = payInfo.merchant || payInfo.Merchant || null;

    this.setState({
      titleInput: bookingType.Title,
      descriptionInput: bookingType.Description,
      length: bookingType.Length,
      bufferBefore: bookingType.BufferBefore,
      bufferAfter: bookingType.BufferAfter,
      enabledPayments: bookingType.Paid,
      price: bookingType.Paid && defaultCategory ? (defaultCategory.price || defaultCategory.Price) + '' : '',
      selectedMerchant: bookingType.Paid && defaultMerchant,
      onchargeFees: (bookingType.Paid && payInfo && payInfo.onchargeFees) || false,
    });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleTimeChange = (key, value) => {
    this.setState({ [key]: value });
  };

  selectOption = (o) => {
    this.setState({
      selectedOption: o,
    });
  };

  getMerchantOptions = () => {
    return (this.props.merchants || []).map((m) => {
      return {
        ...m,
        Key: m.id,
        Title: m.nickname ? `${m.nickname} (${m.id})` : m.id,
      };
    });
  };

  getSelectedMerchant() {
    const selection = _.find(this.props.merchants, (m) => {
      return m.id === this.state.selectedMerchant;
    });
    if (!selection) {
      return {
        Title: 'Select',
      };
    }
    return {
      Title: selection.nickname ? `${selection.nickname} (${selection.id})` : selection.id,
      Id: selection.id,
    };
  }

  selectMerchant = (m) => {
    this.setState({
      selectedMerchant: m,
    });
    this.getFeeStructure(m);
  };

  getFeeStructure = (accountId) => {
    if (!accountId) {
      this.setState({ feeStructure: null });
      return;
    }
    paymentActions.getFeeStructure(this.props.auth.site, accountId).then((res) => {
      this.setState({
        feeStructure: res.data,
      });
    });
  };

  getPaymentsData = () => {
    if (!this.state.enabledPayments) {
    }
    return new this.props.payment.composePaymentInfo(this.state.selectedMerchant, this.state.price, this.state.onchargeFees);
  };

  validateLoading() {
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  validatePayment() {
    if (!this.state.enabledPayments) {
      return true;
    }
    if (!this.state.selectedMerchant) {
      return false;
    }
    if (!this.props.payment.isCurrency(this.state.price)) {
      return false;
    }
    return true;
  }

  validateLength() {
    if (this.state.length === 0) {
      return false;
    }
    if (this.state.length + this.state.bufferBefore + this.state.bufferAfter >= 1440) {
      return false;
    }
    return true;
  }

  validateCompulsoryText() {
    if (_.isEmpty(this.state.titleInput)) {
      return false;
    }
    return true;
  }

  validateForm() {
    if (!this.validateCompulsoryText()) {
      return false;
    }
    if (!this.validatePayment()) {
      return false;
    }
    if (!this.validateLength()) {
      return false;
    }
    if (!this.validateLoading()) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true });

    if (this.state.id) {
      bookingActions
        .editBookingType(
          this.state.id,
          this.state.titleInput,
          this.state.descriptionInput,
          this.state.length,
          this.state.bufferBefore,
          this.state.bufferAfter,
          this.state.enabledPayments,
          this.getPaymentsData(),
        )
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    } else {
      bookingActions
        .addBookingType(
          this.props.auth.site,
          this.state.titleInput,
          this.state.descriptionInput,
          this.state.length,
          this.state.bufferBefore,
          this.state.bufferAfter,
          this.state.enabledPayments,
          this.getPaymentsData(),
        )
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
          // this.props.calendarsUpdate(this.props.auth.site);
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  clearSuccess() {
    this.setState({
      success: false,
      submitting: false,
      showWarnings: false,
    });
  }

  inputsDisabled() {
    if (this.state.submitting) {
      return true;
    }
    return false;
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`Booking type has been ${this.state.id != null ? 'edited' : 'added'}`}
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          {!this.inputsDisabled() ? 'Cancel' : 'Back'}
        </Button>
        {!this.inputsDisabled() && (
          <Button inline buttonType="primary" onClick={() => this.handleSubmit()} isActive={this.validateForm()}>
            Save
          </Button>
        )}
      </div>
    );
  }

  renderFees() {
    const { feeStructure, onchargeFees } = this.state;
    const { payment } = this.props;

    if (!payment.isPriceValid(this.state.price) || !feeStructure) {
      return;
    }
    const price = payment.textToPrice(this.state.price);
    const fee = payment.getFee(feeStructure, price);
    return (
      <div className="marginBottom-16">
        <Text type="help">
          This booking type will incur {payment.formatCurrency(fee)} in fees and paid by{' '}
          {onchargeFees ? 'the user' : 'the connected account'}. {payment.formatCurrency(price - fee)} will be transferred to the connected
          account.
        </Text>
      </div>
    );
  }

  renderOnchargeFees() {
    if (!this.state.enabledPayments) return null;
    return (
      <div className="marginTop-16 marginBottom-16">
        <RadioButton
          label="Do you want to oncharge fees?"
          isActive={this.state.onchargeFees}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ onchargeFees: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ onchargeFees: false }) },
          ]}
        />
        <div className="genericInput-help" style={{ marginTop: 4 }}>
          This will request users to pay the fees on top of the price.
        </div>
      </div>
    );
  }

  renderPayment() {
    // return (
    //   <div className="optionsContent_bottom">
    //     <Text type="help">Coming soon.</Text>
    //   </div>
    // );
    let content = <Text type="help">In-App payments are not set up.</Text>;
    if (!_.isEmpty(this.props.merchants)) {
      const paymentToggle = (
        <RadioButton
          label="Do you want to take payments for this type of booking?"
          isActive={this.state.enabledPayments}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ enabledPayments: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ enabledPayments: false }) },
          ]}
        />
      );

      content = paymentToggle;

      if (this.state.enabledPayments) {
        content = (
          <>
            {paymentToggle}
            <DropdownInput
              id="selectedMerchant"
              label="Account"
              placeholder="Select Account"
              ignoreValue="Select Account"
              className="marginTop-20"
              value={this.getSelectedMerchant().Title}
              options={this.getMerchantOptions()}
              onSelect={this.selectMerchant.bind(this)}
            />
            {this.renderOnchargeFees()}
            <GenericInput
              id={`price`}
              type="text"
              label={'Price'}
              placeholder={'e.g. $1.23'}
              value={this.state.price}
              onChange={(e) => this.handleChange(e)}
              isValid={() => this.props.payment.isPriceValid(this.state.price)}
              showError={() => this.state.showWarnings && !this.props.payment.isPriceValid(this.state.price)}
              errorMessage={'Amount Required'}
              style={{ width: '100%', maxWidth: 230, marginRight: 15 }}
              alwaysShowLabel
            />
            {this.renderFees()}
          </>
        );
      }
    }
    return <div className="optionsContent_bottom">{content}</div>;
  }

  renderCancellation() {
    return (
      <div className="optionsContent_bottom">
        <Text type="help">Coming soon.</Text>
      </div>
    );
  }

  renderReminders() {
    return (
      <div className="optionsContent_bottom">
        <Text type="help">Coming soon.</Text>
      </div>
    );
  }

  renderSelectedOption() {
    const { selectedOption } = this.state;
    return (
      <div>
        <div style={{ display: selectedOption === 'payment' ? 'block' : 'none' }}>{this.renderPayment()}</div>
        <div style={{ display: selectedOption === 'cancellation' ? 'block' : 'none' }}>{this.renderCancellation()}</div>
        <div style={{ display: selectedOption === 'reminders' ? 'block' : 'none' }}>{this.renderReminders()}</div>
      </div>
    );
  }

  renderOptionsSection() {
    const options = [
      {
        key: 'payment',
        icon: faDollar,
        iconType: 'fa',
        text: 'Payment',
      },
      {
        key: 'cancellation',
        icon: faBan,
        iconType: 'fa',
        text: 'Cancellation',
      },
      {
        key: 'reminders',
        icon: faBell,
        iconType: 'fa',
        text: 'Reminders',
      },
      {
        key: 'confirmations',
        icon: faEnvelopeCircleCheck,
        iconType: 'fa',
        text: 'Confirmations',
      },
    ];
    return (
      <OptionsSection options={options} selected={this.state.selectedOption} selectOption={this.selectOption} overflowButtons>
        {this.renderSelectedOption()}
      </OptionsSection>
    );
  }

  renderForm = () => {
    if (this.state.success) {
      return null;
    }
    return (
      <div>
        <div className="padding-60 paddingVertical-40">
          <Text type="formTitleLarge" className="marginBottom-24 text-capitaliseWords">
            {this.state.updateId == null ? 'New' : 'Edit'} Booking Type
          </Text>
          {/* title */}
          <GenericInput
            id="titleInput"
            type="text"
            label="Title"
            placeholder="Insert title here"
            value={this.state.titleInput}
            onChange={(e) => this.handleChange(e)}
            isRequired
            isValid={() => {
              return !_.isEmpty(this.state.titleInput);
            }}
            showError={() => {
              return this.state.showWarnings && _.isEmpty(this.state.titleInput);
            }}
            alwaysShowLabel
          />
          {/* description */}
          <GenericInput
            id="descriptionInput"
            label="Description"
            type="textarea"
            placeholder="Insert description here"
            componentClass="textarea"
            value={this.state.descriptionInput}
            onChange={(e) => this.handleChange(e)}
            inputStyle={{
              height: 120,
            }}
            alwaysShowLabel
          />
          <div className="marginBottom-16">
            <Text type="formLabel">Length of booking</Text>
            <DurationInput
              handleChange={(value) => {
                this.handleTimeChange('length', value);
              }}
              value={this.state.length}
            />
          </div>
          {/* <div className="marginBottom-16">
            <Text type="formLabel">Buffer time</Text>
            <Text type="help">Buffer time blocks out time in the calendar before/after a booking of this type</Text>
            <DurationInput
              className="marginTop-8"
              handleChange={(value) => {
                this.handleTimeChange('bufferBefore', value);
              }}
              label="Before"
              value={this.state.bufferBefore}
            />
            <DurationInput
              className="marginTop-8"
              handleChange={(value) => {
                this.handleTimeChange('bufferAfter', value);
              }}
              label="After"
              value={this.state.bufferAfter}
            />
          </div>
          <div className="marginBottom-16">
            <Text type="formLabel">
              Total time of booking: {minutesToString(this.state.length + this.state.bufferBefore + this.state.bufferAfter)}
            </Text>
            <Text type="help">
              The total time is calculated by adding buffer time to length of booking. This is the time that a booking of this type will
              take up in a calendar. Likewise if there isn't this amount of time available, a booking can not be made.
            </Text>
          </div> */}
        </div>

        {this.renderOptionsSection()}
      </div>
    );
  };

  render = () => {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--fixedPopupSize">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  };
}

const mapStateToProps = (state) => {
  const { auth } = state;
  const merchants = getMerchantsFromState(state);
  return { auth, merchants };
};

export default withPay(connect(mapStateToProps, { addBookingType })(AddBookingType));
