import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getApiError } from '../../../session';
import { automationActions } from '../../../webapi';
import { whiteLabelLoaded, whiteLabelAppSet } from '../../../actions';
import { DropdownInput } from '../../../components';

class AppSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: '',
      appsOptions: [],
      whiteLabels: [],
    };
  }

  componentDidMount() {
    this.loadWhiteLabels();
  }

  getAppName = (app, defaultName) => `${app.ClientName || defaultName} (${app.ClientCode})`;

  loadWhiteLabels = () => {
    const { includeSubApps } = this.props;

    this.setState({ loading: true, message: 'Loading apps list...' }, async () => {
      try {
        const { data } = await automationActions.getWhiteLabelList();
        // console.log('loadWhiteLabels - whiteLabels', data);
        const appsOptions = [];
        data.forEach((w) => {
          if (includeSubApps && w.Apps) {
            w.Apps.forEach((a) => {
              appsOptions.push({ Id: a.ClientCode, Title: this.getAppName(a, w.ClientName), Key: a.ClientCode });
            });
          } else {
            appsOptions.push({ Id: w.ClientCode, Title: this.getAppName(w), Key: w.ClientCode });
          }
        });
        // console.log('loadWhiteLabels - appsOptions', appsOptions);
        this.setState({ loading: false, message: '', whiteLabels: data, appsOptions: _.orderBy(appsOptions, 'Title', 'asc') }, () => {
          if (this.props.activeApp) this.onSelectApp(this.props.activeApp.ClientCode);
        });
      } catch (error) {
        const message = getApiError(error).message;
        console.log('loadWhiteLabels', message, error);
        this.setState({ loading: false, message: `Error: ${message}` });
      }
    });
  };

  onSelectApp = (key) => {
    if (!key) return;
    const { appsOptions, whiteLabels } = this.state;

    // Check if valid option selected
    const selectedOption = appsOptions.find((a) => a.Key === key);
    // console.log('selectedOption', selectedOption);
    if (!selectedOption) return;

    const selectedWhiteLabel = whiteLabels.find((w) => w.ClientCode === key || (w.Apps && w.Apps.some((a) => a.ClientCode === key)));
    // console.log('selectedWhiteLabel', selectedWhiteLabel);
    if (selectedWhiteLabel) this.props.whiteLabelLoaded(selectedWhiteLabel);

    const selectedApp = selectedWhiteLabel.Apps
      ? selectedWhiteLabel.Apps.find((a) => a.ClientCode === key)
      : { ClientCode: selectedWhiteLabel.ClientCode, ClientName: selectedWhiteLabel.ClientName };
    // console.log('selectedApp', selectedApp);
    if (selectedApp) this.props.whiteLabelAppSet(selectedApp);

    const { onSelected } = this.props;
    if (onSelected) onSelected(selectedOption);
  };

  render() {
    const { appsOptions, loading, message } = this.state;
    const { selected, disabled } = this.props;

    return (
      <div className="flex flex-row">
        <DropdownInput
          id={`dropdown_whitelabels`}
          style={{ width: 500, marginTop: 10, marginBottom: 'unset' }}
          placeholder={message || 'Select App'}
          value={selected ? selected.Title : ''}
          options={appsOptions}
          onSelect={this.onSelectApp}
          disabled={disabled || loading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { automation } = state;
  // console.log('automation.active', automation.active);
  // console.log('automation.activeApp', automation.activeApp);
  return {
    activeWhiteLabel: automation.active,
    activeApp: automation.activeApp,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded, whiteLabelAppSet })(AppSelector);
