import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/fonts/pluss60-icons/pluss60IconStyles.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import { faMobileAlt, faDesktop } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';

config.autoAddCss = false;
library.add(faMobileAlt, faDesktop);

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
