import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Text, ExportCsvPopup, StatBox } from '../';
import { faAt, faEye, faLink, faPhone, faTicket, faUser } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { analyticsActions } from '../../webapi';
import { countActivities } from '../../js';
import { validateAccess } from '../../session';

const getInitialState = () => ({
  activeAppUsers: 0,
  activeWebUsers: 0,
  prevActiveAppUsers: 0,
  prevActiveWebUsers: 0,
  isLoading: true,
});

// ServiceAnalytics Component
const ServiceAnalytics = ({ startTime, endTime, auth, prevText, dayCount }) => {
  const [analyticsData, setAnalyticsData] = useState(getInitialState());
  const [isExportOpen, setIsExportOpen] = useState(false);

  const hasAccess = validateAccess(auth.site, 'services', auth);
  if (!hasAccess) {
    return null;
  }

  const exportColumns = [
    { label: 'Select All', key: '' },
    { label: 'Start Date', key: 'startDate' },
    { label: 'End Date', key: 'endDate' },
    { label: 'Page Views', key: 'pageViews' },
    { label: 'Unique Visitors', key: 'uniquePageView' },
    { label: 'Link Clicks', key: 'linkClicks' },
    { label: 'Phone Clicks', key: 'phoneClicks' },
    { label: 'Email Clicks', key: 'emailClicks' },
    { label: 'Enquiries', key: 'bookingCompleteds' },
  ];

  useEffect(() => {
    getData();
  }, [startTime, endTime]);

  const getData = async () => {
    setAnalyticsData(getInitialState());
    // Load analytics data here using startTime and endTime
    const timeDifference = endTime - startTime;
    const [currentStatsResponse, prevStatsResponse] = await Promise.all([
      analyticsActions.getAggregateEntityStats(auth.site, 'service', startTime, endTime, true),
      analyticsActions.getAggregateEntityStats(auth.site, 'service', startTime - timeDifference, startTime, true),
    ]);

    const data = {
      pageViews: countActivities(currentStatsResponse.data, 'PageView', 'total'),
      prevPageViews: countActivities(prevStatsResponse.data, 'PageView', 'total'),
      uniquePageView: countActivities(currentStatsResponse.data, 'UniquePageView', 'uniquearray'),
      prevUniquePageView: countActivities(prevStatsResponse.data, 'UniquePageView', 'uniquearray'),
      linkClicks: countActivities(currentStatsResponse.data, 'UrlClick', 'total'),
      linkClicksPrev: countActivities(prevStatsResponse.data, 'UrlClick', 'total'),
      phoneClicks: countActivities(currentStatsResponse.data, 'PhoneClick', 'total'),
      phoneClicksPrev: countActivities(prevStatsResponse.data, 'PhoneClick', 'total'),
      emailClicks: countActivities(currentStatsResponse.data, 'EmailClick', 'total'),
      emailClicksPrev: countActivities(prevStatsResponse.data, 'EmailClick', 'total'),
      bookingCompleteds: countActivities(currentStatsResponse.data, 'BookingCompleted', 'total'),
      bookingCompletedsPrev: countActivities(prevStatsResponse.data, 'BookingCompleted', 'total'),
      isLoading: false,
    };
    setAnalyticsData(data);
  };

  const isReadyToOpenCSV = () => {
    return !analyticsData.isLoading;
  };

  const getExportSource = () => {
    return [
      {
        startDate: moment(startTime + 1).format('D-MM-YYYY'),
        endDate: moment(endTime).format('D-MM-YYYY'),
        pageViews: analyticsData.pageViews,
        uniquePageView: analyticsData.uniquePageView,
        linkClicks: analyticsData.linkClicks,
        phoneClicks: analyticsData.phoneClicks,
        emailClicks: analyticsData.emailClicks,
        bookingCompleteds: analyticsData.bookingCompleteds,
      },
    ];
  };

  const csvPopup = () => {
    if (!isExportOpen) {
      return null;
    }
    const source = getExportSource();
    return (
      <ExportCsvPopup
        onClose={() => {
          setIsExportOpen(false);
        }}
        columns={exportColumns}
        source={source}
        filename={`serviceanalytics_${source[0].startDate}_${source[0].endDate}.csv`}
      />
    );
  };

  return (
    <div className="dashboardSection">
      {csvPopup()}
      <div>
        <Text type="h4" className="inlineBlock marginRight-40">
          Services
        </Text>
        <Button
          inline
          buttonType="primaryAction"
          onClick={() => {
            if (!isReadyToOpenCSV()) return;
            setIsExportOpen(true);
          }}
          isActive={isReadyToOpenCSV()}
          leftIcon="file-code-o"
        >
          Export CSV
        </Button>
      </div>
      <div className="analyticsSection dashboardSection_content">
        <StatBox
          title="Service Page Views"
          icon={faEye}
          value={analyticsData.pageViews}
          previousValue={analyticsData.prevPageViews}
          prevText={prevText}
          viewGraphLink={`/chart?entity=service&startTime=${startTime}&endTime=${endTime}&key=PageView&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Unique Service Visitors"
          icon={faUser}
          value={analyticsData.uniquePageView}
          previousValue={analyticsData.prevUniquePageView}
          prevText={prevText}
          viewGraphLink={`/chart?entity=service&startTime=${startTime}&endTime=${endTime}&key=UniquePageView&countType=uniquearray&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Service Link Clicks"
          icon={faLink}
          value={analyticsData.linkClicks}
          previousValue={analyticsData.linkClicksPrev}
          prevText={prevText}
          viewGraphLink={`/chart?entity=service&startTime=${startTime}&endTime=${endTime}&key=UrlClick&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Service Phone Clicks"
          icon={faPhone}
          value={analyticsData.phoneClicks}
          previousValue={analyticsData.phoneClicksPrev}
          prevText={prevText}
          viewGraphLink={`/chart?entity=service&startTime=${startTime}&endTime=${endTime}&key=PhoneClick&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Service Email Clicks"
          icon={faAt}
          value={analyticsData.emailClicks}
          previousValue={analyticsData.emailClicksPrev}
          prevText={prevText}
          viewGraphLink={`/chart?entity=service&startTime=${startTime}&endTime=${endTime}&key=EmailClick&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Service Enquiries"
          icon={faTicket}
          value={analyticsData.bookingCompleteds}
          previousValue={analyticsData.bookingCompletedsPrev}
          prevText={prevText}
          viewGraphLink={`/chart?entity=service&startTime=${startTime}&endTime=${endTime}&key=BookingCompleted&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

const toExport = connect(mapStateToProps, {})(ServiceAnalytics);
export { toExport as ServiceAnalytics };
