import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CONST_STRINGS } from '../../config';
import { ProfilePic } from '../../components';
import { getActivityText } from '../../config/features';
import { getAnA } from '../../helper';

class ActivityEntry extends Component {
  renderText() {
    const { data } = this.props;
    const classes = 'activityText';
    const clickableClasses = 'activityText_link';
    const highlightedClasses = 'activityText_highlighted';

    return getActivityText(data, classes, clickableClasses, highlightedClasses, () => {
      switch (data.Type) {
        case 'AddAlert':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;added a new alert:&nbsp;
              <Link to={`/alerts/alert/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'EditAlert':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;edited the alert:&nbsp;
              <Link to={`/alerts/alert/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'SignUpFromCode':
          return (
            <p className={classes}>
              <Link to={`/usershub/user/${data.Id}`} className={clickableClasses}>
                {data.Data.name}
              </Link>
              &nbsp;signed up via invite from:&nbsp;
              <Link to={`/usershub/user/${data.User.id}`} className={clickableClasses}>
                {data.User.displayName}
              </Link>
            </p>
          );
        case 'AddUser':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;added a new user:&nbsp;
              <Link to={`/usershub/user/${data.Id}`} className={clickableClasses}>
                {data.Data.name}
              </Link>
            </p>
          );
        case 'AddEventSubmission':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;submitted a new event for review:&nbsp;
              <Link to={`/events/eventsubmission/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'ApproveEventSubmission':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;approved an event submission:&nbsp;
              <Link to={`/events/event/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'RejectEventSubmission':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;rejected an event submission:&nbsp;
              <span className={highlightedClasses}>{data.Data.title}</span>
            </p>
          );
        case 'AddEvent':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;added a new event:&nbsp;
              <Link to={`/events/event/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'EditEvent':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;edited an event:&nbsp;
              <Link to={`/events/event/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'DeleteEvent':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;deleted an event:&nbsp;
              <span className={highlightedClasses}>{data.Data.title}</span>
            </p>
          );
        case 'AddFacility':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;added a new {CONST_STRINGS.FACILITY.toLowerCase()}:&nbsp;
              <Link to={`/facilities/facility/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'EditFacility':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;edited a {CONST_STRINGS.FACILITY.toLowerCase()}:&nbsp;
              <Link to={`/facilities/facility/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'DeleteFacility':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;deleted a {CONST_STRINGS.FACILITY.toLowerCase()}:&nbsp;
              <span className={highlightedClasses}>{data.Data.title}</span>
            </p>
          );
        case 'AddService':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;added a new service:&nbsp;
              <Link to={`/services/service/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'EditService':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;edited a service:&nbsp;
              <Link to={`/services/service/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'AddMap':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;added a new map:&nbsp;
              <Link to={`/info/map/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'EditMap':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;edited a map:&nbsp;
              <Link to={`/info/map/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'DeleteMap':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;removed a map:&nbsp;
              <span className={highlightedClasses}>{data.Data.title}</span>
            </p>
          );
        case 'AddFeedback':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;submitted new feedback
            </p>
          );
        case 'AddImportantContact':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;added a new contact:&nbsp;
              <Link to={`/info/importantcontact/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'EditImportantContact':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;edited a contact:&nbsp;
              <Link to={`/info/importantcontact/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'DeleteImportantContact':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;removed a contact:&nbsp;
              <span className={highlightedClasses}>{data.Data.title}</span>
            </p>
          );
        case 'AddInfoPage':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;added a new {CONST_STRINGS.FAQ_ENTRY}:&nbsp;
              <Link to={`/info/infopage/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'EditInfoPage':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;edited an existing {CONST_STRINGS.FAQ_ENTRY}:&nbsp;
              <Link to={`/info/infopage/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'DeleteInfoPage':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;removed an existing {CONST_STRINGS.FAQ_ENTRY}:&nbsp;
              <span className={highlightedClasses}>{data.Data.title}</span>
            </p>
          );
        case 'AddWelcomePage':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;added a new welcome page:&nbsp;
              <Link to={`/info/welcome/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'EditWelcomePage':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;edited an existing welcome page:&nbsp;
              <Link to={`/info/welcome/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'DeleteWelcomePage':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;removed an existing welcome page:&nbsp;
              <span className={highlightedClasses}>{data.Data.title}</span>
            </p>
          );
        case 'AddPoll':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;added a new survey:&nbsp;
              <Link to={`/polls/results/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'AddOffer':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;added a new offer:&nbsp;
              <Link to={`/offers/offer/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'EditOffer':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;edited an existing offer:&nbsp;
              <Link to={`/offers/offer/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'AddForm':
          return (
            <p className={classes}>
              <span className={highlightedClasses}>{data.User.displayName}</span>
              &nbsp;added a new form:&nbsp;
              <Link to={`/signinHub/form/${data.Id}`} className={clickableClasses}>
                {data.Data.title}
              </Link>
            </p>
          );
        case 'Comment':
          return (
            <>
              <p className={classes}>
                <span className={highlightedClasses}>{data.User.displayName}</span>
                &nbsp;commented on {getAnA(data.Feature.singularName)} {data.Feature.singularName}:&nbsp;
                <Link
                  to={`/comments/thread/${data.EntityId}${data.ParentId ? `?parentId=${data.ParentId}` : ''}`}
                  className={clickableClasses}
                >
                  {data.EntityName}
                </Link>
              </p>
              {data.Comment && <p className="activitySubtext">{data.Comment.substr(0, 50)}</p>}
            </>
          );
        default:
          return null;
      }
    });
  }

  renderTimestamp() {
    const activityTime = moment.utc(this.props.data.Timestamp).local();
    // const timeText = `${activityTime.format('D MMMM YYYY').toUpperCase()} • ${activityTime.format('h:mmA')}`;
    const timeText = activityTime.fromNow();
    return <p className="activityTime">{timeText}</p>;
    // return (
    //   <div className="activityTimeContainer">
    //     <FontAwesome className="activityTimeIcon" name="check-circle" />
    //     <p className="activityTime">{timeText}</p>
    //   </div>
    // );
  }

  render() {
    const text = this.renderText();
    if (!text) return null;

    const { index, data } = this.props;
    return (
      <div className="activityEntry" key={index}>
        <ProfilePic className="profilePic" size={34} image={data.User.profilePic}></ProfilePic>
        <div>
          {text}
          {this.renderTimestamp()}
        </div>
      </div>
    );
  }
}

export default ActivityEntry;
