import React from 'react';
import { SVGIcon } from '../../../../../components';
import { COLOUR_BRANDING_MAIN, hexToRGBAstring } from '../../../../../js';

export default () => {
  return (
    <div className="fp2_widget_content fp2_widget_content-events">
      <div className="fp2_widget_content_third">
        <div className="fp2_widget_silhouette fp2_widget_silhouette-rectangle">
          <div className="fp2_widget_silhouette_icon">
            <SVGIcon icon={'events'} colour={hexToRGBAstring(COLOUR_BRANDING_MAIN, 0.5)} />
          </div>
          <div className="fp2_widget_silhouetteDark fp2_widget_silhouette-textLine"></div>
        </div>
        <div className="fp2_widget_silhouette fp2_widget_silhouette-textLine"></div>
        <div className="fp2_widget_silhouette fp2_widget_silhouette-textLineLong"></div>
      </div>
      <div className="fp2_widget_content_third">
        <div className="fp2_widget_silhouette fp2_widget_silhouette-rectangle">
          <div className="fp2_widget_silhouette_icon">
            <SVGIcon icon={'events'} colour={hexToRGBAstring(COLOUR_BRANDING_MAIN, 0.5)} />
          </div>
          <div className="fp2_widget_silhouetteDark fp2_widget_silhouette-textLine"></div>
        </div>
        <div className="fp2_widget_silhouette fp2_widget_silhouette-textLine"></div>
        <div className="fp2_widget_silhouette fp2_widget_silhouette-textLineLong"></div>
      </div>

      <div className="fp2_widget_content_third">
        <div className="fp2_widget_silhouette fp2_widget_silhouette-rectangle">
          <div className="fp2_widget_silhouette_icon">
            <SVGIcon icon={'events'} colour={hexToRGBAstring(COLOUR_BRANDING_MAIN, 0.5)} />
          </div>
          <div className="fp2_widget_silhouetteDark fp2_widget_silhouette-textLine"></div>
        </div>
        <div className="fp2_widget_silhouette fp2_widget_silhouette-textLine"></div>
        <div className="fp2_widget_silhouette fp2_widget_silhouette-textLineLong"></div>
      </div>
    </div>
  );
};
