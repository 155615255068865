import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { addCalendar, addRecentlyCreated, bookingTypesLoaded } from '../../actions';
import {
  Button,
  GenericInput,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  SuccessPopup,
  Text,
  TimePicker,
  DropdownInput,
  P60Icon,
  CheckBox,
  OptionsSection,
  RadioButton,
} from '../../components';
import { bookingActions } from '../../webapi';
import { hasTimeframeOverlap, minutesToString, safeReadParams, timepickerToMinutes } from '../../helper';
import { checkLoggedIn } from '../../session';
import DayOptions from '../../json/dayOptions.json';
import FontAwesome from 'react-fontawesome';
import { faEnvelopeCircleCheck } from '@fortawesome/free-solid-svg-icons';

class AddCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: safeReadParams(props, 'id'),
      titleInput: '',
      descriptionInput: '',
      hours: [],
      bookingTypes: [],
      emailEnabled: false,
      emailRecipient: '',

      selectedOption: 'email',

      showWarnings: false,
      success: false,
      submitting: false,
    };

    this.dayOptions = _.cloneDeep(DayOptions);
  }

  componentDidMount() {
    this.addDay();
    this.props.addRecentlyCreated('calendars');
    checkLoggedIn(this, this.props.auth);
    if (this.state.id) {
      this.getData();
    }
    this.getBookingTypes();
  }

  getData() {
    bookingActions.getCalendar(this.state.id).then((res) => {
      this.parseUpdate(res.data.calendar);
    });
  }

  getBookingTypes() {
    bookingActions.getBookingTypesBySite(this.props.auth.site).then((res) => {
      this.props.bookingTypesLoaded(res.data.Items);
    });
  }

  parseUpdate(calendar) {
    this.setState({
      titleInput: calendar.Title,
      descriptionInput: calendar.Description,
      hours: calendar.Hours.map((h) => {
        return {
          Day: h.Day,
          StartTime: moment().startOf('d').add(h.StartTime, 'm').format('h:mma'),
          EndTime: moment().startOf('d').add(h.EndTime, 'm').format('h:mma'),
        };
      }),
      bookingTypes: calendar.BookingTypes,
      emailEnabled: !!(calendar.EmailConfig && calendar.EmailConfig.Enabled),
      emailRecipient: calendar.EmailConfig ? calendar.EmailConfig.Recipient : '',
    });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  onToggleType = (id) => {
    const newBookingTypes = [...this.state.bookingTypes];
    if (newBookingTypes.includes(id)) {
      newBookingTypes.splice(newBookingTypes.indexOf(id), 1);
    } else {
      newBookingTypes.push(id);
    }
    this.setState({
      bookingTypes: newBookingTypes,
    });
  };

  getDayOptions = () => {
    return this.dayOptions;
  };

  getSelectedDay = (day) => {
    return this.dayOptions[day] || {};
  };

  addDay = () => {
    const newHours = [...this.state.hours];
    let day = 0;
    let startTime = '9:00am';
    let endTime = '5:00pm';
    if (!_.isEmpty(this.state.hours)) {
      const lastDay = _.last(this.state.hours);
      day = (lastDay.Day + 1) % 7;
      startTime = lastDay.StartTime;
      endTime = lastDay.EndTime;
    }
    newHours.push({
      StartTime: startTime,
      EndTime: endTime,
      Day: day,
    });
    this.setState({
      hours: newHours,
    });
  };

  removeDay = (index) => {
    const newHours = [...this.state.hours];
    newHours.splice(index, 1);
    this.setState({
      hours: newHours,
    });
  };

  onHandleHourChange = (index, key, value) => {
    const newHours = [...this.state.hours];
    const newDay = { ...newHours[index] };
    newDay[key] = value;
    newHours.splice(index, 1, newDay);
    this.setState({
      hours: newHours,
    });
  };

  getHours = () => {
    return this.state.hours.map((day) => {
      return {
        Day: day.Day,
        StartTime: timepickerToMinutes(day.StartTime),
        EndTime: timepickerToMinutes(day.EndTime),
      };
    });
  };

  getEmailConfig = () => {
    return {
      Enabled: this.state.emailEnabled,
      Recipient: _.trim(this.state.emailRecipient),
    };
  };

  getSelectedBookingTypes = () => {
    if (_.isEmpty(this.props.bookingTypes)) {
      return this.state.bookingTypes;
    }
    return this.state.bookingTypes.filter((bt) => {
      return _.some(this.props.bookingTypes, (pbt) => pbt.Id === bt);
    });
  };

  validateLoading() {
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  validateCompulsoryText() {
    if (_.isEmpty(this.state.titleInput)) {
      return false;
    }
    return true;
  }

  validateTimeFrame = (startTime, endTime) => {
    return startTime < endTime;
  };

  validateDay = (day) => {
    const startTime = timepickerToMinutes(day.StartTime);
    const endTime = timepickerToMinutes(day.EndTime);
    if (!this.validateTimeFrame(startTime, endTime)) {
      return false;
    }

    //validate clashes
    if (
      _.some(this.state.hours, (h) => {
        if (h === day || h.Day !== day.Day) {
          return false;
        }
        return hasTimeframeOverlap(startTime, endTime, timepickerToMinutes(h.StartTime), timepickerToMinutes(h.EndTime));
      })
    ) {
      return false;
    }
    return true;
  };

  validateHours = () => {
    if (
      _.some(this.state.hours, (day) => {
        return !this.validateDay(day);
      })
    ) {
      return false;
    }
    return true;
  };

  validateForm() {
    if (!this.validateCompulsoryText()) {
      return false;
    }
    if (!this.validateHours()) {
      return false;
    }
    if (!this.validateLoading()) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true });

    const hours = this.getHours();
    const emailConfig = this.getEmailConfig();
    const bookingTypes = this.getSelectedBookingTypes();

    if (this.state.id) {
      bookingActions
        .editCalendar(this.state.id, this.state.titleInput, this.state.descriptionInput, hours, bookingTypes, emailConfig)
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
          // this.props.calendarsUpdate(this.props.auth.site);
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    } else {
      bookingActions
        .addCalendar(this.props.auth.site, this.state.titleInput, this.state.descriptionInput, hours, this.state.bookingTypes, emailConfig)
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
          // this.props.calendarsUpdate(this.props.auth.site);
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  clearSuccess() {
    this.setState({
      success: false,
      submitting: false,
      showWarnings: false,
    });
  }

  inputsDisabled() {
    if (this.state.submitting) {
      return true;
    }
    return false;
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`Calendar has been ${this.state.id != null ? 'edited' : 'added'}`}
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          {!this.inputsDisabled() ? 'Cancel' : 'Back'}
        </Button>
        {!this.inputsDisabled() && (
          <Button inline buttonType="primary" onClick={() => this.handleSubmit()} isActive={this.validateForm()}>
            Save
          </Button>
        )}
      </div>
    );
  }

  renderDay = (day, index) => {
    return (
      <div className="flex flex-align-end marginTop-10">
        <DropdownInput
          id={`${index}day`}
          placeholder="Select day"
          value={this.getSelectedDay(day.Day).Title}
          options={this.getDayOptions()}
          onSelect={(val) => {
            this.onHandleHourChange(index, 'Day', val);
          }}
          className="marginRight-20"
          style={{ marginBottom: 0 }}
        />
        <TimePicker
          selectedTime={day.StartTime}
          selectTime={(val) => {
            this.onHandleHourChange(index, 'StartTime', val);
          }}
          className="marginRight-20"
        />
        <Text type="bodyLarge" className="marginRight-20">
          –
        </Text>
        <TimePicker
          selectedTime={day.EndTime}
          selectTime={(val) => {
            this.onHandleHourChange(index, 'EndTime', val);
          }}
          className="marginRight-20"
        />

        <P60Icon
          className="removeoption"
          icon="remove-circle"
          onClick={() => {
            this.removeDay(index);
          }}
        />
      </div>
    );
  };

  renderBookingType = (type) => {
    return (
      <CheckBox
        key={type.Id}
        label={
          <span>
            {type.Title} <i>({minutesToString(type.TotalLength)})</i>
          </span>
        }
        isActive={this.state.bookingTypes.includes(type.Id)}
        onChange={() => this.onToggleType(type.Id)}
        style={{ marginTop: 8 }}
      />
    );
  };

  renderBookingTypes = () => {
    return (
      <>
        <Text type="formLabel" className="marginTop-16">
          Booking Types
        </Text>
        <Text type="help">
          Booking Types are the types of bookings users can make for this calendar. Specify information such as how long the booking goes
          for, cost of booking, whether the booking can be cancelled, and other important details.
        </Text>
        {this.props.bookingTypes.map((type) => {
          return this.renderBookingType(type);
        })}
      </>
    );
  };

  renderTimes = () => {
    return (
      <>
        <Text type="formLabel">Regular Available Hours</Text>
        {this.state.hours.map((day, index) => {
          return this.renderDay(day, index);
        })}
        <div className="iconTextButton marginTop-10" onClick={this.addDay}>
          <FontAwesome className="iconTextButton_icon" name="plus" />
          <p className="iconTextButton_text">Add Day</p>
        </div>
      </>
    );
  };

  renderEmails() {
    return (
      <div className="optionsContent_bottom">
        <RadioButton
          label="Do you want to be notified via email when a booking is made?"
          isActive={this.state.emailEnabled}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ emailEnabled: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ emailEnabled: false }) },
          ]}
        />
        {this.state.emailEnabled && (
          <div className="marginTop-16">
            <GenericInput
              id="emailRecipient"
              type="text"
              label="Email Address"
              placeholder="example@domain.com"
              value={this.state.emailRecipient}
              help="For multiple addresses, separate with a comma"
              onChange={(e) => this.handleChange(e)}
              alwaysShowLabel
            />
          </div>
        )}
      </div>
    );
  }

  renderSelectedOption() {
    const { selectedOption } = this.state;
    return (
      <div>
        <div style={{ display: selectedOption === 'email' ? 'block' : 'none' }}>{this.renderEmails()}</div>
      </div>
    );
  }

  renderOptionsSection() {
    const options = [
      {
        key: 'email',
        icon: faEnvelopeCircleCheck,
        iconType: 'fa',
        text: 'Emails',
      },
    ];
    return (
      <OptionsSection options={options} selected={this.state.selectedOption} selectOption={this.selectOption} overflowButtons>
        {this.renderSelectedOption()}
      </OptionsSection>
    );
  }

  renderForm = () => {
    if (this.state.success) {
      return null;
    }
    return (
      <div>
        <div className="padding-60 paddingVertical-40">
          <Text type="formTitleLarge" className="marginBottom-24 text-capitaliseWords">
            {this.state.updateId == null ? 'New' : 'Edit'} Booking Calendar
          </Text>
          {/* title */}
          <GenericInput
            id="titleInput"
            type="text"
            label="Title"
            placeholder="Insert title here"
            value={this.state.titleInput}
            onChange={(e) => this.handleChange(e)}
            isRequired
            isValid={() => {
              return !_.isEmpty(this.state.titleInput);
            }}
            showError={() => {
              return this.state.showWarnings && _.isEmpty(this.state.titleInput);
            }}
            alwaysShowLabel
          />
          {/* description */}
          <GenericInput
            id="descriptionInput"
            label="Description"
            type="textarea"
            placeholder="Insert description here"
            componentClass="textarea"
            value={this.state.descriptionInput}
            onChange={(e) => this.handleChange(e)}
            inputStyle={{
              height: 120,
            }}
            alwaysShowLabel
          />
          {this.renderTimes()}
          {this.renderBookingTypes()}
        </div>

        {this.renderOptionsSection()}
      </div>
    );
  };

  render = () => {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--fixedPopupSize">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  };
}

const mapStateToProps = (state) => {
  const { types } = state.bookings;
  const { auth } = state;
  return {
    auth,
    bookingTypes: types,
  };
};

export default connect(mapStateToProps, { addCalendar, addRecentlyCreated, bookingTypesLoaded })(AddCalendar);
