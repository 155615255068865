import { UPDATE_STRINGS } from '../actions/types';
import { readStorageWithCookie, setLocalStorage } from '../helper';

const INITIAL_STATE = {
  config: readStorageWithCookie('constStrings', true) || {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_STRINGS:
      setLocalStorage('constStrings', action.payload, true);
      return {
        ...state,
        config: action.payload,
      };
    default:
      return state;
  }
};
