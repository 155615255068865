import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  setAuthLocation,
  clearEvents,
  clearFacilities,
  clearContacts,
  clearJobs,
  clearKeys,
  clearMaps,
  clearNews,
  clearUsers,
  clearDevices,
} from '../actions';
import { isTheBest, validateAccess } from '../session';
import { capitalize } from '../helper';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { Popup, GenericInput, Button } from '../components';

class Site extends Component {
  state = {
    site: null,
    locations: [],
    showAddSite: false,
    viewTitleInput: '',
    availablePlans: [
      {
        name: 'app',
        title: 'App Only',
      },
      {
        name: 'appTv',
        title: 'App, TV and Kiosk',
      },
    ],
    selectedPlan: '',
  };

  UNSAFE_componentWillMount() {
    this.setState({ site: this.props.auth.site });
  }

  getSiteName() {
    if (this.state.site) {
      const site = _.find(this.props.roles, (l) => {
        return l.site === this.state.site;
      });
      if (site && site.siteInfo && !_.isEmpty(site.siteInfo.siteName)) {
        return capitalize(site.siteInfo.siteName);
      }
      return capitalize(this.state.site);
    }
    return '';
  }

  selectSite(key, e) {
    if (key === this.state.site) {
      return;
    }
    this.props.setAuthLocation(key);
    this.props.clearEvents();
    this.props.clearFacilities();
    this.props.clearContacts();
    this.props.clearJobs();
    this.props.clearKeys();
    this.props.clearMaps();
    this.props.clearNews();
    this.props.clearUsers();
    this.props.clearDevices();
    this.setState({
      site: key,
    });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  pathToTv() {
    this.props.history.push('/tv');
  }

  openAddSite = () => {
    this.setState({ showAddSite: true, viewTitleInput: '', selectedPlan: '' });
  };

  closeAddSite = () => {
    this.setState({ showAddSite: false });
  };

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  selectPlan = (selectedPlan) => {
    this.setState({ selectedPlan });
  };

  isSiteNameValid = () => {
    const { viewTitleInput } = this.state;
    return !_.isEmpty(viewTitleInput.trim());
  };

  isCreateSiteValid = () => {
    const { selectedPlan } = this.state;
    return this.isSiteNameValid() && !_.isEmpty(selectedPlan);
  };

  createSite = () => {
    if (!this.isCreateSiteValid()) return;

    const { viewTitleInput, selectedPlan } = this.state;
    this.closeAddSite();
    this.props.history.push(`/newsite?siteName=${encodeURIComponent(viewTitleInput)}&plan=${encodeURIComponent(selectedPlan)}`);
  };

  renderOptions() {
    return _.sortBy(this.props.roles, (s) => {
      return capitalize(s.siteInfo && s.siteInfo.siteName ? s.siteInfo.siteName : s.site);
    }).map((ev, index) => {
      if (ev != null) {
        const siteName = ev.siteInfo && ev.siteInfo.siteName ? ev.siteInfo.siteName : ev.site;
        return (
          <MenuItem key={ev.site + index} eventKey={ev.site} active={this.state.site === ev.site}>
            {capitalize(siteName)}
          </MenuItem>
        );
      }
      return null;
    });
  }

  renderPlan(plan) {
    const isSelected = plan.name === this.state.selectedPlan;

    return (
      <div key={plan.name} className={`planContainer ${isSelected ? 'selected' : ''}`}>
        <div className="planInfoContainer">
          <p className={`planTitle ${this.state.selectedPlan && !isSelected ? 'unselected' : ''}`}>{plan.title}</p>
        </div>
        <div className="buttonContainer">
          <Button
            inline
            buttonType={isSelected ? 'outlinedAction' : 'primaryAction'}
            buttonClassName="selectButton"
            onClick={() => this.selectPlan(plan.name)}
            isActive={!isSelected}
          >
            {isSelected ? 'Selected' : 'Select this plan'}
          </Button>
        </div>
      </div>
    );
  }

  renderAddSitePopup() {
    const { showAddSite, availablePlans } = this.state;
    if (!showAddSite) return null;

    return (
      <Popup
        maxWidth={800}
        buttons={[
          {
            type: 'primaryAction',
            onClick: this.createSite,
            isActive: this.isCreateSiteValid(),
            text: 'Create Site',
            className: 'createButton',
          },
        ]}
        onClose={this.closeAddSite}
        title="Set up your new site"
        hasPadding
      >
        {/* <div className="closeContainer">
          <FontAwesome className="closeButton" name="times" onClick={this.closeAddSite} />
        </div> */}
        {/* <div className="headerSection">
          <p className="title">Set up your new site</p>
          <p className="description" hidden>You can add based on your existing site or set up a new one.</p>
        </div> */}
        <div className="siteNameSection">
          <GenericInput
            className="siteName"
            inputClass="siteNameInput"
            id="viewTitleInput"
            type="text"
            isRequired
            value={this.state.viewTitleInput}
            onChange={(e) => this.handleChange(e)}
            maxLength={50}
            placeholder="Enter site name"
            isValid={this.isSiteNameValid}
          />
        </div>
        <div className="planSection">
          <p className="title">Choose your plan</p>
          <div className="plans">{availablePlans.map((plan) => this.renderPlan(plan))}</div>
        </div>
      </Popup>
    );
  }

  render() {
    if (
      !validateAccess('hq', 'master', this.props.auth) &&
      (!this.props.auth || !this.props.auth.user || _.isEmpty(this.props.auth.user.Roles) || this.props.auth.user.Roles.length < 2)
    ) {
      return (
        <div className="siteSelector">
          <p className="siteSelector__loggedInText">You are viewing</p>
          <p className="siteSelector__button">{this.getSiteName()}</p>
        </div>
      );
    }
    return (
      <div className="siteSelector">
        <p className="siteSelector__loggedInText">You are viewing</p>
        <div className="flex-reverse flex-center">
          {isTheBest(this.props.auth, false) && <FontAwesome name="plus-circle" className="siteSelector__new" onClick={this.openAddSite} />}
          <div className="flex-1">
            <DropdownButton
              id="secondLevelSort"
              bsSize="xsmall"
              className="siteSelector__button"
              title={this.getSiteName()}
              onSelect={this.selectSite.bind(this)}
            >
              {this.renderOptions()}
            </DropdownButton>
          </div>
        </div>
        {this.renderAddSitePopup()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const roles = _.clone(state.auth && state.auth.user ? state.auth.user.Roles : []);
  return { auth: state.auth, roles };
};

let exportObj = connect(mapStateToProps, {
  setAuthLocation,
  clearEvents,
  clearFacilities,
  clearContacts,
  clearJobs,
  clearKeys,
  clearMaps,
  clearNews,
  clearUsers,
  clearDevices,
})(withRouter(Site));

export { exportObj as Site };
