import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Header, AddButton, HubSidebar } from '../../components';
import { validateAccess } from '../../session';
import { offersLoaded, setAuth } from '../../actions';
import { marketActions } from '../../webapi';
import { COLOUR_BRANDING_OFF } from '../../js';
import Offers from './Offers';
import OffersHubAnalytics from './OffersHubAnalytics';
import { PlussCore } from '../../config/core';

class OffersHub extends Component {
  state = {
    selectedSection: 'all',
    location: '',

    loadingOffers: false,

    offers: [],
    currentOffers: [],
    pastOffers: [],
    now: moment.utc().valueOf(),
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);

    this.loadOffers();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    const offers = [];

    props.offers.forEach((ev) => {
      if (ev != null && !ev.Deleted) {
        offers.push(ev);
      }
    });

    const currentOffers = _.filter(offers, (ev) => {
      return this.state.now > ev.StartUnixTimestamp && this.state.now < ev.ExpiryUnixTimestamp;
    });

    const pastOffers = _.filter(offers, (ev) => {
      return this.state.now > ev.ExpiryUnixTimestamp;
    });

    this.setState({ offers, currentOffers, pastOffers });
  }

  loadOffers() {
    this.setState({
      loadingOffers: true,
    });

    marketActions
      .getOffers(this.props.auth.site)
      .then((res) => {
        this.setState({
          loadingOffers: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.offersLoaded(res.data);
        }
      })
      .catch((res) => {
        this.setState({ loadingOffers: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  addNew = () => {
    if (this.canAddNew()) {
      this.props.history.push(`/offers/offer`);
    }
  };

  canAddNew(isClass) {
    if (validateAccess(this.props.auth.site, 'offers', this.props.auth)) {
      return isClass ? '' : true;
    }
    return isClass ? ' hub-sideContent-topButton--hide' : false;
  }

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? { backgroundColor: '#fff' } : {};
  }

  renderStats(component, loading) {
    if (loading) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    return component;
  }

  renderLeftBar() {
    const sectionItems = [];

    if (this.canAddNew()) {
      sectionItems.push({
        type: 'newButton',
        text: 'New Offer',
        onClick: this.addNew,
      });
    }

    sectionItems.push({
      type: 'navItem',
      text: 'View Offers',
      icon: 'eye',
      isFontAwesome: true,
      selected: this.state.selectedSection === 'all',
      onClick: () => {
        this.setState({ selectedSection: 'all' });
      },
    });

    sectionItems.push({
      type: 'navItem',
      text: 'Analytics',
      icon: 'line-chart',
      isFontAwesome: true,
      selected: this.state.selectedSection === 'Analytics',
      onClick: () => {
        this.setState({ selectedSection: 'Analytics' });
      },
    });

    return (
      <HubSidebar
        sections={[
          {
            title: 'Offers',
            items: sectionItems,
          },
        ]}
        helpGuide={{
          text: 'Help with Offers',
          url: PlussCore.Urls.HelpGuide.Offers,
        }}
      />
    );
  }

  renderRight() {
    if (this.state.selectedSection === 'Analytics') {
      return <OffersHubAnalytics offers={this.state.offers} />;
    } else {
      return <Offers filterOption={this.state.selectedSection} />;
    }
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header>{this.canAddNew() && <AddButton onClick={this.addNew.bind(this)} text="NEW OFFER" />}</Header>
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { offers } = state.market;
  const { auth } = state;
  return {
    offers,
    auth,
  };
};

export default connect(mapStateToProps, { offersLoaded, setAuth })(OffersHub);
