import { getUrl } from './helper';
import { authedFunction } from '../session';

export const bookingActions = {
  searchBookings: (site, minTime, maxTime, userId, calendarId, bookingTypeId) => {
    const query = { site, minTime, maxTime };
    if (userId) {
      query.userId = userId;
    }
    if (calendarId) {
      query.calendarId = calendarId;
    }
    if (bookingTypeId) {
      query.bookingTypeId = bookingTypeId;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('bookings', 'get/booking/search', query),
    });
  },
  getBooking: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('bookings', 'get/booking/single', { id }),
    });
  },
  cancelBooking: (id, refundId = null) => {
    const data = { id };
    if (refundId) data.refundId = refundId;
    return authedFunction({
      method: 'POST',
      url: getUrl('bookings', 'update/booking/cancel'),
      data,
    });
  },
  addBooking: (calendarId, bookingTypeId, date, timeOfDay, userId, entityType, entityId, entity, length, notes) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('bookings', 'update/booking/add'),
      data: { calendarId, bookingTypeId, date, timeOfDay, userId, entityType, entityId, entity, length, notes },
    });
  },
  getTimeslots: (calendarId, bookingTypeId, date) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('bookings', 'get/booking/timeslots', { calendarId, bookingTypeId, date }),
    });
  },
  getCalendarsBySite: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('bookings', 'get/calendar/site', { site }),
    });
  },
  getCalendar: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('bookings', 'get/calendar/single', { id }),
    });
  },
  addCalendar: (site, title, description, hours, bookingTypes, emailConfig) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('bookings', 'update/calendar/add'),
      data: {
        site,
        title,
        description,
        hours,
        bookingTypes,
        emailConfig,
      },
    });
  },
  editCalendar: (id, title, description, hours, bookingTypes, emailConfig) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('bookings', 'update/calendar/edit'),
      data: {
        id,
        title,
        description,
        hours,
        bookingTypes,
        emailConfig,
      },
    });
  },
  deleteCalendar: (id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('bookings', 'update/calendar/delete'),
      data: {
        id,
      },
    });
  },
  getBookingTypesBySite: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('bookings', 'get/type/site', { site }),
    });
  },
  getBookingType: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('bookings', 'get/type/single', { id }),
    });
  },
  addBookingType: (site, title, description, length, bufferBefore, bufferAfter, paid, paymentInfo) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('bookings', 'update/type/add'),
      data: {
        site,
        title,
        description,
        length,
        bufferBefore,
        bufferAfter,
        paid,
        paymentInfo,
      },
    });
  },
  editBookingType: (id, title, description, length, bufferBefore, bufferAfter, paid, paymentInfo) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('bookings', 'update/type/edit'),
      data: {
        id,
        title,
        description,
        length,
        bufferBefore,
        bufferAfter,
        paid,
        paymentInfo,
      },
    });
  },
  deleteBookingType: (id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('bookings', 'update/type/delete'),
      data: {
        id,
      },
    });
  },
};
