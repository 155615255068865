import _ from 'lodash';
import React, { PureComponent } from 'react';
import { SVGIcon } from '../../../../components';
import { getTabKeyFromPage, getTabTitleFromPage, TEXT_LIGHT } from '../../../../js';

class PhoneFooter extends PureComponent {
  render() {
    const { footerType, footerColour, tabSettings, selectedTab, mainBrandingColour } = this.props;

    if (_.isEmpty(tabSettings) || _.filter(tabSettings, (t) => t.isEnabled).length < 2) {
      return null;
    }

    return (
      <div
        className={`fp2_phone_footer${footerType === 'block' ? ' fp2_phone_footer-block' : ''}`}
        style={{ backgroundColor: footerType === 'block' ? footerColour : null }}
      >
        {tabSettings.map((t, i) => {
          if (!t.isEnabled) {
            return null;
          }
          const isSelected = t.key === selectedTab;
          const textColour = footerType === 'block' ? '#fff' : isSelected ? mainBrandingColour : TEXT_LIGHT;
          return (
            <div className={`fp2_phone_footer_tab${isSelected ? ' fp2_phone_footer_tab-selected' : ''}`} key={t.key}>
              <div className="fp2_phone_footer_tab_icon">
                <SVGIcon icon={getTabKeyFromPage(t)} colour={textColour} />
              </div>
              <p className="fp2_phone_footer_tab_text" style={{ color: textColour }}>
                {getTabTitleFromPage(t, i)}
              </p>
            </div>
          );
        })}
      </div>
    );
  }
}

export default PhoneFooter;
