import React, { PureComponent } from 'react';

class GlobalHider extends PureComponent {
  render() {
    return (
      <div>
        {this.props.chatbot && <style>{`#tidio-chat { display: none !important; }`}</style>}
        {this.props.sideMenu && <style>{`.sideMenu { display: none !important; }`}</style>}
      </div>
    );
  }
}

export { GlobalHider };
