import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { calendarsLoaded, bookingTypesLoaded, usersLoaded } from '../../actions';
import { bookingActions, userActions } from '../../webapi';
import { checkLoggedIn, validateAccess } from '../../session';
import { Text } from '../../components/text';
import { TIMESTAMPFORMAT, formatCurrency, minutesToString } from '../../helper';
import { DatePicker, GenericInput, Popup, Tag, UserListing } from '../../components';
import { COLOUR_RED } from '../../js';

class Bookings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortColumn: 'Time', // column to sort by
      sortDesc: false, // if true, sort descending rather than ascending
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(1, 'w').format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    checkLoggedIn(this, this.props.auth);
    this.getData();
    this.getBookings();
  }

  getData() {
    bookingActions.getCalendarsBySite(this.props.auth.site).then((res) => {
      this.props.calendarsLoaded(res.data.Items);
    });

    bookingActions.getBookingTypesBySite(this.props.auth.site).then((res) => {
      this.props.bookingTypesLoaded(res.data.Items);
    });

    userActions.getSiteUsersRecursive(this.props.auth.site).then((users) => {
      this.props.usersLoaded(users);
    });
  }

  getBookings = () => {
    this.setState({
      bookings: [],
      loadingBookings: true,
    });
    bookingActions
      .searchBookings(
        this.props.auth.site,
        moment(this.state.startDate).startOf('d').valueOf(),
        moment(this.state.endDate).endOf('d').valueOf(),
        this.state.selectedUserFilter && this.state.selectedUserFilter.userId,
        this.state.selectedCalendarFilter && this.state.selectedCalendarFilter.Id,
        this.state.selectedTypeFilter && this.state.selectedTypeFilter.Id,
      )
      .then((res) => {
        this.setState({
          bookings: res.data.Items,
          loadingBookings: false,
        });
      });
  };

  openUserFilter = () => {
    this.setState({
      userFilterOpen: true,
    });
  };

  closeUserFilter = () => {
    this.setState({
      userFilterOpen: false,
    });
  };

  selectUserFilter = (user) => {
    this.setState(
      {
        selectedUserFilter: user,
        userFilterOpen: false,
      },
      this.getBookings,
    );
  };

  removeUserFilter = (event) => {
    event.stopPropagation();
    this.setState(
      {
        selectedUserFilter: null,
      },
      this.getBookings,
    );
  };

  openCalendarFilter = () => {
    this.setState({
      calendarFilterOpen: true,
    });
  };

  closeCalendarFilter = () => {
    this.setState({
      calendarFilterOpen: false,
    });
  };

  selectCalendarFilter = (calendar) => {
    this.setState(
      {
        selectedCalendarFilter: calendar,
        calendarFilterOpen: false,
      },
      this.getBookings,
    );
  };

  removeCalendarFilter = (event) => {
    event.stopPropagation();
    this.setState(
      {
        selectedCalendarFilter: null,
      },
      this.getBookings,
    );
  };

  openTypeFilter = () => {
    this.setState({
      typeFilterOpen: true,
    });
  };

  closeTypeFilter = () => {
    this.setState({
      typeFilterOpen: false,
    });
  };

  selectTypeFilter = (type) => {
    this.setState(
      {
        selectedTypeFilter: type,
        typeFilterOpen: false,
      },
      this.getBookings,
    );
  };

  removeTypeFilter = (event) => {
    event.stopPropagation();
    this.setState(
      {
        selectedTypeFilter: null,
      },
      this.getBookings,
    );
  };

  openCancelledFilter = () => {
    this.setState({
      cancelledFilterOpen: true,
    });
  };

  closeCancelledFilter = () => {
    this.setState({
      cancelledFilterOpen: false,
    });
  };

  selectCancelledFilter = (includeCancelled) => {
    this.setState({
      includeCancelled,
      cancelledFilterOpen: false,
    });
  };

  openTimeFilter = () => {
    this.setState({
      timeFilterOpen: true,
    });
  };

  closeTimeFilter = () => {
    this.setState(
      {
        timeFilterOpen: false,
      },
      this.getBookings,
    );
  };

  startDateChanged = (date) => {
    if (moment(date).isSameOrBefore(this.state.endDate)) {
      this.setState({ startDate: date });
    }
  };

  endDateChanged = (date) => {
    if (moment(date).isSameOrAfter(this.state.startDate)) {
      this.setState({ endDate: date });
    }
  };

  getTimeFilterText = () => {
    if (this.state.startDate && this.state.endDate) {
      return `Time: ${moment(this.state.startDate).format('DD/MM/YYYY')}  to  ${moment(this.state.endDate).format('DD/MM/YYYY')}`;
    }
    return 'Time';
  };

  onHandleChange = (event) => {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  };

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  renderItems(source) {
    return source.map((ev) => {
      return (
        <tr key={ev.Id}>
          <td className="table-TitleColumn">
            <Link to={`/bookings/booking/${ev.Id}`}>{ev.Id}</Link>
            {ev.Cancelled && (
              <>
                <br />
                <Text type="red">Cancelled</Text>
              </>
            )}
          </td>
          <td>
            <UserListing size={15} user={ev.User} />
          </td>
          <td>{ev.Calendar.Title}</td>
          <td style={ev.BookingTypeId === 'BLOCKOUT' ? { color: COLOUR_RED } : undefined}>{ev.BookingType.Title}</td>
          <td>{moment.parseZone(ev.TimeString || ev.Time).format(TIMESTAMPFORMAT)}</td>
          <td>{minutesToString((ev.EndTime - ev.Time) / 60000)}</td>
        </tr>
      );
    });
  }

  renderSort(col) {
    if (col !== this.state.sortColumn) {
      return null;
    }
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  sortIsActive(col) {
    if (col !== this.state.sortColumn) {
      return '';
    }
    return ' table--columnActive';
  }

  renderView(source) {
    return (
      <Table className="plussTable" striped bordered condensed hover>
        <thead>
          <tr>
            <th
              className={`${this.sortIsActive('Id')}`}
              style={{ cursor: 'pointer', width: 120 }}
              onClick={() => {
                this.sortByCol('Id');
              }}
            >
              Booking ID{this.renderSort('Id')}
            </th>
            <th
              className={`${this.sortIsActive('User')}`}
              style={{ cursor: 'pointer', width: 140 }}
              onClick={() => {
                this.sortByCol('User');
              }}
            >
              User{this.renderSort('User')}
            </th>
            <th
              className={`${this.sortIsActive('Calendar')}`}
              style={{ cursor: 'pointer', width: 120 }}
              onClick={() => {
                this.sortByCol('Calendar');
              }}
            >
              Calendar{this.renderSort('Calendar')}
            </th>
            <th
              className={`${this.sortIsActive('Type')}`}
              style={{ cursor: 'pointer', width: 120 }}
              onClick={() => {
                this.sortByCol('Type');
              }}
            >
              Type{this.renderSort('Type')}
            </th>
            <th
              className={`${this.sortIsActive('Time')}`}
              style={{ cursor: 'pointer', width: 120 }}
              onClick={() => {
                this.sortByCol('Time');
              }}
            >
              Time
            </th>
            <th style={{ width: 100 }}>Length</th>
          </tr>
        </thead>
        <tbody>{this.renderItems(source)}</tbody>
      </Table>
    );
  }

  renderEmpty() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <Text type="h3">There are no bookings</Text>
        </div>
      </div>
    );
  }

  renderContent() {
    let source = _.sortBy(this.state.bookings, this.state.sortColumn);
    if (this.state.sortDesc) {
      source.reverse();
    }
    if (!this.state.includeCancelled) {
      source = source.filter((b) => !b.Cancelled);
    }
    if (_.isEmpty(source)) {
      return this.renderEmpty();
    }
    return this.renderView(source);
  }

  renderFilters() {
    let calendarFilter = <Tag className="marginRight-10" onClick={this.openCalendarFilter} text="Calendar" />;
    let typeFilter = <Tag className="marginRight-10" onClick={this.openTypeFilter} text="Type" />;
    let userFilter = <Tag className="marginRight-10" onClick={this.openUserFilter} text="User" />;
    let timeFilter = <Tag className="marginRight-10" onClick={this.openTimeFilter} text={this.getTimeFilterText()} />;
    let cancelledFilter = (
      <Tag
        className="marginRight-10"
        onClick={this.openCancelledFilter}
        text={this.state.includeCancelled ? 'Including Cancelled Bookings' : 'Excluding Cancelled Bookings'}
      />
    );

    if (this.state.selectedUserFilter) {
      userFilter = (
        <Tag className="marginRight-10" onClick={this.openUserFilter} rightIcon="close" rightClick={this.removeUserFilter}>
          <UserListing size={15} user={this.state.selectedUserFilter} textClass="tag_text" />
        </Tag>
      );
    }
    if (this.state.selectedCalendarFilter) {
      calendarFilter = (
        <Tag
          className="marginRight-10"
          onClick={this.openCalendarFilter}
          rightIcon="close"
          rightClick={this.removeCalendarFilter}
          text={this.state.selectedCalendarFilter.Title}
        />
      );
    }
    if (this.state.selectedTypeFilter) {
      typeFilter = (
        <Tag
          className="marginRight-10"
          onClick={this.openTypeFilter}
          rightIcon="close"
          rightClick={this.removeTypeFilter}
          text={this.state.selectedTypeFilter.Title}
        />
      );
    }
    return (
      <div className="flex flex-center marginTop-20">
        <Text type="h5" className="marginRight-20">
          Filter by
        </Text>
        {timeFilter}
        {cancelledFilter}
        {calendarFilter}
        {typeFilter}
        {userFilter}
      </div>
    );
  }

  renderUserFilterPopup() {
    if (!this.state.userFilterOpen) {
      return null;
    }
    return (
      <Popup title="Select User" maxWidth={600} minWidth={400} minHeight={400} hasPadding onClose={this.closeUserFilter}>
        <GenericInput
          id="userSearch"
          type="text"
          label="Search"
          placeholder="Enter name"
          value={this.state.userSearch}
          onChange={(e) => this.onHandleChange(e)}
          alwaysShowLabel
        />
        {_.sortBy(this.props.users, (u) => u.displayName.toUpperCase())
          .filter((u) => {
            if (_.isEmpty(this.state.userSearch)) return true;
            return u.displayName.toUpperCase().indexOf(this.state.userSearch.toUpperCase()) > -1;
          })
          .map((user) => {
            return (
              <UserListing
                key={user.userId}
                user={user}
                onClick={() => {
                  this.selectUserFilter(user);
                }}
              />
            );
          })}
      </Popup>
    );
  }

  renderTypeFilterPopup() {
    if (!this.state.typeFilterOpen) {
      return null;
    }
    return (
      <Popup title="Select Type" maxWidth={600} minWidth={400} hasPadding onClose={this.closeTypeFilter}>
        {[{ Id: 'BLOCKOUT', Title: 'Block Out' }, ...this.props.bookingTypes].map((bt) => {
          return (
            <Tag
              onClick={() => {
                this.selectTypeFilter(bt);
              }}
              text={bt.Title}
              className="marginRight-10"
            />
          );
        })}
      </Popup>
    );
  }

  renderCancelledFilterPopup() {
    if (!this.state.cancelledFilterOpen) {
      return null;
    }
    return (
      <Popup title="Cancelled Bookings" maxWidth={600} minWidth={400} hasPadding onClose={this.closeCancelledFilter}>
        <Tag
          onClick={() => {
            this.selectCancelledFilter(true);
          }}
          text="Include Cancelling Bookings"
          className="marginRight-10"
        />
        <Tag
          onClick={() => {
            this.selectCancelledFilter(false);
          }}
          text="Exclude Cancelling Bookings"
          className="marginRight-10"
        />
      </Popup>
    );
  }

  renderCalendarFilterPopup() {
    if (!this.state.calendarFilterOpen) {
      return null;
    }
    return (
      <Popup title="Select Calendar" maxWidth={600} minWidth={400} hasPadding onClose={this.closeCalendarFilter}>
        {this.props.calendars.map((bc) => {
          return (
            <Tag
              onClick={() => {
                this.selectCalendarFilter(bc);
              }}
              text={bc.Title}
              className="marginRight-10"
            />
          );
        })}
      </Popup>
    );
  }

  renderTimeFilterPopup() {
    if (!this.state.timeFilterOpen) {
      return null;
    }
    return (
      <Popup title="Select Time" maxWidth={600} minWidth={400} hasPadding onClose={this.closeTimeFilter}>
        <div className="analyticsFilter__dateRange">
          <DatePicker hideTop selectedDate={this.state.startDate} selectDate={this.startDateChanged} />
          <DatePicker hideTop selectedDate={this.state.endDate} selectDate={this.endDateChanged} />
        </div>
      </Popup>
    );
  }

  render() {
    return (
      <div style={{ minWidth: '100%' }}>
        {this.renderCalendarFilterPopup()}
        {this.renderTypeFilterPopup()}
        {this.renderCancelledFilterPopup()}
        {this.renderUserFilterPopup()}
        {this.renderTimeFilterPopup()}
        {this.renderFilters()}
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { calendars, types } = state.bookings;
  const { users } = state.users;
  const { auth } = state;
  return {
    users,
    calendars,
    auth,
    bookingTypes: types,
  };
};

export default connect(mapStateToProps, { calendarsLoaded, bookingTypesLoaded, usersLoaded })(Bookings);
