import _ from 'lodash';

import {
  CALENDARS_LOADED,
  CALENDAR_REMOVED,
  CALENDAR_ADDED,
  BOOKINGTYPES_LOADED,
  BOOKINGTYPE_REMOVED,
  BOOKINGTYPE_ADDED,
  CALENDARS_PURGE,
} from '../actions/types';

const INITIAL_STATE = {
  calendars: [],
  types: [],
  bookings: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CALENDARS_LOADED:
      return {
        ...state,
        calendars: action.payload,
      };
    case CALENDAR_ADDED:
      return {
        ...state,
        calendars: [...state.calendars, action.payload],
      };
    case CALENDAR_REMOVED:
      const index = _.findIndex(state.calendars, (calendar) => {
        return calendar.Id === action.payload;
      });
      if (index > -1) {
        const newCalendars = [...state.calendars];
        newCalendars.splice(index, 1);
        return { ...state, calendars: newCalendars };
      }
      return state;
    case BOOKINGTYPES_LOADED:
      return {
        ...state,
        types: action.payload,
      };
    case BOOKINGTYPE_ADDED:
      return {
        ...state,
        types: [...state.types, action.payload],
      };
    case BOOKINGTYPE_REMOVED:
      const typeIndex = _.findIndex(state.types, (calendar) => {
        return calendar.Id === action.payload;
      });
      if (typeIndex > -1) {
        const newBookingTypes = [...state.types];
        newBookingTypes.splice(typeIndex, 1);
        return { ...state, types: newBookingTypes };
      }
      return state;
    case CALENDARS_PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
