import React, { Component } from 'react';
import tinycolor from 'tinycolor2';
import 'react-step-progress/dist/index.css';
import _ from 'lodash';
import { connect } from 'react-redux';
import { GenericInput, DropdownInput, Button, Tabs } from '../../components';
import { isTheBest, getApiError } from '../../session';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded, whiteLabelReset } from '../../actions';
import { renderTitle, renderDescription, renderTextStep } from './helper';
import { pctBtwnColours, COLOUR_NAV_GREY } from '../../js';

const SERVERLESS_FILE = '/serverless.yml';
const TYPE_ASSETS = 'assets';
const REPO_AWS = 'minuss-aws';
const REPO_WEB = 'minuss-admin';
const REPO_APP = 'minuss-expo';
const IMAGE_NOT_FOUND = 'NOTFOUND';

class Repository extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepIndex: 0,
      whiteLabelInfo: _.cloneDeep(props.activeWhiteLabel),
      configs: [],
      branchStates: (() => {
        const states = {};
        states[REPO_AWS] = {
          exists: false,
          creating: false,
          deleting: false,
          message: '',
        };
        states[REPO_WEB] = {
          exists: false,
          creating: false,
          deleting: false,
          message: '',
        };
        states[REPO_APP] = {
          exists: false,
          creating: false,
          deleting: false,
          message: '',
          creatingBranchMessage: '',
          deletingBranchMessage: '',
          branches: [],
          newSubBranch: '',
          selectedBranch: '',
        };
        return states;
      })(),
      loadingClientMessage: '',
      savingClientMessage: '',
      loadingConfigMessage: '',
      loading: false,
      assetImages: {},
    };
    this.tabs = [
      { text: 'Select Community', value: 0, render: this.renderStep1, validator: () => this.canMoveNext() },
      { text: 'Configure AWS', value: 1, render: this.renderStep2, validator: () => this.canMoveNext() },
      { text: 'Configure Web', value: 2, render: this.renderStep3, validator: () => this.canMoveNext() },
      { text: 'Configure App', value: 3, render: this.renderStep4, validator: () => this.canMoveNext() },
    ];
    this.repositories = [
      {
        Title: 'Minuss-AWS',
        Key: REPO_AWS,
      },
      {
        Title: 'Minuss-Admin',
        Key: REPO_WEB,
      },
      {
        Title: 'Minuss-Expo',
        Key: REPO_APP,
      },
    ];
  }

  componentDidMount() {
    this.checkBranchesExist();
    // TEST: Preset environment
    // this.setState({ whiteLabelInfo: { ClientCode: 'unittesting' } }, this.onLoadWhiteLabel);
  }

  componentDidUpdate(prevProps) {
    const { activeWhiteLabel } = this.props;
    if (activeWhiteLabel && !_.isEqual(activeWhiteLabel, prevProps.activeWhiteLabel)) {
      const clientChanged = activeWhiteLabel.ClientCode !== prevProps.activeWhiteLabel.ClientCode;
      this.setState({ whiteLabelInfo: activeWhiteLabel }, () => {
        if (clientChanged) this.checkBranchesExist();
      });
    }
  }

  checkBranchesExist = () => {
    const { whiteLabelInfo, branchStates } = this.state;
    if (!whiteLabelInfo || !whiteLabelInfo.ClientCode) return;
    const branchName = whiteLabelInfo.ClientCode;

    const newBranchStates = _.cloneDeep(branchStates);
    this.repositories.forEach((r) => {
      const branchInfo = newBranchStates[r.Key];
      branchInfo.creating = true;
      branchInfo.deleting = true;
      branchInfo.message = `Checking ${branchName} in ${r.Key}...`;
      this.setState({ branchStates: newBranchStates }, async () => {
        try {
          const { data: exists } = await automationActions.hasLiveEnvironment(r.Key, branchName);
          branchInfo.exists = exists || false;
          branchInfo.branches = await this.reloadBranches(r.Key, branchName);
          branchInfo.selectedBranch = branchName;
          branchInfo.creating = false;
          branchInfo.deleting = false;
          branchInfo.message = '';
          // console.log('checkBranchesExist', newBranchStates);
          this.setState({ branchStates: newBranchStates });
        } catch (error) {
          const message = getApiError(error).message;
          branchInfo.exists = false;
          branchInfo.creating = false;
          branchInfo.deleting = false;
          branchInfo.message = `Error: ${message}`;
          this.setState({ branchStates: newBranchStates });
        }
      });
    });
  };

  loadBranches = async (repo, baseBranch) => {
    const { data } = await automationActions.getEnvironments(repo);
    // console.log('loadBranches', data);
    const branches = data
      .filter((env) => env.name === baseBranch || env.name.startsWith(`${baseBranch}-`))
      .map((env) => {
        return { Id: env.uuid, Title: env.name, Key: env.slug };
      });
    return _.orderBy(branches, 'Key');
  };

  canMoveNext = (repo) => !this.isProcessing(repo);

  saveWhiteLabel = async (whiteLabel = null) => {
    const { data } = await automationActions.updateWhiteLabel(whiteLabel || this.state.whiteLabelInfo, this.props.activeWhiteLabel);
    this.props.whiteLabelLoaded(data);
    return data;
  };

  setBranchMessage = (branchInfo, message, isSubBranch, isDeleting = false) => {
    if (!isSubBranch) {
      branchInfo.message = message;
    } else {
      branchInfo[isDeleting ? 'deletingBranchMessage' : 'creatingBranchMessage'] = message;
    }
  };

  reloadBranches = async (repo, branchName) => {
    if (repo === REPO_APP) {
      const { whiteLabelInfo } = this.state;
      if (!whiteLabelInfo.ClientCode) return [];

      const apps = whiteLabelInfo.Apps || [];
      const branches = await this.loadBranches(repo, branchName);
      // console.log('reloadBranches', 'branches', branches, 'Apps', apps);
      if (branches && branches.length !== apps.length) {
        const newApps = [];
        branches.forEach((b) => {
          const app = apps.find((a) => a.ClientCode === b.Key);
          newApps.push(app || { ClientCode: b.Key });
        });
        const newInfo = { ...whiteLabelInfo, Apps: newApps };
        console.log('restoring apps list', newApps);
        await this.saveWhiteLabel(newInfo);
      }

      return branches;
    }

    return [];
  };

  isProcessing = (repo = null) => {
    const { branchStates, loading } = this.state;
    if (!repo) return loading;

    const branchInfo = branchStates[repo];
    return branchInfo.creating || branchInfo.deleting || loading;
  };

  isBranchValid = (repo) => {
    const { whiteLabelInfo, branchStates } = this.state;
    const branchInfo = branchStates[repo];
    return !_.isEmpty(whiteLabelInfo.ClientCode) && branchInfo.exists;
  };

  hasSelectedApp = (repo) => {
    const { branchStates } = this.state;
    const branchInfo = branchStates[repo];
    return this.isBranchValid(repo) && !_.isEmpty(branchInfo.selectedBranch);
  };

  isSubBranch = () => {
    const { whiteLabelInfo, branchStates } = this.state;
    const branchInfo = branchStates[REPO_APP];
    return branchInfo.selectedBranch !== whiteLabelInfo.ClientCode;
  };

  getActualBranch = (repo) => {
    const { whiteLabelInfo, branchStates } = this.state;
    const branchInfo = branchStates[repo];
    return this.isSubBranch() ? branchInfo.selectedBranch : whiteLabelInfo.ClientCode;
  };

  onMoveStep = (stepIndex) => {
    // console.log('stepIndex', stepIndex);
    const { validator } = this.tabs[stepIndex];
    // console.log('validator', validator && validator());
    if (validator && validator()) {
      this.setState({ stepIndex }, this.onStepChanged);
    }
  };

  onBranchNameChanged = (event) => {
    const { whiteLabelInfo } = this.state;
    const newInfo = _.cloneDeep(whiteLabelInfo);
    newInfo.ClientCode = event.target.value.replace(' ', '-');
    this.setState({ whiteLabelInfo: newInfo });
  };

  onBranchChanged = (key, repo) => {
    const { branchStates } = this.state;
    const newBranchStates = _.cloneDeep(branchStates);
    const branch = newBranchStates[repo];
    branch.selectedBranch = key;
    this.setState({ branchStates: newBranchStates, configs: [], savingClientMessage: '' });
  };

  onSubBranchNameChanged = (event, repo) => {
    const { branchStates } = this.state;
    const newBranchStates = _.cloneDeep(branchStates);
    const branch = newBranchStates[repo];
    branch.newSubBranch = event.target.value.replace(' ', '-');
    this.setState({ branchStates: newBranchStates });
  };

  onClearClientCode = () => {
    this.props.whiteLabelReset();
    this.setState({ loading: false, configs: [], loadingClientMessage: '', loadingConfigMessage: '' });
  };

  onCreateBranch = async (repo) => {
    const { whiteLabelInfo, branchStates } = this.state;

    const newInfo = _.cloneDeep(whiteLabelInfo);
    const branchName = newInfo.ClientCode;
    const newBranchStates = _.cloneDeep(branchStates);
    const branchInfo = newBranchStates[repo];
    const isSubBranch = !_.isEmpty(branchInfo.newSubBranch);
    const actualBranch = isSubBranch ? `${branchName}-${branchInfo.newSubBranch}` : branchName;
    const baseBranch = isSubBranch ? branchName : null;

    branchInfo.creating = true;
    this.setBranchMessage(branchInfo, `Creating ${actualBranch} in ${repo}...`, isSubBranch);
    this.setState({ branchStates: newBranchStates }, async () => {
      try {
        const { data } = await automationActions.createLiveEnvironment(repo, actualBranch, baseBranch);
        branchInfo.branches = await this.reloadBranches(repo, whiteLabelInfo.ClientCode);
        branchInfo.creating = false;
        branchInfo.selectedBranch = data.branchName;
        if (!isSubBranch) branchInfo.exists = true;
        this.setBranchMessage(branchInfo, `${data.branchName} in ${repo} created successfully`, isSubBranch);
        this.setState({ branchStates: newBranchStates, configs: [] });
      } catch (error) {
        branchInfo.creating = false;
        this.setBranchMessage(branchInfo, `Error: ${getApiError(error).message}`, isSubBranch);
        this.setState({ branchStates: newBranchStates });
      }
    });
  };

  onDeleteBranch = (repo) => {
    const { whiteLabelInfo, branchStates } = this.state;
    const newBranchStates = _.cloneDeep(branchStates);
    const branchInfo = newBranchStates[repo];
    const isSubBranch = this.isSubBranch();
    const actualBranch = this.getActualBranch(repo);
    if (!window.confirm(`Are you sure you want to delete ${actualBranch} in ${repo}?`)) return;

    branchInfo.deleting = true;
    this.setBranchMessage(branchInfo, `Deleting ${actualBranch} in ${repo}...`, isSubBranch, true);
    this.setState({ branchStates: newBranchStates }, async () => {
      try {
        await automationActions.deleteLiveEnvironment(repo, actualBranch);
        branchInfo.branches = await this.reloadBranches(repo, whiteLabelInfo.ClientCode);
        branchInfo.deleting = false;
        branchInfo.selectedBranch = whiteLabelInfo.ClientCode;
        if (!isSubBranch) branchInfo.exists = false;
        this.setBranchMessage(branchInfo, `${actualBranch} in ${repo} deleted successfully`, isSubBranch, true);
        this.setState({ branchStates: newBranchStates, configs: [] });
      } catch (error) {
        branchInfo.deleting = false;
        this.setBranchMessage(branchInfo, `Error: ${getApiError(error).message}`, isSubBranch);
        this.setState({ branchStates: newBranchStates });
      }
    });
  };

  onStepChanged = () => {
    this.setState({ configs: [], loadingConfigMessage: '', savingClientMessage: '', loadingConfigMessage: '' });
  };

  onChangeEnvironmentConfig = (entityKey, key, value) => {
    const { configs } = this.state;
    const entity = configs.find((c) => c.key === entityKey);
    if (entity) {
      const config = entity.values.find((c) => c.key === key);
      if (config) {
        config.value = value;
        config.modified = true;
        this.setState({ configs });
      }
    }
  };

  onUseDefaults = () => {
    const { configs } = this.state;
    if (!configs) return;

    configs.forEach((type) => {
      type.values.forEach((config) => {
        if (config.default) this.onChangeEnvironmentConfig(type.key, config.key, config.default);
      });
    });
  };

  onSaveEnvironmentConfig = (repo) => {
    const { configs } = this.state;
    const actualBranch = this.getActualBranch(repo);

    this.setState({ loading: true, loadingConfigMessage: 'Updating environment configs...' }, async () => {
      try {
        // Get all modified configurations
        const modifiedConfigs = configs
          .map((c) => {
            return {
              ...c,
              values: c.values.filter((v) => v.modified),
            };
          })
          .filter((c) => c.values && c.values.length > 0);
        // console.log('modifiedConfigs', modifiedConfigs);

        // Save non-serverless configurations
        const normalConfigs = modifiedConfigs.filter((c) => !c.key.endsWith(SERVERLESS_FILE) && c.type !== TYPE_ASSETS);
        if (normalConfigs && normalConfigs.length > 0) {
          // console.log('saving normal configs...', repo, JSON.stringify(normalConfigs));
          await automationActions.updateEnvironmentConfigs(repo, actualBranch, normalConfigs);
        }

        // For serverless files, we need to apply updates to all stacks
        const serverlessConfigs = modifiedConfigs.filter((c) => c.key.endsWith(SERVERLESS_FILE) && c.type !== TYPE_ASSETS);
        if (serverlessConfigs && serverlessConfigs.length > 0) {
          const { data: stacks } = await automationActions.getAwsCoreStacks(actualBranch);
          // console.log(`saving serverless configs for ${stacks.length} stacks...`, repo, JSON.stringify(serverlessConfigs));
          for (let index = 0; index < stacks.length; index++) {
            const newPath = `${stacks[index]}${SERVERLESS_FILE}`;
            const newConfigs = [{ ...serverlessConfigs[0], key: newPath, path: newPath }];
            this.setState({ loadingConfigMessage: `Updating ${newPath}...` });
            await automationActions.updateEnvironmentConfigs(repo, actualBranch, newConfigs);
          }
        }

        // Save assets
        const assetConfigs = modifiedConfigs.filter((c) => c.type === TYPE_ASSETS);
        if (assetConfigs && assetConfigs.length > 0) {
          for (let i = 0; i < assetConfigs.length; i++) {
            const config = assetConfigs[i];
            for (let j = 0; j < config.values.length; j++) {
              const asset = config.values[j];
              const newConfigs = [{ ...config, values: [{ ...asset }] }];
              // console.log('saving asset...', newConfigs);
              this.setState({ loadingConfigMessage: `Updating ${asset.label}...` });
              await automationActions.updateEnvironmentConfigs(repo, actualBranch, newConfigs);
            }
          }
        }

        this.setState(
          { loading: false, loadingConfigMessage: `Environment ${actualBranch} in ${repo} updated successfully` },
          this.loadEnvironmentConfigs,
        );
      } catch (error) {
        const message = getApiError(error).message;
        this.setState({ loading: false, loadingConfigMessage: `Error: ${message}` });
      }
    });
  };

  onLoadWhiteLabel = () => {
    const { whiteLabelInfo } = this.state;
    const { activeWhiteLabel } = this.props;
    const clientCode = whiteLabelInfo.ClientCode;
    if (clientCode === activeWhiteLabel.ClientCode) return;
    if (!clientCode) {
      this.onClearClientCode();
      return;
    }

    let loadingClientMessage = `Loading ${clientCode} details...`;
    this.setState({ loading: true, loadingClientMessage }, async () => {
      try {
        const { data } = await automationActions.getWhiteLabel(clientCode);
        // console.log('onLoadWhiteLabel', data);
        if (data) {
          this.props.whiteLabelLoaded(data);
          loadingClientMessage = `Details for ${data.ClientName} has been loaded`;
        } else {
          this.props.whiteLabelReset();
          loadingClientMessage = clientCode ? `${clientCode} does not exist` : '';
        }
        this.setState({ loading: false, loadingClientMessage, configs: [] });
      } catch (error) {
        this.setState({ loading: false, loadingClientMessage: getApiError(error).message });
      }
    });
  };

  onChangeText = (event, fieldName) => {
    const { whiteLabelInfo } = this.state;
    if (!whiteLabelInfo) return;

    const newInfo = _.cloneDeep(whiteLabelInfo);
    newInfo[fieldName] = event.target.value;
    this.setState({ whiteLabelInfo: newInfo });
  };

  onBrandingColourMainEntered = () => {
    try {
      const { whiteLabelInfo } = this.state;
      const newInfo = _.cloneDeep(whiteLabelInfo);
      let changed = false;
      if (!whiteLabelInfo.BrandingColourDark) {
        newInfo.BrandingColourDark = '#' + tinycolor(pctBtwnColours(whiteLabelInfo.BrandingColourMain, '#000', 0.8)).toHex();
        changed = true;
      }
      if (!whiteLabelInfo.BrandingColourLight) {
        newInfo.BrandingColourLight = '#' + tinycolor(pctBtwnColours('#fff', whiteLabelInfo.BrandingColourMain, 0.6)).toHex();
        changed = true;
      }
      if (changed) this.setState({ whiteLabelInfo: newInfo });
    } catch (error) {
      console.log('onBrandingColourMainEntered', error);
      // Ignore
    }
  };

  onSaveWhiteLabel = () => {
    let savingClientMessage = 'Saving information...';
    this.setState({ loading: true, savingClientMessage }, async () => {
      try {
        const saved = await this.saveWhiteLabel();
        savingClientMessage = saved ? 'Saved successfully' : '';
        this.setState({ loading: false, savingClientMessage, configs: [] });
      } catch (error) {
        savingClientMessage = getApiError(error).message;
        this.setState({ loading: false, savingClientMessage });
      }
    });
  };

  onAppNameChanged = (event) => {
    const { whiteLabelInfo, branchStates } = this.state;
    const newInfo = _.cloneDeep(whiteLabelInfo);
    const branchInfo = branchStates[REPO_APP];
    const app = newInfo.Apps.find((a) => a.ClientCode === branchInfo.selectedBranch);
    app.ClientName = event.target.value;
    this.setState({ whiteLabelInfo: newInfo });
  };

  convertDropdownOptions = (values) => {
    return values.map((val) => {
      return { Title: val, Key: val };
    });
  };

  checkConfigImages = async (repo, branch) => {
    const { configs } = this.state;
    const assets = configs.find((c) => c.key === 'assets');
    if (assets) {
      const assetImages = {};
      const promises = assets.values.map(async (a) => {
        try {
          const { data } = await automationActions.getSourceImage(repo, branch, a.path);
          assetImages[a.path] = data;
        } catch (error) {
          console.log('checkConfigImages', { path: a.path, error });
          assetImages[a.path] = 'ERROR';
        }
      });
      await Promise.all(promises);
      // console.log('checkConfigImages', assetImages);
      this.setState({ assetImages });
    }
  };

  loadEnvironmentConfigs = () => {
    const { whiteLabelInfo, stepIndex } = this.state;
    let actualBranch = whiteLabelInfo.ClientCode;

    if (stepIndex === 0 || _.isEmpty(actualBranch)) return;

    const repo = this.repositories[stepIndex - 1].Key;
    if (repo === REPO_APP) actualBranch = this.getActualBranch(REPO_APP);
    this.setState({ loading: true, loadingConfigMessage: `Loading ${actualBranch} configurations...`, configs: [] }, async () => {
      try {
        const { data } = await automationActions.getEnvironmentConfigs(repo, actualBranch);
        // console.log(actualBranch, JSON.stringify(data));
        this.setState({ loading: false, loadingConfigMessage: '', configs: data, assetImages: [] }, () =>
          this.checkConfigImages(repo, actualBranch),
        );
      } catch (error) {
        const message = getApiError(error).message;
        this.setState({ loading: false, loadingConfigMessage: `Error: ${message}` });
      }
    });
  };

  getSecurableValue = (config) => {
    return config.value || (config.uuid && config.secured ? '******' : '');
  };

  isBrandingColourValid = (code) => !_.isEmpty(code) && code.startsWith('#');

  isImageUrlValid = (url) => !_.isEmpty(url) && url.startsWith('http');

  renderConfig(entityKey, item) {
    const { assetImages, loading } = this.state;
    const helpText = item.default ? `Default: ${item.default}` : '';
    const imagePreview = assetImages && item.path ? assetImages[item.path] : '';
    const imageNotFound = imagePreview === IMAGE_NOT_FOUND;
    // console.log('renderConfig', { assetImages, path: item.path, image: assetImages[item.path], imageNotFound });

    return (
      <div key={item.key} className="flex flex-row">
        <div style={{ width: 200 }}>{item.label}</div>
        <div>
          {imagePreview && !imageNotFound ? (
            <img
              style={{ height: 150, width: 150, marginTop: 10, marginLeft: 10, objectFit: 'contain', backgroundColor: COLOUR_NAV_GREY }}
              src={`data:image/png;base64,${imagePreview}`}
            />
          ) : null}
          <div className="flex flex-row">
            {item.values && item.values.length > 1 ? (
              <DropdownInput
                id={`value_${item.key}`}
                style={{ marginLeft: 10, marginBottom: 0, width: 400 }}
                value={item.value}
                options={this.convertDropdownOptions(item.values)}
                onSelect={(key) => this.onChangeEnvironmentConfig(entityKey, item.key, key)}
                help={helpText}
              />
            ) : (
              <GenericInput
                id={`value_${item.key}`}
                style={{ marginLeft: 10, marginBottom: 0, width: 400 }}
                type="text"
                placeholder={imageNotFound ? `- ${item.label} does not exist -` : ''}
                value={this.getSecurableValue(item)}
                onChange={(event) => this.onChangeEnvironmentConfig(entityKey, item.key, event.target.value)}
                help={helpText}
                disabled={loading}
              />
            )}
            {item.default ? (
              <Button
                className="marginLeft-10"
                textStyle={{ fontSize: 10 }}
                inline
                buttonType="primary"
                onClick={() => !loading && this.onChangeEnvironmentConfig(entityKey, item.key, item.default)}
                isActive={!loading}
              >
                Set Default
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  renderConfigs(repo) {
    const { configs, loadingConfigMessage } = this.state;
    const isBranchValid = this.isBranchValid(repo);
    const canSave = isBranchValid && !this.isProcessing(repo);
    // console.log('renderConfigs', configs);

    return (
      <div className="marginTop-10">
        {configs.map((type) => {
          return (
            <div key={type.key}>
              <p className="fontHeavy fontSize-16 text-dark">{type.key}</p>
              {type.values.map((config) => this.renderConfig(type.key, config))}
            </div>
          );
        })}
        <div className="flex flex-row flex-center marginTop-16">
          {_.isEmpty(configs) ? (
            <div>
              <Button inline buttonType="primary" onClick={this.loadEnvironmentConfigs} isActive={canSave}>
                Load Configurations
              </Button>
            </div>
          ) : (
            <div>
              <Button inline buttonType="primary" onClick={this.onUseDefaults} isActive={canSave}>
                Use Defaults
              </Button>
              <Button
                className="marginLeft-16"
                inline
                buttonType="primary"
                onClick={() => this.onSaveEnvironmentConfig(repo)}
                isActive={canSave}
              >
                Save Configurations
              </Button>
            </div>
          )}
          <div className="marginLeft-16">{loadingConfigMessage}</div>
        </div>
      </div>
    );
  }

  renderBranchInput() {
    const { whiteLabelInfo, loadingClientMessage, loading } = this.state;
    const { activeWhiteLabel } = this.props;
    const branchName = whiteLabelInfo ? whiteLabelInfo.ClientCode : '';
    const isBranchValid = !_.isEmpty(branchName);
    const canClear = isBranchValid && activeWhiteLabel && activeWhiteLabel.ClientCode === branchName;

    return (
      <div className="flex flex-row flex-center">
        <GenericInput
          className="marginTop-10"
          id="branch"
          style={{ width: 300 }}
          type="text"
          placeholder="Community key"
          isValid={() => isBranchValid}
          value={branchName}
          onChange={this.onBranchNameChanged}
          onBlur={this.onLoadWhiteLabel}
          isRequired
        />
        <Button
          style={{ width: 90 }}
          className="marginLeft-24"
          inline
          buttonType="primary"
          onClick={canClear ? this.onClearClientCode : null}
          isActive={branchName && !loading}
        >
          {canClear ? 'Clear' : 'Load'}
        </Button>
        <div className="marginLeft-16">{loadingClientMessage}</div>
      </div>
    );
  }

  renderCreateBranch(repo) {
    const { whiteLabelInfo, branchStates } = this.state;
    const branchName = whiteLabelInfo.ClientCode;
    const isBranchValid = !_.isEmpty(branchName);
    const branch = branchStates[repo];
    const canModify = isBranchValid && !this.isProcessing(repo);
    const canCreate = canModify && !branch.exists;
    const canDelete = canModify && branch.exists;
    const message = branch.newSubBranch ? '' : branch.message;

    return (
      <div className="marginTop-10">
        <div style={{ width: '50%' }}>
          <b>{`${repo}: `}</b>
          {message || `${branchName || '[NO BRANCH - Select Community]'}`}
        </div>
        <div className="flex flex-row flex-center marginTop-16">
          <Button
            className="marginRight-16"
            inline
            buttonType="primary"
            onClick={() => canCreate && this.onCreateBranch(repo)}
            isActive={canCreate}
          >
            Create
          </Button>
          <Button inline buttonType="primary" onClick={() => canDelete && this.onDeleteBranch(repo)} isActive={canDelete}>
            Delete
          </Button>
        </div>
      </div>
    );
  }

  renderAppDetails() {
    const { whiteLabelInfo, loading, savingClientMessage } = this.state;
    if (!whiteLabelInfo) return null;

    const branch = this.getActualBranch(REPO_APP);
    const app = whiteLabelInfo.Apps.find((a) => a.ClientCode === branch);
    // console.log('renderAppDetails', branch, app);
    if (!app) return null;

    const appName = app.ClientName || whiteLabelInfo.ClientName;
    const canSave = !this.isProcessing(REPO_APP);

    return (
      <div>
        <div className="flex flex-row flex-center">
          <div style={{ width: 200 }}>Enter app name</div>
          <GenericInput
            className="marginTop-20"
            id="app_name"
            style={{ width: 250 }}
            type="text"
            placeholder="e.g. Botanica Lifestyle"
            isValid={() => !_.isEmpty(appName)}
            value={appName}
            onChange={this.onAppNameChanged}
            isRequired
            disabled={!canSave}
          />
        </div>
        <div className="flex flex-row flex-center">
          <Button inline buttonType="primary" onClick={() => canSave && this.onSaveWhiteLabel()} isActive={canSave}>
            Save
          </Button>
          <div className="marginLeft-16">{savingClientMessage}</div>
        </div>
      </div>
    );
  }

  renderCreateSubBranch(repo) {
    const { whiteLabelInfo, branchStates } = this.state;
    const branchInfo = branchStates[repo];
    const subBranchName = branchInfo.newSubBranch;
    const hasSubBranchName = !_.isEmpty(subBranchName);

    const canChange = !this.isProcessing(repo);
    const canCreate = this.isBranchValid(repo) && hasSubBranchName && canChange;
    const canDelete = this.isBranchValid(repo) && this.isSubBranch() && canChange;
    // console.log('renderCreateSubBranch', branchInfo.branches);

    return (
      <div className="marginTop-16">
        <div className="flex flex-row flex-center">
          <div style={{ width: 280 }}>
            <DropdownInput
              id={`dropdown_${repo}`}
              className="marginRight-16"
              style={{ width: 250, marginBottom: 'unset' }}
              placeholder="Select Branch"
              value={branchInfo.selectedBranch}
              options={branchInfo.branches}
              onSelect={(key) => this.onBranchChanged(key, repo)}
              disabled={!canChange}
            />
          </div>
          <Button
            className="marginRight-16"
            inline
            buttonType="primary"
            onClick={() => canDelete && this.onDeleteBranch(repo)}
            isActive={canDelete}
          >
            Delete
          </Button>
          <div>{branchInfo.deletingBranchMessage}</div>
        </div>
        <div className="fontHeavy marginTop-10">OR</div>
        <div className="flex flex-row flex-center">
          <div className="flex flex-row flex-center" style={{ width: 280 }}>
            <div className="fontHeavy fontSize-16 marginBottom-10">{`${
              (whiteLabelInfo && whiteLabelInfo.ClientCode) || '[NO BRANCH]'
            }-`}</div>
            <GenericInput
              className="marginTop-10 marginRight-16"
              id="branch"
              style={{ width: 170 }}
              type="text"
              placeholder="Branch name"
              isValid={() => hasSubBranchName}
              value={subBranchName}
              onChange={(e) => this.onSubBranchNameChanged(e, repo)}
              disabled={!canChange}
            />
          </div>
          <Button
            className="marginRight-16"
            inline
            buttonType="primary"
            onClick={() => canCreate && this.onCreateBranch(repo)}
            isActive={canCreate}
          >
            Create
          </Button>
          <div>{branchInfo.creatingBranchMessage}</div>
        </div>
      </div>
    );
  }

  renderBrandingColourInput = (key, placeHolder, onBlur = null) => {
    const { whiteLabelInfo } = this.state;
    const { activeWhiteLabel } = this.props;
    const value = whiteLabelInfo ? whiteLabelInfo[key] : '';
    const disabled = !activeWhiteLabel || !activeWhiteLabel.ClientCode;

    return (
      <GenericInput
        id={key}
        style={{ width: 70, marginBottom: 0 }}
        type="text"
        placeholder={placeHolder}
        isValid={() => this.isBrandingColourValid(value)}
        value={value}
        onChange={(e) => this.onChangeText(e, key)}
        onBlur={onBlur}
        disabled={disabled}
      />
    );
  };

  renderImageUrlInput = (key) => {
    const { whiteLabelInfo } = this.state;
    const { activeWhiteLabel } = this.props;
    const value = whiteLabelInfo ? whiteLabelInfo[key] : '';
    const disabled = !activeWhiteLabel || !activeWhiteLabel.ClientCode;

    return (
      <GenericInput
        id={key}
        style={{ width: 500, marginBottom: 0 }}
        type="text"
        placeholder="https://..."
        isValid={() => this.isImageUrlValid(value)}
        value={value}
        onChange={(e) => this.onChangeText(e, key)}
        disabled={disabled}
      />
    );
  };

  renderCommonConfigurations() {
    const { loading, savingClientMessage } = this.state;
    const { activeWhiteLabel } = this.props;
    const active = activeWhiteLabel && activeWhiteLabel.ClientCode && !loading;

    return (
      <div className="marginTop-10">
        <div className="flex flex-row flex-center">
          <div style={{ width: 350 }}>Enter branding colour - Main</div>
          {this.renderBrandingColourInput('BrandingColourMain', '#FF6363', this.onBrandingColourMainEntered)}
        </div>
        <div className="flex flex-row flex-center">
          <div style={{ width: 350 }}>Enter branding colour - Dark</div>
          {this.renderBrandingColourInput('BrandingColourDark', '#D13636')}
        </div>
        <div className="flex flex-row flex-center">
          <div style={{ width: 350 }}>Enter branding colour - Light</div>
          {this.renderBrandingColourInput('BrandingColourLight', '#FCE1E1')}
        </div>
        <div className="marginTop-16">
          Open{' '}
          <a href={`${window.location.origin}/master/appassets`} target="_blank">
            App Assets
          </a>{' '}
          to upload the logo and generate app assets. Copy the image urls and fill in the following information.
        </div>
        <div className="flex flex-row flex-center marginTop-10">
          <div style={{ width: 350 }}>Enter logo image url</div>
          {this.renderImageUrlInput('ImageUrlLogo')}
        </div>
        <div className="flex flex-row flex-center">
          <div style={{ width: 350 }}>Enter app icon image url</div>
          {this.renderImageUrlInput('ImageUrlAppIcon')}
        </div>
        <div className="flex flex-row flex-center">
          <div style={{ width: 350 }}>Enter app splash image url</div>
          {this.renderImageUrlInput('ImageUrlAppSplash')}
        </div>
        <div className="flex flex-row flex-center marginTop-16">
          <Button inline buttonType="primary" onClick={this.onSaveWhiteLabel} isActive={active}>
            Save
          </Button>
          <div className="marginLeft-16">{savingClientMessage}</div>
        </div>
      </div>
    );
  }

  renderStep1 = () => {
    return (
      <div>
        {renderTitle('Select and Configure Environment')}
        {renderDescription('In this step, we will configure common settings for the new environment.')}
        <ol>
          {renderTextStep(
            <div>
              Enter commnunity key (e.g. aveo)
              {this.renderBranchInput()}
            </div>,
          )}
          {renderTextStep(
            <div>
              Configure base settings
              {this.renderCommonConfigurations()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep2 = () => {
    const isBranchValid = this.isBranchValid(REPO_AWS);

    return (
      <div>
        {renderTitle('Configure AWS')}
        {renderDescription('In this step, we will configure AWS services for newly created environment.')}
        <ol>
          {renderTextStep(
            <div>
              Create branch
              {this.renderCreateBranch(REPO_AWS)}
            </div>,
          )}
          {isBranchValid
            ? renderTextStep(
                <div>
                  Update configurations
                  {this.renderConfigs(REPO_AWS)}
                </div>,
              )
            : null}
        </ol>
      </div>
    );
  };

  renderStep3 = () => {
    const isBranchValid = this.isBranchValid(REPO_WEB);

    return (
      <div>
        {renderTitle('Configure Web')}
        {renderDescription('In this step, we will configure web admin console for newly created environment.')}
        <ol>
          {renderTextStep(
            <div>
              Create branch
              {this.renderCreateBranch(REPO_WEB)}
            </div>,
          )}
          {isBranchValid
            ? renderTextStep(
                <div>
                  Update configurations
                  {this.renderConfigs(REPO_WEB)}
                </div>,
              )
            : null}
        </ol>
      </div>
    );
  };

  renderStep4 = () => {
    const isBranchValid = this.isBranchValid(REPO_APP);
    const hasSelectedApp = this.hasSelectedApp(REPO_APP);

    return (
      <div>
        {renderTitle('Configure App')}
        {renderDescription('In this step, we will configure app for newly created environment.')}
        <ol>
          {renderTextStep(
            <div>
              Create branch
              {this.renderCreateBranch(REPO_APP)}
            </div>,
          )}
          {isBranchValid
            ? renderTextStep(
                <div>
                  Select or Create a sub app
                  {this.renderCreateSubBranch(REPO_APP)}{' '}
                </div>,
              )
            : null}
          {hasSelectedApp
            ? renderTextStep(
                <div>
                  Update app details
                  {this.renderAppDetails()}
                </div>,
              )
            : null}
          {hasSelectedApp
            ? renderTextStep(
                <div>
                  Update configurations
                  {this.renderConfigs(REPO_APP)}
                </div>,
              )
            : null}
        </ol>
      </div>
    );
  };

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    const { stepIndex } = this.state;
    return (
      <div className="flex-1 automation">
        <Tabs
          containerStyle={{ marginBottom: 30 }}
          onSelectTab={(step) => this.onMoveStep(step)}
          selectedTab={this.state.stepIndex}
          tabs={this.tabs}
        />
        {this.tabs[stepIndex].render()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded, whiteLabelReset })(Repository);
