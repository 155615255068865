import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';

function isPathMatchingPattern(pattern, path) {
  const regex = new RegExp(`^${pattern.replace(/:[^\s/]+/g, '([\\w-]+)')}$`);
  return regex.test(path);
}

function withProtectedRoutes(WrappedComponent, unprotectedRoutes = []) {
  class ProtectedRoutesWrapper extends Component {
    async checkRouteAndRedirect(pathname) {
      // Check if the current route matches any of the protected patterns
      const isUnprotectedRoute = unprotectedRoutes.some((pattern) => isPathMatchingPattern(pattern, pathname));

      if (!isUnprotectedRoute) {
        try {
          // This method will throw an error if there is no authenticated user.
          await Auth.currentAuthenticatedUser();
        } catch (error) {
          // If no authenticated user is found, redirect to the login page.
          this.props.history.push('/logout');
        }
      }
    }

    componentDidMount() {
      this.unlisten = this.props.history.listen(async (location, action) => {
        this.checkRouteAndRedirect(location.pathname);
      });

      this.checkRouteAndRedirect(this.props.location.pathname);
    }

    componentWillUnmount() {
      // Cleanup the listener when the component is unmounted
      this.unlisten();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return withRouter(ProtectedRoutesWrapper);
}

export default withProtectedRoutes;
