import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { feedbackActions } from '../../webapi';
import { feedbackLoaded, removeFeedback } from '../../actions';
import { checkLoggedIn, validateAccess } from '../../session';
import { defaultProfileImage } from '../../config';
import { COLOUR_BRANDING_OFF } from '../../js';
import SetFeedbackEmail from './SetFeedbackEmail';
import { CONST_STRINGS } from '../../config';
import { Text } from '../../components/text';

class ListFeedback extends Component {
  state = {
    allFeedback: [],
    now: moment.utc(),

    loading: true,
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    this.updateProps(this.props);
  }

  componentDidMount() {
    this.getFeedback();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    this.setState({
      allFeedback: props.allFeedback,
    });
  }

  getFeedback() {
    feedbackActions
      .getFeedback(this.props.auth.site)
      .then((res) => {
        if (res.data != null) {
          this.props.feedbackLoaded(res.data);
        }
        this.setState({ loading: false });
      })
      .catch((error) => {});
  }

  removeFeedback(event) {
    if (window.confirm(`Are you sure you want to delete this feedback?`)) {
      // var self = this;
      feedbackActions
        .deleteFeedback(this.props.auth.site, event.Id)
        .then((res) => {
          this.props.removeFeedback(event.Id);
          // self.getFeedback();
        })
        .catch((error) => {});
    }
  }

  renderFeedback() {
    // source = source.slice(0, 100);
    let source = _.sortBy(this.state.allFeedback, 'Changed');
    source.reverse();
    return source.map((ev) => {
      if (ev != null) {
        return (
          <tr key={ev.Id}>
            <td>{moment.unix(ev.Changed).format('D MMM YY - h:mma')}</td>
            <td>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <img
                    style={{ height: 30, width: 30, borderRadius: 15 }}
                    src={!ev.isAnonymous && ev.profilePic != null ? ev.profilePic : defaultProfileImage}
                    alt="user profilePic"
                  />
                </div>
                <div style={{ marginLeft: 16 }}>{ev.isAnonymous ? CONST_STRINGS.FEEDBACK_ANONYMOUS : ev.displayName}</div>
              </div>
            </td>
            {/* <td>{ev.userId}</td> */}
            <td>{ev.feedback}</td>

            <td className="table-options">
              <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                {validateAccess(this.props.auth.site, 'feedback', this.props.auth) && (
                  <a
                    onClick={() => {
                      this.removeFeedback(ev);
                    }}
                  >
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="minus-circle" />
                  </a>
                )}
                {!_.isEmpty(ev.image) && (
                  <a href={ev.image} target="_blank">
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="picture-o" />
                  </a>
                )}
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  renderEmpty() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <Text type="h3">
            Feedback comes straight from the app. This is where you review them. Set an email so you are notified when one comes in.
          </Text>
        </div>
      </div>
    );
  }

  renderFeedbackMain() {
    if (this.state.allFeedback.length === 0 && this.state.loading) {
      return (
        <div style={{ minWidth: '100%' }}>
          <div className="padding-60 paddingVertical-40" style={{ textAlign: 'center' }}>
            <FontAwesome style={{ fontSize: 30, color: COLOUR_BRANDING_OFF }} name="spinner fa-pulse fa-fw" />
          </div>
        </div>
      );
    }
    if (_.isEmpty(this.state.allFeedback)) {
      return this.renderEmpty();
    }
    return (
      <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
        <thead>
          <tr>
            <th style={{ cursor: 'pointer', width: 150 }}>Date</th>
            <th style={{ cursor: 'pointer', width: 200 }}>User Name</th>
            {/* <th style={{ cursor: 'pointer', width: 20 }}>User ID</th> */}
            <th style={{ cursor: 'pointer' }}>Feedback</th>
            <th style={{ width: 70 }} />
          </tr>
        </thead>
        <tbody>{this.renderFeedback()}</tbody>
      </Table>
    );
  }

  render() {
    return (
      <div style={{ minWidth: '100%' }}>
        <SetFeedbackEmail />
        <div style={{ padding: '24px 32px' }}>
          <p className="text-sectionTitle">ALL FEEDBACK</p>
          {this.renderFeedbackMain()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { allFeedback } = state.feedback;
  const { auth } = state;
  return { allFeedback, auth, site: auth.site };
};

export default connect(mapStateToProps, { feedbackLoaded, removeFeedback })(ListFeedback);
