import { clientName } from '../config';

// deprecated
export const setChatUser = (auth) => {
  return;
};

// deprecated
export const openChat = () => {
  return;
};

// deprecated
export const hideChat = () => {
  return;
};
