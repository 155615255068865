import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Table, FormControl } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { userActions, typeActions } from '../../webapi';
import { usersUpdate } from '../../actions';
import { Button, OverlayPage, OverlayPageContents, OverlayPageSection, OverlayPageBottomButtons, CheckBox } from '../../components';

import Papa from 'papaparse';
import { requiresEmail } from '../../config';

class BulkImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      success: false,

      PageError: null,

      uploadingFile: false,
      types: [],
      csvData: [],
      sendWelcome: false,

      fileError: false,

      successResult: null,
    };
  }

  componentDidMount() {
    this.getUserTypes();
  }

  getUserTypes() {
    typeActions
      .getUserTypes(this.props.auth.site)
      .then((res) => {
        this.setState({
          types: _.sortBy(res.data, (t) => {
            return t.permissions ? t.permissions.length : 0;
          }),
        });
      })
      .catch((error) => {});
  }

  handleSubmit() {
    const { csvData, fileError, submitting, sendWelcome } = this.state;

    if (_.isEmpty(csvData) || fileError) return;
    if (submitting) return;

    this.setState({ submitting: true });

    userActions
      .bulkNewUser(csvData, this.props.auth.site, sendWelcome)
      .then((res) => {
        console.log('return response from bulk upload');
        console.log(res);
        this.setState({
          success: true,
          submitting: false,
          successResult: res,
        });
        this.props.usersUpdate(this.props.auth.site);
      })
      .catch((error) => {
        console.log('error response');
        console.log(error);
        this.setState({
          success: false,
          PageError: 'Something when wrong, please try again.',
        });
      });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handeOnPhoneChange(value) {
    this.setState({
      userPhone: value,
    });
  }

  selectUpType(type) {
    this.setState({ uploadOption: type });
  }

  renderSubmit() {
    return (
      <div className="marginTop-8 marginBottom-8">
        <CheckBox
          id="sendWelcome"
          label="Send welcome email/text for new users"
          isActive={this.state.sendWelcome}
          onChange={() => this.setState({ sendWelcome: !this.state.sendWelcome })}
        />

        {this.state.submitting ? (
          <Button buttonType="secondary">Saving...</Button>
        ) : (
          <Button inline buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive>
            Upload
          </Button>
        )}
      </div>
    );
  }

  renderFails(list) {
    return list.map((ev, index) => {
      if (!ev.successful) {
        return (
          <div key={index} style={{ fontWeight: 'bold' }}>
            <span style={{ color: 'red' }}>Failed to create:</span> {ev.user.Name}
          </div>
        );
      }
      return null;
    });
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <div>
        <div className="padding-60 background-success flex flex-column flex-center">
          <div className="fontHeavy fontSize-36 text-white">Success!</div>
          <div className="fontMedium fontSize-20 text-white marginTop-32 marginBottom-24">User List uploaded successfully.</div>
          <div className="fontMedium fontSize-16 text-white marginTop-24 marginBottom-32">
            You can monitor the user generation process
            <Link to={`/usershub/bulkuploads`}>{' here'}</Link>
          </div>
          <Button
            buttonType="outlined"
            isActive
            style={{ marginTop: 16 }}
            onClick={() => {
              window.history.back();
            }}
          >
            Go to home
          </Button>
        </div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          {this.state.successResult && this.state.successResult.data.results && (
            <div>{this.renderFails(this.state.successResult.data.results)}</div>
          )}
        </div>
      </div>
    );
  }

  renderError() {
    if (this.state.PageError) {
      return <div style={{ fontWeight: 'bold', color: 'red', fontSize: 20 }}>{this.state.PageError}</div>;
    }
    return null;
  }

  setCSV(data) {
    const newData = [...data];

    _.forEach(newData, (item, index) => {
      let RowIsEmpty = true;
      Object.keys(item).forEach((key) => {
        item[key] = _.trim(item[key]);
        if (_.isEmpty(key)) {
          delete item[key];
        } else if (!_.isUndefined(item[key]) && !_.isEmpty(item[key])) {
          RowIsEmpty = false;
        }
      });

      item.RowIsEmpty = RowIsEmpty;

      if (!_.isEmpty(item['Phone Number']) && _.isEmpty(item.Phone)) {
        item.Phone = item['Phone Number'];
        delete item['Phone Number'];
      }

      if (!_.isEmpty(item['User Type']) && _.isEmpty(item.Type)) {
        item.Type = item['User Type'];
        delete item['User Type'];
      }

      if (_.isEmpty(item.Phone)) {
        delete item.Phone;
      } else {
        if (item.Phone.startsWith('+')) {
          item.Phone = String(item.Phone).replace(/[^\d]/g, '');
          item.Phone = `+${item.Phone}`;
        } else {
          item.Phone = String(item.Phone).replace(/[^\d]/g, '');
          if (item.Phone.startsWith('0')) item.Phone = item.Phone.substring(1);
          if (item.Phone.startsWith('61')) item.Phone = item.Phone.substring(2);
          item.Phone = `+61${item.Phone}`;
        }
      }

      if (!_.isEmpty(item.Type)) {
        const type = _.find(this.state.types, (t) => {
          return t.displayName === item.Type || t.typeName === item.Type;
        });
        delete item.Type;
        if (type) {
          item.Type = type.typeName;
          item.TypeDisplay = type.displayName;
        }
      }

      if (_.isEmpty(item.Type)) {
        const type = _.find(this.state.types, (t) => {
          return t.category === 'resident';
        });
        delete item.Type;
        if (type) {
          item.Type = type.typeName;
          item.TypeDisplay = type.displayName;
        }
      }

      if (_.isEmpty(item.Email)) {
        delete item.Email;
      } else {
        item.Email = _.trim(item.Email.toLowerCase());
      }

      item.Name = _.trim(item.Name);

      if (_.isEmpty(item.Unit)) {
        if (!_.isEmpty(item.Address)) {
          item.Unit = _.trim(item.Address);
        } else {
          delete item.Unit;
        }
      }

      if (_.isEmpty(item.Birthday)) {
        delete item.Birthday;
      } else {
        let birthday = moment(item.Birthday, _.includes(item.Birthday, '/') ? 'DD/MM/YYYY' : 'YYYY-MM-DD');
        if (birthday.isValid()) {
          item.Birthday = birthday.format('YYYY-MM-DD');
        } else {
          delete item.Birthday;
        }
      }

      if (_.isUndefined(item.VisibleInApp)) {
        delete item.VisibleInApp;
      } else {
        item.VisibleInApp = item.VisibleInApp !== 'FALSE';
      }

      item.Location = this.props.auth.site;

      if (_.isEmpty(item.Tags)) {
        delete item.Tags;
      }
    });

    this.setState({
      csvData: _.filter(newData, (ev) => {
        return !ev.RowIsEmpty;
      }),
    });
  }

  handleFileChange(event) {
    const file = event.target.files[0];

    var self = this;

    Papa.parse(file, {
      header: true,
      keepEmptyRows: false,
      skipEmptyLines: true,
      complete: function (results, file) {
        self.setCSV(results.data);
        self.setState({ fileError: false });
      },
      error: function () {
        console.log('Parsing fail:');
        self.setState({ csvData: [], fileError: true });
      },
    });
  }

  isEmail(email) {
    const atpos = email.indexOf('@');
    const dotpos = email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
      return false;
    }
    return true;
  }

  renderCSVData() {
    if (_.isEmpty(this.state.csvData)) {
      return null;
    }
    return this.state.csvData.map((ev, index) => {
      if (ev != null) {
        return (
          <tr key={index}>
            <td style={{ backgroundColor: 'transparent' }}>{ev.Name}</td>
            <td style={{ backgroundColor: 'transparent' }}>{ev.Phone}</td>
            <td
              style={{
                backgroundColor:
                  requiresEmail && (_.isEmpty(ev.Email) || (!_.isEmpty(ev.Email) && !this.isEmail(ev.Email)))
                    ? 'rgba(255, 158, 158, 1)'
                    : 'transparent',
              }}
            >
              {ev.Email}
            </td>
            <td style={{ backgroundColor: _.isEmpty(ev.Unit) && ev.TypeDisplay === 'Resident' ? 'rgba(255, 158, 158, 1)' : 'transparent' }}>
              {ev.Unit}
            </td>
            <td style={{ backgroundColor: 'transparent' }}>
              {!_.isEmpty(ev.Birthday) ? moment(ev.Birthday, 'YYYY-MM-DD').format('D MMM YYYY') : ''}
            </td>
            <td style={{ backgroundColor: _.isEmpty(ev.Type) ? 'rgba(255, 158, 158, 1)' : 'transparent' }}>{ev.TypeDisplay}</td>
            <td style={{ backgroundColor: 'transparent' }}>{ev.Tags}</td>
            <td style={{ backgroundColor: 'transparent' }}>{ev.VisibleInApp || _.isUndefined(ev.VisibleInApp) ? 'TRUE' : 'FALSE'}</td>
          </tr>
        );
      }
      return null;
    });
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderSuccess()}
            {!this.state.success && (
              <div className="padding-60 paddingVertical-40">
                <p className="text-sectionTitle">IMPORT USERS FROM CSV</p>

                {this.state.uploadingFile ? (
                  <p>Uploading file...</p>
                ) : (
                  <div>
                    <FormControl type="file" onChange={(e) => this.handleFileChange(e)} />
                  </div>
                )}

                {this.state.csvData.length > 0 && <div style={{ marginTop: 20 }}>{this.renderSubmit()}</div>}

                <Table
                  className={`plussBorderedTable ${!_.isEmpty(this.state.csvData) ? 'plussBorderedTable--notEmpty' : ''}`}
                  style={{ minWidth: '100%', marginTop: 30 }}
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                      <th>Address</th>
                      <th>Birthday</th>
                      <th>User Type</th>
                      <th>Tags</th>
                      <th>Visible in App</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderCSVData()}</tbody>
                </Table>
              </div>
            )}
            <div className="padding-60 paddingVertical-40">{this.renderError()}</div>
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>
          <Button
            inline
            buttonType="tertiary"
            onClick={() => {
              window.history.back();
            }}
            isActive
          >
            Back
          </Button>
        </OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { usersUpdate })(withRouter(BulkImport));
