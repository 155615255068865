import React, { Component } from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { validateAccess } from '../../session';
import { facilityActions } from '../../webapi';
import { facilitiesLoaded, removeFacility } from '../../actions';
import { InputGroup } from '../../components';
import { COLOUR_BRANDING_OFF, COLOUR_BRANDING_MAIN } from '../../js';
import { CONST_STRINGS } from '../../config';
import { Text } from '../../components/text';

class ListFacilities extends Component {
  state = {
    facilities: [],
    sortColumn: 'Title', // column to sort by
    sortDesc: false, // if true, sort descending rather than ascending
    now: moment.utc(),
    search: '',
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
    this.loadFacilities();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    const facilities = [];

    props.facilities.forEach((facility) => {
      if (facility != null && !facility.Deleted) {
        facilities.push(facility);
      }
    });

    this.setState({ facilities });
  }

  handleCheckChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.checked;
    this.setState(stateChange);
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  removeFacility(facility) {
    if (window.confirm(`Are you sure you want to delete ${facility.Title}?`)) {
      this.props.removeFacility(facility.RowId);
      facilityActions
        .removeFacility(this.props.auth.site, facility.RowId)
        .then((res) => {
          // action
        })
        .catch((res) => {
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  loadFacilities(time) {
    this.setState({
      loading: true,
    });

    facilityActions
      .getFacilities(this.props.auth.site, true)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.facilitiesLoaded(res.data);
        }
      })
      .catch((res) => {
        this.setState({ loading: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  updateFacilityStatus = (facility, isActive) => {
    facilityActions.updateStatus(facility.RowId, isActive).then((res) => {
      const newFacility = _.cloneDeep(facility);
      newFacility.Inactive = !isActive;
      this.props.facilitiesLoaded([newFacility]);
    });
  };

  renderStatus(facility) {
    if (facility.Inactive) {
      return (
        <div
          className="tagWrapper tagWrapper--inactive"
          onClick={() => {
            this.updateFacilityStatus(facility, true);
          }}
        >
          <p className="tagWrapper__content tagWrapper__text">Inactive</p>
          <p className="tagWrapper__hoverContent tagWrapper__text">Activate</p>
        </div>
      );
    }
    return (
      <div
        className="tagWrapper tagWrapper--active"
        onClick={() => {
          this.updateFacilityStatus(facility, false);
        }}
      >
        <p className="tagWrapper__content tagWrapper__text">Active</p>
        <p className="tagWrapper__hoverContent tagWrapper__text">Deactivate</p>
      </div>
    );
  }

  renderFull(reps) {
    if (!reps) {
      return null;
    }
    const filledEvents = _.filter(reps, (rep) => {
      return rep.TicketCount && rep.AttendanceCount && rep.AttendanceCount >= rep.TicketCount;
    });
    if (filledEvents.length === 0) {
      return null;
    }
    return (
      <span
        style={{
          backgroundColor: COLOUR_BRANDING_OFF,
          marginLeft: 15,
          paddingTop: 3,
          paddingBottom: 3,
          paddingLeft: 8,
          paddingRight: 8,
          borderRadius: 3,
          color: COLOUR_BRANDING_MAIN,
          fontSize: 11,
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
        }}
      >
        {filledEvents.length} full event{filledEvents.length > 1 ? 's' : ''}
      </span>
    );
  }

  renderFacilities(source) {
    source = _.filter(source, (facility) => {
      if (!facility || facility.Deleted) {
        return false;
      }
      if (facility.Title.toLowerCase().indexOf(this.state.search.toLowerCase()) === -1) {
        return false;
      }
      return true;
    });

    return source.map((facility) => {
      if (facility != null) {
        return (
          <tr key={facility.RowId}>
            <td className="table-TitleColumn">
              {validateAccess(this.props.auth.site, 'facilities', this.props.auth) ? (
                <Link to={`/facilities/facility/${facility.RowId}`}>
                  <span>{facility.Title}</span>
                </Link>
              ) : (
                <div>
                  <span>{facility.Title}</span>
                </div>
              )}
              {this.renderFull(facility.RepeatedTimes)}
            </td>
            <td>{facility.Location}</td>
            <td>{this.renderStatus(facility)}</td>
            <td className="table-options">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {validateAccess(this.props.auth.site, 'facilities', this.props.auth) && (
                  <Link to={`/facilities/analytics/${facility.RowId}`}>
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name="bar-chart" />
                  </Link>
                )}
                {validateAccess(this.props.auth.site, 'facilities', this.props.auth) && (
                  <Link to={`/facilities/facility/${facility.RowId}`}>
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name="pencil" />
                  </Link>
                )}
                {validateAccess(this.props.auth.site, 'facilities', this.props.auth) && (
                  <a
                    onClick={() => {
                      this.removeFacility(facility);
                    }}
                  >
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="minus-circle" />
                  </a>
                )}
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  renderSort(col) {
    if (col !== this.state.sortColumn) {
      return null;
    }
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  sortIsActive(col) {
    if (col !== this.state.sortColumn) {
      return '';
    }
    return ' table--columnActive';
  }

  renderView(source) {
    return (
      <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
        <thead>
          <tr>
            <th
              className={`${this.sortIsActive('Title')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.sortByCol('Title');
              }}
            >
              Facility{this.renderSort('Title')}
            </th>
            <th
              className={`${this.sortIsActive('Location')}`}
              style={{ cursor: 'pointer', width: 150 }}
              onClick={() => {
                this.sortByCol('Location');
              }}
            >
              Location{this.renderSort('Location')}
            </th>
            <th
              className={`${this.sortIsActive('Status')}`}
              style={{ cursor: 'pointer', width: 100 }}
              onClick={() => {
                this.sortByCol('Status');
              }}
            >
              Status{this.renderSort('Status')}
            </th>
            <th style={{ width: 70 }} />
          </tr>
        </thead>
        <tbody>{this.renderFacilities(source)}</tbody>
      </Table>
    );
  }

  renderEmpty() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <Text type="h3">There are no facilities</Text>
        </div>
      </div>
    );
  }

  renderContent() {
    let source = _.sortBy(this.state.facilities, (facility) => {
      if (this.state.sortColumn === 'Status') {
        return !!facility.Inactive;
      }
      if (this.state.sortColumn !== 'StartTime') {
        return facility[this.state.sortColumn];
      }
      if (!_.isEmpty(facility.RepeatedTimes)) {
        return facility.RepeatedTimes[0].Time;
      }
      return facility.StartTime;
    });
    if (this.state.sortDesc) {
      source.reverse();
    }
    // source = []
    if (_.isEmpty(source)) {
      return this.renderEmpty();
    }
    return this.renderView(source);
  }

  render() {
    return (
      <div style={{ minWidth: '100%' }}>
        <Row className="show-grid">
          <Col xs={6}>
            <InputGroup
              id={`search`}
              type="text"
              label="Search"
              placeholder={`Search by ${CONST_STRINGS.FACILITIES} title.`}
              value={this.state.search}
              onChange={(e) => this.handleChange(e)}
              autoComplete={false}
            />
          </Col>
        </Row>
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { facilities } = state.facilities;
  const { auth } = state;
  return {
    facilities,
    auth,
  };
};

export default connect(mapStateToProps, { facilitiesLoaded, removeFacility })(ListFacilities);
