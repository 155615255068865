import { getUrl } from './helper';
import { authedFunction, unauthedFunction } from '../session';
import { COLOUR_BRANDING_APP } from '../js';
import { extensionHiddenSections, extensionWidgetOptions } from '../config/features';

export const typeActions = {
  getSubdomains: () => {
    return authedFunction({
      method: 'GET',
      url: getUrl('types', 'subdomains/get'),
    });
  },
  getSubdomain: (key) => {
    return unauthedFunction({
      method: 'GET',
      url: getUrl('types', `subdomains/get/${key}`),
    });
  },
  updateSubdomain: (key, background) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('types', 'subdomains/update'),
      data: {
        Key: key,
        Background: background,
      },
    });
  },
  getSites: () => {
    let url = getUrl('types', 'getsites');
    return authedFunction({
      method: 'POST',
      url,
    });
  },
  getSite: async (id) => {
    let url = getUrl('types', `getsite`, { site: id });
    return authedFunction({
      method: 'GET',
      url,
    });
  },
  getUserTypes: (site) => {
    let url = getUrl('types', 'getusertypes');
    return authedFunction({
      method: 'POST',
      url,
      data: {
        site,
      },
    });
  },
  setTypePermissions: (type, adminId) => {
    let url = getUrl('types', 'setusertypepermissions');
    return authedFunction({
      method: 'POST',
      url,
      data: {
        type,
      },
    });
  },
  deleteUserType: (site, type) => {
    let url = getUrl('types', 'usertype/delete');
    return authedFunction({
      method: 'POST',
      url,
      data: {
        site,
        id: type,
      },
    });
  },
  createNewSite: (siteName, options) => {
    let url = getUrl('types', 'setNewSite');
    const request = {
      method: 'POST',
      url,
      data: {
        siteName,
        ...options,
      },
    };
    return authedFunction(request);
  },
  signUpNewSite: (site, user) => {
    let url = getUrl('types', 'site/signup');
    const request = {
      method: 'POST',
      url,
      data: { site, user },
    };
    return unauthedFunction(request);
  },
  getInterface: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('types', `interfaces/get/${id}`),
    });
  },
  getInterfaces: (site, type) => {
    const q = { site };
    if (type) {
      q.type = type;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('types', 'interfaces/get', q),
    });
  },
  deleteInterface: (id) => {
    const request = {
      method: 'POST',
      url: getUrl('types', 'interfaces/remove'),
      data: { id },
    };
    return authedFunction(request);
  },
  saveInterface: (site, type, title, settings, id) => {
    const request = {
      method: 'POST',
      url: getUrl('types', 'interfaces/save'),
      data: { site, type, title, settings, id },
    };
    return authedFunction(request);
  },
  attachInterface: (site, typeName, interfaceId) => {
    const request = {
      method: 'POST',
      url: getUrl('types', 'interfaces/attach'),
      data: { site, typeName, interfaceId },
    };
    return authedFunction(request);
  },
  getSiteBranding: (site) => {
    const request = {
      method: 'GET',
      url: getUrl('types', 'branding/get', { site }),
    };
    return unauthedFunction(request);
  },
  generateSiteBranding: (url) => {
    const request = {
      method: 'GET',
      url: getUrl('types', 'branding/generate', { url }),
    };
    return unauthedFunction(request);
  },
  editSiteBranding: (siteName, branding) => {
    let url = getUrl('types', 'editsitebranding');
    const request = {
      method: 'POST',
      url,
      data: {
        siteName,
        branding,
      },
    };
    return authedFunction(request);
  },
  editSiteSettings: (site, values) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('types', 'site/edit'),
      data: {
        Id: site,
        ...values,
      },
    });
  },
  getDefaultTVSettings: () => {
    return {
      widgets: [],
    };
  },
  getDefaultTabSettings: () => {
    return [
      {
        key: 'tab0',
        tabTitle: 'Home',
        type: 'home',
        isEnabled: true,
        widgets: [],
        widgetOptions: [],
      },
    ];
  },
  getDefaultSiteSettings: (isNew, fromTemplate, signup) => {
    // console.log(isNew, fromTemplate, signup);
    if (isNew || signup) {
      const settings = {
        isNew: true,
        profile: false,
        infoPages: false,
        maps: false,
        surveys: false,
        importantContacts: false,
        feedback: false,
        phonePeople: true,
        peopleFilter: false,
        visitors: true,
        useGlobalEvents: false,
        useEventImage: true,
        showWarnings: false,
        whatsOnDatePicker: false,
        whatsOnEmptyDates: false,
        eventAttendance: false,
        eventComments: false,
        eventNotifications: false,
        serviceNotifications: false,
        facilityNotifications: false,
        surveyNotifications: false,
        offerNotifications: false,
        tabTitles: false,
        tabSettings: typeActions.getDefaultTabSettings(),
        isEditingTitle: {
          tab1: false,
          tab2: false,
          tab3: false,
        },
        isEditingWidgetTitle: null,
        selectedTab: 'tab0',
        colour: COLOUR_BRANDING_APP,
        headerType: 'gradient',
        footerType: 'white',
        logo: null,
        siteNameInput: '',
      };
      extensionHiddenSections.forEach((h) => {
        if (h && h.key) settings[h.key] = h.defaultNew;
      });
      extensionWidgetOptions.forEach((o) => {
        if (o && o.optionKey && o.defaultNew !== undefined) settings[o.optionKey] = o.defaultNew;
      });
      if (fromTemplate) {
        settings.selectedTab = 'tab0';
      }
      return settings;
    }
    const defaultSettings = {
      whatsOn: false,
      marketplace: false,
      people: false,
      more: false,
      profile: false,
      infoPages: false,
      maps: false,
      surveys: false,
      importantContacts: false,
      feedback: false,
      phonePeople: true,
      peopleFilter: false,
      visitors: true,
      useGlobalEvents: false,
      useEventImage: true,
      showWarnings: false,
      whatsOnDatePicker: false,
      whatsOnEmptyDates: false,
      eventAttendance: false,
      eventComments: false,
      eventNotifications: false,
      serviceNotifications: false,
      facilityNotifications: false,
      surveyNotifications: false,
      offerNotifications: false,
      tabTitles: false,
      tabSettings: typeActions.getDefaultTabSettings(),
      selectedTab: 'tab0',
      colour: COLOUR_BRANDING_APP,
      headerType: 'white',
      footerType: 'white',
      logo: null,
      isEditingTitle: {
        tab1: false,
        tab2: false,
        tab3: false,
      },
      isEditingWidgetTitle: null,
      siteNameInput: '',
    };
    extensionHiddenSections.forEach((h) => {
      if (h && h.key) defaultSettings[h.key] = h.default;
    });
    extensionWidgetOptions.forEach((o) => {
      if (o && o.optionKey && o.default !== undefined) defaultSettings[o.optionKey] = o.default;
    });
    return defaultSettings;
  },
};
