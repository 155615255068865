import React, { useState, useEffect } from 'react';
import { Button, ExportCsvPopup, Text, StatBox } from '..';
import { faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { analyticsActions } from '../../webapi';
import { countActivities } from '../../js';
import { validateAccess } from '../../session';
import moment from 'moment';

const getInitialState = () => ({
  alerts: 0,
  prevAlerts: 0,
  alertsRead: 0,
  prevAlertsRead: 0,
  isLoading: true,
});

// AlertAnalytics Component
const AlertAnalytics = ({ startTime, endTime, auth, prevText, dayCount }) => {
  const [analyticsData, setAnalyticsData] = useState(getInitialState());
  const [isExportOpen, setIsExportOpen] = useState(false);

  const hasAccess = validateAccess(auth.site, 'alerts', auth);
  if (!hasAccess) {
    return null;
  }

  const exportColumns = [
    { label: 'Select All', key: '' },
    { label: 'Start Date', key: 'startDate' },
    { label: 'End Date', key: 'endDate' },
    { label: 'Alerts', key: 'alerts' },
    { label: 'Alerts Read', key: 'alertsRead' },
  ];

  useEffect(() => {
    getData();
  }, [startTime, endTime]);

  const getData = async () => {
    setAnalyticsData(getInitialState());
    // Load analytics data here using startTime and endTime
    const timeDifference = endTime - startTime;
    const [currentStatsResponse, prevStatsResponse] = await Promise.all([
      analyticsActions.getAggregateEntityStats(auth.site, 'alert', startTime, endTime, true),
      analyticsActions.getAggregateEntityStats(auth.site, 'alert', startTime - timeDifference, startTime, true),
    ]);

    const data = {
      alerts: countActivities(currentStatsResponse.data, 'Alert', 'unique'),
      prevAlerts: countActivities(prevStatsResponse.data, 'Alert', 'unique'),
      alertsRead: countActivities(currentStatsResponse.data, 'AlertRead', 'total'),
      prevAlertsRead: countActivities(prevStatsResponse.data, 'AlertRead', 'total'),
      isLoading: false,
    };
    setAnalyticsData(data);
  };

  const isReadyToOpenCSV = () => {
    return !analyticsData.isLoading;
  };

  const getExportSource = () => {
    return [
      {
        startDate: moment(startTime + 1).format('D-MM-YYYY'),
        endDate: moment(endTime).format('D-MM-YYYY'),
        alerts: analyticsData.alerts,
        alertsRead: analyticsData.alertsRead,
      },
    ];
  };

  const csvPopup = () => {
    if (!isExportOpen) {
      return null;
    }
    const source = getExportSource();
    return (
      <ExportCsvPopup
        onClose={() => {
          setIsExportOpen(false);
        }}
        columns={exportColumns}
        source={source}
        filename={`alertanalytics_${source[0].startDate}_${source[0].endDate}.csv`}
      />
    );
  };

  return (
    <div className="dashboardSection">
      {csvPopup()}
      <div>
        <Text type="h4" className="inlineBlock marginRight-40">
          Alerts
        </Text>

        <Button
          inline
          buttonType="primaryAction"
          onClick={() => {
            if (!isReadyToOpenCSV()) return;
            setIsExportOpen(true);
          }}
          isActive={isReadyToOpenCSV()}
          leftIcon="file-code-o"
        >
          Export CSV
        </Button>
      </div>
      <div className="analyticsSection dashboardSection_content">
        <StatBox
          title="Alerts"
          icon={faExclamationTriangle}
          value={analyticsData.alerts}
          previousValue={analyticsData.prevAlerts}
          prevText={prevText}
          viewGraphLink={`/chart?entity=alert&startTime=${startTime}&endTime=${endTime}&key=Alert&countType=unique&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Alerts Read"
          icon={faCheckCircle}
          value={analyticsData.alertsRead}
          previousValue={analyticsData.prevAlertsRead}
          prevText={prevText}
          viewGraphLink={`/chart?entity=alert&startTime=${startTime}&endTime=${endTime}&key=AlertRead&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

const toExport = connect(mapStateToProps, {})(AlertAnalytics);
export { toExport as AlertAnalytics };
