import _ from 'lodash';
import { authedFunction } from '../session';
import { getUrl } from './helper';
import { automationConfig } from '../config';

export const automationActions = {
  getEnvironments: (repo) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getEnvironments', { repo }),
    });
  },
  createLiveEnvironment: (repo, name, base) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'createLiveEnvironment/create'),
      data: { repo, name, base },
    });
  },
  hasLiveEnvironment: (repo, name) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'createLiveEnvironment/exist'),
      data: { repo, name },
    });
  },
  deleteLiveEnvironment: (repo, name) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'deleteLiveEnvironment'),
      data: { repo, name },
    });
  },
  getEnvironmentConfigs: (repo, environment) => {
    return authedFunction({
      method: 'GET',
      url: encodeURI(getUrl('automation', 'getEnvironmentConfigs', { repo, environment })),
    });
  },
  getEnvironmentSites: (clientCode) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performEnvironmentTask/sites'),
      data: { clientCode },
    });
  },
  getAppStatusList: () => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performEnvironmentTask/appstatus'),
    });
  },
  resetAppStatus: (rowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performEnvironmentTask/resetappstatus'),
      data: { rowId },
    });
  },
  getBranchConfig: (repo, branch) => {
    return authedFunction({
      method: 'GET',
      url: encodeURI(getUrl('automation', 'getBranchConfig', { repo, branch })),
    });
  },
  updateEnvironmentConfigs: (repo, environment, configs) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'updateEnvironmentConfigs'),
      data: { repo, environment, configs },
    });
  },
  startDeployment: (data = { environment: '', repo: '', stack: '', merge: false }) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performBuildTask/start'),
      data,
    });
  },
  stopDeployment: (repo, buildId, pipelineId = '') => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performBuildTask/stop'),
      data: { repo, buildId, pipelineId },
    });
  },
  restartDeployment: (buildId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performBuildTask/restart'),
      data: { buildId },
    });
  },
  getDeployments: (repo) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getDeployments', { repo }),
    });
  },
  getAwsCoreStacks: (environment) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getAwsStacks', { environment, type: 'core' }),
    });
  },
  getAwsStacks: (environment) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getAwsStacks', { environment }),
    });
  },
  getWhiteLabel: (clientCode, rowId = '') => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWhiteLabelTask/get'),
      data: { clientCode, rowId },
    });
  },
  getAppInfo: (clientCode) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWhiteLabelTask/getapp', null, {
        url: automationConfig.whiteLabelUrl,
        stage: automationConfig.whiteLabelStage,
      }),
      data: { clientCode },
    });
  },
  getWhiteLabelList: () => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWhiteLabelTask/list'),
    });
  },
  updateWhiteLabel: (changed, old) => {
    // console.log('updateWhiteLabel', { changed, old });
    const changedProps = changed.RowId ? Object.keys(changed).filter((prop) => !_.isEqual(changed[prop], old[prop])) : Object.keys(changed);
    const update = { RowId: changed.RowId };
    if (changedProps.length > 0) changedProps.forEach((prop) => (update[prop] = changed[prop]));
    // console.log('updateWhiteLabel - update', update);

    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWhiteLabelTask/update'),
      data: { update },
    });
  },
  deleteWhiteLabel: (rowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWhiteLabelTask/delete'),
      data: { rowId },
    });
  },
  getBuildList: () => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getBuildQueue'),
    });
  },
  getBuild: (buildId) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getBuildQueue', { buildId }),
    });
  },
  createAWSAccount: (rowId, name, email) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'createAWSAccount'),
      data: { rowId, name, email },
    });
  },
  setupAWS: (rowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'setupAWS'),
      data: { rowId },
    });
  },
  restoreLambdaLayers: (rowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAWSTask/restoreLayers'),
      data: { rowId },
    });
  },
  ensureLambdaConcurrencyQuota: (rowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAWSTask/ensureQuotaLambda'),
      data: { rowId },
    });
  },
  getSourceImage: (repo, branch, path) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performRepositoryTask/image'),
      data: { repo, branch, path },
    });
  },
  setupWeb: (rowId, action) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', `performWebTask/${action}`),
      data: { rowId },
    });
  },
  initialiseTestUsers: (rowId, clientCode) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWebTask/initUsers'),
      data: { rowId, clientCode },
    });
  },
  resetTestUser: (rowId, clientCode, emailPrefix) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWebTask/resetUser'),
      data: { rowId, clientCode, emailPrefix },
    });
  },
  getAppVersion: (rowId, appClientCode, platform) => {
    const endPoint = platform === 'ios' ? 'performAppTaskIOS' : 'performAppTaskAndroid';
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', `${endPoint}/getVersion`),
      data: { rowId, appClientCode },
    });
  },
  updateAppVersion: (appClientCode, appVersion) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performEnvironmentTask/updateVersion'),
      data: { appClientCode, appVersion },
    });
  },
  startAppBuild: (rowId, appClientCode, platform, submit = true, whatsNew = '') => {
    const endPoint = platform === 'ios' ? 'performAppTaskIOS' : 'performAppTaskAndroid';
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', `${endPoint}/startBuild`),
      data: { rowId, appClientCode, submit, whatsNew },
    });
  },
  checkAppBuild: (rowId, appClientCode, platform) => {
    const endPoint = platform === 'ios' ? 'performAppTaskIOS' : 'performAppTaskAndroid';
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', `${endPoint}/checkBuild`),
      data: { rowId, appClientCode },
    });
  },
  stopAppBuild: (rowId, appClientCode, platform) => {
    const endPoint = platform === 'ios' ? 'performAppTaskIOS' : 'performAppTaskAndroid';
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', `${endPoint}/stopBuild`),
      data: { rowId, appClientCode },
    });
  },
  submitForReview: (rowId, appClientCode, platform, whatsNew, appVersion) => {
    const endPoint = platform === 'ios' ? 'performAppTaskIOS' : 'performAppTaskAndroid';
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', `${endPoint}/submitReview`),
      data: { rowId, appClientCode, whatsNew, appVersion },
    });
  },
  setupAppStoreApiKey: (rowId, appClientCode, appStoreApiKey) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskIOS/appStoreApiKey'),
      data: { rowId, appClientCode, appStoreApiKey },
    });
  },
  saveAppStoreApiInfo: (rowId, appClientCode, issuerId, keyId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskIOS/appStoreApiInfo'),
      data: { rowId, appClientCode, issuerId, keyId },
    });
  },
  saveAppStoreAppId: (rowId, appClientCode) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskIOS/appStoreAppId'),
      data: { rowId, appClientCode },
    });
  },
  setupAppStoreBundleId: (rowId, appClientCode) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskIOS/bundleId'),
      data: { rowId, appClientCode },
    });
  },
  beginScreenshotUpload: (rowId, appClientCode, screenshotType, fileName, fileSize) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskIOS/beginScreenshot'),
      data: { rowId, appClientCode, screenshotType, fileName, fileSize },
    });
  },
  endScreenshotUpload: (rowId, appClientCode, screenshotType, screenshotIndex, screenshotId, fileChecksum) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskIOS/endScreenshot'),
      data: { rowId, appClientCode, screenshotType, screenshotIndex, screenshotId, fileChecksum },
    });
  },
  initAppStore: (rowId, appClientCode, description, keywords, promotionalText) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskIOS/initAppStore'),
      data: { rowId, appClientCode, description, keywords, promotionalText },
    });
  },
  initPlayStore: (rowId, appClientCode, title, shortDescription, fullDescription) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskAndroid/initPlayStore'),
      data: { rowId, appClientCode, title, shortDescription, fullDescription },
    });
  },
  setupGoogleServiceAccount: (rowId, appClientCode, googleServiceAccount) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskAndroid/googleServiceAccount'),
      data: { rowId, appClientCode, googleServiceAccount },
    });
  },
  setupGoogleService: (rowId, appClientCode, googleService) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskAndroid/googleService'),
      data: { rowId, appClientCode, googleService },
    });
  },
  setupCloudMessage: (rowId, appClientCode, cloudMessageToken) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskAndroid/cloudMessage'),
      data: { rowId, appClientCode, cloudMessageToken },
    });
  },
  uploadAndroidGraphic: (rowId, appClientCode, fileType, fileIndex, file, mimeType) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskAndroid/uploadGraphic'),
      data: { rowId, appClientCode, fileType, fileIndex, file, mimeType },
    });
  },
  deleteAndroidGraphic: (rowId, appClientCode, fileType) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskAndroid/deleteGraphic'),
      data: { rowId, appClientCode, fileType },
    });
  },
  saveReleaseUrl: (rowId, appClientCode, releaseUrl) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskAndroid/releaseUrl'),
      data: { rowId, appClientCode, releaseUrl },
    });
  },
  listExtensions: (repo, branch) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performExtension/list'),
      data: { repo, branch },
    });
  },
  addExtension: (repo, branch, packageName, version, alias) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performExtension/add'),
      data: { repo, branch, packageName, version, alias },
    });
  },
  removeExtension: (repo, branch, packageName, alias) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performExtension/remove'),
      data: { repo, branch, packageName, alias },
    });
  },
  getEmailSender: (rowId, email) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAWSTask/getEmailAddress'),
      data: { rowId, email },
    });
  },
  verifyEmailSender: (rowId, email) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAWSTask/verifyEmailAddress'),
      data: { rowId, email },
    });
  },
  getEmailService: (rowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAWSTask/getEmailService'),
      data: { rowId },
    });
  },
  requestEmailService: (rowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAWSTask/requestEmailService'),
      data: { rowId },
    });
  },
  processAppStatus: () => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'checkAppStatus'),
    });
  },
};
