import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Text, ExportCsvPopup, StatBox } from '../';
import {
  faCalendar,
  faCalendarCheck,
  faCalendarTimes,
  faComment,
  faEye,
  faU,
  faUser,
  faUserPlus,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { analyticsActions } from '../../webapi';
import { countActivities } from '../../js';
import { validateAccess } from '../../session';

const getInitialState = () => ({
  eventReps: 0,
  prevEventReps: 0,
  attendance: 0,
  prevAttendance: 0,
  guestAttendance: 0,
  prevGuestAttendance: 0,
  pageViews: 0,
  prevPageViews: 0,
  uniquePageView: 0,
  prevUniquePageView: 0,
  eventRegistrations: 0,
  prevEventRegistrations: 0,
  eventDeregistrations: 0,
  prevEventDeregistrations: 0,
  comments: 0,
  prevComments: 0,
  isLoading: true,
});

// EventAnalytics Component
const EventAnalytics = ({ startTime, endTime, auth, prevText, dayCount }) => {
  const [analyticsData, setAnalyticsData] = useState(getInitialState());
  const [isExportOpen, setIsExportOpen] = useState(false);

  const hasAccess = validateAccess(auth.site, 'events', auth);
  if (!hasAccess) {
    return null;
  }

  const exportColumns = [
    { label: 'Select All', key: '' },
    { label: 'Start Date', key: 'startDate' },
    { label: 'End Date', key: 'endDate' },
    { label: 'Events', key: 'eventReps' },
    { label: 'Attendees', key: 'attendance' },
    { label: 'Guest Attendees', key: 'guestAttendance' },
    { label: 'Page Views', key: 'pageViews' },
    { label: 'Unique Visitors', key: 'uniquePageView' },
    { label: 'Registrations', key: 'eventRegistrations' },
    { label: 'Cancelled Registrations', key: 'eventDeregistrations' },
    { label: 'Comments', key: 'comments' },
  ];

  useEffect(() => {
    getData();
  }, [startTime, endTime]);

  const getData = async () => {
    setAnalyticsData(getInitialState());
    // Load analytics data here using startTime and endTime
    const timeDifference = endTime - startTime;
    const [currentStatsResponse, prevStatsResponse] = await Promise.all([
      analyticsActions.getAggregateEntityStats(auth.site, 'event', startTime, endTime, true),
      analyticsActions.getAggregateEntityStats(auth.site, 'event', startTime - timeDifference, startTime, true),
    ]);

    const data = {
      eventReps: countActivities(currentStatsResponse.data, 'EventRep', 'total'),
      prevEventReps: countActivities(prevStatsResponse.data, 'EventRep', 'total'),
      attendance: countActivities(currentStatsResponse.data, 'Attendance', 'total'),
      prevAttendance: countActivities(prevStatsResponse.data, 'Attendance', 'total'),
      guestAttendance: countActivities(currentStatsResponse.data, 'GuestAttendance', 'total'),
      prevGuestAttendance: countActivities(prevStatsResponse.data, 'GuestAttendance', 'total'),
      pageViews: countActivities(currentStatsResponse.data, 'PageView', 'total'),
      prevPageViews: countActivities(prevStatsResponse.data, 'PageView', 'total'),
      uniquePageView: countActivities(currentStatsResponse.data, 'UniquePageView', 'uniquearray'),
      prevUniquePageView: countActivities(prevStatsResponse.data, 'UniquePageView', 'uniquearray'),
      eventRegistrations: countActivities(currentStatsResponse.data, 'EventRegistration', 'total'),
      prevEventRegistrations: countActivities(prevStatsResponse.data, 'EventRegistration', 'total'),
      eventDeregistrations: countActivities(currentStatsResponse.data, 'EventDeregistration', 'total'),
      prevEventDeregistrations: countActivities(prevStatsResponse.data, 'EventDeregistration', 'total'),
      comments: countActivities(currentStatsResponse.data, 'Comment', 'total'),
      prevComments: countActivities(prevStatsResponse.data, 'Comment', 'total'),
      isLoading: false,
    };
    setAnalyticsData(data);
  };

  const isReadyToOpenCSV = () => {
    return !analyticsData.isLoading;
  };

  const getExportSource = () => {
    return [
      {
        startDate: moment(startTime + 1).format('D-MM-YYYY'),
        endDate: moment(endTime).format('D-MM-YYYY'),
        eventReps: analyticsData.eventReps,
        attendance: analyticsData.attendance,
        guestAttendance: analyticsData.guestAttendance,
        pageViews: analyticsData.pageViews,
        uniquePageView: analyticsData.uniquePageView,
        eventRegistrations: analyticsData.eventRegistrations,
        eventDeregistrations: analyticsData.eventDeregistrations,
        comments: analyticsData.comments,
      },
    ];
  };

  const csvPopup = () => {
    if (!isExportOpen) {
      return null;
    }
    const source = getExportSource();
    return (
      <ExportCsvPopup
        onClose={() => {
          setIsExportOpen(false);
        }}
        columns={exportColumns}
        source={source}
        filename={`eventanalytics_${source[0].startDate}_${source[0].endDate}.csv`}
      />
    );
  };

  return (
    <div className="dashboardSection">
      {csvPopup()}
      <div>
        <Text type="h4" className="inlineBlock marginRight-40">
          Events
        </Text>
        <Button
          inline
          buttonType="primaryAction"
          onClick={() => {
            if (!isReadyToOpenCSV()) return;
            setIsExportOpen(true);
          }}
          isActive={isReadyToOpenCSV()}
          leftIcon="file-code-o"
        >
          Export CSV
        </Button>
      </div>
      <div className="analyticsSection dashboardSection_content">
        <StatBox
          title="Events"
          icon={faCalendar}
          value={analyticsData.eventReps}
          previousValue={analyticsData.prevEventReps}
          prevText={prevText}
          viewGraphLink={`/chart?entity=event&startTime=${startTime}&endTime=${endTime}&key=EventRep&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Total Event Attendance"
          icon={faUsers}
          value={analyticsData.attendance}
          previousValue={analyticsData.prevAttendance}
          prevText={prevText}
          viewGraphLink={`/chart?entity=event&startTime=${startTime}&endTime=${endTime}&key=Attendance&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Guest Attendees"
          icon={faUserPlus}
          value={analyticsData.guestAttendance}
          previousValue={analyticsData.prevGuestAttendance}
          prevText={prevText}
          viewGraphLink={`/chart?entity=event&startTime=${startTime}&endTime=${endTime}&key=GuestAttendance&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Event Page Views"
          icon={faEye}
          value={analyticsData.pageViews}
          previousValue={analyticsData.prevPageViews}
          prevText={prevText}
          viewGraphLink={`/chart?entity=event&startTime=${startTime}&endTime=${endTime}&key=PageView&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Unique Event Visitors"
          icon={faUser}
          value={analyticsData.uniquePageView}
          previousValue={analyticsData.prevUniquePageView}
          prevText={prevText}
          viewGraphLink={`/chart?entity=event&startTime=${startTime}&endTime=${endTime}&key=UniquePageView&countType=uniquearray&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Event Registrations"
          icon={faCalendarCheck}
          value={analyticsData.eventRegistrations}
          previousValue={analyticsData.prevEventRegistrations}
          prevText={prevText}
          viewGraphLink={`/chart?entity=event&startTime=${startTime}&endTime=${endTime}&key=EventRegistration&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Cancelled Event Registrations"
          icon={faCalendarTimes}
          value={analyticsData.eventDeregistrations}
          previousValue={analyticsData.prevEventDeregistrations}
          prevText={prevText}
          viewGraphLink={`/chart?entity=event&startTime=${startTime}&endTime=${endTime}&key=EventDeregistration&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Event Comments"
          icon={faComment}
          value={analyticsData.comments}
          previousValue={analyticsData.prevComments}
          prevText={prevText}
          viewGraphLink={`/chart?entity=event&startTime=${startTime}&endTime=${endTime}&key=Comment&countType=total&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

const toExport = connect(mapStateToProps, {})(EventAnalytics);
export { toExport as EventAnalytics };
