import React, { Component } from 'react';
import { Header } from '../../../components';
import ManageSubdomains from './ManageSubdomains';

class ManageSubdomainsPage extends Component {
  render() {
    return (
      <div className="hub-wrapperContainer">
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentWrapper">
            <ManageSubdomains />
          </div>
        </div>
      </div>
    );
  }
}

export default ManageSubdomainsPage;
