import { extensionReducers } from '../config/features';
import { combineReducers } from 'redux';
import AlertReducer from './AlertReducer';
import AuthReducer from './AuthReducer';
import BookingReducer from './BookingReducer';
import EventReducer from './EventReducer';
import FeedbackReducer from './FeedbackReducer';
import JobsReducer from './JobsReducer';
import KeysReducer from './KeysReducer';
import LocalReducer from './LocalReducer';
import UsersReducer from './UsersReducer';
import ContactsReducer from './ContactsReducer';
import MapsReducer from './MapsReducer';
import MarketReducer from './MarketReducer';
import NavReducer from './NavReducer';
import InfoPagesReducer from './InfoPagesReducer';
import ServicesReducer from './ServicesReducer';
import MediaReducer from './MediaReducer';
import FacilityReducer from './FacilityReducer';
import DevicesReducer from './DevicesReducer';
import FoodReducer from './FoodReducer';
import FormReducer from './FormReducer';
import AutomationReducer from './AutomationReducer';
import StringsReducer from './StringsReducer';

const reducers = {
  ...extensionReducers,
  auth: AuthReducer,
  bookings: BookingReducer,
  events: EventReducer,
  feedback: FeedbackReducer,
  alerts: AlertReducer,
  jobs: JobsReducer,
  keys: KeysReducer,
  local: LocalReducer,
  users: UsersReducer,
  contacts: ContactsReducer,
  maps: MapsReducer,
  market: MarketReducer,
  nav: NavReducer,
  infoPages: InfoPagesReducer,
  services: ServicesReducer,
  media: MediaReducer,
  facilities: FacilityReducer,
  devices: DevicesReducer,
  food: FoodReducer,
  forms: FormReducer,
  automation: AutomationReducer,
  strings: StringsReducer,
};

export default combineReducers(reducers);
