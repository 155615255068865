import { getUrl } from './helper';
import { authedFunction } from '../session';

export const onboardingActions = {
  getCompletedSteps: async (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('onboarding', 'get/completedsteps', { site }),
    });
  },
};
