import { baseStage, commonAPIStage, commonAPIUrl } from '../config';
import { authedFunction, unauthedFunction } from '../session';
import { getUrl } from './helper';

export const activityActions = {
  getActivity: (site, limit) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('activity', 'get', { site, limit }),
    });
  },
  getInsightBlogPosts: () => {
    let url = getUrl('content-manager', 'get/blog');
    if (baseStage !== 'dev') {
      // hit demo api
      url = getUrl('content-manager', 'get/blog', undefined, { url: commonAPIUrl, stage: commonAPIStage });
    }
    return unauthedFunction({
      method: 'GET',
      url,
    });
  },
  getFeaturedBanners: () => {
    let url = getUrl('content-manager', 'get/banners');
    if (baseStage !== 'dev') {
      // hit demo api
      url = getUrl('content-manager', 'get/banners', undefined, { url: commonAPIUrl, stage: commonAPIStage });
    }
    return unauthedFunction({
      method: 'GET',
      url,
    });
  },
};
