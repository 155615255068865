import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Header, AddButton, HubSidebar } from '../../components';
import { validateAccess } from '../../session';
import { facilitiesLoaded, setAuth } from '../../actions';
import { facilityActions } from '../../webapi';
import ListFacilities from './ListFacilities';
import { COLOUR_BRANDING_OFF } from '../../js';
import { CONST_STRINGS } from '../../config';
import { PlussCore } from '../../config/core';

class FacilityHub extends Component {
  state = {
    selectedSection: 'all',
    loadingFacilities: false,
    facilities: [],
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
    this.loadFacilities();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    const facilities = [];

    props.facilities.forEach((ev) => {
      if (ev != null && !ev.Deleted) facilities.push(ev);
    });

    this.setState({ facilities });
  }

  loadFacilities = async () => {
    this.setState({ loadingFacilities: true });

    try {
      const res = await facilityActions.getFacilities(this.props.auth.site);
      if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
        this.props.facilitiesLoaded(res.data);
      }
    } catch (error) {
      alert('Something went wrong with the request. Please try again.');
    } finally {
      this.setState({ loadingFacilities: false });
    }
  };

  addNew = () => {
    if (validateAccess(this.props.auth.site, 'facilities', this.props.auth)) {
      this.props.history.push(`/facilities/facility`);
    }
  };

  canAddNew(isClass) {
    if (validateAccess(this.props.auth.site, 'facilities', this.props.auth)) {
      return isClass ? '' : true;
    }
    return isClass ? ' hub-sideContent-topButton--hide' : false;
  }

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? '#fff' : 'transparent';
  }

  renderStats(stat, loading) {
    if (loading) return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    return stat;
  }

  renderLeftBar() {
    const sectionItems = [];

    if (this.canAddNew()) {
      sectionItems.push({
        type: 'newButton',
        text: `New ${CONST_STRINGS.FACILITY}`,
        onClick: this.addNew,
      });
    }

    sectionItems.push({
      type: 'navItem',
      text: `View ${CONST_STRINGS.FACILITIES}`,
      icon: 'eye',
      isFontAwesome: true,
      selected: this.state.selectedSection === 'all',
      onClick: () => {
        this.setState({ selectedSection: 'all' });
      },
    });

    return (
      <HubSidebar
        sections={[
          {
            title: CONST_STRINGS.FACILITIES,
            items: sectionItems,
          },
        ]}
        helpGuide={{
          text: 'Help with Facilities',
          url: PlussCore.Urls.HelpGuide.Facilities,
        }}
      />
    );
  }

  renderRight() {
    return <ListFacilities />;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { facilities } = state.facilities;
  const { auth } = state;
  return {
    facilities,
    auth,
  };
};

export default connect(mapStateToProps, { facilitiesLoaded, setAuth })(FacilityHub);
