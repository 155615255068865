import React, { Component } from 'react';
import { Table, Form, Row, Col } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router';
import { AddButton, CheckBox } from '../../../components';
import { checkLoggedIn, validateAccess } from '../../../session';
import { setAuth, inviteCodesLoaded, inviteCodeRemoved } from '../../../actions';
import { typeActions, userActions } from '../../../webapi';
import { PageTitle } from '../../../components/text';

class InviteCodesList extends Component {
  state = {
    userTypes: [],
    loading: false,
    excludeFamily: true,
    sortColumn: 'Expiry',
    sortDesc: true,
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    this.getInviteCodes();
    this.getUserTypes();
  }

  getInviteCodes = () => {
    this.setState({ loading: true }, async () => {
      try {
        const { data } = await userActions.getInviteCodes(this.props.auth.site);
        const codes = data.map((c) => {
          return {
            ...c,
            InvitingUserName: c.InvitingUser.displayName,
          };
        });
        // console.log('getInviteCodes', codes);
        this.props.inviteCodesLoaded(codes);
        this.setState({ loading: false });
      } catch (error) {
        this.setState({ loading: false });
        console.log('getInviteCodes error', error);
      }
    });
  };

  getUserTypes = async () => {
    try {
      const { data } = await typeActions.getUserTypes(this.props.auth.site);
      this.setState({ userTypes: data }, () => {
        this.props.setAuth({ userTypes: _.cloneDeep(data) });
      });
    } catch (error) {
      console.log('getUserTypes error', error);
    }
  };

  hasLinkedType = () => {
    return _.some(this.state.userTypes, (t) => {
      return t.category === 'family';
    });
  };

  onShowNewInviteCode = () => {
    this.props.history.push(`/inviteCodes/code`);
  };

  sortByCol = (col) => {
    const { sortColumn, sortDesc } = this.state;
    if (sortColumn === col) {
      this.setState({ sortDesc: !sortDesc });
    } else {
      this.setState({ sortColumn: col, sortDesc: false });
    }
  };

  onDeleteInviteCode = async (inviteCode) => {
    if (!window.confirm(`Are you sure you want to delete invite code ${inviteCode.Code}?`)) return;

    try {
      this.props.inviteCodeRemoved(inviteCode);
      await userActions.deleteInviteCode(inviteCode.Code);
    } catch (error) {
      console.log('onDeleteInviteCode error', error);
      alert('Something went wrong with the request. Please try again.');
    }
  };

  renderNewInviteCode() {
    if (!validateAccess(this.props.auth.site, 'inviteCode', this.props.auth)) return null;

    return (
      <div className="addNewContainer">
        <AddButton onClick={this.onShowNewInviteCode} text="NEW INVITE CODE" />
      </div>
    );
  }

  renderFilters() {
    const { excludeFamily } = this.state;

    if (!this.hasLinkedType()) {
      return null;
    }

    return (
      <Form>
        <Row className="show-grid">
          <Col xs={6}>
            <CheckBox
              label={'Exclude Family Invite Code'}
              isActive={excludeFamily}
              onChange={() => this.setState({ excludeFamily: !excludeFamily })}
              style={{ marginBottom: 0 }}
            />
          </Col>
        </Row>
      </Form>
    );
  }

  renderListHeader() {
    return (
      <tr>
        <th style={{ cursor: 'pointer' }} onClick={() => this.sortByCol('Code')}>
          Code
        </th>
        <th style={{ cursor: 'pointer' }} onClick={() => this.sortByCol('UserType')}>
          Type
        </th>
        <th style={{ cursor: 'pointer' }} onClick={() => this.sortByCol('InvitingUserName')}>
          Set Up By
        </th>
        <th style={{ cursor: 'pointer' }} onClick={() => this.sortByCol('Expiry')}>
          Expiry
        </th>
        <th></th>
      </tr>
    );
  }

  getUserTypeName = (type, userType) => {
    const found = this.state.userTypes.find((ut) => ut.typeName === userType);
    const userTypeName = found ? found.displayName : _.startCase(userType);
    return type === 'custom' ? userTypeName : _.startCase(type);
  };

  getExpiryText = (expiry) => {
    const expiryDate = moment(expiry);
    const expiryDateText = expiryDate.format('DD MMMM YYYY');
    const hours = moment.duration(expiryDate.diff(moment())).asHours();
    if (hours < 0) {
      return (
        <div>
          Expired
          <br />
          {expiryDateText}
        </div>
      );
    } else if (hours < 24) {
      return `Expires ${expiryDate.fromNow()}`;
    } else {
      return expiryDateText;
    }
  };

  renderList() {
    const { excludeFamily, sortColumn, sortDesc } = this.state;
    const { inviteCodes } = this.props;
    if (_.isEmpty(inviteCodes)) return null;

    const ordered = _.orderBy(inviteCodes, sortColumn, sortDesc ? 'desc' : 'asc');
    const filtered = excludeFamily ? ordered.filter((i) => i.Type !== 'family') : ordered;
    return filtered.map((ev) => {
      if (ev != null) {
        const { Code, Type, UserType, InvitingUser, Expiry } = ev;
        return (
          <tr key={Code}>
            <td>
              <Link to={`/inviteCodes/code/${ev.Code}`}>{Code}</Link>
            </td>
            <td>{this.getUserTypeName(Type, UserType)}</td>
            <td>{InvitingUser.displayName}</td>
            <td>{this.getExpiryText(Expiry)}</td>
            <td className="table-options">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to={`/inviteCodes/code/${ev.Code}`}>
                  <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name="pencil" />
                </Link>
                {validateAccess(this.props.auth.site, 'inviteCode', this.props.auth) && (
                  <a onClick={() => this.onDeleteInviteCode(ev)}>
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="minus-circle" />
                  </a>
                )}
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  render() {
    return (
      <div className="editPermissions">
        <div className="headingSection">
          <PageTitle>Invite Codes</PageTitle>
          {this.renderNewInviteCode()}
        </div>
        {this.renderFilters()}
        <Table className="plussTable" striped bordered condensed hover style={styles.tableContainer}>
          <thead>{this.renderListHeader()}</thead>
          <tbody>{this.renderList()}</tbody>
        </Table>
      </div>
    );
  }
}

const styles = {
  tableContainer: {
    marginTop: '15px',
    minWidth: '100%',
    paddingBottom: '180px',
  },
};

const mapStateToProps = (state) => {
  const { auth, users } = state;
  return {
    auth,
    inviteCodes: users.inviteCodes,
  };
};

export default connect(mapStateToProps, { setAuth, inviteCodesLoaded, inviteCodeRemoved })(withRouter(InviteCodesList));
