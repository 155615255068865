import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { alertsUpdate, addRecentlyCreated } from '../../actions';
import {
  AudienceSelector,
  RadioButton,
  Button,
  GenericInput,
  DatePicker,
  OptionsSection,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  SuccessPopup,
} from '../../components';
import { typeActions, alertActions } from '../../webapi';
import { safeReadParams } from '../../helper';
import { Text } from '../../components/text';
import { isTVEnabled } from '../../session';
// import categories from '../../json/eventCategories.json';

class AddAlert extends Component {
  initialState = {
    locations: [],

    infoId: safeReadParams(this.props, 'infoId'),
    loading: true,

    Deleted: false,

    Title: '',
    Description: '',
    Site: '',
    Important: false,

    showDate: false,
    Expiry: moment().add(7, 'days').format('YYYY-MM-DD'),
    ExpiryText: moment().add(7, 'days').format('DD/MM/YYYY'),

    showWarnings: false,
    success: false,
    submitting: false,

    Times: [],

    isAudienceValid: true,
    isTvMode: true,
  };

  state = { ...this.initialState };

  componentDidMount() {
    // this.getServices();
    this.getSites();
    this.setState({ loading: false });
    this.props.addRecentlyCreated('alerts');
  }

  selectSite(key, e) {
    this.setState({
      Site: key,
    });
  }

  selectOption = (o) => {
    this.setState({
      selectedOption: o,
    });
  };

  updateAudienceValidation(valid) {
    this.setState({
      isAudienceValid: valid,
    });
  }

  getSites() {
    typeActions
      .getSites()
      .then((res) => {
        res.data.forEach((e) => {
          e.Title = e.siteName;
          e.Key = e.Id;
        });
        this.setState({
          locations: res.data,
        });
      })
      .catch((error) => {});
  }

  handleDateTextChange(value) {
    const newU = { ExpiryText: value };
    const m = moment(value, 'DD/MM/YYYY');

    if (m.isValid() && m.year() > 1900) {
      newU.Expiry = m.format('YYYY-MM-DD');
    }
    this.setState(newU);
  }

  handleDateChange(date) {
    var stateChange = {
      Expiry: date,
      ExpiryText: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      showDate: false,
    };
    this.setState(stateChange);
  }

  inputsDisabled() {
    if (this.state.submitting) {
      return true;
    }
    // if (this.canManageEvent()) {
    //     return false;
    // }
    // if (this.canSubmitEvent()) {
    //     return false;
    // }
    return false;
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  validateCompulsoryText() {
    if (_.isEmpty(this.state.Title)) {
      return false;
    }
    /* if (_.isEmpty(this.state.Description)) {
            return false;
        } */
    return true;
  }

  validateForm() {
    if (!this.validateCompulsoryText()) {
      return false;
    }
    if (!this.state.isAudienceValid) {
      return false;
    }
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    this.audienceSelector.getWrappedInstance().onSubmit();
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true, showWarnings: false });

    const obj = {
      Title: this.state.Title,
      Description: this.state.Description,
      Expiry: this.state.Expiry,
      AudienceType: this.audienceSelector.getWrappedInstance().getAudienceType(),
      AudienceTypeSelection: this.audienceSelector.getWrappedInstance().getAudienceTypeSelection(),
      IsImportant: this.state.Important,
      Site: this.props.auth.site,
      TVMode: this.state.isTvMode,
    };

    const excludeList = this.audienceSelector.getWrappedInstance().getExcludeList();
    if (!_.isEmpty(excludeList)) {
      obj.Exclude = excludeList;
    }
    const includeList = this.audienceSelector.getWrappedInstance().getIncludeList();
    if (!_.isEmpty(includeList)) {
      obj.Include = includeList;
    }

    alertActions
      .addAlert(obj)
      .then((res) => {
        this.setState({
          success: true,
          submitting: false,
        });
        this.props.alertsUpdate(this.props.auth.site);
      })
      .catch((res) => {
        this.setState({ submitting: false });
        console.log(res);
        alert('Something went wrong with the request. Please try again.');
      });
  }

  renderTVMode() {
    if (!isTVEnabled(this.props.auth.interfaces, 'announcements')) {
      return null;
    }
    return (
      <RadioButton
        className="marginTop-16"
        label="Do you want to publish this alert to TV Mode?"
        isActive={this.state.isTvMode}
        options={[
          { Label: 'Yes', Value: true, onChange: () => this.setState({ isTvMode: true }) },
          { Label: 'No', Value: false, onChange: () => this.setState({ isTvMode: false }) },
        ]}
      />
    );
  }

  renderPublishOptions() {
    return (
      <div className="optionsContent_bottom">
        <RadioButton
          label="Is this an important alert?"
          isActive={this.state.Important}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ Important: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ Important: false }) },
          ]}
          help="Important alerts will lock the users home screen until they dismiss this alert."
        />
        {this.renderTVMode()}
      </div>
    );
  }

  renderAudience() {
    return (
      <div className="optionsContent_bottom">
        <AudienceSelector
          updateValidation={this.updateAudienceValidation.bind(this)}
          ref={(a) => {
            this.audienceSelector = a;
          }}
          auth={this.props.auth}
          allowIncludes
          allowExcludes
          allowTags
        />
      </div>
    );
  }

  renderSelectedOption(selectedOption) {
    return (
      <div>
        <div style={{ display: selectedOption === 'audience' ? 'block' : 'none' }}>{this.renderAudience()}</div>
        <div style={{ display: selectedOption === 'publishOptions' ? 'block' : 'none' }}>{this.renderPublishOptions()}</div>
      </div>
    );
  }

  renderOptionsSection() {
    let options = [
      {
        key: 'audience',
        icon: 'audience',
        text: 'Audience',
      },
      {
        key: 'publishOptions',
        icon: 'bell',
        text: 'Publish Options',
      },
    ];

    if (_.isEmpty(options)) {
      return null;
    }

    const selectedOption = this.state.selectedOption || options[0].key;

    return (
      <OptionsSection options={options} selected={selectedOption} selectOption={this.selectOption}>
        {this.renderSelectedOption(selectedOption)}
      </OptionsSection>
    );
  }

  renderForm() {
    if (this.state.success) {
      return null;
    }
    return (
      <div>
        <div className="padding-60 paddingVertical-40">
          <Text type="formTitleLarge" className="marginBottom-24">
            New Alert
          </Text>
          {/* title */}
          <GenericInput
            id="Title"
            type="text"
            label="Title"
            placeholder="Insert title here"
            showError={() => {
              return this.state.showWarnings && _.isEmpty(this.state.Title);
            }}
            value={this.state.Title}
            onChange={(e) => this.handleChange(e)}
            isRequired
            isValid={() => {
              return !_.isEmpty(this.state.Title);
            }}
            alwaysShowLabel
          />
          {/* Description */}
          <GenericInput
            id="Description"
            label="Text"
            type="textarea"
            componentClass="textarea"
            placeholder="Insert text here"
            value={this.state.Description}
            onChange={(e) => this.handleChange(e)}
            inputStyle={{
              height: 120,
            }}
            alwaysShowLabel
          />

          {/* Expiry Date */}
          <GenericInput
            id="ExpiryText"
            label="Expiry Date"
            alwaysShowLabel
            placeholder={this.state.ExpiryText}
            value={this.state.ExpiryText}
            onChange={(e) => this.handleDateTextChange(e.target.value)}
            onClick={(e) => this.setState({ showDate: !this.state.showDate })}
            style={{ marginTop: 24 }}
            help="Alerts by default will be removed from users' notifications after one week."
          />
          {this.state.showDate && <DatePicker selectedDate={this.state.Expiry} selectDate={this.handleDateChange.bind(this)} />}
        </div>
        {this.renderOptionsSection()}
      </div>
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          {this.state.infoId == null ? 'Cancel' : 'Back'}
        </Button>
        {!this.inputsDisabled() && (
          <Button inline buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateForm()}>
            Send
          </Button>
        )}
      </div>
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`Alert has been ${this.state.infoId != null ? 'edited' : 'added'}`}
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { alertsUpdate, addRecentlyCreated })(AddAlert);
