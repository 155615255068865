import _ from 'lodash';
import React, { PureComponent } from 'react';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { ProfilePic } from '../../../../components';
import { getTabTitleFromPage, hexToRGBAstring } from '../../../../js';

class PhoneFooter extends PureComponent {
  renderHeaderContent() {
    const { headerType, tabSettings, siteName, selectedTab, mainBrandingColour, logo, auth } = this.props;
    const tab = _.find(tabSettings, (t) => t.key === selectedTab);
    const tabIndex = tabSettings.indexOf(tab);
    const tabType = tab ? tab.type : 'home';
    const inverseTextColour = headerType !== 'white';
    let logoColour = inverseTextColour ? '#fff' : mainBrandingColour;
    //TODO custom font and colour

    switch (tabType) {
      case 'home':
        return (
          <>
            <FontAwesome
              name="bell-o"
              className="fp2_phone_header_bell"
              style={{ color: inverseTextColour ? '#fff' : mainBrandingColour }}
            />
            <ProfilePic className="fp2_phone_header_profilePic" size={32} image={auth && auth.user ? auth.user.profilePic : null} />
            {logo ? (
              <div className="fp2_phone_header_logo">
                <img className="fp2_phone_header_logo_image" src={logo} />
              </div>
            ) : (
              <p className="fp2_phone_header_logoText" style={{ color: logoColour }}>
                {siteName}
              </p>
            )}
          </>
        );
      default:
        return (
          <p className="fp2_phone_header_tabTitle" style={{ color: inverseTextColour ? '#fff' : mainBrandingColour }}>
            {getTabTitleFromPage(tab, tabIndex)}
          </p>
        );
    }
  }

  render() {
    const { headerType, usePatternColour, headerPattern, patternColour, mainBrandingColour } = this.props;

    const headerStyle = {};

    switch (headerType) {
      case 'white':
        break;
      case 'block':
        headerStyle.backgroundColor = mainBrandingColour;
        break;
      case 'gradient':
        headerStyle.background = `linear-gradient(to right, ${mainBrandingColour} 0%, ${hexToRGBAstring(mainBrandingColour, 0.7)} 100%)`;
        break;
      case 'pattern':
        if (!usePatternColour) {
          headerStyle.backgroundImage = `url(${headerPattern})`;
          headerStyle.backgroundColor = mainBrandingColour;
        } else {
          headerStyle['-webkit-mask-image'] = `url(${headerPattern})`;
          headerStyle.maskImage = `url(${headerPattern})`;
          headerStyle.backgroundColor = patternColour || '#fff';
        }
        break;
      default:
        break;
    }
    return (
      <div className="fp2_phone_header" style={headerStyle}>
        {this.renderHeaderContent()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(PhoneFooter);
