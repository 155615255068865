import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Header, HubSidebar } from '../../components';

class MasterMenu extends Component {
  renderLeftBar() {
    const environmentItems = [];

    environmentItems.push({
      type: 'navItem',
      text: 'App Branding',
      // icon: 'paint-brush',
      // isFontAwesome: true,
      selected: true,
      url: '/master/branding',
    });

    environmentItems.push({
      type: 'navItem',
      text: 'Login Customiser',
      // icon: 'sign-in',
      // isFontAwesome: true,
      url: '/branding/loginpicker',
    });

    const assetItems = [];

    assetItems.push({
      type: 'navItem',
      text: 'Icons & Splash',
      // icon: 'mobile',
      // isFontAwesome: true,
      url: '/master/appassets',
    });

    assetItems.push({
      type: 'navItem',
      text: 'Store Screenshots',
      // icon: 'apple',
      // isFontAwesome: true,
      url: '/master/appstore',
    });

    return (
      <HubSidebar
        sections={[
          {
            title: 'Environment Settings',
            items: environmentItems,
          },
          {
            title: 'Asset Creator',
            items: assetItems,
          },
        ]}
      />
    );
  }

  renderRight() {
    return null;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(MasterMenu);
