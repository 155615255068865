import React, { Component } from 'react';
import { Table, Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import QRCode from 'qrcode.react';
import SignatureCanvas from 'react-signature-canvas';
import { CSVLink } from 'react-csv';
import { fileActions, formActions } from '../../webapi';
import { formSubmissionsLoaded } from '../../actions';
import { Button, InputGroup, DropdownInput, GenericInput, DatePicker, AddButton, Popup, TimePicker } from '../../components';
import { baseStorageUrl } from '../../config';
import SignInForm from './SignInForm';
import { getUTCFromTimeDatePickers } from '../../helper';

const DEFAULT_FROM = moment().add(-7, 'days');

class SignInList extends Component {
  constructor(props) {
    super(props);

    const filterDateStart = DEFAULT_FROM.startOf('day');
    const filterDateEnd = moment().endOf('day');
    this.state = {
      newSignInVisible: false,
      sortColumn: 'StartTime', // column to sort by
      sortDesc: false, // if true, sort descending rather than ascending
      selectedSignIn: null,
      filteredList: [],
      filterText: '',
      filterStatus: '',
      filterSignOut: '',
      filterDateStart,
      filterDateStartVisible: false,
      filterDateStartText: this.getDateText(filterDateStart),
      filterDateEnd,
      filterDateEndVisible: false,
      filterDateEndText: this.getDateText(filterDateEnd),
    };
    this.statusOptions = [
      {
        Title: 'Any',
        Key: '',
      },
      {
        Title: 'Granted',
        Key: 'Granted',
      },
      {
        Title: 'Denied',
        Key: 'Denied',
      },
    ];
    this.signOutOptions = [
      {
        Title: 'Any',
        Key: '',
      },
      {
        Title: 'Not Signed Out',
        Key: 'notSignedOut',
      },
      {
        Title: 'Signed Out',
        Key: 'signedOut',
      },
    ];
  }

  componentDidMount() {
    // Pre-load existing list
    const { submissions } = this.props;
    if (!_.isEmpty(submissions)) this.filterList(submissions);

    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.submissions, this.props.submissions)) {
      this.onSearchChanged();
    }
  }

  getData = async () => {
    try {
      const defaultFrom = this.state.filterDateStart.unix();
      const res = await formActions.getSubmissions(this.props.auth.site, 'signIn', defaultFrom);
      // console.log('getSubmissions', res.data);
      const submissions = res.data.map((item) => {
        const { RowId, Id, Site, FormName, Submitted, Answers, Extras } = item;
        const { valid, signOutCode, signedIn, signedOut } = Extras;
        const name = Answers.find((a) => a.question.toLowerCase().includes('name'));
        const phone = Answers.find((a) => a.question.toLowerCase().includes('phone') || a.question.toLowerCase().includes('mobile'));
        return {
          RowId,
          Id,
          Site,
          Submitted: Submitted,
          Type: FormName,
          Name: name ? name.answer : '',
          Phone: phone ? phone.answer : '',
          Status: valid ? 'Granted' : 'Denied',
          Code: signOutCode,
          SignedIn: signedIn,
          SignedOut: signedOut,
          Answers,
          Extras,
        };
      });

      this.props.formSubmissionsLoaded(submissions);
    } catch (error) {
      console.log('getSubmissions error', error);
    }
  };

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  filterList = (list) => {
    const { submissions } = this.props;
    const { filterText, filterStatus, filterSignOut, filterDateStart, filterDateEnd } = this.state;
    const source = _.orderBy(list || submissions, 'Submitted', 'desc');

    const filteredList = _.filter(source, (ev) => {
      if (!ev) return false;

      const submittedDate = moment.unix(ev.Submitted);
      return (
        (ev.Name.toLowerCase().includes(filterText.toLowerCase()) || ev.Phone.toLowerCase().includes(filterText.toLowerCase())) &&
        submittedDate.isSameOrAfter(filterDateStart) &&
        submittedDate.isSameOrBefore(filterDateEnd) &&
        (_.isEmpty(filterStatus) || ev.Status === filterStatus) &&
        (_.isEmpty(filterSignOut) ||
          (filterSignOut === 'notSignedOut' && ev.SignedIn && !ev.SignedOut) ||
          (filterSignOut === 'signedOut' && ev.SignedIn && ev.SignedOut))
      );
    }).map((item) => {
      const { RowId, Id, Site, Submitted, Type, Name, Phone, Status, Code, SignedIn, SignedOut, Answers, Extras } = item;
      return {
        RowId,
        Id,
        Site,
        Submitted: moment.unix(Submitted).format('DD/MM/YYYY'),
        Type,
        Name,
        Phone,
        Status,
        Code,
        SignedIn: SignedIn ? moment.unix(SignedIn).format('hh:mm a') : '',
        SignedOut: SignedOut ? moment.unix(SignedOut).format('hh:mm a') : '',
        Answers,
        Extras,
      };
    });

    this.setState({
      filteredList,
      filterDateStartText: this.getDateText(filterDateStart),
      filterDateEndText: this.getDateText(filterDateEnd),
    });
  };

  getExportList = (list) => {
    return list.map((item) => {
      const { Id, Site, ...others } = item;
      return {
        ...others,
        Answers: item.Answers.map((a) => {
          return `${a.question}: ${a.answer}`;
        }).join('\n'),
      };
    });
  };

  getStatusTitle = (key) => {
    const item = this.statusOptions.find((s) => s.Key === key);
    return item ? item.Title : '';
  };

  getSignOutTitle = (key) => {
    const item = this.signOutOptions.find((s) => s.Key === key);
    return item ? item.Title : '';
  };

  getDateText = (date) => moment(date).format('DD/MM/YYYY');

  onOpenNewSignIn = () => this.setState({ newSignInVisible: true });

  onCloseNewSignIn = () => this.setState({ newSignInVisible: false });

  onSubmitSignIn = () => {
    this.onCloseNewSignIn();
    this.getData();
  };

  onSearchChanged = (event) => {
    const filterText = event && event.target.value ? event.target.value : '';
    this.setState({ filterText }, this.filterList);
  };

  onDateFilterStartChanged = (date) => {
    const { filterDateEnd } = this.state;
    const start = moment(date);
    if (start.isSameOrBefore(filterDateEnd)) {
      this.setState({ filterDateStart: start.startOf('day'), filterDateStartVisible: false }, async () => {
        await this.getData();
        this.filterList();
      });
    }
  };

  onDateFilterEndChanged = (date) => {
    const { filterDateStart } = this.state;
    const end = moment(date);
    if (end.isSameOrAfter(filterDateStart)) {
      this.setState({ filterDateEnd: end.endOf('day'), filterDateEndVisible: false }, this.filterList);
    }
  };

  onEditSignInDateChanged = (date) => {
    const selection = moment(date);
    this.setState({ editSignInDate: selection, editSignInDateText: this.getDateText(selection), editSignInDateVisible: false });
  };

  onEditSignOutDateChanged = (date) => {
    const selection = moment(date);
    this.setState({ editSignOutDate: selection, editSignOutDateText: this.getDateText(selection), editSignOutDateVisible: false });
  };

  onStatusChanged = (filterStatus) => {
    this.setState({ filterStatus }, this.filterList);
  };

  onSignOutChanged = (filterSignOut) => {
    this.setState({ filterSignOut }, this.filterList);
  };

  onViewSignIn = (selectedSignIn) => {
    this.setState({ selectedSignIn });
  };

  onHideSignIn = () => {
    this.setState({ selectedSignIn: null });
  };

  onOpenEdit = (editSignIn) => {
    let signInDate = moment();
    let signOutDate = moment();
    const newState = {
      editSignIn,
      editSignInDate: null,
      editSignInDateText: null,
      editSignInDateVisible: false,
      editSignOutDate: null,
      editSignOutDateText: null,
      editSignOutDateVisible: false,
    };
    if (editSignIn && editSignIn.Extras) {
      if (editSignIn.Extras.signedIn) {
        signInDate = moment.unix(editSignIn.Extras.signedIn).local();
        newState.editSignInDate = signInDate;
        newState.editSignInDateText = this.getDateText(signInDate);
        newState.editSignInTime = signInDate.format('h:mma');
        newState.editSignInDateVisible = false;
      }
      if (editSignIn.Extras.signedOut) {
        signOutDate = moment.unix(editSignIn.Extras.signedOut).local();
        newState.editSignOutDate = signOutDate;
        newState.editSignOutDateText = this.getDateText(signOutDate);
        newState.editSignOutTime = signOutDate.format('h:mma');
        newState.editSignOutDateVisible = false;
      }
    }
    this.setState(newState);
  };

  onCloseEdit = () => {
    this.setState({ editSignIn: null });
  };

  onSaveEdit = () => {
    const signIn = getUTCFromTimeDatePickers(this.state.editSignInDate, this.state.editSignInTime).local();
    const changes = {
      Submitted: signIn.unix(),
      SignedIn: signIn.unix(),
      Extras: {
        ...this.state.editSignIn.Extras,
        signedIn: signIn.unix(),
      },
    };
    if (this.state.editSignOutDate) {
      const signOut = getUTCFromTimeDatePickers(this.state.editSignOutDate, this.state.editSignOutTime).local();
      changes.SignedOut = signOut.unix();
      changes.Extras.signedOut = signOut.unix();
    }
    formActions.editSubmission(this.state.editSignIn.RowId, changes).then(() => {
      this.getData();
    });
    this.setState({ editSignIn: null });
  };

  onCancelSignatureSignOut = () => {
    this.setState({
      signOutSignature: null,
    });
  };

  onClearSignature = () => {
    this.sigPad_signout.clear();
  };

  uploadSignOutSignature = async () => {
    const fileRes = await fileActions.putCanvas(this.sigPad_signout, 'signature');
    return fileRes.key;
  };

  processSignOut = (entry, extras) => {
    this.setState({ signingOut: true }, async () => {
      try {
        await formActions.performFormAction(entry.Site, 'signIn', 'signOut', entry.RowId, extras);
        this.setState({
          signingOut: false,
          signOutSignature: null,
        });
        this.getData();
      } catch (error) {
        console.log('performFormAction error', error);
        this.setState({ signingOut: false });
      }
    });
  };

  onConfirmSignatureSignOut = async () => {
    this.setState({ signingOut: true }, async () => {
      this.processSignOut(this.state.signOutSignature, {
        Signature: await this.uploadSignOutSignature(),
      });
    });
  };

  onSignOut = (entry) => {
    if (entry.Extras && entry.Extras.requireSignature) {
      this.setState({
        signOutSignature: entry,
      });
      return;
    }
    if (window.confirm('Are you sure you want to sign out the selected entry')) {
      this.processSignOut(entry);
    }
  };

  onToggleEditSignInFilter = () => {
    this.setState({
      editSignInDateVisible: !this.state.editSignInDateVisible,
    });
  };

  onToggleEditSignOutFilter = () => {
    this.setState({
      editSignOutDateVisible: !this.state.editSignOutDateVisible,
    });
  };

  onToggleStartDateFilter = () => {
    const { filterDateStartVisible } = this.state;
    if (filterDateStartVisible) {
      console.log('blurred');
      setTimeout(() => this.setState({ filterDateStartVisible: false }), 200);
    } else {
      this.setState({ filterDateStartVisible: true });
    }
  };

  onToggleEndDateFilter = () => {
    const { filterDateEndVisible } = this.state;
    if (filterDateEndVisible) {
      setTimeout(() => this.setState({ filterDateEndVisible: false }), 200);
    } else {
      this.setState({ filterDateEndVisible: true });
    }
  };

  renderSignature(key) {
    return (
      <div className="signatureContainer">
        <img src={`${baseStorageUrl}${key}`} alt="signature" className="signatureImg" />
      </div>
    );
  }

  renderDetail() {
    const { Answers, Extras } = this.state.selectedSignIn;
    const ordered = _.orderBy(Answers, ['page', 'position']);
    if (Extras && Extras.signature) {
      ordered.push({
        page: '99',
        position: '0',
        answer: Extras.signature,
        type: 'signature',
        question: 'Sign Out Signature',
      });
    }
    return (
      <div>
        <div style={styles.detailHeading}>Sign In Details</div>
        <div style={styles.detailContainer}>
          {ordered.map((answer) => {
            const key = `${answer.page}-${answer.position}`;
            const value = (() => {
              if (typeof answer.answer === 'boolean') return answer.answer ? 'Yes' : 'No';
              if (answer.type === 'checkbox') return answer.answer.map((p) => <div>{p}</div>);
              if (answer.type === 'signature') return this.renderSignature(answer.answer);
              return answer.answer;
            })();
            return (
              <Row key={key} style={answer.position === '0' ? styles.firstQuestion : null} className="marginTop-10">
                <Col xs={9} style={styles.questionLabel}>
                  {answer.question}
                </Col>
                <Col xs={3} style={styles.questionAnswer}>
                  {value}
                </Col>
              </Row>
            );
          })}
        </div>
        <div style={styles.detailButtonContainer}>
          <Button inline buttonType="outlined" onClick={this.onHideSignIn} isActive>
            Close
          </Button>
        </div>
      </div>
    );
  }

  renderNewPopup() {
    if (!this.state.newSignInVisible) return null;

    return (
      <div style={styles.popUpOuter}>
        <div style={styles.newSignInBox}>
          <div style={{ flex: 1 }}>
            <SignInForm
              site={this.props.auth.site}
              optionStyle={styles.optionContainer}
              onCancel={() => {}}
              onSubmit={this.onSubmitSignIn}
              disableAutoFill
            />
          </div>
          <div style={styles.signInButtonContainer}>
            <Button inline buttonType="outlined" onClick={this.onCloseNewSignIn} isActive>
              Close
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderDetailPopup() {
    if (!this.state.selectedSignIn) return null;

    return (
      <div style={styles.popUpOuter}>
        <div style={styles.whiteBox}>
          <div style={styles.popupContainer}>{this.renderDetail()}</div>
        </div>
      </div>
    );
  }

  renderListHeader() {
    return (
      <tr>
        <th
          style={{ cursor: 'pointer' }}
          onClick={() => {
            this.sortByCol('Submitted');
          }}
        >
          Date
        </th>
        <th>Type</th>
        <th>Name</th>
        <th>Phone</th>
        <th>Status</th>
        <th>Code</th>
        <th>Sign In</th>
        <th>Sign Out</th>
      </tr>
    );
  }

  renderList() {
    const { filteredList } = this.state;
    if (_.isEmpty(filteredList)) return null;

    return filteredList.map((ev, index) => {
      if (ev != null) {
        const { Id, Type, Submitted, Name, Phone, Status, Code, SignedIn, SignedOut } = ev;
        return (
          <tr key={Id}>
            <td>
              <a style={styles.link} onClick={() => this.onViewSignIn(ev)}>
                {Submitted}
              </a>
            </td>
            <td>{Type}</td>
            <td>{Name}</td>
            <td>{Phone}</td>
            <td>{Status}</td>
            <td>{Code}</td>
            <td>{SignedIn}</td>
            <td>{SignedOut}</td>
            <td className="table-options">
              <div style={styles.optionsContainer}>
                <a onClick={() => this.onOpenEdit(ev)}>
                  <FontAwesome style={styles.option} name="pencil" />
                </a>
                <a onClick={() => this.onViewSignIn(ev)}>
                  <FontAwesome style={styles.option} name="eye" />
                </a>
                {SignedIn && !SignedOut ? (
                  <a onClick={() => this.onSignOut(ev)}>
                    <FontAwesome style={styles.option} name="sign-out" />
                  </a>
                ) : null}
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  renderSignaturePopup() {
    if (!this.state.signOutSignature) {
      return null;
    }
    return (
      <Popup subtitle="Signature (optional)" maxWidth="90%" maxHeight="90%" hasPadding>
        <p className="visitorSignIn_header_subtitle">Please sign below</p>
        <div className="signature">
          <SignatureCanvas
            penColor="black"
            canvasProps={{ width: 600, className: 'signature_canvas' }}
            ref={(ref) => {
              this.sigPad_signout = ref;
            }}
          />
          <Button buttonType="secondary" onClick={this.onClearSignature} isActive>
            Clear signature
          </Button>
        </div>
        <div className="flex flex-center-row marginTop-10">
          {this.state.signingOut ? (
            <p>Signing out</p>
          ) : (
            <Button buttonType="primary" onClick={this.onConfirmSignatureSignOut} isActive>
              Sign Out
            </Button>
          )}
        </div>
      </Popup>
    );
  }

  renderEditPopup() {
    if (!this.state.editSignIn) {
      return null;
    }
    return (
      <Popup
        title="Change time"
        maxWidth="90%"
        maxHeight="90%"
        minWidth={400}
        hasPadding
        onClose={this.onCloseEdit}
        buttons={[
          {
            type: 'primary',
            onClick: this.onSaveEdit,
            isActive: true,
            text: 'Save',
          },
          {
            type: 'tertiary',
            onClick: this.onCloseEdit,
            isActive: true,
            text: 'Cancel',
          },
        ]}
      >
        <GenericInput
          id="editSignInDate"
          label="Sign In"
          alwaysShowLabel
          placeholder={'Select date'}
          value={this.state.editSignInDateText}
          onClick={this.onToggleEditSignInFilter}
          readOnly
        />
        {this.state.editSignInDateVisible ? (
          <DatePicker
            hideTop
            selectedDate={this.state.editSignInDate}
            selectDate={this.onEditSignInDateChanged}
            style={styles.datePickerPopup}
          />
        ) : null}
        <TimePicker
          selectedTime={this.state.editSignInTime}
          selectTime={(val) => {
            this.setState({ editSignInTime: val });
          }}
        />

        {this.state.editSignOutDate && (
          <div className="marginTop-20">
            <GenericInput
              id="editSignOutDate"
              label="Sign Out"
              alwaysShowLabel
              placeholder={'Select date'}
              value={this.state.editSignOutDateText}
              onClick={this.onToggleEditSignOutFilter}
              readOnly
            />
            {this.state.editSignOutDateVisible ? (
              <DatePicker
                hideTop
                selectedDate={this.state.editSignOutDate}
                selectDate={this.onEditSignOutDateChanged}
                style={styles.datePickerPopup}
              />
            ) : null}
            <TimePicker
              selectedTime={this.state.editSignOutTime}
              selectTime={(val) => {
                this.setState({ editSignOutTime: val });
              }}
            />
          </div>
        )}
      </Popup>
    );
  }

  renderFilters() {
    const {
      filteredList,
      filterText,
      filterStatus,
      filterSignOut,
      filterDateStart,
      filterDateStartVisible,
      filterDateStartText,
      filterDateEnd,
      filterDateEndVisible,
      filterDateEndText,
    } = this.state;
    const exportList = this.getExportList(filteredList);

    return (
      <Form>
        <Row className="show-grid">
          <Col xs={6}>
            <InputGroup
              id="filterText"
              type="text"
              label="Sign In Search"
              placeholder="Search by name or phone."
              value={filterText}
              onChange={this.onSearchChanged}
            />
          </Col>
          <Col xs={6} style={styles.qrCodeContainer}>
            <QRCode renderAs={'svg'} size={100} value={`${window.location.origin}/signin?site=${this.props.auth.site}`} />
            <div style={styles.qrCodeLabel}>Scan to sign in on your device</div>
          </Col>
        </Row>
        <Row style={{ marginTop: '15px' }}>
          <Col xs={3}>
            <GenericInput
              id="dateStart"
              label="Start Date"
              alwaysShowLabel
              placeholder={'Select start date'}
              value={filterDateStartText}
              onClick={this.onToggleStartDateFilter}
              // onBlur={this.onToggleStartDateFilter}
              readOnly
            />
            {filterDateStartVisible ? (
              <DatePicker hideTop selectedDate={filterDateStart} selectDate={this.onDateFilterStartChanged} style={styles.datePicker} />
            ) : null}
          </Col>
          <Col xs={3}>
            <GenericInput
              id="dateEnd"
              label="End Date"
              alwaysShowLabel
              placeholder={'Select end date'}
              value={filterDateEndText}
              onClick={this.onToggleEndDateFilter}
              // onBlur={this.onToggleEndDateFilter}
              readOnly
            />
            {filterDateEndVisible ? (
              <DatePicker hideTop selectedDate={filterDateEnd} selectDate={this.onDateFilterEndChanged} style={styles.datePicker} />
            ) : null}
          </Col>
          <Col xs={3}>
            <DropdownInput
              id="statusSelect"
              label="Status"
              alwaysShowLabel
              placeholder="Status"
              value={this.getStatusTitle(filterStatus)}
              options={this.statusOptions}
              onSelect={this.onStatusChanged}
            />
          </Col>
          <Col xs={3}>
            <DropdownInput
              id="signOutSelect"
              label="Sign Out"
              alwaysShowLabel
              placeholder="Sign Out"
              value={this.getSignOutTitle(filterSignOut)}
              options={this.signOutOptions}
              onSelect={this.onSignOutChanged}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button inline buttonType="outlinedAction" isActive>
              <CSVLink data={exportList} style={{ textDecoration: 'none' }} filename="sign-ins.csv">
                Download List
              </CSVLink>
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    return (
      <div style={styles.container}>
        <AddButton style={styles.newSignIn} onClick={this.onOpenNewSignIn} text="NEW SIGN IN" />
        {this.renderFilters()}
        <Table className="plussTable" striped bordered condensed hover style={styles.tableContainer}>
          <thead>{this.renderListHeader()}</thead>
          <tbody>{this.renderList()}</tbody>
        </Table>
        {this.renderEditPopup()}
        {this.renderNewPopup()}
        {this.renderDetailPopup()}
        {this.renderSignaturePopup()}
      </div>
    );
  }
}

const styles = {
  container: {
    minWidth: '100%',
  },
  newSignIn: {
    marginBottom: '32px',
  },
  popUpOuter: {
    zIndex: 100,
    width: '100%',
    height: '100%',
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.7)',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 30,
    overflow: 'auto',
    flex: '0 1 auto',
    top: 0,
    left: 0,
  },
  newSignInBox: {
    backgroundColor: 'white',
    width: '1000px',
    maxHeight: '95%',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  whiteBox: {
    minWidth: '60%',
    maxWidth: '80%',
  },
  popupContainer: {
    borderRadius: 15,
    backgroundColor: 'white',
    padding: 20,
    marginBottom: 30,
  },
  detailHeading: {
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 36,
    fontWeight: 'bold',
  },
  detailContainer: {
    marginLeft: '5px',
    marginRight: '5px',
    overflow: 'hidden',
  },
  firstQuestion: {
    borderTop: '1px solid #ccc',
    paddingTop: 10,
  },
  questionLabel: {
    fontWeight: 'bold',
  },
  questionAnswer: {
    textAlign: 'right',
    paddingRight: '30px',
  },
  detailButtonContainer: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingTop: 15,
    borderTop: '1px solid #ccc',
  },
  signInButtonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    borderTop: '1px solid #ccc',
    padding: 15,
  },
  link: {
    cursor: 'pointer',
  },
  optionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  option: {
    fontSize: 20,
    padding: 5,
    marginLeft: 12,
    cursor: 'pointer',
  },
  qrCodeContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
  },
  qrCodeLabel: {
    fontWeight: 'bold',
    textAlign: 'right',
    marginRight: '20px',
    width: '100px',
  },
  tableContainer: {
    marginTop: '15px',
    minWidth: '100%',
    paddingBottom: '180px',
  },
  datePicker: {
    position: 'absolute',
    top: '55px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 10px rgba(106, 163, 216, 0.7)',
    zIndex: 100,
    borderRadius: 4,
  },
  datePickerPopup: {
    position: 'absolute',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 10px rgba(106, 163, 216, 0.7)',
    zIndex: 100,
    borderRadius: 4,
  },
  optionContainer: {
    height: 'unset',
    minHeight: '150px',
  },
};

const mapStateToProps = (state) => {
  const { auth, forms } = state;
  return {
    auth,
    submissions: forms.submissions,
  };
};

export default connect(mapStateToProps, { formSubmissionsLoaded })(SignInList);
