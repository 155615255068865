import { WHITE_LABEL_LOADED, WHITE_LABEL_ADDED, WHITE_LABEL_PURGE, WHITE_LABEL_APP_SET } from './types';

export const whiteLabelLoaded = (whiteLabel) => {
  return {
    type: WHITE_LABEL_LOADED,
    payload: whiteLabel,
  };
};

export const whiteLabelAdded = (clientCode) => {
  return {
    type: WHITE_LABEL_ADDED,
    payload: clientCode,
  };
};

export const whiteLabelReset = () => {
  return {
    type: WHITE_LABEL_PURGE,
  };
};

export const whiteLabelAppSet = (app) => {
  return {
    type: WHITE_LABEL_APP_SET,
    payload: app,
  };
};
