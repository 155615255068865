import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _, { times } from 'lodash';
import moment from 'moment';
import { Button, Comment, Header, P60Icon, SVGIcon } from '../../components';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { checkLoggedIn, validateAccess } from '../../session';
import { activityActions, analyticsActions, deviceActions, onboardingActions, reactionActions } from '../../webapi';
import { Text } from '../../components/text';
import { getFeatureInfo, getPercentage, getPluralS, getSiteLevelFromState } from '../../helper';
import { devicesLoaded } from '../../actions';
import ActivityEntry from './ActivityEntry';
import { COLOUR_GREEN } from '../../js';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setupTrackerExpanded: false,
      appUseWeek: 0,
      appUsePrevWeek: 0,
      appUseMonth: 0,
      appUsePrevMonth: 0,
      adminUseMonth: 0,
      adminUsePrevMonth: 0,

      selectedBannerIndex: 0,

      comments: [],
      allActivity: [],
      activity: [],
      banners: [],
      loadingActivity: true,
      loadingComments: true,
    };
  }

  componentDidMount() {
    checkLoggedIn(this, this.props.auth);

    const newState = {};
    if (!_.isUndefined(this.props.auth.site)) {
      newState.location = this.props.auth.site;
      if (this.props.auth.user) {
        analyticsActions.log(this.props.auth.site, 'AdminUse', 'user', this.props.auth.user.Id, { sourceId: this.props.auth.user.Id });
      }
    }
    this.getFeaturedBanners();

    // this.getSetupCompletion();
    this.getUserStats();

    this.setState(newState);

    this.getActivity();
    this.getBlogPosts();
    this.getDevices();
    this.connect();
  }

  connect = () => {
    this.getComments();

    this.commentsInterval = setInterval(this.getComments, 30000);
  };

  disconnect = () => {
    clearInterval(this.commentsInterval);
  };

  getSetupCompletion = async () => {
    onboardingActions.getCompletedSteps(this.props.auth.site).then((res) => {
      this.setState(
        {
          stepCompletion: res.data,
        },
        () => {
          const stepCompletion = this.getSetupTrackerStats();
          if (stepCompletion.completedCount < stepCompletion.totalCount) {
            this.setState({
              setupTrackerExpanded: true,
            });
          }
        },
      );
    });
  };

  getDevices = async () => {
    try {
      const res = await deviceActions.getDevices(this.props.auth.site);
      // console.table('getDevices', res.data);
      this.props.devicesLoaded(res.data);
    } catch (error) {
      console.log('getDevices error', error);
    } finally {
      this.setState({ loading: false });
    }
  };

  getActivity = () => {
    activityActions.getActivity(this.props.auth.site, 50).then((res) => {
      const activity = res.data.map((a) => {
        return { ...a, SortTimestamp: a.UnixTimestamp * 1000, ActivityType: 'Activity' };
      });
      this.setState(
        {
          activity,
          loadingActivity: false,
        },
        this.setRecentActivity,
      );
    });
  };

  getBlogPosts = () => {
    activityActions.getInsightBlogPosts().then((res) => {
      this.setState({
        blogPosts: res.data,
      });
    });
  };

  getFeaturedBanners = () => {
    activityActions.getFeaturedBanners().then((res) => {
      this.setState({
        banners: res.data,
      });

      this.scheduleCycleBanner();
    });
  };

  getComments = async () => {
    const minTime = _.isEmpty(this.state.comments) ? 0 : this.state.comments[0].Timestamp + 1;
    reactionActions.getSiteComments(this.props.auth.site, 50, minTime).then((res) => {
      const newComments = res.data.map((c) => {
        const feature = getFeatureInfo(c.EntityType);
        return { ...c, SortTimestamp: c.Timestamp, Type: 'Comment', Feature: { ...feature } };
      });
      const comments = _.uniqBy([...this.state.comments, ...newComments], (c) => c.Id);

      this.setState(
        {
          comments: _.orderBy(comments, 'Timestamp', 'desc'),
          loadingComments: false,
        },
        this.setRecentActivity,
      );
    });
  };

  setRecentActivity = () => {
    const allActivity = _.orderBy([...this.state.comments, ...this.state.activity], 'SortTimestamp', 'desc');
    this.setState({
      allActivity,
      // recentActivity: _.take(allActivity, 4),
    });
  };

  getUserStats() {
    this.setState({ loadingUserStats: true }, async () => {
      try {
        const newStates = { loadingUserStats: false };
        const today = moment().valueOf();

        // Get month usage
        const monthAgo = moment().subtract(1, 'months').valueOf();
        const monthRes = await analyticsActions.getAggregateEntityStats(this.props.auth.site, 'user', monthAgo, today);
        const monthStats = Object.values(monthRes.data);
        newStates.appUseMonth = monthStats.filter((stat) => stat.AppUse).length;
        newStates.adminUseMonth = monthStats.filter((stat) => stat.AdminUse).length;

        // Get week usage
        const weekAgo = moment().subtract(1, 'weeks').valueOf();
        const weekRes = await analyticsActions.getAggregateEntityStats(this.props.auth.site, 'user', weekAgo, today);
        const weekStats = Object.values(weekRes.data);
        newStates.appUseWeek = weekStats.filter((stat) => stat.AppUse).length;
        newStates.adminUseWeek = weekStats.filter((stat) => stat.AdminUse).length;

        // Get prev month usage
        const prevMonthAgo = moment().subtract(2, 'months').valueOf();
        const prevMonthRes = await analyticsActions.getAggregateEntityStats(this.props.auth.site, 'user', prevMonthAgo, monthAgo);
        const prevMonthStats = Object.values(prevMonthRes.data);
        newStates.appUsePrevMonth = prevMonthStats.filter((stat) => stat.AppUse).length;
        newStates.adminUsePrevMonth = prevMonthStats.filter((stat) => stat.AdminUse).length;

        // Get prev week usage
        const prevWeekAgo = moment().subtract(2, 'weeks').valueOf();
        const prevWeekRes = await analyticsActions.getAggregateEntityStats(this.props.auth.site, 'user', prevWeekAgo, weekAgo);
        const prevWeekStats = Object.values(prevWeekRes.data);
        newStates.appUsePrevWeek = prevWeekStats.filter((stat) => stat.AppUse).length;
        newStates.adminUsePrevWeek = prevWeekStats.filter((stat) => stat.AdminUse).length;

        this.setState(newStates);
      } catch (error) {
        console.log('getUserStats', error);
        this.setState({ loadingUserStats: false });
      }
    });
  }

  goToFeaturePicker = () => {
    this.props.history.push(this.props.siteLevel.featurePickerUrl);
  };

  toggleExpandSetupTracker = () => {
    this.setState({
      setupTrackerExpanded: !this.state.setupTrackerExpanded,
    });
  };

  expandSetupTrackerSection = (index) => {
    this.setState({
      trackerSectionExpanded: index,
    });
  };

  selectBannerIndex = (index) => {
    this.setState({
      selectedBannerIndex: index,
    });
    this.scheduleCycleBanner();
  };

  scheduleCycleBanner = () => {
    const lastChange = moment().valueOf();
    this.setState({
      lastBannerChange: lastChange,
    });
    setTimeout(() => {
      if (this.state.lastBannerChange === lastChange) {
        this.selectBannerIndex((this.state.selectedBannerIndex + 1) % this.state.banners.length);
      }
    }, 5000);
  };

  openLink = (url) => {
    if (_.isEmpty(url)) {
      return;
    }
    if (url === '/featurepicker') {
      return this.goToFeaturePicker();
    }
    if (url.indexOf('/') === 0) {
      this.props.history.push(url);
    } else {
      window.open(url, '_blank').focus();
    }
  };

  getSetupTrackerSteps = () => {
    const stepCompletion = this.state.stepCompletion || {};
    const steps = [
      {
        title: 'Setup your account',
        text: <span>You've taken the first steps. Welcome to the Community Manager!</span>,
        button: {
          text: 'Manage my Profile',
          onClick: () => {
            this.props.history.push('/profile');
          },
        },
        completed: stepCompletion.hasProfile,
      },
      {
        title: 'Pick your App Features',
        text: (
          <span>
            Use the Feature Picker to select the type of content you want for your community.
            <br />
            <br />
            From Events to News we’ll leave it up to you to decide.
          </span>
        ),
        image:
          'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/982ce9284459a2bb31ba61769a/dnd.png',
        button: {
          text: 'Launch the Feature Picker',
          onClick: this.goToFeaturePicker,
        },
        completed: stepCompletion.hasFeatures,
      },
      {
        title: 'Create your Brand',
        text: (
          <span>
            There are lots of options to manage your brand. Upload your own logo or create your own using a selection of fonts. Make sure
            the App experience is presented with your colour scheme.
          </span>
        ),
        button: {
          text: 'Manage my Community Brand',
          onClick: () => {
            this.props.history.push('/branding/edit');
          },
        },
        completed: stepCompletion.hasBrand,
      },
      {
        title: 'Create your Tags',
        text: (
          <span>
            Tags can help you manage the users in your Community. You can send out content to specific groups of users, so your Community
            members only see what's relevant to them.
          </span>
        ),
        button: {
          text: 'Set Up User Tags',
          onClick: () => {
            this.props.history.push('/tagging');
          },
        },
        completed: stepCompletion.hasUserTags,
      },
      {
        title: 'Create your Content',
        text: (
          <span>
            With your features chosen, it's time to load up the Community with content. Find your features in the menu to the left and start
            creating content. You can also create content directly from the App.
          </span>
        ),
        completed: stepCompletion.hasContent,
      },
      {
        title: 'Invite Users',
        text: (
          <span>
            Time to invite other people to your Community. You can add additional admins to help manage the content or invite the other
            people in your Community.
            <br />
            <br />
            You can either directly set up other people's account, or you can set up an invite code so they can register themselves through
            the app.
          </span>
        ),
        button: {
          text: 'Set Up Users',
          onClick: () => {
            this.props.history.push('/usershub');
          },
        },
        completed: stepCompletion.hasUsers,
      },
    ];
    if (_.isUndefined(this.state.trackerSectionExpanded)) {
      const sectionToExpand = _.findIndex(steps, (s) => {
        return !s.completed;
      });
      if (sectionToExpand > -1) {
        steps[sectionToExpand].expanded = true;
      }
    } else {
      steps[this.state.trackerSectionExpanded].expanded = true;
    }
    return steps;
  };

  getSetupTrackerStats = () => {
    const steps = this.getSetupTrackerSteps();
    return {
      completedCount: _.filter(steps, (s) => {
        return s.completed;
      }).length,
      totalCount: steps.length,
    };
  };

  renderHeader() {
    return (
      <Header>
        {validateAccess(this.props.auth.site, 'featurePicker', this.props.auth) && (
          <Link className="dashboardFeaturePickerButton" to={this.props.siteLevel.featurePickerUrl}>
            <p className="dashboardFeaturePickerButton_text">FEATURE PICKER</p>
            <FontAwesome className="dashboardFeaturePickerButton_icon" name="mobile" />
          </Link>
        )}
      </Header>
    );
  }

  renderFeatureBanner(banner, index) {
    return (
      <div className="dashboardBox-feature_inner" style={{ transform: `translateX(${100 * (index - this.state.selectedBannerIndex)}%)` }}>
        <div className="dashboardBox-feature_left">
          <Text type="h2">{banner.Title}</Text>
          <Text type="bodyLarge" className="marginTop-10">
            {banner.Text}
          </Text>
          {banner.Button && (
            <div className="marginTop-20">
              <Button
                buttonType="outlined"
                isActive
                onClick={() => {
                  this.openLink(banner.Button.Link);
                }}
              >
                {banner.Button.Text}
              </Button>
            </div>
          )}
        </div>
        <div className="dashboardBox-feature_right" style={{ backgroundImage: `url(${banner.Image})` }}></div>
      </div>
    );
  }

  renderFeatureBanners() {
    if (_.includes(this.props.auth.hidden, 'adminBanner') || _.isEmpty(this.state.banners)) {
      return null;
    }
    return (
      <div className="dashboardBox dashboardSection dashboardBox-feature">
        {this.state.banners.map((b, i) => {
          return this.renderFeatureBanner(b, i);
        })}
        {this.state.banners.length > 1 && (
          <div className="dashboardBox-feature_dots">
            {this.state.banners.map((b, i) => {
              return (
                <div
                  className={`dashboardBox-feature_dot${i === this.state.selectedBannerIndex ? ' dashboardBox-feature_dot-selected' : ''}`}
                  onClick={() => {
                    this.selectBannerIndex(i);
                  }}
                ></div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  renderSetupTrackerSection(section, index) {
    return (
      <div
        className={`dashboardBox-setupTracker_bottom_section${
          section.completed ? ' dashboardBox-setupTracker_bottom_section-completed' : ''
        }${section.expanded ? ' dashboardBox-setupTracker_bottom_section-expanded' : ''}`}
        onClick={
          section.expanded
            ? null
            : () => {
                this.expandSetupTrackerSection(index);
              }
        }
      >
        <div className="dashboardBox-setupTracker_bottom_section_top">
          <div className="dashboardBox-setupTracker_bottom_section_top_circle">
            <SVGIcon icon="check" className="dashboardBox-setupTracker_bottom_section_top_circle_check" colour={COLOUR_GREEN} />
          </div>
          <Text type="h6">{section.title}</Text>
        </div>
        <div className="dashboardBox-setupTracker_bottom_section_bottom">
          {section.image && <img className="dashboardBox-setupTracker_bottom_section_image" src={section.image} />}
          <div className="flex-1">
            {section.text && <Text type="bodyLarge">{section.text}</Text>}
            {section.button && (
              <Button buttonType="outlined" className="marginTop-20" isActive onClick={section.button.onClick}>
                {section.button.text}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderSetupTracker() {
    const setupStats = this.getSetupTrackerStats();
    const setupSteps = this.getSetupTrackerSteps();
    return (
      <div
        className={`dashboardBox dashboardSection dashboardBox-setupTracker${
          this.state.setupTrackerExpanded ? ' dashboardBox-setupTracker-expanded' : ''
        }`}
      >
        <div className="dashboardBox-setupTracker_top" style={{ opacity: this.state.stepCompletion ? 1 : 0 }}>
          <div className="dashboardBox-setupTracker_top_left">
            <Text type="h4">Your Community App Setup</Text>
            <div className="dashboardBox-setupTracker_barContainer">
              <Text type="bodyLarge" className="dashboardBox-setupTracker_completionText">
                {setupStats.completedCount} of {setupStats.totalCount} Completed
              </Text>
              <div className="dashboardBox-setupTracker_bar">
                <div
                  className="dashboardBox-setupTracker_bar_fill"
                  style={{ width: getPercentage(setupStats.completedCount, setupStats.totalCount) }}
                ></div>
              </div>
            </div>
          </div>
          <div className="dashboardBox-setupTracker_top_right" onClick={this.toggleExpandSetupTracker}>
            <FontAwesome name="angle-left" className="dashboardBox-setupTracker_top_right_icon" />
          </div>
        </div>
        <div className="dashboardBox-setupTracker_bottom" style={{ opacity: this.state.stepCompletion ? 1 : 0 }}>
          {setupSteps.map((s, i) => {
            return this.renderSetupTrackerSection(s, i);
          })}
        </div>
      </div>
    );
  }

  renderActivateTV() {
    if (!this.props.siteLevel.hasTV || !validateAccess(this.props.auth.site, 'activateDevices', this.props.auth)) {
      return null;
    }
    return (
      <div className="dashboardSection">
        <Text type="h4">Modules</Text>
        <div className="dashboardBox dashboardSection_content">
          <Link className="dashboard_activateTV" to={`/activatetv`}>
            <div className="dashboard_activateTV_top">
              <div className="dashboard_activateTV_top_icon"></div>
              <div className="dashboard_activateTV_top_activate">
                <p className="dashboard_activateTV_top_activate_text">Activate a TV</p>
                <FontAwesome className="dashboard_activateTV_top_activate_icon" name="angle-right" />
              </div>
            </div>
            <div className="dashboard_activateTV_bottom">
              <p className="dashboard_activateTV_bottom_text">{`${this.props.devices.length} Active TV${getPluralS(
                this.props.devices.length,
              )}`}</p>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  renderStatBox(title, count, prevCount, changeText) {
    let change = (count / prevCount - 1) * 100;
    if (isNaN(change)) {
      change = 0;
    }
    if (!isFinite(change)) {
      change = 100;
    }
    change = parseInt(change);
    const opacity = this.state.loadingUserStats ? 0 : 1;
    return (
      <div
        className={`dashboardBox dashboardBox-stats${change > 0 ? ' dashboardBox-stats-positive' : ''}${
          change < 0 ? ' dashboardBox-stats-negative' : ''
        }`}
      >
        <Text type="h6">{title}</Text>
        <div className="dashboardBox-stats_mid" style={{ opacity }}>
          <Text type="h2" className="dashboardBox-stats_count">
            {count}
          </Text>
          <p className="dashboardBox-stats_change">{change === 0 ? 'No change' : `${change}% ${change < 0 ? '↓' : '↑'}`}</p>
        </div>
        <Text type="help" style={{ opacity }}>
          Compared to {changeText}
        </Text>
      </div>
    );
  }

  renderStats() {
    return (
      <div className="dashboardSection">
        <Text type="h4">Community Stats</Text>
        <div className="dashboardStats dashboardSection_content">
          {this.renderStatBox(`App users this week`, this.state.appUseWeek, this.state.appUsePrevWeek, 'last week')}
          {this.renderStatBox(`App users this month`, this.state.appUseMonth, this.state.appUsePrevMonth, 'last month')}
          {this.renderStatBox(`Admin users this month`, this.state.adminUseMonth, this.state.adminUsePrevMonth, 'last month')}
        </div>
      </div>
    );
  }

  renderActivity() {
    return (
      <div className="dashboardSection">
        <div className="flex flex-between">
          <Text type="h4">Latest Activity</Text>
          <Link to="/comments">
            <Text type="h6" className="text-link">
              View All Activity
            </Text>
          </Link>
        </div>
        <div className="dashboardBox dashboardBox-minHeightPlaceholder dashboardSection_content">
          {this.state.allActivity.map((activity, index) => {
            return <ActivityEntry data={activity} index={index} key={index} />;
          })}
          {!this.state.loadingActivity && !this.state.loadingComments && _.isEmpty(this.state.allActivity) && (
            <div className="dashboardBox_empty">
              <Text type="bodyLarge">Community Activity will show here.</Text>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderNews() {
    if (_.includes(this.props.auth.hidden, 'adminBlog') || _.isEmpty(this.state.blogPosts)) {
      return null;
    }
    return (
      <div className="dashboardSection">
        <div className="flex flex-between">
          <Text type="h4">Community Insights & News</Text>
          <a href="https://plusscommunities.com/blog" target="_blank">
            <Text type="h6" className="text-link">
              View All
            </Text>
          </a>
        </div>
        <div className="dashboardSection_content dashboardSection_content-blog">
          {this.state.blogPosts.map((post) => {
            return (
              <a href={post.Url} target="_blank" className="dashboardBox dashboardBox-blog">
                <div className="dashboardBox-blog_image" style={{ backgroundImage: `url(${post.Image})` }}></div>
                <div className="dashboardBox-blog_bottom">
                  <Text type="h6" className="dashboardBox-blog_title">
                    {post.Title}
                  </Text>
                  <div className="dashboardBox-blog_metaSection">
                    <Text type="author" className="dashboardBox-blog_author">{`By ${post.Author}`}</Text>
                    <Text type="time">{`Posted ${moment.utc(post.CreatedTime).fromNow()}`}</Text>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <div className="hub-content dashboardContent">
        {this.renderFeatureBanners()}
        {/* {this.renderSetupTracker()} */}
        {this.renderStats()}
        {/* {this.renderActivateTV()} */}
        {this.renderNews()}
        {this.renderActivity()}
      </div>
    );
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        <div className="hub-headerContentWrapper">
          {this.renderHeader()}
          <div className="hub-contentCenter">{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, devices } = state;

  console.log(auth);

  return {
    auth,
    devices: devices.list,
    siteLevel: getSiteLevelFromState(state),
  };
};

export default connect(mapStateToProps, { devicesLoaded })(withRouter(Dashboard));
