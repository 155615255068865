import _ from 'lodash';
import { SERVICE_REMOVED, SERVICES_LOADED, SERVICES_UPDATED, SERVICES_DASHBOARD_LOADING } from './types';
import { serviceActions } from '../webapi';
import { readStorageWithCookie } from '../helper';

export const servicesUpdate = (site, isdashboard) => {
  return (dispatch) => {
    if (isdashboard) {
      dispatch({
        type: SERVICES_DASHBOARD_LOADING,
      });
    }
    serviceActions.getServices(site).then((res) => {
      const currentSite = readStorageWithCookie('site');
      if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === currentSite) {
        dispatch({
          type: SERVICES_UPDATED,
          payload: res.data,
        });
      } else {
        dispatch({
          type: SERVICES_UPDATED,
          payload: [],
        });
      }
    });
  };
};

export const servicesLoaded = (events) => {
  return {
    type: SERVICES_LOADED,
    payload: events,
  };
};

export const removeService = (id) => {
  return {
    type: SERVICE_REMOVED,
    payload: id,
  };
};
