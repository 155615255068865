import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import _ from 'lodash';
import { getApiError } from '../../session';
import { GenericInput, DropdownInput, Button } from '../../components';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded, whiteLabelAppSet } from '../../actions';
import { renderTitle, renderTextStep } from './helper';
import AppSelector from './common/AppSelector';

const MESSAGE_APP = 'app';
const MESSAGE_SITES = 'sites';
const REPO_APP = 'minuss-expo';
const CONFIG_TYPE_ASSETS = 'assets';

class AddSubApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteLabelInfo: _.cloneDeep(props.activeWhiteLabel),
      loading: false,
      selectedApp: null,
      subAppKey: '',
      subAppName: '',
      siteOptions: [],
      selectedSite: '',
      showWarnings: false,
      showProgress: false,
      completeAppBranch: false,
      completeAppDetails: false,
      completeAppConfig: false,
      completeAppUsers: false,
    };
  }

  componentDidMount() {
    // this.setState({
    //   selectedApp: {
    //     Id: 'unittesting',
    //     Key: 'unittesting',
    //     Title: 'Unit Testing',
    //   },
    //   subAppKey: 'sub5',
    //   subAppName: 'Unit Testing 5',
    // });
  }

  componentDidUpdate(prevProps) {
    const { activeWhiteLabel } = this.props;
    if (
      activeWhiteLabel.ClientCode !== prevProps.activeWhiteLabel.ClientCode ||
      !_.isEqual(activeWhiteLabel.Apps, prevProps.activeWhiteLabel.Apps)
    ) {
      this.setState({ whiteLabelInfo: activeWhiteLabel });
    }
  }

  getAppName = (app, defaultName) => `${app.ClientName || defaultName} (${app.ClientCode})`;

  setMessage = (key, message, extraStates = {}) => {
    return new Promise((resolve) => {
      const newState = { ...extraStates };
      newState[`message_${key}`] = message;
      // console.log('setMessage', newState);
      this.setState(newState, resolve());
    });
  };

  getMessage = (key) => this.state[`message_${key}`];

  getActualBranch = () => {
    const { selectedApp, subAppKey } = this.state;
    return selectedApp && subAppKey ? `${selectedApp.Key}-${subAppKey}` : '';
  };

  getAppInfo = () => {
    const { whiteLabelInfo, subAppKey } = this.state;
    const actualKey = this.getActualBranch(subAppKey);
    return whiteLabelInfo.Apps.find((a) => a.ClientCode === actualKey);
  };

  createAppBranch = async (actualBranch, baseBranch) => {
    await this.setMessage(MESSAGE_APP, `Checking ${actualBranch}...`, { loading: true });
    const { data: exists } = await automationActions.hasLiveEnvironment(REPO_APP, actualBranch);
    if (!exists) {
      await this.setMessage(MESSAGE_APP, `Creating ${actualBranch}...`);
      await automationActions.createLiveEnvironment(REPO_APP, actualBranch, baseBranch);
    }
    await this.setMessage(MESSAGE_APP, '', { loading: false, completeAppBranch: true });
  };

  saveAppDetails = async (clientCode) => {
    const { whiteLabelInfo, subAppName, selectedSite } = this.state;
    await this.setMessage(MESSAGE_APP, `Creating app information...`, { loading: true });
    const newWhiteLabel = _.cloneDeep(whiteLabelInfo);
    const appIndex = newWhiteLabel.Apps.findIndex((a) => a.ClientCode === clientCode);
    let modified = false;
    if (appIndex > -1) {
      const app = newWhiteLabel.Apps[appIndex];
      if (app.ClientName !== subAppName || app.Site !== selectedSite) {
        app.ClientName = subAppName;
        app.Site = selectedSite;
        modified = true;
      }
    } else {
      newWhiteLabel.Apps.push({
        ClientCode: clientCode,
        ClientName: subAppName,
        Site: selectedSite,
      });
      modified = true;
    }
    // console.log('saveAppDetails - modified', modified);
    if (modified) {
      await this.setMessage(MESSAGE_APP, `Saving app information...`);
      const { data: updated } = await automationActions.updateWhiteLabel(newWhiteLabel, this.props.activeWhiteLabel);
      this.props.whiteLabelLoaded(updated);
    }
    await this.setMessage(MESSAGE_APP, '', { loading: false, completeAppDetails: true });
  };

  configureApp = async (actualBranch) => {
    await this.setMessage(MESSAGE_APP, `Checking app configurations...`, { loading: true });
    const { data: configs } = await automationActions.getEnvironmentConfigs(REPO_APP, actualBranch);
    // console.log('configureApp - configs', configs);
    configs.forEach((type) => {
      if (type.type === CONFIG_TYPE_ASSETS) return;
      type.values.forEach((config) => {
        if (config.default && config.default !== config.value) {
          config.value = config.default;
          config.modified = true;
        }
      });
    });
    const modifiedConfigs = configs
      .map((c) => {
        return {
          ...c,
          values: c.values.filter((v) => v.modified),
        };
      })
      .filter((c) => c.values && c.values.length > 0);
    // console.log('configureApp - modifiedConfigs', modifiedConfigs);
    if (!_.isEmpty(modifiedConfigs)) {
      await this.setMessage(MESSAGE_APP, `Saving app configurations...`);
      await automationActions.updateEnvironmentConfigs(REPO_APP, actualBranch, modifiedConfigs);
    }
    await this.setMessage(MESSAGE_APP, '', { loading: false, completeAppConfig: true });
  };

  setupTestUsers = async (actualBranch) => {
    const { whiteLabelInfo } = this.state;
    const app = whiteLabelInfo.Apps.find((a) => a.ClientCode === actualBranch);
    if (app && !app.Users) {
      await this.setMessage(MESSAGE_APP, `Setting up app review users...`, { loading: true });
      const { data: updated } = await automationActions.initialiseTestUsers(whiteLabelInfo.RowId, actualBranch);
      // console.log('setupTestUsers', updated);
      this.props.whiteLabelLoaded(updated);
    }
    await this.setMessage(MESSAGE_APP, '', { loading: false, completeAppUsers: true });
  };

  isValid = () => {
    const { loading, subAppKey, subAppName, selectedSite } = this.state;
    return !loading && !_.isEmpty(subAppKey) && !_.isEmpty(subAppName) && !_.isEmpty(selectedSite);
  };

  checkMultipeAppSite = (actualBranch) => {
    const { whiteLabelInfo, selectedSite } = this.state;
    if (whiteLabelInfo.Apps.filter((a) => a.ClientCode !== actualBranch && a.Site === selectedSite).length > 0) {
      throw new Error(`There are apps already connected to ${selectedSite}`);
    }
  };

  resetProgress = async (show = false) => {
    await this.setMessage(MESSAGE_APP, '', {
      showProgress: show,
      completeAppBranch: false,
      completeAppDetails: false,
      completeAppConfig: false,
      completeAppUsers: false,
    });
  };

  isComplete = () => {
    const { loading, completeAppBranch, completeAppDetails, completeAppConfig, completeAppUsers } = this.state;
    return !_.isNil(this.getAppInfo()) && !loading && completeAppBranch && completeAppDetails && completeAppConfig && completeAppUsers;
  };

  onAppSelected = (selectedApp) => {
    this.setState({ selectedApp }, async () => {
      const { selectedApp } = this.state;
      try {
        this.resetProgress();
        await this.setMessage(MESSAGE_SITES, `Loading sites for ${selectedApp.Title}...`, { loading: true, siteOptions: [] });
        const { data: sites } = await automationActions.getEnvironmentSites(selectedApp.Key);
        const siteOptions = _.orderBy(
          sites.map((s) => {
            return {
              Id: s.id,
              Key: s.siteName,
              Title: s.siteName,
            };
          }),
          'Title',
          'asc',
        );
        // console.log('onAppSelected - sites', siteOptions);
        await this.setMessage(MESSAGE_SITES, '', { loading: false, siteOptions, subAppKey: '', subAppName: '', selectedSite: '' });
      } catch (error) {
        const message = getApiError(error).message;
        await this.setMessage(MESSAGE_SITES, `Error: ${message}`, { loading: false });
      }
    });
  };

  onSubAppKeyChanged = (e) => {
    this.setState({ subAppKey: e.target.value });
  };

  onSubAppKeySet = () => {
    const app = this.getAppInfo();
    if (app) {
      this.props.whiteLabelAppSet(app);
      this.setState({
        subAppName: app.ClientName || '',
        selectedSite: app.Site || '',
      });
    }
    this.resetProgress();
  };

  onSubAppNameChanged = (e) => {
    this.setState({ subAppName: e.target.value });
  };

  onSelectSite = (key) => {
    const { siteOptions } = this.state;
    const selected = siteOptions.find((o) => o.Key === key);
    if (selected) this.setState({ selectedSite: selected.Id });
  };

  onCreateApp = async (subAppKey) => {
    if (!this.isValid()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ showWarnings: false });

    const { selectedApp } = this.state;
    const actualBranch = this.getActualBranch(subAppKey);
    const baseBranch = selectedApp.Key;

    try {
      await this.resetProgress(true);

      this.checkMultipeAppSite(actualBranch);
      await this.createAppBranch(actualBranch, baseBranch);
      await this.saveAppDetails(actualBranch);
      await this.configureApp(actualBranch);
      await this.setupTestUsers(actualBranch);

      await this.setMessage(MESSAGE_APP, 'Saved successfully', { loading: false });
      setTimeout(() => this.setMessage(MESSAGE_APP, ''), 5000);
    } catch (error) {
      const message = getApiError(error).message;
      await this.setMessage(MESSAGE_APP, `Error: ${message}`, { loading: false });
    }
  };

  onSelectShortcut = (key) => {
    const { onChangeShortcut } = this.props;
    if (onChangeShortcut) onChangeShortcut(key);
  };

  renderAppProgress() {
    const { showProgress, completeAppBranch, completeAppDetails, completeAppConfig, completeAppUsers } = this.state;

    if (!showProgress) return null;

    return (
      <div className="marginTop-16">
        <div className="flex flex-row flex-center marginBottom-5">
          <div>- Setup app code</div>
          {completeAppBranch ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
        </div>
        <div className="flex flex-row flex-center marginBottom-5">
          <div>- Save app details</div>
          {completeAppDetails ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
        </div>
        <div className="flex flex-row flex-center marginBottom-5">
          <div>- Configure app</div>
          {completeAppConfig ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
        </div>
        <div className="flex flex-row flex-center marginBottom-5">
          <div>- Create app review users</div>
          {completeAppUsers ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
        </div>
      </div>
    );
  }

  renderAppDetails() {
    const { selectedApp, loading, subAppKey, subAppName, siteOptions, selectedSite, showWarnings } = this.state;
    const message = this.getMessage(MESSAGE_SITES);

    if (message) {
      return <div className="marginTop-16">{message ? <div>{message}</div> : null}</div>;
    }

    return (
      <div className="marginTop-10">
        <div className="flex flex-row flex-center">
          <div style={{ width: 200 }}>Enter app key</div>
          <div className="flex flex-row flex-center" style={{ width: 500 }}>
            <div className="fontHeavy fontSize-16">{`${(selectedApp && selectedApp.Key) || '[NO APP SELECTED]'}-`}</div>
            <GenericInput
              id="app_name"
              style={{ width: 170, marginTop: 3, marginBottom: 'unset' }}
              type="text"
              placeholder="Enter sub app key here"
              value={subAppKey}
              onChange={this.onSubAppKeyChanged}
              onBlur={this.onSubAppKeySet}
              disabled={loading}
              showError={() => showWarnings && _.isEmpty(subAppKey)}
              errorMessage={'App key is required'}
            />
          </div>
        </div>
        <div className="flex flex-row flex-center" style={{ marginTop: 5 }}>
          <div style={{ width: 200 }}>Enter app name</div>
          <GenericInput
            id="app_name"
            style={{ width: 250, marginTop: 10, marginBottom: 5 }}
            type="text"
            placeholder="Enter sub app name here"
            value={subAppName}
            onChange={this.onSubAppNameChanged}
            disabled={loading}
            showError={() => showWarnings && _.isEmpty(subAppName)}
            errorMessage={'App name is required'}
          />
        </div>
        <div className="flex flex-row flex-center">
          <div style={{ width: 200 }}>Select target site</div>
          <DropdownInput
            id={`dropdown_sites`}
            style={{ width: 250, marginTop: 10, marginBottom: 10 }}
            placeholder="Select Site"
            value={selectedSite}
            options={siteOptions}
            onSelect={this.onSelectSite}
            disabled={loading}
            showError={() => showWarnings && _.isEmpty(selectedSite)}
            errorMessage={'Connected site is required'}
          />
        </div>
        <div className="flex flex-row flex-center">
          <Button className="marginRight-16" inline buttonType="primary" onClick={() => this.onCreateApp(subAppKey)} isActive={!loading}>
            Save
          </Button>
          <div>{this.getMessage(MESSAGE_APP)}</div>
        </div>
      </div>
    );
  }

  render() {
    const { selectedApp, loading } = this.state;
    const completed = this.isComplete();

    return (
      <div>
        {renderTitle('Add a sub app')}
        <ol>
          {renderTextStep(
            <div>
              Select the base app to create the sub app from
              <AppSelector onSelected={this.onAppSelected} selected={selectedApp} disabled={loading} />
            </div>,
          )}
          {selectedApp
            ? renderTextStep(
                <div>
                  Enter sub app details
                  {this.renderAppDetails()}
                  {this.renderAppProgress()}
                </div>,
              )
            : null}
          {completed
            ? renderTextStep(
                <div>
                  What's next?
                  <div className="marginTop-10 flex flex-column">
                    <a onClick={() => this.onSelectShortcut('appAssets')}>Click here to change app images</a>
                    <a onClick={() => this.onSelectShortcut('appColours')}>Click here to change app colours</a>
                  </div>
                </div>,
              )
            : null}
        </ol>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { automation } = state;
  return { activeWhiteLabel: automation.active };
};

export default connect(mapStateToProps, { whiteLabelLoaded, whiteLabelAppSet })(AddSubApp);
