import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import _ from 'lodash';
import { Header, HubSidebar } from '../../components';
import { validateAccess, isFeatureEnabled } from '../../session';
import ImportantContacts from '../ImportantContacts/ImportantContacts';
import Maps from '../Maps/Maps';
import { COLOUR_BRANDING_OFF } from '../../js';
import { CONST_STRINGS } from '../../config';
import InfoPages from './InfoPages';
import { withRouter } from 'react-router-dom';
import { PlussCore } from '../../config/core';

class InfoHub extends Component {
  state = {
    selectedSection: '',
  };

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? 'fontHeavy' : 'fontLight ';
  }

  renderStats(gweg, loading) {
    if (loading) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    return gweg;
  }

  renderLeftBar() {
    const sections = [];

    let addButtonCount = 0;

    if (isFeatureEnabled(this.props.auth.features, 'infoPages') && validateAccess(this.props.auth.site, 'infoPages', this.props.auth)) {
      const infoItems = [];

      infoItems.push({
        type: 'newButton',
        text: `New ${_.startCase(CONST_STRINGS.FAQ_ENTRY)}`,
        onClick: () => {
          this.props.history.push(`/info/infopage`);
        },
      });
      addButtonCount++;

      infoItems.push({
        type: 'navItem',
        text: `View ${CONST_STRINGS.FAQS}`,
        icon: 'eye',
        isFontAwesome: true,
        selected: this.state.selectedSection === 'InfoPages',
        onClick: () => {
          this.setState({ selectedSection: 'InfoPages' });
        },
      });

      sections.push({
        title: CONST_STRINGS.FAQS,
        items: infoItems,
      });
    }

    if (
      isFeatureEnabled(this.props.auth.features, 'importantContacts') &&
      validateAccess(this.props.auth.site, 'importantContacts', this.props.auth)
    ) {
      const contactItems = [];

      contactItems.push({
        type: 'newButton',
        text: `New Contact`,
        onClick: () => {
          this.props.history.push(`/info/importantcontact`);
        },
      });
      addButtonCount++;

      contactItems.push({
        type: 'navItem',
        text: `View Contacts`,
        icon: 'eye',
        isFontAwesome: true,
        selected: this.state.selectedSection === 'Contacts',
        onClick: () => {
          this.setState({ selectedSection: 'Contacts' });
        },
      });

      sections.push({
        title: 'Contacts',
        items: contactItems,
      });
    }

    if (isFeatureEnabled(this.props.auth.features, 'maps') && validateAccess(this.props.auth.site, 'maps', this.props.auth)) {
      const mapItems = [];

      mapItems.push({
        type: 'newButton',
        text: `New Map`,
        onClick: () => {
          this.props.history.push(`/info/map`);
        },
      });
      addButtonCount++;

      mapItems.push({
        type: 'navItem',
        text: `View Maps`,
        icon: 'eye',
        isFontAwesome: true,
        selected: this.state.selectedSection === 'Maps',
        onClick: () => {
          this.setState({ selectedSection: 'Maps' });
        },
      });

      sections.push({
        title: 'Maps',
        items: mapItems,
      });
    }

    if (addButtonCount > 1) {
      sections.forEach((section) => {
        section.items.forEach((item) => {
          if (item.type === 'newButton') {
            item.buttonType = 'outlined';
          }
        });
      });
    }

    return (
      <HubSidebar 
        sections={sections} 
        helpGuide={{
          text: 'Help with Information',
          url: PlussCore.Urls.HelpGuide.Information,
        }}
      />
    );
  }

  renderRight() {
    if (this.state.selectedSection === 'Contacts' && validateAccess(this.props.auth.site, 'importantContacts', this.props.auth)) {
      return <ImportantContacts />;
    }
    if (this.state.selectedSection === 'Maps' && validateAccess(this.props.auth.site, 'maps', this.props.auth)) {
      return <Maps />;
    }
    if (this.state.selectedSection === 'InfoPages' && validateAccess(this.props.auth.site, 'infoPages', this.props.auth)) {
      return <InfoPages />;
    }
    return null;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentWrapper" style={this.state.selectedSection === 'Feedback' ? { padding: 0 } : {}}>
            {this.renderRight()}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
    paddingRight: 32,
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(withRouter(InfoHub));
