import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import {
  Button,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  Text,
  UserListing,
  SuccessPopup,
  DropdownInput,
  DatePicker,
  Tag,
  GenericInput,
  SVGIcon,
} from '../../components';
import { bookingActions, userActions } from '../../webapi';
import { minutesToString, safeReadParams, timepickerToMinutes } from '../../helper';
import { calendarsLoaded, bookingTypesLoaded, usersLoaded } from '../../actions';
import { checkLoggedIn } from '../../session';
import { withRouter } from 'react-router-dom';
import { COLOUR_DUSK } from '../../js';

class AddBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeslots: [],
      notes: '',
    };
  }

  componentDidMount() {
    checkLoggedIn(this, this.props.auth);
    this.getData();
  }

  getData() {
    bookingActions.getCalendarsBySite(this.props.auth.site).then((res) => {
      this.props.calendarsLoaded(res.data.Items);
    });

    bookingActions.getBookingTypesBySite(this.props.auth.site).then((res) => {
      this.props.bookingTypesLoaded(res.data.Items);
    });

    userActions.getSiteUsersRecursive(this.props.auth.site).then((users) => {
      this.props.usersLoaded(users);
    });
  }

  onBack = () => {
    window.history.back();
  };

  inputsDisabled() {
    if (this.state.submitting) {
      return true;
    }
    return false;
  }

  getCalendarOptions = () => {
    return this.props.calendars.map((c) => {
      return { Key: c.Id, Title: c.Title };
    });
  };

  getSelectedCalendar = () => {
    return this.state.selectedCalendar || {};
  };

  getSelectedBookingType = () => {
    return this.state.selectedBookingType || {};
  };

  getTypeTitle = (type) => {
    if (!type || !type.Title) {
      return null;
    }
    return `${type.Title} (${minutesToString(type.TotalLength)})`;
  };

  getTypeOptions = () => {
    const selectedCalendar = this.getSelectedCalendar();
    return this.props.bookingTypes
      .filter((bt) => selectedCalendar.BookingTypes.includes(bt.Id))
      .map((bt) => {
        return { Key: bt.Id, Title: this.getTypeTitle(bt) };
      });
  };

  onSelectCalendar = (calendarId) => {
    const calendar = this.props.calendars.find((c) => c.Id === calendarId);
    console.log(calendar);
    this.setState(
      {
        selectedCalendar: calendar,
        selectedBookingType: null,
        selectedDay: null,
        selectedTime: null,
      },
      () => {
        const typeOptions = this.getTypeOptions();
        if (typeOptions.length === 1) {
          this.onSelectBookingType(typeOptions[0].Key);
        }
      },
    );
  };

  onSelectBookingType = (bookingTypeId) => {
    const bookingType = this.props.bookingTypes.find((bt) => bt.Id === bookingTypeId);
    console.log(bookingType);
    this.setState({
      selectedBookingType: bookingType,
      selectedDay: null,
      selectedTime: null,
    });
  };

  onSelectDate = (date) => {
    this.setState({ selectedDay: date, timeslots: [], selectedTime: null }, this.getTimeslots);
  };

  onSelectTime = (ts) => {
    this.setState({
      selectedTime: ts.StartTime,
    });
  };

  onHandleChange = (event) => {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  };

  onSelectUser = (user) => {
    this.setState({
      selectedUser: user,
    });
  };

  getTimeslots = () => {
    this.setState({
      checkingAvailability: true,
      timeslots: [],
      selectedTime: null,
    });
    bookingActions
      .getTimeslots(this.state.selectedCalendar.Id, this.state.selectedBookingType.Id, moment(this.state.selectedDay).format('DD-MM-YYYY'))
      .then((res) => {
        console.log(res.data.timeslots);
        this.setState({
          checkingAvailability: false,
          timeslots: res.data.timeslots,
        });
      });
  };

  getCombinedTime = () => {
    const timeToUse = moment(this.state.selectedDay);
    if (this.state.selectedDay && this.state.selectedTime) {
      timeToUse.hours(Math.floor(this.state.selectedTime / 60));
      timeToUse.minutes(this.state.selectedTime % 60);
    }
    return timeToUse.local();
  };

  isValidForm = () => {
    if (this.state.makingBooking) {
      return false;
    }
    if (!this.state.selectedCalendar) {
      return false;
    }
    if (!this.state.selectedBookingType) {
      return false;
    }
    if (!this.state.selectedDay) {
      return false;
    }
    if (!this.state.selectedTime) {
      return false;
    }
    if (!this.state.selectedUser) {
      return false;
    }
    return true;
  };

  onBook = async () => {
    if (!this.isValidForm()) {
      return;
    }

    this.setState({
      makingBooking: true,
    });

    bookingActions
      .addBooking(
        this.state.selectedCalendar.Id,
        this.state.selectedBookingType.Id,
        moment(this.state.selectedDay).format('DD-MM-YYYY'),
        this.state.selectedTime,
        this.state.selectedUser.userId,
        'Admin',
        'Admin',
        {
          Id: 'Admin',
          Type: 'Admin',
          Title: 'Admin',
        },
        undefined,
        this.state.notes,
      )
      .then((res) => {
        this.setState({
          success: true,
          booking: res.data.booking,
        });
      })
      .catch((err) => {
        console.log('onBook - error', err);
      })
      .finally(() => {
        this.setState({
          makingBooking: false,
        });
      });
  };

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          {!this.inputsDisabled() ? 'Cancel' : 'Back'}
        </Button>
        {!this.inputsDisabled() && (
          <Button inline buttonType="primary" onClick={this.onBook} isActive={this.isValidForm()}>
            Save
          </Button>
        )}
      </div>
    );
  }

  renderCalendars() {
    return (
      <div className="genericInputContainer">
        <Text type="formLabel" className="marginTop-16">
          Calendar
        </Text>
        <DropdownInput
          id={`calendarDropdown`}
          placeholder="Select calendar"
          value={this.getSelectedCalendar().Title}
          options={this.getCalendarOptions()}
          onSelect={(val) => {
            this.onSelectCalendar(val);
          }}
          style={{ marginBottom: 0 }}
        />
      </div>
    );
  }

  renderBookingTypes() {
    if (!this.state.selectedCalendar) {
      return null;
    }
    return (
      <div className="genericInputContainer">
        <Text type="formLabel">Booking Type</Text>
        <DropdownInput
          id={`bookingTypeDropdown`}
          placeholder="Select booking type"
          value={this.getTypeTitle(this.getSelectedBookingType())}
          options={this.getTypeOptions()}
          onSelect={(val) => {
            this.onSelectBookingType(val);
          }}
          style={{ marginBottom: 0 }}
        />
      </div>
    );
  }

  renderDay() {
    if (!this.state.selectedBookingType) {
      return null;
    }
    return (
      <div className="genericInputContainer">
        <Text type="formLabel" className="marginBottom-5">
          Select Date
        </Text>
        <DatePicker selectedDate={this.state.selectedDay || moment()} selectDate={this.onSelectDate} />
      </div>
    );
  }

  renderTimes() {
    if (!this.state.selectedDay) {
      return null;
    }
    return (
      <div className="genericInputContainer">
        <Text type="formLabel">Select Time</Text>
        <div>
          {this.state.timeslots.map((ts) => {
            const isSelected = this.state.selectedTime === ts.StartTime;
            const hasSelected = !_.isNil(this.state.selectedTime);
            return (
              <Tag
                className="marginRight-10 marginBottom-10"
                style={{ opacity: hasSelected && !isSelected ? 0.5 : 1 }}
                onClick={() => {
                  this.onSelectTime(ts);
                }}
                text={minutesToString(ts.StartTime, true)}
                rightIcon={isSelected ? 'check' : null}
              />
            );
          })}
        </div>
      </div>
    );
  }

  renderNotes() {
    if (!this.state.selectedTime) {
      return null;
    }
    return (
      <GenericInput
        id="notes"
        label="Notes"
        type="textarea"
        placeholder="Insert notes here"
        componentClass="textarea"
        value={this.state.notes}
        onChange={(e) => this.onHandleChange(e)}
        inputStyle={{
          height: 120,
        }}
        alwaysShowLabel
      />
    );
  }

  renderUsers() {
    if (!this.state.selectedTime) {
      return null;
    }
    let content = null;
    if (this.state.selectedUser) {
      content = (
        <div>
          <UserListing
            key={this.state.selectedUser.userId}
            user={this.state.selectedUser}
            rightContent={
              <SVGIcon
                className="removeIcon"
                icon="close"
                onClick={() => {
                  this.onSelectUser();
                }}
                colour={COLOUR_DUSK}
              />
            }
          />
        </div>
      );
    } else {
      content = (
        <div>
          <GenericInput
            id="userSearch"
            type="text"
            // label="Search"
            placeholder="Search name"
            value={this.state.userSearch}
            onChange={(e) => this.onHandleChange(e)}
            alwaysShowLabel
          />
          {_.sortBy(this.props.users, (u) => u.displayName.toUpperCase())
            .filter((u) => {
              if (_.isEmpty(this.state.userSearch)) return true;
              return u.displayName.toUpperCase().indexOf(this.state.userSearch.toUpperCase()) > -1;
            })
            .map((user) => {
              return (
                <UserListing
                  key={user.userId}
                  user={user}
                  onClick={() => {
                    this.onSelectUser(user);
                  }}
                />
              );
            })}
        </div>
      );
    }
    return (
      <div className="genericInputContainer">
        <Text type="formLabel">Select User</Text>
        {content}
      </div>
    );
  }

  renderForm = () => {
    if (this.state.success) {
      return null;
    }
    return (
      <div>
        <div className="padding-60 paddingVertical-40">
          <Text type="formTitleLarge" className="marginBottom-24 text-capitaliseWords">
            Make Booking
          </Text>
          {this.renderCalendars()}
          {this.renderBookingTypes()}
          {this.renderDay()}
          {this.renderTimes()}
          {this.renderNotes()}
          {this.renderUsers()}
        </div>
      </div>
    );
  };

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`Booking has been added`}
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              this.props.history.push(`/bookings/booking/${this.state.booking.Id}`);
            },
            text: 'View booking',
          },
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  render = () => {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--fixedPopupSize">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  };
}

const mapStateToProps = (state) => {
  const { calendars, types } = state.bookings;
  const { users } = state.users;
  const { auth } = state;
  return {
    users,
    calendars,
    auth,
    bookingTypes: types,
  };
};

export default connect(mapStateToProps, { calendarsLoaded, bookingTypesLoaded, usersLoaded })(withRouter(AddBooking));
