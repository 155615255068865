import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup } from 'react-bootstrap';
import _ from 'lodash';
import { infoPagesUpdate, addRecentlyCreated } from '../../actions';
import {
  Button,
  GenericInput,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  SuccessPopup,
  RadioButton,
  TextFormatPopup,
  OptionsSection,
  P60Icon,
  FileInput,
  AudienceSelector,
} from '../../components';
import { infoActions, fileActions } from '../../webapi';
import { getFileName, safeReadParams } from '../../helper';
import { checkLoggedIn } from '../../session';
import { CONST_STRINGS } from '../../config';
import { Text } from '../../components/text';

class AddInfoPage extends Component {
  initialState = {
    infoId: safeReadParams(this.props, 'infoId'),
    titleInput: '',
    textInput: '',
    attachments: [],
    showWarnings: false,
    success: false,
    submitting: false,
    shouldNotify: false,

    isAudienceValid: true,
    audienceType: '',
    audienceTypeSelection: [],
  };

  state = { ...this.initialState };

  UNSAFE_componentWillReceiveProps(nextProps) {}

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    if (this.state.infoId) {
      this.getData();
    }
  }

  componentDidMount() {
    this.props.addRecentlyCreated('infoPages');
  }

  getData() {
    infoActions.getInfoPage(this.state.infoId).then((res) => {
      this.parseUpdate(res.data);
    });
  }

  parseUpdate(update) {
    this.setState({
      titleInput: update.title,
      textInput: update.text,
      attachments: _.isEmpty(update.attachments) ? [] : update.attachments,
      shouldNotify: update.notify,
      audienceType: update.AudienceType || '',
      audienceTypeSelection: update.AudienceTypeSelection || [],
    });
  }

  isEmpty(text) {
    return text === '';
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handlePDFFileChange(event, attachment) {
    const file = event.target.files[0];
    if (!file || attachment.Uploading) {
      return;
    }
    attachment.Uploading = true;
    this.setState({
      attachments: this.state.attachments,
    });
    fileActions
      .uploadMediaAsync(file, file.name)
      .then((fileRes) => {
        const newAttachments = [...this.state.attachments];
        const attachmentIndex = newAttachments.indexOf(attachment);
        if (attachmentIndex !== -1) {
          newAttachments[attachmentIndex].Uploading = false;
          newAttachments[attachmentIndex].Source = fileRes;
          const fileSplit = fileRes.split('/');
          newAttachments[attachmentIndex].Title = _.last(fileSplit).split('.')[0];
          this.setState({
            attachments: newAttachments,
          });
        }
      })
      .catch((uploadErrorRes) => {
        attachment.Uploading = false;
        this.setState({
          attachments: this.state.attachments,
        });
      });
  }

  handleAttachmentChange(index, column, event) {
    var stateChange = {
      attachments: this.state.attachments,
    };
    stateChange.attachments[index][column] = event.target.value;
    this.setState(stateChange);
  }

  getAttachments() {
    const attachments = [];
    this.state.attachments.forEach((attachment) => {
      if (!attachment.Removed && !_.isEmpty(attachment.Source)) {
        attachments.push({
          Id: attachment.Id,
          Title: attachment.Title,
          Source: attachment.Source,
        });
      }
    });
    return attachments;
  }

  addAttachment = (url) => {
    if (_.isEmpty(url)) {
      return;
    }
    const newReps = [...this.state.attachments];
    const id = _.isEmpty(this.state.attachments) ? 0 : _.maxBy(this.state.attachments, 'Id').Id + 1;
    newReps.push({
      Id: id,
      Title: getFileName(url, true),
      Source: url,
      Uploading: false,
      Type: 'pdf',
    });
    this.setState({
      attachments: newReps,
    });

    this.pdFileInput && this.pdFileInput.getWrappedInstance().setValue('');
  };

  removeAttachment(index) {
    const newReps = [...this.state.attachments];
    newReps[index].Removed = true;
    this.setState({
      attachments: newReps,
    });
  }

  toggleTextFormat(isOpen) {
    this.setState({
      textFormatOpen: isOpen,
    });
  }

  validateLoading() {
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  validateCompulsoryText() {
    if (this.isEmpty(this.state.titleInput)) {
      return false;
    }
    return true;
  }

  validateForm() {
    if (!this.validateCompulsoryText()) {
      return false;
    }
    if (!this.validateLoading()) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true });

    if (this.state.infoId) {
      infoActions
        .editInfoPage(
          this.props.auth.site,
          this.state.infoId,
          this.state.titleInput,
          this.state.textInput,
          undefined,
          this.getAttachments(),
          this.state.shouldNotify,
          {
            AudienceType: this.state.audienceType,
            AudienceTypeSelection: this.state.audienceTypeSelection,
          },
        )
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
          this.props.infoPagesUpdate(this.props.auth.site);
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    } else {
      infoActions
        .addInfoPage(
          this.props.auth.site,
          this.state.titleInput,
          this.state.textInput,
          undefined,
          this.getAttachments(),
          this.state.shouldNotify,
          {
            AudienceType: this.state.audienceType,
            AudienceTypeSelection: this.state.audienceTypeSelection,
          },
        )
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
          this.props.infoPagesUpdate(this.props.auth.site);
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  clearForm() {
    this.setState(this.initialState);
  }

  clearSuccess() {
    this.setState({
      success: false,
      submitting: false,
      showWarnings: false,
    });
  }

  selectOption = (o) => {
    this.setState({
      selectedOption: o,
    });
  };

  inputsDisabled() {
    if (this.state.submitting) {
      return true;
    }
    return false;
  }

  onChangeAudience = (audienceType, audienceTypeSelection, isAudienceValid) => {
    this.setState({ audienceType, audienceTypeSelection, isAudienceValid });
  };

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`${CONST_STRINGS.FAQ_ENTRY} has been ${this.state.infoId != null ? 'edited' : 'added'}`}
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  renderSelectedOption(selectedOption) {
    return (
      <div>
        <div style={{ display: selectedOption === 'publishOptions' ? 'block' : 'none' }}>{this.renderPublishOptions()}</div>
        <div style={{ display: selectedOption === 'attachments' ? 'block' : 'none' }}>{this.renderAttachments()}</div>
        <div style={{ display: selectedOption === 'audience' ? 'block' : 'none' }}>{this.renderAudience()}</div>
      </div>
    );
  }

  renderOptionsSection() {
    let options = [
      {
        key: 'publishOptions',
        icon: 'bell',
        text: 'Publish Options',
      },
      {
        key: 'audience',
        icon: 'audience',
        text: 'Audience',
      },
      {
        key: 'attachments',
        icon: 'attachments',
        text: 'Attachments',
      },
    ];

    if (_.isEmpty(options)) {
      return null;
    }

    const selectedOption = this.state.selectedOption || options[0].key;

    return (
      <OptionsSection options={options} selected={selectedOption} selectOption={this.selectOption}>
        {this.renderSelectedOption(selectedOption)}
      </OptionsSection>
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          {!this.inputsDisabled() ? 'Cancel' : 'Back'}
        </Button>
        {!this.inputsDisabled() && (
          <Button inline buttonType="primary" onClick={() => this.handleSubmit()} isActive={this.validateForm()}>
            Save
          </Button>
        )}
      </div>
    );
  }

  renderAddAttachment() {
    if (this.inputsDisabled()) {
      return null;
    }
    return (
      <FileInput
        ref={(ref) => {
          this.pdFileInput = ref;
        }}
        style={{ height: 120, width: 240 }}
        refreshCallback={this.addAttachment}
        accept="application/pdf"
        simpleStyle
      />
    );
  }

  renderAttachments() {
    return (
      <div className="optionsContent_bottom">
        <FormGroup controlId="eventAttachment">
          {this.state.attachments.map((attachment, index) => {
            if (attachment.Removed) {
              return null;
            }
            return (
              <div key={index} className="pdfAttachmentInput">
                <img
                  src="https://s3-ap-southeast-2.amazonaws.com/pluss60-dev-media/pluss/document.svg"
                  className="pdfAttachmentInput_icon"
                  alt="file"
                />
                <Text type="formTitleSmall" className="pdfAttachmentInput_title">
                  {getFileName(attachment.Source)}
                </Text>
                <GenericInput
                  id={`eventAttachmentTitle${index}`}
                  label="Title"
                  type="text"
                  placeholder="Title"
                  value={attachment.Title}
                  onChange={(e) => this.handleAttachmentChange(index, 'Title', e)}
                  disabled={this.inputsDisabled()}
                  alwaysShowLabel
                  style={{ margin: 0, flex: 1 }}
                />
                <P60Icon
                  className="removeoption pdfAttachmentInput_remove"
                  icon="remove-circle"
                  onClick={() => {
                    this.removeAttachment(index);
                  }}
                />
              </div>
            );
          })}
          {this.renderAddAttachment()}
        </FormGroup>
      </div>
    );
  }

  renderPublishOptions() {
    return (
      <div className="optionsContent_bottom">
        <RadioButton
          label="Do you want to send a notification with this information?"
          isActive={this.state.shouldNotify}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ shouldNotify: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ shouldNotify: false }) },
          ]}
          help="This will send an alert to all users who have enabled push notifications."
        />
      </div>
    );
  }

  renderAudience() {
    const { auth } = this.props;
    const { audienceType, audienceTypeSelection } = this.state;

    return (
      <div className="optionsContent_bottom">
        <AudienceSelector
          onChange={this.onChangeAudience}
          auth={auth}
          custom
          audienceType={audienceType}
          audienceTypeSelection={audienceTypeSelection}
          disallowSingleUsers
          disallowUserType
        />
      </div>
    );
  }

  renderForm() {
    if (this.state.success) {
      return null;
    }
    return (
      <div>
        <div className="padding-60 paddingVertical-40">
          <Text type="formTitleLarge" className="marginBottom-24 text-capitaliseWords">
            {this.state.updateId == null ? 'New' : 'Edit'} {CONST_STRINGS.FAQ_ENTRY}
          </Text>
          {/* title */}
          <GenericInput
            id="titleInput"
            type="text"
            label="Title"
            placeholder="Insert title here"
            value={this.state.titleInput}
            onChange={(e) => this.handleChange(e)}
            isRequired
            isValid={() => {
              return !this.isEmpty(this.state.titleInput);
            }}
            showError={() => {
              return this.state.showWarnings && this.isEmpty(this.state.titleInput);
            }}
            alwaysShowLabel
          />
          {/* description */}
          <GenericInput
            id="textInput"
            label="Text"
            type="textarea"
            placeholder="Insert title here"
            componentClass="textarea"
            value={this.state.textInput}
            onChange={(e) => this.handleChange(e)}
            inputStyle={{
              height: 120,
            }}
            help={
              <a
                onClick={() => {
                  this.toggleTextFormat(true);
                }}
                className="pointer"
              >
                Text formatting
              </a>
            }
            alwaysShowLabel
          />
        </div>
        {this.renderOptionsSection()}
      </div>
    );
  }

  render() {
    return (
      <OverlayPage>
        <TextFormatPopup onClose={this.toggleTextFormat.bind(this, false)} isOpen={this.state.textFormatOpen} />
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { infoPagesUpdate, addRecentlyCreated })(AddInfoPage);
