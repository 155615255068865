import React, { useState, useEffect } from 'react';
import { Button, ExportCsvPopup, Text, StatBox } from '../';
import { faMobileAlt, faDesktop } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { analyticsActions } from '../../webapi';
import { countActivities } from '../../js';
import { validateAccess } from '../../session';
import moment from 'moment';

const getInitialState = () => ({
  activeAppUsers: 0,
  activeWebUsers: 0,
  prevActiveAppUsers: 0,
  prevActiveWebUsers: 0,
  isLoading: true,
});

// UserAnalytics Component
const UserAnalytics = ({ startTime, endTime, auth, prevText, dayCount }) => {
  const [analyticsData, setAnalyticsData] = useState(getInitialState());
  const [isExportOpen, setIsExportOpen] = useState(false);

  const hasAccess = validateAccess(auth.site, 'userManagement', auth);
  if (!hasAccess) {
    return null;
  }

  const exportColumns = [
    { label: 'Select All', key: '' },
    { label: 'Start Date', key: 'startDate' },
    { label: 'End Date', key: 'endDate' },
    { label: 'App Users', key: 'appUsers' },
    { label: 'Community Manager Users', key: 'webUsers' },
  ];

  useEffect(() => {
    getData();
  }, [startTime, endTime]);

  const getData = async () => {
    setAnalyticsData(getInitialState());
    // Load analytics data here using startTime and endTime
    const timeDifference = endTime - startTime;
    const [currentStatsResponse, prevStatsResponse] = await Promise.all([
      analyticsActions.getAggregateEntityStats(auth.site, 'user', startTime, endTime, true),
      analyticsActions.getAggregateEntityStats(auth.site, 'user', startTime - timeDifference, startTime, true),
    ]);

    const data = {
      appUsers: countActivities(currentStatsResponse.data, 'AppUse', 'unique'),
      prevAppUsers: countActivities(prevStatsResponse.data, 'AppUse', 'unique'),
      webUsers: countActivities(currentStatsResponse.data, 'AdminUse', 'unique'),
      prevWebUsers: countActivities(prevStatsResponse.data, 'AdminUse', 'unique'),
      isLoading: false,
    };
    setAnalyticsData(data);
  };

  const isReadyToOpenCSV = () => {
    return !analyticsData.isLoading;
  };

  const getExportSource = () => {
    return [
      {
        startDate: moment(startTime + 1).format('D-MM-YYYY'),
        endDate: moment(endTime).format('D-MM-YYYY'),
        appUsers: analyticsData.appUsers,
        webUsers: analyticsData.webUsers,
      },
    ];
  };

  const csvPopup = () => {
    if (!isExportOpen) {
      return null;
    }
    const source = getExportSource();
    return (
      <ExportCsvPopup
        onClose={() => {
          setIsExportOpen(false);
        }}
        columns={exportColumns}
        source={source}
        filename={`useranalytics_${source[0].startDate}_${source[0].endDate}.csv`}
      />
    );
  };

  return (
    <div className="dashboardSection">
      {csvPopup()}
      <div>
        <Text type="h4" className="inlineBlock marginRight-40">
          Users
        </Text>

        <Button
          inline
          buttonType="primaryAction"
          onClick={() => {
            if (!isReadyToOpenCSV()) return;
            setIsExportOpen(true);
          }}
          isActive={isReadyToOpenCSV()}
          leftIcon="file-code-o"
        >
          Export CSV
        </Button>
      </div>
      <div className="analyticsSection dashboardSection_content">
        <StatBox
          title="App Users"
          icon={faMobileAlt}
          value={analyticsData.appUsers}
          previousValue={analyticsData.prevAppUsers}
          prevText={prevText}
          viewGraphLink={`/chart?entity=user&startTime=${startTime}&endTime=${endTime}&key=AppUse&countType=unique&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
        <StatBox
          title="Community Manager Users"
          icon={faDesktop}
          value={analyticsData.webUsers}
          previousValue={analyticsData.prevWebUsers}
          prevText={prevText}
          viewGraphLink={`/chart?entity=user&startTime=${startTime}&endTime=${endTime}&key=AdminUse&countType=unique&dayCount=${dayCount}`}
          isLoading={analyticsData.isLoading}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

const toExport = connect(mapStateToProps, {})(UserAnalytics);
export { toExport as UserAnalytics };
