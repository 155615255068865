import _ from 'lodash';
import { FACILITIES_LOADING, FACILITIES_LOADED, FACILITY_REMOVED, FACILITIES_PURGE } from './types';
import { facilityActions } from '../webapi';
import { readStorageWithCookie } from '../helper';

export const facilitiesUpdate = (site, isdashboard) => {
  return (dispatch) => {
    if (isdashboard) {
      dispatch({
        type: FACILITIES_LOADING,
      });
    }
    facilityActions.getFacilities(site).then((res) => {
      const currentSiteFacys = readStorageWithCookie('site');
      if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === currentSiteFacys) {
        dispatch({
          type: FACILITIES_LOADED,
          payload: res.data,
        });
      } else {
        dispatch({
          type: FACILITIES_LOADED,
          payload: [],
        });
      }
    });
  };
};

export const facilitiesLoaded = (facilities) => {
  return {
    type: FACILITIES_LOADED,
    payload: facilities,
  };
};

export const removeFacility = (id) => {
  return {
    type: FACILITY_REMOVED,
    payload: id,
  };
};

export const clearFacilities = () => {
  return {
    type: FACILITIES_PURGE,
  };
};
