import React from 'react';
import { SVGIcon } from '../../../../../components';
import { COLOUR_BRANDING_MAIN, hexToRGBAstring } from '../../../../../js';

export default () => {
  return (
    <div className="fp2_widget_content fp2_widget_content-people">
      <div className="fp2_widget_content_vListing">
        <div className="fp2_widget_silhouette fp2_widget_silhouette-circle">
          <SVGIcon icon={'people4'} colour={hexToRGBAstring(COLOUR_BRANDING_MAIN, 0.5)} />
        </div>
        <div className="flex-1">
          <div className="fp2_widget_silhouette fp2_widget_silhouette-textLine"></div>
          <div className="fp2_widget_silhouette fp2_widget_silhouette-textLineFull"></div>
        </div>
      </div>
      <div className="fp2_widget_content_vListing">
        <div className="fp2_widget_silhouette fp2_widget_silhouette-circle">
          <SVGIcon icon={'people4'} colour={hexToRGBAstring(COLOUR_BRANDING_MAIN, 0.5)} />
        </div>
        <div className="flex-1">
          <div className="fp2_widget_silhouette fp2_widget_silhouette-textLine"></div>
          <div className="fp2_widget_silhouette fp2_widget_silhouette-textLineFull"></div>
        </div>
      </div>
      <div className="fp2_widget_content_vListing">
        <div className="fp2_widget_silhouette fp2_widget_silhouette-circle">
          <SVGIcon icon={'people4'} colour={hexToRGBAstring(COLOUR_BRANDING_MAIN, 0.5)} />
        </div>
        <div className="flex-1">
          <div className="fp2_widget_silhouette fp2_widget_silhouette-textLine"></div>
          <div className="fp2_widget_silhouette fp2_widget_silhouette-textLineFull"></div>
        </div>
      </div>
    </div>
  );
};
