import _ from 'lodash';
import { IMAGE_LIBRARY_DASHBOARD_LOADING, IMAGE_LIBRARY_LOADED, IMAGE_FOLDER_UPDATED, IMAGE_FOLDER_REMOVED } from './types';
import { fileActions } from '../webapi';
import { readStorageWithCookie } from '../helper';

export const imageLibraryUpdate = (site, isdashboard) => {
  return (dispatch) => {
    if (isdashboard) {
      dispatch({
        type: IMAGE_LIBRARY_DASHBOARD_LOADING,
      });
    }
    fileActions.getMediaFolders(site).then((res) => {
      const currentSite = readStorageWithCookie('site');
      if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === currentSite) {
        dispatch({
          type: IMAGE_LIBRARY_LOADED,
          payload: res.data,
        });
      } else {
        dispatch({
          type: IMAGE_LIBRARY_LOADED,
          payload: [],
        });
      }
    });
  };
};

export const imageFolderUpdated = (folder) => {
  return {
    type: IMAGE_FOLDER_UPDATED,
    payload: folder,
  };
};

export const imageFolderRemoved = (folder) => {
  return {
    type: IMAGE_FOLDER_REMOVED,
    payload: folder,
  };
};
