import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AddForm from '../Forms/AddForm';

class SignUpQuestions extends Component {
  state = {
    terms: '',
    termsTitle: 'Terms & Conditions',
    loaded: false,
    showWarnings: false,
  };

  render() {
    return (
      <div style={{ width: '100%' }}>
        <AddForm
          formType="signUpQuestions"
          formId={`${this.props.auth.site}_signUpQuestions`}
          id="signUpQuestions"
          hiddenFields={['signature']}
          noFail
          hidePreview
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(SignUpQuestions);
