import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import _ from 'lodash';
import axios from 'axios';
import { connect } from 'react-redux';
import md5 from 'crypto-js/md5';
import { GenericInput, DropdownInput, Button, FileInput } from '../../components';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded, whiteLabelReset } from '../../actions';
import { isTheBest, getApiError } from '../../session';
import {
  renderTitle,
  renderDescription,
  renderOpenLinkStep,
  renderTextStep,
  renderImageStep,
  renderEmbed,
  renderCopyToClipboardText,
  getWhiteLabelAppUser,
} from './helper';

const REVIEW_USER_PREFIX = 'apple';
const REVIEW_USER_MISSING = '[MISSING REVIEW USER]';
const STEP_ENABLE_API_FILE = '1.1';
const STEP_ENABLE_API_KEY = '1.2';
const STEP_BUILD_IPA = '2';
const STEP_SUBMIT_CHECK = '3.1';
const STEP_SUBMIT_INIT = '3.2';
const SCREENSHOT_WIDTH = 220;
const SCREENSHOT_HEIGHT = 240;
const SCREENSHOT_TYPE_IPHONE65 = 'APP_IPHONE_65';
const SCREENSHOT_TYPE_IPHONE55 = 'APP_IPHONE_55';
const SCREENSHOT_TYPE_IPAD129_3RD = 'APP_IPAD_PRO_3GEN_129';
const SCREENSHOT_TYPE_IPAD129_2ND = 'APP_IPAD_PRO_129';

class AppSetupiOS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteLabelInfo: _.cloneDeep(props.activeWhiteLabel),
      selectedApp: '',
      loading: false,
      loadingMessage: '',
      resetMessage: '',
      message: {},
      issuerId: '',
      keyId: '',
      description: '',
      keywords: '',
      promotionalText: '',
    };
    this.steps = [
      { label: 'Select App', name: '0', render: this.renderStepSelectApp },
      { label: 'Enable API', name: '1', render: this.renderStepEnableApi },
      { label: 'Set up iOS App', name: '2', render: this.renderStepSetupIosApp },
      { label: 'Build IPA', name: '3', render: this.renderStepBuildIpa },
      { label: 'Upload IPA', name: '4', render: this.renderStepUploadIpa },
      { label: 'Set up Review User', name: '5', render: this.renderStepSetupReviewUser },
      { label: 'Submit for Review', name: '6', render: this.renderStepSubmitForReview },
    ];
  }

  componentDidMount() {
    // TEST: Preset environment
    // this.setState({ whiteLabelInfo: { ClientCode: 'autotest1' } }, this.onLoadWhiteLabel);
  }

  componentDidUpdate(prevProps) {
    const { activeWhiteLabel } = this.props;
    if (!_.isEqual(prevProps.activeWhiteLabel, activeWhiteLabel)) {
      this.setState({ whiteLabelInfo: activeWhiteLabel }, this.setAppInfo);
    }
  }

  getAppBranchName = (defaultName = '{MISSING_NAME}') => {
    const { selectedApp, whiteLabelInfo } = this.state;
    return selectedApp || whiteLabelInfo.ClientCode || defaultName;
  };

  getAppBundleId = () => this.getAppBranchName().replace('-', '');

  getAppInfo = () => {
    const { selectedApp, whiteLabelInfo } = this.state;
    if (!selectedApp && !(whiteLabelInfo && whiteLabelInfo.Apps)) return {};

    return (whiteLabelInfo.Apps && whiteLabelInfo.Apps.find((a) => a.ClientCode === (selectedApp || whiteLabelInfo.ClientCode))) || {};
  };

  getAppProperty = (key) => {
    const app = this.getAppInfo();
    return app ? app[key] : null;
  };

  setAppInfo = () => {
    const app = this.getAppInfo();
    // console.log('setAppInfo', app);
    const { AppStoreApiIssuerId, AppStoreApiKeyId, AppStoreDescription, AppStoreKeywords, AppStorePromotionalText } = app;
    this.setState({
      issuerId: AppStoreApiIssuerId,
      keyId: AppStoreApiKeyId,
      description: AppStoreDescription || '',
      keywords: AppStoreKeywords || '',
      promotionalText: AppStorePromotionalText || '',
    });
  };

  getScreenshotUrl = (templateUrl) =>
    templateUrl ? templateUrl.replace('{w}', SCREENSHOT_WIDTH).replace('{h}', SCREENSHOT_HEIGHT).replace('{f}', 'png') : '';

  getScreenshotMessage = (type, index) => {
    return _.clone(this.state[`${type}_${index}`]);
  };

  setScreenshotMessage = (type, index, message) => {
    const update = {};
    update[`${type}_${index}`] = message;
    this.setState(update);
  };

  hasApiInfo = () => {
    const hasAppStoreApiKey = !_.isEmpty(this.getAppProperty('AppStoreApiKey'));
    const hasIssuerId = !_.isEmpty(this.getAppProperty('AppStoreApiIssuerId'));
    const hadKeyId = !_.isEmpty(this.getAppProperty('AppStoreApiKeyId'));
    return hasAppStoreApiKey && hasIssuerId && hadKeyId;
  };

  onMoveStep = (stepIndex) => {
    if (this.props.onMoveStep) this.props.onMoveStep(stepIndex);
  };

  onBranchNameChanged = (event) => {
    const { whiteLabelInfo } = this.state;
    const newInfo = _.cloneDeep(whiteLabelInfo);
    newInfo.ClientCode = event.target.value.replace(' ', '-');
    this.setState({ whiteLabelInfo: newInfo });
  };

  onBranchChanged = (key) => {
    this.setState({ selectedApp: key, resetMessage: '' }, this.setAppInfo);
  };

  onClearClientCode = () => {
    this.props.whiteLabelReset();
    this.setState({ loading: false, loadingMessage: '', resetMessage: '', selectedApp: '' }, this.setAppInfo);
  };

  onLoadWhiteLabel = () => {
    const { whiteLabelInfo } = this.state;
    const { activeWhiteLabel } = this.props;
    const clientCode = whiteLabelInfo.ClientCode;
    if (clientCode === activeWhiteLabel.ClientCode) return;
    if (!clientCode) {
      this.onClearClientCode();
      return;
    }

    let loadingMessage = `Loading ${clientCode} details...`;
    this.setState({ loading: true, loadingMessage, resetMessage: '' }, async () => {
      try {
        const { data } = await automationActions.getWhiteLabel(clientCode);
        const selectedApp = data ? data.ClientCode : '';
        // console.log('onLoadWhiteLabel', data, 'selectedApp', selectedApp);
        loadingMessage = data ? `Details for ${data.ClientName} has been loaded` : `${clientCode} does not exist`;
        this.setState({ loading: false, loadingMessage, selectedApp }, () => {
          if (data) {
            this.props.whiteLabelLoaded(data);
          } else {
            this.props.whiteLabelReset();
          }
        });
      } catch (error) {
        this.setState({ loading: false, loadingMessage: getApiError(error).message });
      }
    });
  };

  onAddApiKeyFile = (file) => {
    return new Promise((resolve, reject) => {
      const { message, whiteLabelInfo } = this.state;

      var reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        message[STEP_ENABLE_API_FILE] = 'Processing...';
        this.setState({ loading: true, message }, async () => {
          try {
            const appStoreApiKey = e.target.result;
            const { data } = await automationActions.setupAppStoreApiKey(whiteLabelInfo.RowId, this.getAppBranchName(''), appStoreApiKey);
            message[STEP_ENABLE_API_FILE] = 'Saved successfully';
            this.props.whiteLabelLoaded(data);
            this.setState({ loading: false, message }, resolve());
          } catch (error) {
            message[STEP_ENABLE_API_FILE] = getApiError(error).message;
            this.setState({ loading: false, message }, reject(error));
          }
        });
      };
      reader.onerror = (error) => {
        message[STEP_ENABLE_API_FILE] = error.message;
        reject(error);
      };
    });
  };

  onSaveAppStoreKeyInfo = () => {
    const { whiteLabelInfo, issuerId, keyId, message } = this.state;
    message[STEP_ENABLE_API_KEY] = 'Saving...';
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.saveAppStoreApiInfo(whiteLabelInfo.RowId, this.getAppBranchName(''), issuerId, keyId);
        message[STEP_ENABLE_API_KEY] = 'Saved successfully';
        this.props.whiteLabelLoaded(data);
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_ENABLE_API_KEY] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  onSetupBundleId = () => {
    const { whiteLabelInfo, message } = this.state;
    message[STEP_BUILD_IPA] = 'Saving...';
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.setupAppStoreBundleId(whiteLabelInfo.RowId, this.getAppBranchName(''));
        message[STEP_BUILD_IPA] = 'Saved successfully';
        this.props.whiteLabelLoaded(data);
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_BUILD_IPA] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  onSaveAppId = () => {
    const { whiteLabelInfo, message } = this.state;
    message[STEP_SUBMIT_CHECK] = 'Checking build status...';
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.saveAppStoreAppId(whiteLabelInfo.RowId, this.getAppBranchName(''));
        // console.log('onSaveAppId', data);
        message[STEP_SUBMIT_CHECK] = '';
        this.props.whiteLabelLoaded(data);
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_SUBMIT_CHECK] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  onResetUser = () => {
    const { whiteLabelInfo, selectedApp } = this.state;
    this.setState({ loading: true, resetMessage: 'Setting up review user...' }, async () => {
      try {
        const { data } = await automationActions.resetTestUser(
          whiteLabelInfo.RowId,
          selectedApp || whiteLabelInfo.ClientCode,
          REVIEW_USER_PREFIX,
        );
        // console.log('onResetUser', data);
        this.setState({ loading: false, resetMessage: 'Review user has been set up successfully' }, () => {
          if (data) this.props.whiteLabelLoaded(data);
        });
      } catch (error) {
        this.setState({ loading: false, resetMessage: getApiError(error).message });
      }
    });
  };

  onUploadScreenshot = async (type, index, file) => {
    const { whiteLabelInfo } = this.state;

    try {
      const appClientCode = this.getAppBranchName('');
      const checksum = md5(file).toString();
      this.setScreenshotMessage(type, index, `Start uploading ${file.name}...`);
      const { data: begin } = await automationActions.beginScreenshotUpload(
        whiteLabelInfo.RowId,
        appClientCode,
        type,
        file.name,
        file.size,
      );
      const uploadId = begin.id;
      this.setScreenshotMessage(type, index, `Reserving ${file.name} upload...`);
      const uploadOperation = begin.attributes.uploadOperations[0];

      // Upload screenshot
      const { method, url, requestHeaders } = uploadOperation;
      const headers = {};
      requestHeaders.forEach((h) => (headers[h.name] = h.value));
      this.setScreenshotMessage(type, index, `Uploading ${file.name}...`);
      await axios({
        method,
        url,
        headers,
        data: file,
      });
      this.setScreenshotMessage(type, index, `Finish uploading ${file.name}...`);

      const { data: end } = await automationActions.endScreenshotUpload(
        whiteLabelInfo.RowId,
        appClientCode,
        type,
        index,
        uploadId,
        checksum,
      );
      this.setScreenshotMessage(type, index, '');
      this.props.whiteLabelLoaded(end);
    } catch (error) {
      const apiError = getApiError(error);
      console.error('onUploadScreenshot', apiError);
      const message = apiError.message || apiError;
      this.setScreenshotMessage(type, index, `Error - ${message}`);
    }
  };

  onInitialiseAppStore = () => {
    const { whiteLabelInfo, message, description, keywords, promotionalText } = this.state;
    message[STEP_SUBMIT_INIT] = 'Initialising app store...';
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.initAppStore(
          whiteLabelInfo.RowId,
          this.getAppBranchName(''),
          description,
          keywords,
          promotionalText,
        );
        message[STEP_SUBMIT_INIT] = 'Initialised successfully';
        this.props.whiteLabelLoaded(data);
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_SUBMIT_INIT] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  renderBranchInput = () => {
    const { whiteLabelInfo, loading, loadingMessage } = this.state;
    const { activeWhiteLabel } = this.props;
    const branchName = whiteLabelInfo ? whiteLabelInfo.ClientCode : '';
    const isBranchValid = !_.isEmpty(branchName);
    const canClear = isBranchValid && activeWhiteLabel && activeWhiteLabel.ClientCode === branchName;

    return (
      <div className="flex flex-row flex-center">
        <GenericInput
          className="marginTop-10"
          id="branch"
          style={{ width: 300 }}
          type="text"
          placeholder="Client code"
          isValid={() => isBranchValid}
          value={branchName}
          onChange={this.onBranchNameChanged}
          onBlur={this.onLoadWhiteLabel}
          isRequired
        />
        <Button
          style={{ width: 90 }}
          className="marginLeft-24"
          inline
          buttonType="primary"
          onClick={canClear ? this.onClearClientCode : null}
          isActive={branchName && !loading}
        >
          {canClear ? 'Clear' : 'Load'}
        </Button>
        <div className="marginLeft-16">{loadingMessage}</div>
      </div>
    );
  };

  renderSelectApp = () => {
    const { whiteLabelInfo, loading } = this.state;
    const branches = whiteLabelInfo.Apps
      ? whiteLabelInfo.Apps.map((b) => {
          return { Title: b.ClientCode, Key: b.ClientCode };
        })
      : [];

    return (
      <DropdownInput
        id={`dropdown_app`}
        style={{ width: 250, marginBottom: 'unset', marginTop: 10 }}
        placeholder="Select App"
        value={this.getAppBranchName('')}
        options={branches}
        onSelect={this.onBranchChanged}
        disabled={loading}
      />
    );
  };

  renderBundleId = () => {
    const { message } = this.state;
    const app = this.getAppInfo();
    const exists = app.BundleId && app.BundleIdKey;

    return (
      <div className="marginTop-10">
        {exists ? (
          <div>
            <div>
              {`Bundle Id: ${app.BundleId}`}
              <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
            </div>
            <div>
              {`Bundle Id Key: ${app.BundleIdKey}`}
              <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
            </div>
          </div>
        ) : (
          <Button style={{ width: 90 }} inline buttonType="primary" onClick={this.onSetupBundleId} isActive={true}>
            Setup
          </Button>
        )}
        <div className="marginTop-10">{message[STEP_BUILD_IPA]}</div>
      </div>
    );
  };

  renderAppId = () => {
    const { message } = this.state;
    const app = this.getAppInfo();
    // console.log('renderAppId', app);
    const appStoreIdExists = !_.isEmpty(app.AppStoreAppId);

    return (
      <div className="flex flex-row flex-center marginTop-10">
        {appStoreIdExists ? (
          <div>
            {`App Id: ${app.AppStoreAppId}`}
            <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
          </div>
        ) : (
          <Button style={{ width: 90 }} inline buttonType="primary" onClick={this.onSaveAppId} isActive={true}>
            Save
          </Button>
        )}
        <div className="marginLeft-16">{message[STEP_BUILD_IPA]}</div>
      </div>
    );
  };

  renderStepSelectApp = () => {
    return (
      <div>
        {renderTitle('Select App')}
        {renderDescription('Select iOS app to build.')}
        <ol>
          {renderTextStep(
            <div>
              Enter client code (e.g. aveo)
              {this.renderBranchInput()}
            </div>,
          )}
          {renderTextStep(
            <div>
              Select app
              {this.renderSelectApp()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStepEnableApi = () => {
    const { message, loading, issuerId, keyId } = this.state;
    const hasAppStoreApiKey = !_.isEmpty(this.getAppProperty('AppStoreApiKey'));
    const hasIssuerId = !_.isEmpty(this.getAppProperty('AppStoreApiIssuerId'));
    const hadKeyId = !_.isEmpty(this.getAppProperty('AppStoreApiKeyId'));
    const issuerIdValid = !_.isEmpty(issuerId);
    const keyIdValid = !_.isEmpty(keyId);

    return (
      <div>
        {renderTitle('Enable App Store API - Optional')}
        {renderDescription(
          'In this step, we will create an App Store Connect API key to enable automation. If not enabled, some automation features will not be available and will require manual intervention.',
        )}
        <ol>
          {renderOpenLinkStep('https://appstoreconnect.apple.com/apps')}
          {renderImageStep(
            <div>
              Sign in to Apple Developer
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Apple ID</b> and <b>Password</b>
                </li>
                <li>Resolve two factor authentication</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/1+-+Sign+In.png',
            '50%',
            '20%',
          )}
          {renderImageStep(
            <div>
              Go to the <b>Keys</b> tab under the <b>Users and Access</b> section
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-asc-api-key/01-open-app-store-connect-portal.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click the <b>+</b> button beside the <b>Active</b> header
              <br />
              If an API key has already been set up previously, <a href="#uploadapikey">go to step 7 to upload the api key</a>
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-asc-api-key/02-big-plus-button.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Give the API key a name, <b>{renderCopyToClipboardText('Pluss Automation')}</b>
              <br />
              Attach <b>Admin</b> level role
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-asc-api-key/03-key-fields.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click the <b>Download API Key</b> button to download the .p8 file and store the file in a secure place
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-asc-api-key/04-download-key.png',
            '40%',
            '60%',
          )}
          {renderTextStep(
            <div id="uploadapikey">
              Drop and upload the .p8 file downloaded
              <div className="flex flex-row flex-center marginTop-10">
                <FileInput
                  ref={(ref) => {
                    this.jsonFileInput = ref;
                  }}
                  style={{ height: 120, width: 240 }}
                  handleFile={this.onAddApiKeyFile}
                  accept="plain/text"
                  simpleStyle
                  multiple={false}
                />
                <div className="marginLeft-16">{message[STEP_ENABLE_API_FILE]}</div>
                {hasAppStoreApiKey ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
              </div>
            </div>,
          )}
          {renderImageStep(
            <div>
              Find and fill in the following information
              <ul className="marginTop-10">
                <li>
                  Copy and paste the <b>Issuer ID</b> below
                  <div className="flex flex-row flex-center">
                    <GenericInput
                      className="marginTop-10"
                      id="issuerId"
                      style={{ width: 400 }}
                      type="text"
                      isValid={() => issuerIdValid}
                      value={issuerId}
                      onChange={(e) => this.setState({ issuerId: e.target.value })}
                    />
                    {hasIssuerId ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
                  </div>
                </li>
                <li>
                  Copy and paste the <b>KEY ID</b> below
                  <div className="flex flex-row flex-center">
                    <GenericInput
                      className="marginTop-10"
                      id="keyId"
                      style={{ width: 400 }}
                      type="text"
                      isValid={() => keyIdValid}
                      value={keyId}
                      onChange={(e) => this.setState({ keyId: e.target.value })}
                    />
                    {hadKeyId ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
                  </div>
                </li>
              </ul>
              <div className="flex flex-row flex-center">
                <Button inline buttonType="primary" onClick={this.onSaveAppStoreKeyInfo} isActive={issuerIdValid && keyIdValid && !loading}>
                  Save
                </Button>
                <div className="marginLeft-16">{message[STEP_ENABLE_API_KEY]}</div>
              </div>
            </div>,
            'https://github.com/expo/fyi/raw/main/assets/creating-asc-api-key/05-other-info.png',
            '40%',
            '60%',
          )}
          {renderTextStep('Close the Incognito window')}
        </ol>
      </div>
    );
  };

  renderStepBuildIpa = () => {
    const apiEnabled = this.hasApiInfo();

    return (
      <div>
        {renderTitle(apiEnabled ? 'Build and upload IPA' : 'Build IPA')}
        {renderDescription(
          `In this step, we will build an IPA file ${
            apiEnabled ? 'and upload' : 'to upload'
          }. The following steps assume that you have Docker Desktop installed and have the plussdev/default image.`,
        )}
        <ol>
          {renderTextStep(
            <div>
              For detailed instructions on how to set up the Docker Desktop for building the app, refer to{' '}
              <a href="https://docs.google.com/document/d/1bxv7Xvy_K4Xk16L0bolRzIsLj6EtXC8O6sHgXt5Bxr4/edit?usp=sharing" target="_blank">
                Building and Uploading Apps
              </a>
            </div>,
          )}
          {renderImageStep(
            <div>
              Ensure you are running a docker container from the <b>plussdev/default</b> image then click <b>Run</b> button without changing
              any settings.
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Run+Docker+Image.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click on the <b>Containers/Apps</b> menu on the left and then click the CLI button to open the command prompt
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Open+Command+Prompt.png',
            '40%',
            '60%',
          )}
          {renderTextStep(
            <div>
              Ensure you are in <b>minuss-expo</b> folder. If not, switch to the folder by typing,
              {renderEmbed('cd minuss-expo')}
            </div>,
          )}
          {renderTextStep(
            <div>
              Start building the ipa by typing,
              {renderEmbed(`npm run build:ios${apiEnabled ? '' : ':nosubmit'} ${this.getAppBranchName()}`)}
              This will get the latest source code and install necessary packages required for the build.
            </div>,
          )}
          {renderImageStep(
            <div>
              Log in to Expo
              <ol className="marginBottom-16">
                <li>
                  When asked <b>Do you want to log in to your Apple account?</b>, enter <b>Y</b>
                </li>
                <li>
                  Enter Apple ID <b>{renderCopyToClipboardText('developer@plusscommunities.com')}</b>
                </li>
                <li>
                  Enter <b>Password</b> and validate Code for Two-factor Authentication
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/logInAppStoreConnect.png',
            '40%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Select Team
              <ol className="marginBottom-16">
                <li>
                  Select <b>Team</b> - <b>Pluss Communities PTY LTD</b>
                </li>
                <li>
                  Select <b>Provider</b> - <b>Pluss Communities PTY LTD</b>
                </li>
                <li>
                  Ensure that <b>Bundle identifier: com.pluss.{this.getAppBundleId()}</b> is registered
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/selectAppStoreTeam.png',
            '40%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Select Certificates and Build
              <ol className="marginBottom-16">
                <li>
                  When prompted, enter <b>Y</b> to select the default distribution certificate (
                  <b>Cert ID: DA3TU2FXX5, Serial number: 362CE685EF27F4BD801AB19098B6D26A, Team ID: MD49LE9D3X</b>)
                </li>
                <li>
                  When prompted, enter <b>Y</b> to generate a new Apple Provisioning Profile
                </li>
                <li>
                  When prompted, enter <b>Y</b> to set up Push Notifications for the project
                </li>
                <li>
                  Select <b>Push Notification Key</b> (<b>Key ID: DU7AC24FB2, Team ID: MD49LE9D3X</b>)
                </li>
                <li>
                  If the key doesn't exist, upload from,
                  {renderEmbed('./certificates/plussAPNs.p8')}
                  Enter key <b>DU7AC24FB2</b>
                </li>
                <li>{apiEnabled ? 'Wait until the build and the submission completes' : 'Wait until the build completes'}</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/selectCertAndBuild.png',
            '40%',
            '50%',
          )}
          {!apiEnabled
            ? renderTextStep(
                <div>
                  Download IPA file (Bacause API access is not yet enabled for this app, the IPA file needs to be manually uploaded to the
                  store)
                  <ol className="marginBottom-16">
                    <li>
                      Right click on the url to the standalone app built (e.g.{' '}
                      <b>https://expo.io/artifacts/94e075f9-852d-48a6-bd17-e8228a9ca618</b>)
                    </li>
                    <li>Open the url to start downloading the IPA file</li>
                  </ol>
                </div>,
              )
            : null}
        </ol>
      </div>
    );
  };

  renderStepSetupIosApp = () => {
    const apiEnabled = this.hasApiInfo();
    const bundleId = this.getAppBundleId();

    return (
      <div>
        {renderTitle('Set up new iOS App')}
        {renderDescription(
          apiEnabled
            ? 'Because API access is enabled, a new app will be automatically created. Click "Next".'
            : 'In this step, we will set up a new iOS App.',
        )}
        {!apiEnabled ? (
          <ol>
            {renderOpenLinkStep('https://developer.apple.com/account/resources/identifiers/bundleId/add/bundle')}
            {renderTextStep(
              <div>
                Under <b>Description</b>, enter <b>{renderCopyToClipboardText(this.getAppProperty('ClientName'))}</b>
              </div>,
            )}
            {renderTextStep(
              <div>
                Under <b>Bundle ID</b>, enter <b>{renderCopyToClipboardText(`com.pluss.${bundleId}`)}</b>
              </div>,
            )}
            {renderTextStep(
              <div>
                In the <b>Capabilities</b> list, scroll down and tick <b>Push Notifications</b>
              </div>,
            )}
            {renderTextStep(
              <div>
                Click <b>Continue</b>
              </div>,
            )}
            {renderTextStep(
              <div>
                Click <b>Register</b> and wait for the Bundle ID to be created
              </div>,
            )}
            {renderTextStep(<div>Once registered, the window can be closed</div>)}
            {renderOpenLinkStep('https://appstoreconnect.apple.com/', false)}
            {renderImageStep(
              <div>
                Under <b>App Store Connect</b>, click <b>My Apps</b>
              </div>,
              'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Set+up+new+iOS+App/1+-+My+Apps.png',
              '40%',
              '40%',
            )}
            {renderImageStep(
              <div>
                Click the <b>+</b> button next to <b>Apps</b>, then click <b>New App</b>
              </div>,
              'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Set+up+new+iOS+App/2+-+New+App.png',
              '40%',
              '40%',
            )}
            {renderImageStep(
              <div>
                Under <b>New App</b>,
                <ol className="marginBottom-16">
                  <li>
                    Under <b>Platform</b>, tick <b>iOS</b>
                  </li>
                  <li>
                    Enter <b>Name</b> <b>{renderCopyToClipboardText(this.getAppProperty('ClientName'))}</b>
                  </li>
                  <li>
                    Select <b>Primary Language</b> - <b>English (Australia)</b>
                  </li>
                  <li>
                    Select <b>Bundle ID</b> - <b>{`com.pluss.${bundleId}`}</b>
                  </li>
                  <li>
                    Enter <b>SKU</b> <b>{renderCopyToClipboardText(`com.pluss.${bundleId}`)}</b>
                  </li>
                  <li>
                    Under <b>User Access</b>, tick <b>Full Access</b>
                  </li>
                </ol>
                Then click <b>Create</b> button
              </div>,
              'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Set+up+new+iOS+App/3+-+Create.png',
              '50%',
              '40%',
            )}
            {renderTextStep('Close the Incognito window')}
          </ol>
        ) : null}
      </div>
    );
  };

  renderStepUploadIpa = () => {
    const apiEnabled = this.hasApiInfo();

    return (
      <div>
        {renderTitle('Upload IPA')}
        {renderDescription(
          apiEnabled
            ? 'Because API access is enabled, the app will be uploaded automatically. Click "Next".'
            : 'In this step, we upload the IPA file to App Store Connect.',
        )}
        {!apiEnabled ? (
          <ol>
            {renderImageStep(
              <div>
                Ensure you have downloaded <b>Transporter</b> app from <b>Mac App Store</b> by navigating to{' '}
                <a href="https://apps.apple.com/us/app/transporter/id1450874784" target="_blank" rel="noopener noreferrer">
                  https://apps.apple.com/us/app/transporter/id1450874784
                </a>
              </div>,
              'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Upload+IPA/1+-+Transporter.png',
              '40%',
              '50%',
            )}
            {renderTextStep(
              <div>
                Sign in to <b>Transporter</b> app
              </div>,
            )}
            {renderImageStep(
              <div>
                Select IPA File
                <ol>
                  <li>
                    Ensure <b>Pluss Communities PTY LTD</b> is selected as the provider
                  </li>
                  <li>
                    Add (click <b>+</b> or drag) the IPA file built in the previous step
                  </li>
                  <li>
                    Click <b>Deliver</b> button
                  </li>
                  <li>Wait until upload completes</li>
                </ol>
              </div>,
              'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Upload+IPA/2+-+Select+File.png',
              '40%',
              '50%',
            )}
            {renderTextStep(
              <div>
                Close <b>Transporter</b> app
              </div>,
            )}
          </ol>
        ) : null}
      </div>
    );
  };

  renderResetUser = () => {
    const { loading, resetMessage } = this.state;
    const users = this.getAppProperty('Users') || [];
    const user = users.find((u) => u.email.startsWith('apple'));
    const canReset = !loading;

    return (
      <div className="marginTop-10">
        {user ? (
          <div>
            <div>
              {`User: ${user.user}`}
              {user.user ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
            </div>
            <div>
              {`Email: ${user.email}`}
              {user.email ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
            </div>
            <div>
              {`Password: ${user.password}`}
              {user.password ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
            </div>
          </div>
        ) : null}
        <div className="flex flex-row flex-center marginTop-10">
          <Button style={{ width: 90 }} inline buttonType="primary" onClick={() => canReset && this.onResetUser()} isActive={canReset}>
            Start
          </Button>
          <div className="marginLeft-16">{resetMessage}</div>
        </div>
      </div>
    );
  };

  renderStepSetupReviewUser = () => {
    return (
      <div>
        {renderTitle('Set up Review User')}
        {renderDescription(
          'In this step, we will ensure an app review user is set up and/or have the password reset for a fresh review process.',
        )}
        <ol>
          {renderTextStep(
            <div>
              Create or reset app review user
              {this.renderResetUser()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderScreenshotInput = (type, index, screenshot, enabled) => {
    const message = this.getScreenshotMessage(type, index);
    const screenshotSet = !_.isEmpty(screenshot);
    const processing = !_.isEmpty(message);
    return (
      <div className="marginRight-10" style={{ width: SCREENSHOT_WIDTH, height: SCREENSHOT_HEIGHT, position: 'relative' }}>
        {screenshotSet && !processing ? (
          <img style={{ width: SCREENSHOT_WIDTH, height: SCREENSHOT_HEIGHT, objectFit: 'contain' }} src={screenshot} />
        ) : null}
        {enabled ? (
          <FileInput
            ref={(ref) => {
              this.screenshotInput = ref;
            }}
            containerStyle={{
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: screenshotSet && !processing ? 0.2 : 1,
            }}
            style={{ width: SCREENSHOT_WIDTH, height: SCREENSHOT_HEIGHT }}
            handleFile={(file) => this.onUploadScreenshot(type, index, file)}
            accept="image/png"
            simpleStyle
            multiple={false}
          />
        ) : null}
        {processing ? (
          <div
            className={`padding-8 ${message.startsWith('Error') ? 'text-error' : 'text-body'}`}
            style={{ width: SCREENSHOT_WIDTH, height: SCREENSHOT_HEIGHT / 4, position: 'absolute', top: 0, left: 0 }}
          >
            {message}
          </div>
        ) : null}
      </div>
    );
  };

  renderScreenshotSet = (type, title, helpText, enabled) => {
    const app = this.getAppInfo();
    const iphone65Screenshots = app.AppStoreScreenshots && app.AppStoreScreenshots[type];
    let screenshot1, screenshot2, screenshot3, screenshot4;
    if (iphone65Screenshots) {
      screenshot1 = this.getScreenshotUrl(iphone65Screenshots[0] && iphone65Screenshots[0].templateUrl);
      screenshot2 = this.getScreenshotUrl(iphone65Screenshots[1] && iphone65Screenshots[1].templateUrl);
      screenshot3 = this.getScreenshotUrl(iphone65Screenshots[2] && iphone65Screenshots[2].templateUrl);
      screenshot4 = this.getScreenshotUrl(iphone65Screenshots[3] && iphone65Screenshots[3].templateUrl);
    }

    return (
      <div className="flex flex-row flex-center marginTop-10">
        <div className="marginRight-20" style={{ width: 200 }}>
          <div className="font-bold">{title}</div>
          {!_.isEmpty(helpText) ? <div>{helpText}</div> : null}
        </div>
        {this.renderScreenshotInput(type, 0, screenshot1, enabled)}
        {this.renderScreenshotInput(type, 1, screenshot2, enabled)}
        {this.renderScreenshotInput(type, 2, screenshot3, enabled)}
        {/* {this.renderScreenshotInput(type, 3, screenshot4, enabled)} */}
      </div>
    );
  };

  renderBuildReady = () => {
    const { message, loading } = this.state;
    const app = this.getAppInfo();
    const appStoreIdExists = !_.isEmpty(app.AppStoreAppId);

    return (
      <div className="marginTop-10">
        <div>
          {`App Store Id: ${app.AppStoreAppId || '[Not Ready]'}`}
          {appStoreIdExists ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
        </div>
        {!appStoreIdExists ? (
          <div className="flex flex-row flex-center marginTop-10">
            <Button style={{ width: 200 }} inline buttonType="primary" onClick={this.onSaveAppId} isActive={!loading}>
              Check Submission
            </Button>
            <div className="marginLeft-16">{message[STEP_SUBMIT_CHECK]}</div>
          </div>
        ) : null}
      </div>
    );
  };

  renderInitialiseAppStore = () => {
    const { message, loading, description, keywords, promotionalText } = this.state;
    const app = this.getAppInfo();
    const appStoreIdExists = !_.isEmpty(app.AppStoreAppId);
    const appStoreInitialised = app.AppStoreInitialised;
    const isDescriptionValid = !_.isEmpty(description);
    const isPromotionalTextValid = !_.isEmpty(promotionalText);
    const isKeywordsValid = !_.isEmpty(keywords);

    return (
      <div className="marginTop-10">
        <ul className="marginTop-10">
          <li>
            Description
            <div className="flex flex-row flex-center">
              <GenericInput
                className="marginTop-10"
                id="description"
                style={{ width: 800 }}
                type="textarea"
                componentClass="textarea"
                isValid={isDescriptionValid}
                value={description}
                onChange={(e) => this.setState({ description: e.target.value })}
                maxLength={4000}
                help={`${4000 - description.length} characters`}
              />
            </div>
          </li>
          <li>
            Promotional Text
            <div className="flex flex-row flex-center">
              <GenericInput
                className="marginTop-10"
                id="promotionalText"
                style={{ width: 800 }}
                type="textarea"
                componentClass="textarea"
                isValid={isPromotionalTextValid}
                value={promotionalText}
                onChange={(e) => this.setState({ promotionalText: e.target.value })}
                maxLength={170}
                help={`${170 - promotionalText.length} characters`}
              />
            </div>
          </li>
          <li>
            Keywords
            <div className="flex flex-row flex-center">
              <GenericInput
                className="marginTop-10"
                id="keywords"
                style={{ width: 800 }}
                type="text"
                isValid={isKeywordsValid}
                value={keywords}
                onChange={(e) => this.setState({ keywords: e.target.value })}
                maxLength={100}
                help={`${100 - keywords.length} characters`}
              />
            </div>
          </li>
        </ul>
        <div className="flex flex-row flex-center marginTop-10">
          {appStoreIdExists ? (
            <Button style={{ width: 90 }} inline buttonType="primary" onClick={this.onInitialiseAppStore} isActive={!loading}>
              Save
            </Button>
          ) : null}
          <div className="marginLeft-10">{message[STEP_SUBMIT_INIT]}</div>
          {appStoreInitialised ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
        </div>
      </div>
    );
  };

  renderStepSubmitForReview = () => {
    const { whiteLabelInfo, selectedApp } = this.state;
    const appUser = getWhiteLabelAppUser(whiteLabelInfo, selectedApp, REVIEW_USER_PREFIX);
    const userName = appUser ? appUser.email : REVIEW_USER_MISSING;
    const password = appUser ? appUser.password : REVIEW_USER_MISSING;
    const apiEnabled = this.hasApiInfo();
    const appStoreIdExists = !_.isEmpty(this.getAppProperty('AppStoreAppId'));

    return (
      <div>
        {renderTitle('Submit version for review')}
        {renderDescription(
          `In this step, we will submit the app to Apple for review.${
            !apiEnabled ? ' Because API is not enabled, there are some extra steps that needs manual intervention.' : ''
          }`,
        )}
        <ol>
          {apiEnabled
            ? renderTextStep(
                <div>
                  Check Build Ready
                  {this.renderBuildReady()}
                </div>,
              )
            : null}
          {apiEnabled
            ? renderTextStep(
                <div>
                  Initialise App Store
                  {this.renderInitialiseAppStore()}
                </div>,
              )
            : null}
          {apiEnabled
            ? renderTextStep(
                <div>
                  Drop and upload screenshots
                  {this.renderScreenshotSet(SCREENSHOT_TYPE_IPHONE65, 'iPhone 6.5"', '1284 x 2778 pixels', appStoreIdExists)}
                  {this.renderScreenshotSet(SCREENSHOT_TYPE_IPHONE55, 'iPhone 5.5"', '1242 x 2208  pixels', appStoreIdExists)}
                  {this.renderScreenshotSet(SCREENSHOT_TYPE_IPAD129_3RD, 'iPad Pro 12.9" (3rd)', '2048 x 2732  pixels', appStoreIdExists)}
                  {this.renderScreenshotSet(SCREENSHOT_TYPE_IPAD129_2ND, 'iPad Pro 12.9" (2nd)', '2048 x 2732  pixels', appStoreIdExists)}
                </div>,
              )
            : null}
          {renderOpenLinkStep('https://appstoreconnect.apple.com/apps')}
          {renderImageStep(
            <div>
              Sign in to Apple Developer
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Apple ID</b> and <b>Password</b>
                </li>
                <li>Resolve two factor authentication</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/1+-+Sign+In.png',
            '50%',
            '20%',
          )}
          {renderTextStep(
            <div>
              Click on the <b>{this.getAppProperty('ClientName')}</b> app
            </div>,
          )}
          {!apiEnabled
            ? renderImageStep(
                <div>
                  Click on <b>App Information</b> from the left menu
                  <ol className="marginBottom-16">
                    <li>
                      Enter <b>Name</b>
                    </li>
                    <li>
                      Enter <b>Subtitle</b>
                    </li>
                    <li>
                      Select <b>Primary Category</b> - <b>Lifestyle</b>
                    </li>
                    <li>
                      Select <b>Secondary Category</b> - <b>Social Networking</b>
                    </li>
                    <li>
                      Under <b>Content Rights</b>, click <b>Set Up Content Rights Information</b> and when it asks{' '}
                      <b>Does your app contain, show, or access third-party content?</b>, check{' '}
                      <b>No, it does not contain, show, or access third-party content</b> and click <b>Done</b>
                    </li>
                  </ol>
                  Then click <b>Save</b> button
                </div>,
                'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/2+-+App+Information.png',
                '40%',
                '60%',
              )
            : null}
          {renderImageStep(
            <div>
              Click on <b>Pricing and Availability</b> from the left menu
              <ol className="marginBottom-16">
                <li>Select USD 0 (Free) on the Price Schedule</li>
              </ol>
              Then click <b>Save</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/3+-+Pricing.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click on <b>App Privacy</b> from the left menu
              <ol className="marginBottom-16">
                <li>
                  Click <b>Get Started</b> button at the bottom
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/4+-+App+Privacy.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>Data Collection</b>,
              <ol className="marginBottom-16">
                <li>
                  Check <b>Yes, we collect data from this app</b> and click <b>Next</b>
                </li>
                <li>
                  For <b>Contact Info</b>, check,
                  <ul>
                    <li>
                      <b>Name</b>
                    </li>
                    <li>
                      <b>Email Address</b>
                    </li>
                    <li>
                      <b>Phone Number</b>
                    </li>
                    <li>
                      <b>Physical Address</b>
                    </li>
                    <li>
                      <b>Other User Contact Info</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Location</b>, check,
                  <ul>
                    <li>
                      <b>Coarse Location</b>
                    </li>
                  </ul>
                </li>
                <li>
                  Check <b>Contacts</b>
                </li>
                <li>
                  For <b>User Content</b>, check,
                  <ul>
                    <li>
                      <b>Photos or Videos</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Identifier</b>, check,
                  <ul>
                    <li>
                      <b>User ID</b>
                    </li>
                    <li>
                      <b>Device ID</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Usage Data</b>, check,
                  <ul>
                    <li>
                      <b>Product Interaction</b>
                    </li>
                    <li>
                      <b>Other Usage Data</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Diagnostis</b>, check,
                  <ul>
                    <li>
                      <b>Crash Data</b>
                    </li>
                    <li>
                      <b>Performance Data</b>
                    </li>
                    <li>
                      <b>Other Diagnostic Data</b>
                    </li>
                  </ul>
                </li>
                <li>
                  Check <b>Other Data</b>
                </li>
                <li>
                  Click <b>Save</b> button
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/5+-+Data+Collection.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              For each data type selected from the previous step,
              <ol className="marginBottom-16">
                <li>
                  For <b>Name</b>
                  <ul>
                    <li>
                      Check <b>Product Personalization</b>
                    </li>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>Yes, names collected from this app are linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use names for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Email Address</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>Yes, email addresses collected from this app are linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use email addresses for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Phone Number</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>Yes, phone numbers collected from this app are linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use phone numbers for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Physical Address</b>
                  <ul>
                    <li>
                      Check <b>Analytics</b>
                    </li>
                    <li>
                      Check <b>Product Personalization</b>
                    </li>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, physical addresses collected from this app are not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use physical addresses for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Other User Contact Info</b>
                  <ul>
                    <li>
                      Check <b>Product Personalization</b>
                    </li>
                    <li>
                      Select <b>No, other user contact info collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use other user contact info for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Coarse Location</b>
                  <ul>
                    <li>
                      Check <b>Product Personalization</b>
                    </li>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, coarse location data collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use coarse location data for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Contacts</b>
                  <ul>
                    <li>
                      Check <b>Product Personalization</b>
                    </li>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, contacts collected from this app are not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use contacts for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Photos or Videos</b>
                  <ul>
                    <li>
                      Check <b>Product Personalization</b>
                    </li>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, photos or videos collected from this app are not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use photos or videos for tracking purposes</b>
                    </li>
                  </ul>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/appprivacy.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              <ol>
                <li>
                  For <b>User ID</b>
                  <ul>
                    <li>
                      Check <b>Product Personalization</b>
                    </li>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, user IDs collected from this app are not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use user IDs for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Device ID</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, device IDs collected from this app are not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use device IDs for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Product Interaction</b>
                  <ul>
                    <li>
                      Check <b>Analytics</b>
                    </li>
                    <li>
                      Check <b>Product Personalization</b>
                    </li>
                    <li>
                      Select <b>No, product interaction data collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use product interaction data for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Other Usage Data</b>
                  <ul>
                    <li>
                      Check <b>Analytics</b>
                    </li>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, other usage data collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use other usage data for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Crash Data</b>
                  <ul>
                    <li>
                      Check <b>Analytics</b>
                    </li>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, crash data data collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use crash data for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Performance Data</b>
                  <ul>
                    <li>
                      Check <b>Analytics</b>
                    </li>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, performance data data collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use performance data for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Other Diagnostic Data</b>
                  <ul>
                    <li>
                      Check <b>Analytics</b>
                    </li>
                    <li>
                      Select <b>No, other diagnostic data data collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use other diagnostic data for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Other Data</b>
                  <ul>
                    <li>
                      Check <b>Analytics</b>
                    </li>
                    <li>
                      Select <b>No, other data types collected from this app are not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use other data types for tracking purposes</b>
                    </li>
                  </ul>
                </li>
              </ol>
              Then click <b>Publish</b> button and confirm publication
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/appprivacy_1.png',
            '40%',
            '60%',
          )}
          {!apiEnabled
            ? renderImageStep(
                <div>
                  Click on <b>Prepare for Submission</b> from the left menu
                  <ol className="marginBottom-16">
                    <li>
                      Under <b>Version Informaiton</b> section, upload the screenshots as required
                    </li>
                    <li>
                      Enter <b>Description</b>
                    </li>
                    <li>
                      Enter <b>Keywords</b>
                    </li>
                    <li>
                      Enter <b>Support URL</b>
                    </li>
                    <li>
                      Enter <b>Marketing URL</b>
                    </li>
                  </ol>
                </div>,
                'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/7+-+Version+Information.png',
                '40%',
                '60%',
              )
            : null}
          {!apiEnabled
            ? renderImageStep(
                <div>
                  Under <b>Build</b> section,
                  <ol className="marginBottom-16">
                    <li>
                      Select the build uploaded from the previous step (If asked about encryption, answer <b>No</b>)
                    </li>
                  </ol>
                </div>,
                'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/8+-+Build.png',
                '40%',
                '60%',
              )
            : null}
          {!apiEnabled
            ? renderImageStep(
                <div>
                  Under <b>General App Information</b> section,
                  <ol className="marginBottom-16">
                    <li>
                      Enter <b>Copyright</b> (<b>2023 Pluss Communities PTY LTD</b>)
                    </li>
                    <li>
                      Enter <b>Version</b> without the last version component (Revision number)
                    </li>
                    <li>
                      Click <b>Edit</b> button next to <b>Age Rating</b> and answer all questions with <b>No</b> - This should result in{' '}
                      <b>Ages 4+</b> rating
                    </li>
                  </ol>
                </div>,
                'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/9+-+General+App+Information.png',
                '40%',
                '60%',
              )
            : null}
          {!apiEnabled
            ? renderImageStep(
                <div>
                  Under <b>App Review Information</b> section,
                  <ol className="marginBottom-16">
                    <li>
                      Under <b>Sign-in required</b>,
                      <ol>
                        <li>
                          Enter <b>User name</b> <b>{renderCopyToClipboardText(userName)}</b>
                        </li>
                        <li>
                          Enter <b>Password</b> <b>{renderCopyToClipboardText(password)}</b>
                        </li>
                      </ol>
                    </li>
                    <li>
                      Under <b>Contact Information</b>,
                      <ol>
                        <li>
                          Enter <b>First name</b> <b>{renderCopyToClipboardText('Thorbjorn')}</b>
                        </li>
                        <li>
                          Enter <b>Last name</b> <b>{renderCopyToClipboardText('Kappel Davis')}</b>
                        </li>
                        <li>
                          Enter <b>Phone number</b> <b>{renderCopyToClipboardText('+61422095493')}</b>
                        </li>
                        <li>
                          Enter <b>Email</b> <b>{renderCopyToClipboardText('thor@plusscommunities.com')}</b>
                        </li>
                        <li>Attach any notes to help the reviewer navigating the app</li>
                      </ol>
                    </li>
                  </ol>
                </div>,
                'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/10+-+App+Review+Information.png',
                '40%',
                '60%',
              )
            : null}
          {renderTextStep(
            <div>
              Click <b>Submit for Review</b>
            </div>,
          )}
          {renderTextStep('Close the Incognito window')}
        </ol>
      </div>
    );
  };

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    const { stepIndex } = this.props;
    const step = stepIndex && stepIndex < this.steps.length ? stepIndex : 0;
    return (
      <div className="flex-1 automation">
        <StepProgressBar
          startingStep={step}
          progressClass="progressBar"
          primaryBtnClass="primaryBtn"
          secondaryBtnClass="secondaryBtn"
          submitBtnName="Done"
          onPrevious={() => this.onMoveStep(step - 1)}
          onNext={() => this.onMoveStep(step + 1)}
          onSubmit={() => (this.props.onDone ? this.props.onDone() : null)}
          steps={this.steps}
        />
        {this.steps[step].render()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded, whiteLabelReset })(AppSetupiOS);
