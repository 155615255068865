import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, GenericInput, Header, Tag, Text } from '../../components';
import FontAwesome from 'react-fontawesome';
import { checkLoggedIn } from '../../session';
import { profileActions } from '../../webapi';
import { getSiteNameFromRoles } from '../../helper';

class TagsHub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagInput: '',
      tags: [],
      addingTag: false,
      editingTag: false,
    };
  }

  componentDidMount() {
    checkLoggedIn(this, this.props.auth);

    this.getTags();
  }

  getTags = async () => {
    profileActions.getUserTagsBySite(this.props.auth.site).then((res) => {
      this.setState({
        tags: res.data,
      });
    });
  };

  startAddingTag = () => {
    this.setState({
      addingTag: true,
      tagInput: '',
    });
  };

  startEditingTag = () => {
    this.setState({
      editingTag: true,
    });
  };

  finishEditingTag = () => {
    this.setState({
      editingTag: false,
    });
  };

  deleteTag = (tag) => {
    if (!window.confirm('Are you sure you want to delete that tag? This will remove the tag from all tagged users.')) {
      return;
    }
    profileActions.deleteUserTag(tag.Id);

    this.setState({
      tags: _.filter(this.state.tags, (t) => {
        return t.Id !== tag.Id;
      }),
    });
  };

  saveTag = () => {
    if (_.isEmpty(this.state.tagInput)) {
      return;
    }

    profileActions.addUserTag(this.props.auth.site, this.state.tagInput).then((res) => {
      this.setState({
        tags: [...(this.state.tags || []), res.data],
      });
    });

    this.setState({
      addingTag: null,
      tagInput: '',
    });
  };

  renderTags() {
    const { tags, editingTag, addingTag, tagInput } = this.state;
    return (
      <div className="userTagsAvailableSite">
        <div className="flex flex-between flex-center">
          {!editingTag && (
            <div
              className="iconTextButton iconTextButton-action"
              onClick={() => {
                this.startEditingTag();
              }}
            >
              <FontAwesome name="pencil" className="iconTextButton_icon" />
              <p className="iconTextButton_text">Edit Tags</p>
            </div>
          )}
          {editingTag && (
            <Button
              inline
              buttonType="primary"
              onClick={() => {
                this.finishEditingTag();
              }}
              isActive
              narrow
            >
              Save
            </Button>
          )}
        </div>
        <div className="userTagsAvailableSite_tags">
          {!addingTag && !editingTag && (
            <Tag
              className="tag-outlined tag-minSize"
              centerClass="tag_center-spaceAround"
              text="Add Tag"
              onClick={() => {
                this.startAddingTag();
              }}
              leftIcon="plus"
              leftIconStyle={{ paddingTop: 2 }}
            />
          )}
          {addingTag && !editingTag && (
            <Tag className="tag-outlined tag-minSize" rightIcon="check-circle" rightIconStyle={{ fontSize: 16 }} rightClick={this.saveTag}>
              <GenericInput
                value={tagInput}
                id={`tagInput`}
                onChange={(e) => {
                  this.setState({ tagInput: e.target.value });
                }}
                autofocus
                className="tag_input"
              />
            </Tag>
          )}
          {tags.map((t) => {
            return (
              <Tag
                key={t.Id}
                className="marginLeft-16 marginTop-5 marginBottom-5"
                text={t.Title}
                rightIcon={editingTag ? 'trash' : undefined}
                rightClick={
                  editingTag
                    ? () => {
                        this.deleteTag(t);
                      }
                    : undefined
                }
              />
            );
          })}
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <div style={{ minWidth: '100%' }}>
        <Text type="h3" className="marginBottom-20">
          {getSiteNameFromRoles(this.props.auth.site, this.props.auth.user.Roles)} Tags
        </Text>
        {this.renderTags()}
      </div>
    );
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentWrapper">{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(TagsHub);
