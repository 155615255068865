import _ from 'lodash';
import { formatCurrency, currencyToFloat, isCurrency, getCurrencySymbol, textToPrice } from '../helper';

export class Category {
  constructor(category, price, quantity) {
    this.category = category;
    this.price = price;
    this.quantity = quantity;
  }
}

export class PaymentInfo {
  constructor(merchant, categories) {
    this.merchant = merchant;
    this.categories = categories;
  }
}

export class FeeInfo {
  constructor(fee, isPercentage) {
    this.fee = fee;
    this.isPercentage = isPercentage;
  }
}

/**
 * A custom hook that provides payment-related utility functions and selectors.
 *
 * @param {Function} selector - An optional selector function to extract specific payment information from the Redux store.
 * @returns {Object} An object containing payment-related utility functions and selectors.
 */
export const usePay = (props) => {
  const paymentInfoState = (props && props.state && props.state.auth && props.state.auth.user && props.state.auth.user.paymentInfo) || {};

  /**
   * Build and return a payment info object.
   *
   * @param {String} merchant
   * @param {Array<Category> | Category | String | undefined} categoryInfo
   * @param {Boolean} onchargeFees
   */
  const composePaymentInfo = (merchant = null, categoryInfo = null, onchargeFees = false) => {
    const paymentInfo = {
      merchant: merchant,
      categories: [],
      onchargeFees,
    };

    if (!categoryInfo || typeof categoryInfo === 'string') {
      paymentInfo.categories = [
        {
          category: 'default',
          price: categoryInfo ? isCurrency(categoryInfo) : 0,
          quantity: 0,
        },
      ];
    } else if (Array.isArray(categoryInfo)) {
      paymentInfo.categories = categoryInfo.map((c) => ({
        category: c.category,
        price: isCurrency(c.price),
        quantity: c.quantity || 0,
      }));
    } else if (typeof categoryInfo === 'object') {
      paymentInfo.categories = [
        {
          category: categoryInfo.category || 'default',
          price: isCurrency(categoryInfo.price),
          quantity: categoryInfo.quantity || 0,
        },
      ];
    }

    return paymentInfo;
  };

  /**
   * Determines if payment is enabled and PaymentInfo is valid
   *
   * @param {PaymentInfo} paymentInfo
   * @returns {Boolean} True if the payment info is valid, false otherwise.
   */
  const isPaymentInfoValid = (paymentInfo) => {
    return (
      paymentInfoState.enabled &&
      !_.isNil(paymentInfo) &&
      !_.isNil(paymentInfo.merchant) &&
      !_.isNil(paymentInfo.categories) &&
      paymentInfo.categories.length > 0 &&
      paymentInfo.categories.every((t) => _.isNil(t.price) || _.isNumber(t.price))
    );
  };

  /**
   * Determines if the entity has a valid payment information
   *
   * @param {Object} entity
   * @returns {Boolean} True if the entity has a valid payment information, false otherwise.
   */
  const hasPaymentInfo = (entity) => {
    if (!entity || (!entity.PaymentInfo && !entity.Tickets)) return false;
    return isPaymentInfoValid(entity.PaymentInfo || entity.Tickets);
  };

  /**
   * Parse payment info and clean up invalid categories and override merchant or onchargeFees if specified
   *
   * @param {Array<Category> | PaymentInfo} paymentInfo
   * @param {String} merchant
   * @param {Boolean} onchargeFees
   * @returns
   */
  const parsePaymentInfo = (paymentInfo, merchant, onchargeFees) => {
    let newPaymentInfo = { categories: [], onchargeFees: onchargeFees || false };
    if (Array.isArray(paymentInfo)) {
      newPaymentInfo.categories = paymentInfo;
    } else if (typeof paymentInfo === 'object') {
      newPaymentInfo = _.cloneDeep(paymentInfo);
      // Override onchargeFees if specified
      if (!_.isNil(onchargeFees)) newPaymentInfo.onchargeFees = onchargeFees;
    }
    // Override merchant if specified
    if (!_.isNil(merchant)) newPaymentInfo.merchant = merchant;

    // Clean up categories
    newPaymentInfo.categories = newPaymentInfo.categories
      .filter((c) => isPriceValid(c.price))
      .map((ticket) => {
        const newTicket = _.cloneDeep(ticket);
        newTicket.price = isCurrency(newTicket.price);
        return newTicket;
      });

    return newPaymentInfo;
  };

  /**
   * Calculate fee based on feeInfo and amount
   *
   * @param {FeeInfo} feeInfo
   * @param {Number} amount for percentage fee
   * @returns fee amount
   */
  const getFee = (feeInfo, amount) => {
    if (!feeInfo) return 0;
    const { percentageFee, fixedFee } = feeInfo;
    if (percentageFee || fixedFee) {
      const pFee = percentageFee || 0;
      const fFee = fixedFee || 0;
      const totalFee = (pFee / 100) * (Math.abs(amount) || 0) + fFee;
      return totalFee;
    }
    // DEPRECATED
    if (feeInfo.fee) {
      return feeInfo.isPercentage ? (feeInfo.fee / 100) * (Math.abs(amount) || 0) : feeInfo.fee;
    }
    return 0;
  };

  /**
   * Get price text for a payment info or for a category
   *
   * @param {PaymentInfo | Array<Category>} paymentInfo
   * @param {string | undefined} categoryName
   * @returns Price text
   */
  const getPriceText = (paymentInfo, categoryName = null) => {
    const category = categoryName ? paymentInfo.categories.find((c) => c.category === categoryName) : paymentInfo;
    if (!category) return '';
    return typeof category.price === 'string' ? category.price : formatCurrency(category.price);
  };

  /**
   * Get total quantity of all categories
   *
   * @param {PaymentInfo | Array<Category>} paymentInfo
   * @returns Total quantity of all categories
   */
  const getTotalQuantity = (paymentInfo) => {
    const categories = paymentInfo.categories || paymentInfo;
    return _.sumBy(categories, (t) => t.quantity || 0);
  };

  /**
   * Get total price of all categories
   *
   * @param {PaymentInfo | Array<Category>} paymentInfo
   * @returns Total price of all categories
   */
  const getTotalPrice = (paymentInfo) => {
    const categories = paymentInfo.categories || paymentInfo;
    return _.sumBy(categories, (t) => t.price * (t.quantity || 0));
  };

  /**
   * Determines if the category name exists in the payment info
   *
   * @param {String} category
   * @param {PaymentInfo} paymentInfo
   * @returns
   */
  const isCategoryValid = (category, paymentInfo) => {
    return !_.isEmpty(category) && paymentInfo.categories.filter((t) => t.category === category).length === 1;
  };

  /**
   * Check if the price is a valid currency text or number
   *
   * @param {String | Number} price
   * @returns True if price is a valid currency text or number, false otherwise.
   */
  const isPriceValid = (price) => {
    const result = isCurrency(price);
    return result !== false && result >= 0;
  };

  /**
   * Determines if the payment info has categories
   *
   * @param {PaymentInfo} paymentInfo
   * @returns True if the payment info has categories, false otherwise.
   */
  const hasCategories = (paymentInfo) => {
    return paymentInfo && paymentInfo.categories && paymentInfo.categories.length > 0;
  };

  /**
   * Determines if the payment info has valid payment categories and merchant
   *
   * @param {PaymentInfo} paymentInfo
   * @param {String} merchant
   * @returns True if the payment info has valid payment categories, false otherwise.
   */
  const hasValidCategories = (paymentInfo, merchant) => {
    if (hasCategories(paymentInfo)) {
      if (!merchant && paymentInfoState.enabled) return false;
      return paymentInfo.categories.every((category) => isCategoryValid(category.category, paymentInfo) && isPriceValid(category.price));
    }
    return true;
  };

  const combineTickets = (ticketsArray, diff = false) => {
    if (_.isNil(ticketsArray) || ticketsArray.length === 0) return [];

    const combined = ticketsArray.reduce((a, c) => {
      if (_.isNil(a) || a.length === 0) return c;
      const summed = a.map((cat) => {
        const cloned = _.clone(cat);
        const match = c.find((i) => i.category === cloned.category);
        cloned.quantity = (diff ? -1 : 1) * (cloned.quantity || 0) + (match ? match.quantity || 0 : 0);
        return cloned;
      });
      return summed;
    });
    return combined;
  };

  const getPayCategories = (transaction) => (transaction.PaymentInfo ? transaction.PaymentInfo.categories : transaction.Item.categories);

  const combineTransactions = (transactionsArray) => {
    if (_.isNil(transactionsArray) || transactionsArray.length === 0) return null;

    const combined = _.orderBy(transactionsArray, 'UnixTimestamp', 'desc').reduce((a, c) => {
      const cloned = _.cloneDeep(c);
      cloned.Amount = cloned.Amount + a.Amount;
      cloned.Fee.amount = cloned.Fee.amount + a.Fee.amount;
      const combined = combineTickets([getPayCategories(cloned), getPayCategories(a)]);
      if (!cloned.PaymentInfo) {
        cloned.PaymentInfo = {};
      }
      cloned.PaymentInfo.categories = combined;
      return cloned;
    });
    return combined;
  };

  const consolidateTransactions = (transactionsArray) => {
    const transactions = transactionsArray
      .filter((pt) => pt.TransactionType === 'PAYMENT')
      .map((p) => {
        // Combine with all refunds for the transaction
        const refunds = transactionsArray.filter((rt) => rt.TransactionType === 'REFUND' && rt.TransactionId === p.TransactionId);
        return combineTransactions([...refunds, p]);
      })
      .filter((p) => getTotalQuantity(getPayCategories(p)) > 0);
    return transactions;
  };

  return {
    Category,
    PaymentInfo,
    FeeInfo,
    ...paymentInfoState,
    getCurrencySymbol,
    textToPrice,
    currencyToFloat,
    isCurrency,
    formatCurrency,
    composePaymentInfo,
    isPaymentInfoValid,
    hasPaymentInfo,
    parsePaymentInfo,
    getFee,
    getPriceText,
    getTotalQuantity,
    getTotalPrice,
    isCategoryValid,
    isPriceValid,
    hasCategories,
    hasValidCategories,
    combineTickets,
    getPayCategories,
    combineTransactions,
    consolidateTransactions,
  };
};
