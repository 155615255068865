import _ from 'lodash';
import {
  NEWS_LOADED,
  NEWS_UPDATED,
  NEWS_REMOVED,
  NEWS_SUBMISSIONS_LOADED,
  NEWS_SUBMISSION_REMOVED,
  NEWS_PURGE,
  NEWS_DASHBOARD_LOADING,
  NEWS_SUBMISSIONS_DASHBOARD_LOADING,
} from './types';
import { newsletterActions } from '../webapi';
import { readStorageWithCookie } from '../helper';

export const newsUpdate = (site, isdashboard) => {
  return (dispatch) => {
    if (isdashboard) {
      dispatch({
        type: NEWS_DASHBOARD_LOADING,
      });
    }
    newsletterActions.recursiveGetNews(site).then((res) => {
      const currentSite = readStorageWithCookie('site');
      if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === currentSite) {
        dispatch({
          type: NEWS_UPDATED,
          payload: res.data,
        });
      } else {
        dispatch({
          type: NEWS_UPDATED,
          payload: [],
        });
      }
    });
  };
};

export const newsLoaded = (events) => {
  return {
    type: NEWS_LOADED,
    payload: events,
  };
};

export const newsUpdated = (events) => {
  return {
    type: NEWS_UPDATED,
    payload: events,
  };
};

export const removeNews = (id) => {
  return {
    type: NEWS_REMOVED,
    payload: id,
  };
};

export const newsSubmissionsUpdate = (site, isdashboard) => {
  return (dispatch) => {
    if (isdashboard) {
      dispatch({
        type: NEWS_SUBMISSIONS_DASHBOARD_LOADING,
      });
    }
    newsletterActions.getNewsletterSubmissions(site).then((res) => {
      const currentSiteSub = readStorageWithCookie('site');
      if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === currentSiteSub) {
        dispatch({
          type: NEWS_SUBMISSIONS_LOADED,
          payload: res.data,
        });
      } else {
        dispatch({
          type: NEWS_SUBMISSIONS_LOADED,
          payload: [],
        });
      }
    });
  };
};

export const newsSubmissionsLoaded = (events) => {
  return {
    type: NEWS_SUBMISSIONS_LOADED,
    payload: events,
  };
};

export const removeNewsSubmission = (id) => {
  return {
    type: NEWS_SUBMISSION_REMOVED,
    payload: id,
  };
};

export const clearNews = () => {
  return {
    type: NEWS_PURGE,
  };
};
