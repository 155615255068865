import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Header, Text, LineChart, AnalyticsFilter, ExportCsvPopup, Button } from '../../components';
import { getUrlParams } from '../../helper';
import { analyticsActions } from '../../webapi';
import { countActivities, generateDateActivities, generateDateRangeObjects, getAnalyticsFilterOptions } from '../../js';
import { UTC_OFFSET } from '../../config';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { onlyAlphanumeric } from '../../helper';
import FontAwesome from 'react-fontawesome';

class ChartPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };

    this.groupings = [
      {
        text: 'Group by Day',
        key: 'day',
      },
      {
        text: 'Group by Week',
        key: 'week',
      },
      {
        text: 'Group by Month',
        key: 'month',
      },
    ];

    this.exportColumns = [
      { label: 'Select All', key: '' },
      { label: 'Date', key: 'date' },
      { label: 'Label', key: 'label' },
      { label: 'Value', key: 'value' },
    ];
  }

  componentDidMount() {
    this.parseParams();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (prevProps.location.search !== location.search) {
      this.setState(
        {
          isLoading: true,
        },
        this.parseParams,
      );
    }
  }

  onBack = () => {
    window.history.back();
  };

  parseParams = () => {
    const { countType, entity, key, startTime, endTime, title, dayCount, groupBy } = getUrlParams();

    const parsedStart = Number(startTime);
    const parsedEnd = Number(endTime);
    const parsedCount = Number(dayCount);
    const rawData = generateDateRangeObjects(parsedStart, parsedEnd);
    const filterOptions = getAnalyticsFilterOptions();
    const selectedFilter =
      (moment(parsedEnd + 1).isSame(moment(), 'D') &&
        _.find(filterOptions, (o) => {
          return o.dayCount === parsedCount;
        })) ||
      _.find(filterOptions, (o) => {
        return o.dayCount === 0;
      });
    const selectedGrouping = _.find(this.groupings, (g) => g.key === groupBy) || this.groupings[1];
    this.setState(
      {
        countType,
        entity,
        key,
        startTime: parsedStart,
        endTime: parsedEnd,
        dayCount: parsedCount,
        title: decodeURIComponent(title),
        rawData,
        data: generateDateActivities(rawData, key, countType, parsedStart, parsedEnd, selectedGrouping.key),
        selectedFilter: selectedFilter,
        selectedGrouping,
      },
      this.getData,
    );
  };

  filterChanged = (selectedFilter) => {
    this.setState({ selectedFilter }, () => {
      this.setTimeframe();
    });
  };

  filterDateRangeChanged = (startDate, endDate) => {
    this.setTimeframe(startDate, endDate);
  };

  setTimeframe = (min, max) => {
    let minToUse = min;
    let maxToUse = max;
    if (this.state.selectedFilter.dayCount === 0) {
      if (!min) {
        minToUse = this.state.startTime + 1;
      }
      if (!max) {
        maxToUse = this.state.endTime + 1;
      }
    }
    const lastDay = maxToUse ? moment(maxToUse).utc() : moment.utc();
    const dayCount = minToUse ? moment(maxToUse).diff(moment(minToUse), 'days') : this.state.selectedFilter.dayCount;
    const groupBy = this.state.selectedGrouping.key;

    const startOfLastDay = lastDay.utcOffset(UTC_OFFSET).startOf('d');
    const minTime = moment(startOfLastDay).add(-dayCount, 'd').valueOf() - 1;
    const maxTime = moment(startOfLastDay).valueOf() - 1;

    const { entity, key, countType, title } = this.state;

    this.props.history.replace(
      `/chart?entity=${entity}&startTime=${minTime}&endTime=${maxTime}&key=${key}&countType=${countType}&dayCount=${dayCount}&groupBy=${groupBy}&title=${encodeURIComponent(
        title,
      )}`,
    );
  };

  selectGrouping = (key) => {
    const selectedGrouping = _.find(this.groupings, (o) => {
      return o.key === key;
    });
    this.setState(
      {
        selectedGrouping,
      },
      () => {
        this.setTimeframe();
      },
    );
  };

  getData = async () => {
    const { startTime, endTime, entity, key, countType, selectedGrouping } = this.state;
    const { site } = this.props.auth;
    try {
      const response = await analyticsActions.getAggregateEntityStats(site, entity, startTime, endTime, true);
      this.setState({
        rawData: response.data,
        data: generateDateActivities(response.data, key, countType, startTime, endTime, selectedGrouping.key),
        isLoading: false,
      });
    } catch (e) {
      console.error(e);
    }
  };

  setIsExportOpen = (isOpen) => {
    this.setState({
      isExportOpen: isOpen,
    });
  };

  renderGroupingOptions() {
    return this.groupings.map((ev) => {
      return (
        <MenuItem key={ev.key} eventKey={ev.key} active={this.state.selectedGrouping === ev}>
          {ev.text}
        </MenuItem>
      );
    });
  }

  renderCSVExport = () => {
    if (!this.state.isExportOpen) {
      return null;
    }
    return (
      <ExportCsvPopup
        onClose={() => {
          this.setIsExportOpen(false);
        }}
        columns={this.exportColumns}
        source={this.state.data}
        filename={`${onlyAlphanumeric(this.state.title)}_${this.state.selectedFilter.dayCount}days_by${
          this.state.selectedGrouping.key
        }.csv`}
      />
    );
  };

  render() {
    const { data, title, isLoading } = this.state;

    return (
      <div className="hub-wrapperContainer">
        {this.renderCSVExport()}
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentCenter">
            <div className="hub-content dashboardContent">
              <div className="flex flex-center marginTop-40">
                <FontAwesome className="titleBack" name="angle-left" onClick={this.onBack} />
                <Text type="h1">{title || 'Chart'}</Text>
              </div>

              {isLoading ? (
                <div style={{ height: 30 }}></div>
              ) : (
                <div className="flex">
                  <div>
                    <AnalyticsFilter
                      defaultFilter={this.state.selectedFilter}
                      defaultStart={this.state.startTime + 1}
                      defaultEnd={this.state.endTime + 1}
                      filterChanged={this.filterChanged}
                      filterDateRangeChanged={this.filterDateRangeChanged}
                      hideOptions={[1]}
                    />
                  </div>
                  <div className="analyticsFilter__dropDown marginLeft-40">
                    <DropdownButton
                      id="secondLevelSort"
                      bsSize="xsmall"
                      className="siteSelector__button analyticsFilter__dropDown__button"
                      title={this.state.selectedGrouping.text}
                      onSelect={this.selectGrouping}
                    >
                      {this.renderGroupingOptions()}
                    </DropdownButton>
                  </div>
                  <div className="marginLeft-40">
                    <Button
                      inline
                      buttonType="primaryAction"
                      onClick={() => {
                        this.setIsExportOpen(true);
                      }}
                      isActive
                      leftIcon="file-code-o"
                    >
                      Export CSV
                    </Button>
                  </div>
                </div>
              )}
              <div className="dashboardBox padding-40 marginTop-20" style={{ height: 600 }}>
                <LineChart data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(withRouter(ChartPage));
