import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getApiError } from '../../session';
import { DropdownInput, Button, CheckBox, GenericInput } from '../../components';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded, whiteLabelAppSet } from '../../actions';
import { renderTitle, renderSubTitle, renderTextStep } from './helper';
import AppSelector from './common/AppSelector';

const MESSAGE_APP = 'app';
const MESSAGE_HISTORY = 'history';
const RESULT_SUCCESS = 'SUCCESSFUL';
const STATUS_COMPLETE = 'COMPLETED';
const EXPO_STATUS_FINISHED = 'finished';
const EXPO_STATUS_ERRORED = 'errored';
const EXPO_STATUS_CANCELED = 'canceled';

class BuildApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteLabelInfo: _.cloneDeep(props.activeWhiteLabel),
      versionInfo: null,
      appInfo: null,
      buildInfo: null,
      loading: false,
      loadingSteps: false,
      selectedApp: null,
      selectedPlatform: null,
      appVersion: '',
      shouldSubmit: false,
      whatsNew: '',
      showWarnings: false,
    };
    this.platformOptions = [
      {
        Key: 'ios',
        Title: 'iOS',
      },
      {
        Key: 'android',
        Title: 'Android',
      },
    ];
  }

  componentDidMount() {
    // For testing the page only
    // this.props.whiteLabelAppSet({ ClientCode: 'unittesting' });
  }

  componentDidUpdate(prevProps) {
    const { activeWhiteLabel } = this.props;
    if (
      activeWhiteLabel.ClientCode !== prevProps.activeWhiteLabel.ClientCode ||
      !_.isEqual(activeWhiteLabel.Apps, prevProps.activeWhiteLabel.Apps)
    ) {
      // console.log('componentDidUpdate', activeWhiteLabel.Apps);
      this.setState({ whiteLabelInfo: activeWhiteLabel }, this.setAppInfo);
    }
  }

  setMessage = (key, message, extraStates = {}) => {
    return new Promise((resolve) => {
      const newState = { ...extraStates };
      newState[`message_${key}`] = message;
      // console.log('setMessage', newState);
      this.setState(newState, resolve());
    });
  };

  getMessage = (key) => this.state[`message_${key}`];

  getAppInfo = () => {
    const { selectedApp, whiteLabelInfo } = this.state;
    // console.log('getAppInfo', { selectedApp, whiteLabelInfo });
    if (!selectedApp || !whiteLabelInfo || !whiteLabelInfo.Apps) return {};

    return whiteLabelInfo.Apps.find((a) => a.ClientCode === selectedApp.Key) || {};
  };

  setAppInfo = () => {
    this.setState({ appInfo: this.getAppInfo() }, this.setBuildInfo);
  };

  hasBuildInfo = () => {
    const { buildInfo } = this.state;
    return !_.isNil(buildInfo) && !_.isNil(buildInfo.PipelineId);
  };

  isBuildInProgress = () => {
    const { buildInfo } = this.state;
    const hasBuildInfo = this.hasBuildInfo();
    if (!hasBuildInfo) return false;
    if (buildInfo.AutoSubmit) {
      if (!_.isNil(buildInfo.SubmitStatus)) return false;
    } else {
      if (!_.isNil(buildInfo.BuildStatus)) return false;
    }
    if (buildInfo.PipelineStatus === STATUS_COMPLETE) return false;
    return true;
  };

  setBuildInfo = async () => {
    await this.setMessage(MESSAGE_APP, '');

    const { appInfo, selectedPlatform } = this.state;
    if (!appInfo || !selectedPlatform) return;

    const buildKey = `Build${_.startCase(selectedPlatform.Key)}`;
    const buildInfo = appInfo[buildKey];
    // console.log('setBuildInfo', buildInfo);
    this.setState({ buildInfo, appVersion: '', shouldSubmit: false, whatsNew: '' }, this.getAppVersion);
  };

  getAppVersion = async () => {
    if (!this.isValid()) return;

    const { whiteLabelInfo, selectedApp, selectedPlatform } = this.state;
    const whitelabelId = whiteLabelInfo.RowId;
    const appClientCode = selectedApp.Key;
    const platform = selectedPlatform.Key;
    try {
      await this.setMessage(MESSAGE_APP, `Getting ${selectedPlatform.Title} version information...`, { loading: true });
      const { data } = await automationActions.getAppVersion(whitelabelId, appClientCode, platform);
      // console.log('getAppVersion', data);
      await this.setMessage(MESSAGE_APP, '', { versionInfo: data, appVersion: data.AppVersionIOS, loading: false });
    } catch (error) {
      const message = getApiError(error).message;
      await this.setMessage(MESSAGE_APP, `Error: ${message}`, { loading: false });
    }
  };

  onRefreshBuildHistory = async () => {
    if (this.state.loadingSteps) return;

    const { whiteLabelInfo, selectedApp, selectedPlatform } = this.state;
    const whitelabelId = whiteLabelInfo.RowId;
    const appClientCode = selectedApp.Key;
    const platform = selectedPlatform.Key;
    try {
      await this.setMessage(MESSAGE_HISTORY, `Checking ${selectedPlatform.Title} app build...`, { loadingSteps: true });
      const { data } = await automationActions.checkAppBuild(whitelabelId, appClientCode, platform);
      // console.log('onRefreshBuildHistory', data);
      if (data) this.props.whiteLabelLoaded(data);
      await this.setMessage(MESSAGE_HISTORY, '', { loadingSteps: false });
    } catch (error) {
      const message = getApiError(error).message;
      await this.setMessage(MESSAGE_HISTORY, `Error: ${message}`, { loadingSteps: false });
    }
  };

  isValid = () => {
    const { loading, whiteLabelInfo, selectedApp, selectedPlatform } = this.state;
    return !loading && whiteLabelInfo && selectedApp && selectedPlatform;
  };

  isValidVersion = () => {
    const { appVersion } = this.state;
    if (!appVersion) return false;
    const matches = appVersion.match(/[0-9]+\.[0-9]+\.[0-9]+/g);
    return matches && matches.length > 0 && matches[0] === appVersion;
  };

  isValidWhatsNew = () => {
    const { shouldSubmit, whatsNew } = this.state;
    return !shouldSubmit || !_.isEmpty(whatsNew);
  };

  canStartBuild = () => {
    return this.isValid() && this.isValidVersion() && this.isValidWhatsNew();
  };

  onAppSelected = (selectedApp) => {
    // console.log('onAppSelected', selectedApp);
    this.setState({ selectedApp }, this.setAppInfo);
  };

  onSelectPlatform = (key) => {
    // console.log('onSelectPlatform', key);
    const selected = this.platformOptions.find((o) => o.Key === key);
    if (selected) this.setState({ selectedPlatform: selected }, this.setBuildInfo);
  };

  onStartBuild = async () => {
    if (!this.canStartBuild()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ showWarnings: false });

    if (this.isBuildInProgress()) {
      if (!window.confirm(`There is a build in progress. Are you sure you want to start another build?`)) return;
    }

    const { whiteLabelInfo, selectedApp, selectedPlatform, shouldSubmit, appVersion, whatsNew } = this.state;
    const whitelabelId = whiteLabelInfo.RowId;
    const appClientCode = selectedApp.Key;
    const platform = selectedPlatform.Key;
    try {
      await this.setMessage(MESSAGE_APP, `Setting app version to ${appVersion}...`, { loading: true });
      const { data: version } = await automationActions.updateAppVersion(appClientCode, appVersion);
      // console.log('onStartBuild - version', version);
      await this.setMessage(MESSAGE_APP, `Starting ${selectedPlatform.Title} app build...`);
      const { data: whiteLabel } = await automationActions.startAppBuild(whitelabelId, appClientCode, platform, shouldSubmit, whatsNew);
      // console.log('onStartBuild - whiteLabel', whiteLabel);
      if (whiteLabel) this.props.whiteLabelLoaded(whiteLabel);
      await this.setMessage(MESSAGE_APP, '', { loading: false });
    } catch (error) {
      const message = getApiError(error).message;
      await this.setMessage(MESSAGE_APP, `Error: ${message}`, { loading: false });
    }
  };

  onSubmit = async () => {
    const { whiteLabelInfo, selectedApp, selectedPlatform, appVersion, whatsNew } = this.state;
    const whitelabelId = whiteLabelInfo.RowId;
    const appClientCode = selectedApp.Key;
    const platform = selectedPlatform.Key;
    try {
      await this.setMessage(MESSAGE_APP, `Submitting ${selectedPlatform.Title}...`, { loading: true });
      const { data } = await automationActions.submitForReview(whitelabelId, appClientCode, platform, whatsNew, appVersion);
      console.log('onSubmit', data);
      await this.setMessage(MESSAGE_APP, '', { loading: false });
    } catch (error) {
      const message = getApiError(error).message;
      await this.setMessage(MESSAGE_APP, `Error: ${message}`, { loading: false });
    }
  };

  onStopBuild = async () => {
    if (!this.isValid()) return;

    const { whiteLabelInfo, selectedApp, selectedPlatform } = this.state;
    const whitelabelId = whiteLabelInfo.RowId;
    const appClientCode = selectedApp.Key;
    const platform = selectedPlatform.Key;

    try {
      await this.setMessage(MESSAGE_APP, `Stopping ${selectedPlatform.Title} app build...`, { loading: true });
      const { data } = await automationActions.stopAppBuild(whitelabelId, appClientCode, platform);
      //   console.log('onStopBuild', data);
      if (data) this.props.whiteLabelLoaded(data);
      await this.setMessage(MESSAGE_APP, '', { loading: false });
    } catch (error) {
      const message = getApiError(error).message;
      await this.setMessage(MESSAGE_APP, `Error: ${message}`, { loading: false });
    }
  };

  renderProgressStatus(inProgress, successful, failed) {
    if (failed) return <FontAwesome className="marginLeft-10 text-plussRed" name={'times'} />;
    if (successful) return <FontAwesome className="marginLeft-10 text-teal" name={'check'} />;
    if (inProgress) return <FontAwesome className="marginLeft-10 fontSize-13" name="spinner fa-pulse fa-fw" />;
  }

  renderProgress() {
    const { buildInfo, loadingSteps } = this.state;

    let message = this.getMessage(MESSAGE_HISTORY);
    if (buildInfo) {
      if (buildInfo.BuildError) message = buildInfo.BuildError.message;
      if (buildInfo.SubmitError) message = buildInfo.SubmitError.message;
    }

    const renderBuildSteps = () => {
      if (!buildInfo) return null;

      const isPipelineProgress = !_.isNil(buildInfo.PipelineId);
      const isPipelineSuccess = buildInfo.PipelineStatus === STATUS_COMPLETE && buildInfo.PipelineResult === RESULT_SUCCESS;
      const isPipelineFail = buildInfo.PipelineStatus === STATUS_COMPLETE && buildInfo.PipelineResult !== RESULT_SUCCESS;
      const isBuildSuccess = buildInfo.BuildStatus === EXPO_STATUS_FINISHED;
      const isBuildFail = buildInfo.BuildStatus === EXPO_STATUS_ERRORED || buildInfo.BuildStatus === EXPO_STATUS_CANCELED;
      const isSubmitSuccess = buildInfo.SubmitStatus === EXPO_STATUS_FINISHED;
      const isSubmitFail = buildInfo.SubmitStatus === EXPO_STATUS_ERRORED || buildInfo.SubmitStatus === EXPO_STATUS_CANCELED;
      const isStoreSuccess = !_.isNil(buildInfo.StoreSubmitState);
      const isStoreFail =
        !_.isNil(buildInfo.StoreSubmitState) && (_.isNil(buildInfo.StoreSubmitVersion) || _.isNil(buildInfo.StoreBuildVersion));
      // console.log('renderProgress', buildInfo);

      return (
        <div>
          <div className="flex flex-row flex-center marginBottom-5">
            <div style={{ width: 242 }}>Request package to be built</div>
            {this.renderProgressStatus(isPipelineProgress, isPipelineSuccess, isPipelineFail)}
            {buildInfo.PipelineUrl ? (
              <a href={buildInfo.PipelineUrl} className="marginLeft-10" target="_blank" rel="noopener noreferrer">
                See More
              </a>
            ) : null}
          </div>
          <div className="flex flex-row flex-center marginBottom-5">
            <div style={{ width: 242 }}>Build package for submission</div>
            {this.renderProgressStatus(isPipelineSuccess, isBuildSuccess, isBuildFail)}
            {isPipelineSuccess && buildInfo.BuildProgressUrl ? (
              <a href={buildInfo.BuildProgressUrl} className="marginLeft-10" target="_blank" rel="noopener noreferrer">
                See More
              </a>
            ) : null}
            {buildInfo.BuildUrl ? (
              <a href={buildInfo.BuildUrl} className="marginLeft-10" target="_blank" rel="noopener noreferrer">
                Download Package
              </a>
            ) : null}
          </div>
          {buildInfo.AutoSubmit ? (
            <div>
              <div className="flex flex-row flex-center marginBottom-5">
                <div style={{ width: 242 }}>Submit package to the store</div>
                {this.renderProgressStatus(isBuildSuccess, isSubmitSuccess, isSubmitFail)}
                {buildInfo.SubmitLogUrl ? (
                  <a href={buildInfo.SubmitLogUrl} className="marginLeft-10" target="_blank" rel="noopener noreferrer">
                    See More
                  </a>
                ) : null}
              </div>
              <div className="flex flex-row marginBottom-5">
                <div style={{ width: 242 }}>Prepare submission</div>
                {this.renderProgressStatus(isSubmitSuccess, isStoreSuccess, isStoreFail)}
                {buildInfo.StoreSubmitState ? (
                  <div className="marginLeft-10">
                    <div>
                      <b>Status: </b>
                      {buildInfo.StoreSubmitState.replace(/_/g, ' ')}
                    </div>
                    <div className="flex flex-row">
                      <div>
                        <b>Version: </b>
                        {buildInfo.StoreSubmitVersion}
                      </div>
                      <div className="marginLeft-8">
                        <b>Build: </b>
                        {buildInfo.StoreBuildVersion}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-row flex-center marginBottom-5">
                <div style={{ width: 242 }}>Review submission</div>
                {this.renderProgressStatus(isSubmitSuccess, isStoreSuccess, isStoreFail)}
                {buildInfo.StoreUrl ? (
                  <a href={buildInfo.StoreUrl} className="marginLeft-10" target="_blank" rel="noopener noreferrer">
                    Click to Review and Submit
                  </a>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      );
    };

    return (
      <div style={{ minWidth: 550 }}>
        {renderSubTitle('Monitor Build Steps')}
        {buildInfo ? renderBuildSteps() : null}
        <div className="flex flex-row flex-center marginTop-24">
          {buildInfo ? (
            <Button className="marginRight-16" inline buttonType="primary" onClick={this.onRefreshBuildHistory} isActive={!loadingSteps}>
              Refresh
            </Button>
          ) : null}
          <div>{message}</div>
        </div>
      </div>
    );
  }

  renderStartButton() {
    const { appVersion, loading } = this.state;
    const message = this.getMessage(MESSAGE_APP);

    return (
      <div className="flex flex-row flex-center marginTop-24">
        {appVersion ? (
          <Button className="marginRight-16" inline buttonType="primary" onClick={this.onStartBuild} isActive={!loading}>
            Start
          </Button>
        ) : null}
        {/* {appVersion ? (
          <Button className="marginRight-16" inline buttonType="primary" onClick={this.onSubmit} isActive={!loading}>
            Test Submit
          </Button>
        ) : null} */}
        <div>{message}</div>
      </div>
    );
  }

  renderPlatformSelect() {
    const { loading, selectedPlatform, showWarnings } = this.state;
    const selectedValue = selectedPlatform ? selectedPlatform.Title : '';

    return (
      <div className="flex flex-row flex-center">
        <div style={{ width: 250 }}>Select platform to build</div>
        <DropdownInput
          id={`dropdown_platforms`}
          style={{ width: 250, marginTop: 10, marginBottom: 10 }}
          placeholder="Select Platform"
          value={selectedValue}
          options={this.platformOptions}
          onSelect={this.onSelectPlatform}
          disabled={loading}
          showError={() => showWarnings && _.isEmpty(selectedValue)}
          errorMessage={'Platform is required'}
        />
      </div>
    );
  }

  renderStartBuild() {
    const { versionInfo, selectedPlatform, appVersion, loading, showWarnings } = this.state;
    if (!selectedPlatform) return null;
    const platform = selectedPlatform.Key;
    const canAutoSubmit = appVersion && versionInfo && (platform === 'ios' ? versionInfo.AppStoreVersion : versionInfo.PlayStoreVersion);
    const storeVersion = versionInfo && (platform === 'ios' ? versionInfo.AppStoreVersion : versionInfo.PlayStoreVersion);
    const buildVersion = versionInfo && (platform === 'ios' ? versionInfo.AppStoreBuildVersion : versionInfo.PlayStoreBuildVersion);

    return (
      <div style={{ minWidth: 600 }}>
        {renderSubTitle('Start New Build')}
        {versionInfo ? (
          <div>
            <div className="flex flex-row flex-center marginTop-16">
              <div style={{ width: 200 }}>Store Version: </div>
              <div>
                <b>{storeVersion || 'NOT AVAILABLE'}</b>
              </div>
            </div>
            <div className="flex flex-row flex-center">
              <div style={{ width: 200 }}>Store Build Version: </div>
              <div>
                <b>{buildVersion || 'NOT AVAILABLE'}</b>
              </div>
            </div>
            <div className="flex flex-row flex-center">
              <div style={{ width: 200 }}>Current Build Version: </div>
              <div>
                <b>{versionInfo.AppVersionIOS}</b>
              </div>
            </div>
            <ol style={{ marginTop: 8, paddingLeft: 15 }}>
              <li>
                <div className="flex flex-row flex-center">
                  <div style={{ width: 200 }}>Enter new build version</div>
                  <GenericInput
                    id="appVersion"
                    style={{ width: 250, marginTop: 10, marginBottom: 5 }}
                    type="text"
                    placeholder="Enter new build version"
                    value={appVersion}
                    onChange={(e) => this.setState({ appVersion: e.target.value })}
                    disabled={loading}
                    isValid={this.isValidVersion}
                    showError={showWarnings}
                    errorMessage={'App version is invalid'}
                  />
                </div>
              </li>
              {canAutoSubmit ? <li> {this.renderAutoSubmit()}</li> : <li>Note: Auto submission is currently not set up for this app</li>}
            </ol>
          </div>
        ) : null}
        {this.renderStartButton()}
      </div>
    );
  }

  renderAutoSubmit() {
    const { shouldSubmit, whatsNew, loading, showWarnings } = this.state;

    return (
      <div>
        <div style={{ marginBottom: 16 }}>
          Select to upload the build and prepare the submission for review automatically. If not selected, you can manually download the
          build and prepare the submission yourself.
        </div>
        <CheckBox
          key={'check_submit'}
          label="Yes, upload and prepare submission"
          isActive={shouldSubmit}
          onChange={() => this.setState({ shouldSubmit: !shouldSubmit })}
          style={{ marginBottom: 16 }}
          disabled={loading}
        />
        {shouldSubmit ? (
          <GenericInput
            id="whatsNew"
            label="What's New"
            type="textarea"
            componentClass="textarea"
            placeholder="Enter what's changed here"
            value={whatsNew}
            onChange={(e) => this.setState({ whatsNew: e.target.value })}
            inputStyle={{
              height: 120,
            }}
            alwaysShowLabel
            isValid={this.isValidWhatsNew}
            showError={showWarnings}
            errorMessage={"What's New is required"}
          />
        ) : null}
      </div>
    );
  }

  render() {
    const { selectedApp, selectedPlatform, loading } = this.state;

    return (
      <div>
        {renderTitle('Store Release Upgrade')}
        <div>
          <ol className="marginRight-40">
            {renderTextStep(
              <div>
                <AppSelector onSelected={this.onAppSelected} selected={selectedApp} includeSubApps disabled={loading} />
              </div>,
            )}
            {selectedApp ? renderTextStep(this.renderPlatformSelect()) : null}
            {selectedPlatform
              ? renderTextStep(
                  <div>
                    <div style={{ width: 250 }}>Monitor last build or start a new build</div>
                    <div className="flex flex-row marginTop-16" style={{ minWidth: 800 }}>
                      {this.renderProgress()}
                      <div style={{ borderRightStyle: 'inset', marginRight: 50 }}></div>
                      {this.renderStartBuild()}
                    </div>
                  </div>,
                )
              : null}
          </ol>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { automation } = state;
  return { activeWhiteLabel: automation.active };
};

export default connect(mapStateToProps, { whiteLabelLoaded, whiteLabelAppSet })(BuildApp);
