import moment from 'moment';
import { authedFunction } from '../session';
import { getUrl } from './helper';

export const analyticsActions = {
  log: (site, actionType, entityType, entityId, data) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('analytics', 'log'),
      data: {
        site,
        actionType,
        entityType,
        entityId,
        data,
      },
    });
  },
  getEntityStats: (entityId, entityType) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('analytics', 'getentity', { entityId, entityType }),
    });
  },
  getActivityLog: (entityId, entityType, actionType, minTime, maxTime) => {
    const query = { entityId, entityType, actionType };
    if (minTime) {
      query.minTime = minTime;
    }
    if (maxTime) {
      query.maxTime = maxTime;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('analytics', 'activity', query),
    });
  },
  getAggregateUserStats: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('analytics', 'users', { site }),
    });
  },
  getAggregateEntityStats: (site, type, minTime, maxTime, raw) => {
    const query = { site, type, minTime, maxTime };
    if (raw) {
      query.raw = raw;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('analytics', 'aggregate', query),
    });
  },
  getUserRetention: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('analytics', 'get/userretention', { site }),
    });
  },
};
