import _ from 'lodash';
import { getFeatureInfo } from '../helper';

export const getTabTitleFromPage = (page, index) => {
  if (_.isEmpty(page.tabTitle)) {
    return `Page ${index + 1}`;
  }
  return page.tabTitle;
};

export const getTabIconFromWidget = (widget) => {
  switch (widget) {
    case 'people':
    case 'surveys':
      return 'survey';
    case 'facilities':
      return 'foryou';
    default:
      return widget; // when icon name matches
  }
};

export const getTabKeyFromPage = (page) => {
  if (page.icon) {
    return page.icon;
  }
  if (page.type === 'home') {
    return 'home';
  }
  if (page.type === 'menu') {
    return 'more';
  }
  if (_.isEmpty(page.widgets)) {
    return 'empty';
  }
  return getTabIconFromWidget(page.widgets[0]);
};

export const getWidgetInfo = (w) => {
  if (!w) {
    return null;
  }
  const fInfo = getFeatureInfo(w);
  if (!fInfo) {
    return null;
  }
  return fInfo.featurePickerInfo;
};
