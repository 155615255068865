import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, ExportCsvPopup, Text } from '../';
import { connect } from 'react-redux';
import { analyticsActions } from '../../webapi';
import { validateAccess } from '../../session';
import { getPercentage } from '../../helper';

const getInitialState = () => ({
  isLoading: true,
  'Not Registered': 0,
  'Not Active': 0,
  Casual: 0,
  Active: 0,
  Engaged: 0,
});

// UserRetentionAnalytics Component
const UserRetentionAnalytics = ({ auth }) => {
  const [analyticsData, setAnalyticsData] = useState(getInitialState());
  const [isExportOpen, setIsExportOpen] = useState(false);

  const hasAccess = validateAccess(auth.site, 'userManagement', auth);
  if (!hasAccess) {
    return null;
  }

  const exportColumns = [
    { label: 'Select All', key: '' },
    { label: 'Not Registered', key: 'Not Registered' },
    { label: 'Not Active', key: 'Not Active' },
    { label: 'Casual', key: 'Casual' },
    { label: 'Active', key: 'Active' },
    { label: 'Very Active', key: 'Engaged' },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setAnalyticsData(getInitialState());
    // Load analytics data here using startTime and endTime
    const retentionResponse = await analyticsActions.getUserRetention(auth.site);

    const data = {
      ...analyticsData,
      isLoading: false,
      ...retentionResponse.data,
    };
    setAnalyticsData(data);
  };

  const isReadyToOpenCSV = () => {
    return !analyticsData.isLoading;
  };

  const getExportSource = () => {
    return [
      {
        'Not Registered': analyticsData['Not Registered'],
        'Not Active': analyticsData['Not Active'],
        Casual: analyticsData['Casual'],
        Active: analyticsData['Active'],
        Engaged: analyticsData['Engaged'],
      },
    ];
  };

  const getBarPercentage = (key) => {
    const maxValue = _.max([
      analyticsData['Not Registered'],
      analyticsData['Not Active'],
      analyticsData.Active,
      analyticsData.Casual,
      analyticsData.Engaged,
    ]);
    return getPercentage(analyticsData[key], maxValue);
  };

  const csvPopup = () => {
    if (!isExportOpen) {
      return null;
    }
    const source = getExportSource();
    return (
      <ExportCsvPopup
        onClose={() => {
          setIsExportOpen(false);
        }}
        columns={exportColumns}
        source={source}
        filename={`userengagement.csv`}
      />
    );
  };

  return (
    <div className="dashboardSection">
      {csvPopup()}
      <div>
        <Text type="h4" className="inlineBlock marginRight-40">
          User Engagement
        </Text>

        <Button
          inline
          buttonType="primaryAction"
          onClick={() => {
            if (!isReadyToOpenCSV()) return;
            setIsExportOpen(true);
          }}
          isActive={isReadyToOpenCSV()}
          leftIcon="file-code-o"
        >
          Export CSV
        </Button>
      </div>
      <div className="analyticsSection dashboardSection_content">
        <div className="dashboardBox dashboardBox-userRetention">
          <div className="userRetentionBar">
            <div className="userRetentionBar_bar" style={{ opacity: 0.2, width: getBarPercentage('Not Registered') }}></div>
            <Text type="h6" className="userRetentionBar_text">
              Not Registered: {analyticsData['Not Registered']}
            </Text>
          </div>
          <div className="userRetentionBar">
            <div className="userRetentionBar_bar" style={{ opacity: 0.4, width: getBarPercentage('Not Active') }}></div>
            <Text type="h6" className="userRetentionBar_text">
              Not Active: {analyticsData['Not Active']}
            </Text>
          </div>
          <div className="userRetentionBar">
            <div className="userRetentionBar_bar" style={{ opacity: 0.6, width: getBarPercentage('Casual') }}></div>
            <Text type="h6" className="userRetentionBar_text">
              Casual: {analyticsData['Casual']}
            </Text>
          </div>
          <div className="userRetentionBar">
            <div className="userRetentionBar_bar" style={{ opacity: 0.8, width: getBarPercentage('Active') }}></div>
            <Text type="h6" className="userRetentionBar_text">
              Active: {analyticsData['Active']}
            </Text>
          </div>
          <div className="userRetentionBar">
            <div className="userRetentionBar_bar" style={{ opacity: 1, width: getBarPercentage('Engaged') }}></div>
            <Text type="h6" className="userRetentionBar_text">
              Very Active: {analyticsData['Engaged']}
            </Text>
          </div>
          <Text type="body" className="marginTop-20">
            <b>Not Registered</b> is a user who has yet to log into the app and accept the terms and conditions.
            <br />
            <b>Not Active</b> is a user who has not used the app in the last 4 weeks.
            <br />
            <b>Casual</b> is a user who has used the app less than 5 times in the last 4 weeks.
            <br />
            <b>Active</b> is a user who has used the app at least 5 times in the last 4 weeks, but has had a week without any activity.
            <br />
            <b>Very Active</b> is a user who has used the app at least once every single week in the last 4 weeks.
          </Text>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

const toExport = connect(mapStateToProps, {})(UserRetentionAnalytics);
export { toExport as UserRetentionAnalytics };
