import { usePay } from './usePay';

class AttendanceInconsistency {
  constructor(userId, ticketCount, attendanceCount, originalAttendance) {
    this.userId = userId;
    this.ticketCount = ticketCount;
    this.attendanceCount = attendanceCount;
    this.originalAttendance = originalAttendance;
  }
}

/**
 * Compares transactions for an event with the registered attendance and returns any inconsistencies
 *
 * @param {Array<Attendee>} attendance
 * @param {Array<Transaction>} transactions
 * @returns {Array<AttendanceInconsistency>}
 */
const getAttendanceInconsistencies = (attendance, transactions) => {
  const paymentHelper = usePay();
  const userIds = Array.from(new Set(transactions.map((t) => t.UserId)));
  const result = [];
  userIds.forEach((userId) => {
    const userTransactions = transactions.filter((p) => p.UserId === userId);
    const combinedTransactions = paymentHelper.combineTransactions(userTransactions);
    const ticketCount = paymentHelper.getTotalQuantity(combinedTransactions.PaymentInfo || combinedTransactions.Item);
    const attendee = attendance.find((a) => {
      return a.id === userId;
    });
    const attendanceCount = attendee ? attendee.Count || 1 : 0;

    // only include cases where the purchased tickets exceed the attendance number
    if (ticketCount > attendanceCount) {
      result.push({ userId, ticketCount, attendanceCount, originalAttendance: attendee });
    }
  });
  return result;
};

const eventPayments = { getAttendanceInconsistencies };
export { eventPayments };
