import { DEVICES_LOADED, DEVICE_REMOVED, DEVICE_VIEWS_LOADED, DEVICES_PURGE } from '../actions/types';

const INITIAL_STATE = {
  list: [],
  views: [],
};

const getDeviceStatus = (status) => {
  switch (status) {
    case 'EXPIRED':
      return 'Code Expired';
    case 'ACTIVE':
      return 'Activated';
    case 'NOTFOUND':
    case 'INVALID':
    case 'INACTIVE':
    default:
      return 'Not Activated';
  }
};

const getAppStateCode = (message) => {
  if (!message) return '';

  if (message.startsWith('STATUS:')) {
    return message.split(' ')[1];
  } else if (message.startsWith('ERROR:')) {
    return 'error';
  }
  return '';
};

export default (state = INITIAL_STATE, action) => {
  let list;
  switch (action.type) {
    case DEVICES_LOADED:
      list = action.payload.map((device) => {
        return {
          ...device,
          Status: getDeviceStatus(device.Status),
          AppStateCode: getAppStateCode(device.AppState),
        };
      });
      return { ...state, list };
    case DEVICE_REMOVED:
      list = state.list.filter((device) => device.DeviceId !== action.payload);
      return { ...state, list };
    case DEVICE_VIEWS_LOADED:
      return { ...state, views: action.payload };
    case DEVICES_PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
