import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import {
  Button,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  Text,
  SuccessPopup,
  DropdownInput,
  DatePicker,
  TimePicker,
} from '../../components';
import { bookingActions, userActions } from '../../webapi';
import { timepickerToMinutes } from '../../helper';
import { calendarsLoaded, usersLoaded } from '../../actions';
import { checkLoggedIn } from '../../session';
import { withRouter } from 'react-router-dom';

class AddBlockOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeslots: [],
    };
  }

  componentDidMount() {
    checkLoggedIn(this, this.props.auth);
    this.getData();
  }

  getData() {
    bookingActions.getCalendarsBySite(this.props.auth.site).then((res) => {
      this.props.calendarsLoaded(res.data.Items);
    });

    userActions.getSiteUsersRecursive(this.props.auth.site).then((users) => {
      this.props.usersLoaded(users);
    });
  }

  onBack = () => {
    window.history.back();
  };

  inputsDisabled() {
    if (this.state.submitting) {
      return true;
    }
    return false;
  }

  getCalendarOptions = () => {
    return this.props.calendars.map((c) => {
      return { Key: c.Id, Title: c.Title };
    });
  };

  getSelectedCalendar = () => {
    return this.state.selectedCalendar || {};
  };

  onSelectCalendar = (calendarId) => {
    const calendar = this.props.calendars.find((c) => c.Id === calendarId);
    console.log(calendar);
    this.setState({
      selectedCalendar: calendar,
      selectedDay: null,
      selectedTime: null,
    });
  };

  onSelectDate = (date) => {
    this.setState({ selectedDay: date, timeslots: [], selectedTime: null }, this.getTimeslots);
  };

  onSelectTime = (ts) => {
    this.setState({
      selectedTime: ts.StartTime,
    });
  };

  onHandleChange = (event) => {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  };

  onHandleHourChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  getCombinedTime = () => {
    const timeToUse = moment(this.state.selectedDay);
    if (this.state.selectedDay && this.state.startTime) {
      const startTime = timepickerToMinutes(this.state.startTime);
      timeToUse.hours(Math.floor(startTime / 60));
      timeToUse.minutes(startTime % 60);
    }
    return timeToUse.local();
  };

  getBlockoutLength = () => {
    if (!this.state.startTime || !this.state.endTime) {
      return 0;
    }
    return timepickerToMinutes(this.state.endTime) - timepickerToMinutes(this.state.startTime);
  };

  isValidForm = () => {
    if (this.state.makingBooking) {
      return false;
    }
    if (!this.state.selectedCalendar) {
      return false;
    }
    if (!this.state.selectedDay) {
      return false;
    }
    if (this.getBlockoutLength() <= 0) {
      return false;
    }
    return true;
  };

  onBook = async () => {
    if (!this.isValidForm()) {
      return;
    }

    this.setState({
      makingBooking: true,
    });

    bookingActions
      .addBooking(
        this.state.selectedCalendar.Id,
        'BLOCKOUT',
        moment(this.state.selectedDay).format('DD-MM-YYYY'),
        timepickerToMinutes(this.state.startTime),
        null,
        'Admin',
        'Admin',
        {
          Id: 'Admin',
          Type: 'Admin',
          Title: 'Admin',
        },
        this.getBlockoutLength(),
      )
      .then((res) => {
        this.setState({
          success: true,
          booking: res.data.booking,
        });
      })
      .catch((err) => {
        console.log('onBook - error', err);
      })
      .finally(() => {
        this.setState({
          makingBooking: false,
        });
      });
  };

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          {!this.inputsDisabled() ? 'Cancel' : 'Back'}
        </Button>
        {!this.inputsDisabled() && (
          <Button inline buttonType="primary" onClick={this.onBook} isActive={this.isValidForm()}>
            Save
          </Button>
        )}
      </div>
    );
  }

  renderCalendars() {
    return (
      <div className="genericInputContainer">
        <Text type="formLabel" className="marginTop-16">
          Calendar
        </Text>
        <DropdownInput
          id={`calendarDropdown`}
          placeholder="Select calendar"
          value={this.getSelectedCalendar().Title}
          options={this.getCalendarOptions()}
          onSelect={(val) => {
            this.onSelectCalendar(val);
          }}
          style={{ marginBottom: 0 }}
        />
      </div>
    );
  }

  renderDay() {
    if (!this.state.selectedCalendar) {
      return null;
    }
    return (
      <div className="genericInputContainer">
        <Text type="formLabel" className="marginBottom-5">
          Select Date
        </Text>
        <DatePicker selectedDate={this.state.selectedDay || moment()} selectDate={this.onSelectDate} />
      </div>
    );
  }

  renderTimes() {
    if (!this.state.selectedDay) {
      return null;
    }
    return (
      <div className="genericInputContainer">
        <Text type="formLabel">Select Time</Text>
        <div className="flex flex-align-end">
          <TimePicker
            selectedTime={this.state.startTime}
            selectTime={(val) => {
              this.onHandleHourChange('startTime', val);
            }}
            className="marginRight-20"
          />
          <Text type="bodyLarge" className="marginRight-20">
            –
          </Text>
          <TimePicker
            selectedTime={this.state.endTime}
            selectTime={(val) => {
              this.onHandleHourChange('endTime', val);
            }}
            className="marginRight-20"
          />
        </div>
      </div>
    );
  }

  renderForm = () => {
    if (this.state.success) {
      return null;
    }
    return (
      <div>
        <div className="padding-60 paddingVertical-40">
          <Text type="formTitleLarge" className="marginBottom-24 text-capitaliseWords">
            Block Out Time
          </Text>
          {this.renderCalendars()}
          {this.renderDay()}
          {this.renderTimes()}
        </div>
      </div>
    );
  };

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`Booking has been added`}
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              this.props.history.push(`/bookings/booking/${this.state.booking.Id}`);
            },
            text: 'View booking',
          },
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  render = () => {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--fixedPopupSize">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  };
}

const mapStateToProps = (state) => {
  const { calendars, types } = state.bookings;
  const { users } = state.users;
  const { auth } = state;
  return {
    users,
    calendars,
    auth,
  };
};

export default connect(mapStateToProps, { calendarsLoaded, usersLoaded })(withRouter(AddBlockOut));
