import _ from 'lodash';
import { WHITE_LABEL_LOADED, WHITE_LABEL_ADDED, WHITE_LABEL_PURGE, WHITE_LABEL_APP_SET } from '../actions/types';

const INITIAL_INFO = {
  RowId: '',
  ClientCode: '',
  ClientName: '',
  Section: 0,
  Step: 0,
  Apps: [],
};
const INITIAL_STATE = {
  active: _.clone(INITIAL_INFO),
  activeApp: null,
};

const initialiseApps = (info) => {
  const newInfo = _.cloneDeep(info);
  const { Apps, ClientCode, ClientName } = newInfo;
  if (ClientCode && ClientName) {
    if (!Apps || Apps.length === 0) {
      newInfo.Apps = [{ ClientCode, ClientName }];
    } else if (!Apps.find((a) => a.ClientCode === ClientCode)) {
      Apps.push({ ClientCode, ClientName });
    }
  }
  return newInfo;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WHITE_LABEL_LOADED:
    case WHITE_LABEL_PURGE:
      return { ...state, active: initialiseApps(action.payload || INITIAL_INFO) };
    case WHITE_LABEL_ADDED:
      const active = _.clone(INITIAL_INFO);
      if (action.payload) active.ClientCode = action.payload;
      return { active };
    case WHITE_LABEL_APP_SET:
      return { ...state, activeApp: action.payload };
    default:
      return state;
  }
};
