import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { bookingTypesLoaded, removeBookingType } from '../../actions';
import { bookingActions } from '../../webapi';
import { checkLoggedIn, validateAccess } from '../../session';
import { Text } from '../../components/text';
import { formatCurrency, minutesToString } from '../../helper';

class BookingTypes extends Component {
  state = {
    sortColumn: 'EndTime', // column to sort by
    sortDesc: true, // if true, sort descending rather than ascending
  };

  componentDidMount() {
    checkLoggedIn(this, this.props.auth);
    this.getData();
  }

  getData() {
    bookingActions.getBookingTypesBySite(this.props.auth.site).then((res) => {
      this.props.bookingTypesLoaded(res.data.Items);
    });
  }

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  removeType(type) {
    if (window.confirm(`Are you sure you want to delete that type?`)) {
      this.props.removeBookingType(type.Id);
      bookingActions
        .deleteBookingType(type.Id)
        .then((res) => {
          this.getData();
        })
        .catch((res) => {
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  renderItems(source) {
    return source.map((ev) => {
      return (
        <tr key={ev.Id}>
          <td className="table-TitleColumn">
            <Link to={`/bookings/type/${ev.Id}`}>{ev.Title}</Link>
          </td>
          <td>{moment(ev.Changed).format('D MMM YYYY')}</td>
          <td>{minutesToString(ev.Length + ev.BufferBefore + ev.BufferAfter)}</td>
          <td>
            {ev.Paid ? 'Yes' : 'No'}
            {ev.Paid && ev.PaymentInfo && (
              <>
                <br />
                {`${formatCurrency(ev.PaymentInfo.categories ? ev.PaymentInfo.categories[0].price : ev.PaymentInfo.Price)}`}
              </>
            )}
          </td>
          <td className="table-options">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {validateAccess(this.props.auth.site, 'bookings', this.props.auth) && (
                <Link to={`/bookings/type/${ev.Id}`}>
                  <FontAwesome
                    style={{
                      fontSize: 20,
                      padding: 5,
                      marginLeft: 12,
                      cursor: 'pointer',
                    }}
                    name="pencil"
                  />
                </Link>
              )}
              {validateAccess(this.props.auth.site, 'bookings', this.props.auth) && (
                <a
                  onClick={() => {
                    this.removeType(ev);
                  }}
                >
                  <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="minus-circle" />
                </a>
              )}
            </div>
          </td>
        </tr>
      );
    });
  }

  renderSort(col) {
    if (col !== this.state.sortColumn) {
      return null;
    }
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  sortIsActive(col) {
    if (col !== this.state.sortColumn) {
      return '';
    }
    return ' table--columnActive';
  }

  renderView(source) {
    return (
      <Table className="plussTable" striped bordered condensed hover>
        <thead>
          <tr>
            <th
              className={`${this.sortIsActive('Title')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.sortByCol('Title');
              }}
            >
              Title{this.renderSort('Title')}
            </th>
            <th
              className={`${this.sortIsActive('Changed')}`}
              style={{ cursor: 'pointer', width: 120 }}
              onClick={() => {
                this.sortByCol('Changed');
              }}
            >
              Last Changed{this.renderSort('Changed')}
            </th>
            <th style={{ width: 140 }}>Total Length</th>
            <th style={{ width: 140 }}>Paid</th>
            <th style={{ width: 70 }} />
          </tr>
        </thead>
        <tbody>{this.renderItems(source)}</tbody>
      </Table>
    );
  }

  renderEmpty() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <Text type="h3">There are no booking types</Text>
        </div>
      </div>
    );
  }

  renderContent() {
    let source = _.sortBy(this.props.bookingTypes, this.state.sortColumn);
    if (this.state.sortDesc) {
      source.reverse();
    }
    // source = []
    if (_.isEmpty(source)) {
      return this.renderEmpty();
    }
    return this.renderView(source);
  }

  render() {
    return <div style={{ minWidth: '100%' }}>{this.renderContent()}</div>;
  }
}

const mapStateToProps = (state) => {
  const { types } = state.bookings;
  const { auth } = state;
  return {
    bookingTypes: types,
    auth,
  };
};

export default connect(mapStateToProps, { bookingTypesLoaded, removeBookingType })(BookingTypes);
