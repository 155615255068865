import React, { Component } from 'react';
import { Table, Form, Row, Col } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { usersLoaded } from '../../actions';
import { InputGroup, Button, ExportCsvPopup } from '../../components';
import { checkLoggedIn, validateAccess } from '../../session';
import { typeActions, userActions } from '../../webapi';
import { requiresUserTermsAndConditions, CONST_STRINGS } from '../../config';
import { isKiosk } from '../../helper';
import { Text } from '../../components/text';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sortColumn: 'displayName',
      sortDesc: false,
      search: '',
      startIndex: 0,
      endIndex: 100,
      exportCsvOpen: false,
      exportSource: [],
      exportColumns: [
        { label: 'Select All', key: '' },
        { label: 'Id', key: 'Id' },
        { label: 'Name', key: 'displayName' },
        { label: 'Email', key: 'email' },
        { label: 'Phone', key: 'phoneNumber' },
        { label: 'Birthday', key: 'birthday' },
        { label: 'Last App Use', key: 'LastAppUse' },
        { label: 'Accepted Terms', key: 'onBoardingSeen' },
        { label: 'Time of Accepting Terms', key: 'onBoardingSeenTime' },
        { label: 'Initial Password', key: 'initialPassword' },
        { label: 'MFA Enabled', key: 'isMFAEnabled' },
      ],
    };
  }

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    if (!validateAccess(this.props.auth.site, 'userManagement', this.props.auth, true)) {
      this.props.history.push('/mastermenu');
    } else {
      this.getSites();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.users.length !== this.props.users.length) {
      this.setState(
        {
          startIndex: 0,
          endIndex: 100,
        },
        this.getInitialPasswords,
      );
    }
  }

  getSites() {
    typeActions
      .getSites()
      .then((res) => {
        res.data.forEach((e) => {
          e.Title = e.siteName;
          e.Key = e.Id;
        });
        this.setState({
          sites: res.data,
        });
      })
      .catch((error) => {});
  }

  getInitialPasswords = async () => {
    try {
      const data = await userActions.fetchInitialPasswords(this.props.auth.site);
      // console.log('getInitialPasswords - data', data);

      const exportColumns = [...this.state.exportColumns];

      const exportSource = this.getSource().map((u) => {
        const match = data.find((i) => i.Id === u.Id);
        const user = {
          Id: u.Id,
          displayName: u.displayName,
          email: u.email,
          phoneNumber: u.phoneNumber,
          birthday: this.getBirthday(u.birthday, 'DD/MM/YYYY'),
          LastAppUse: this.getFormattedDate(u.LastAppUse, 'DD/MM/YYYY'),
          onBoardingSeen: u.onBoardingSeen ? 'Yes' : 'No',
          onBoardingSeenTime: this.getFormattedDate(u.onBoardingSeenTime, 'DD/MM/YYYY'),
          initialPassword: match ? match.initialPassword : null,
          isMFAEnabled: u.isMFAEnabled ? 'Yes' : 'No',
        };
        if (!_.isEmpty(u.SignUpQuestions)) {
          u.SignUpQuestions.forEach((q) => {
            user[q.question] = q.answer;
            if (
              !_.some(exportColumns, (col) => {
                return col.key === q.question;
              })
            ) {
              exportColumns.push({ key: q.question, label: q.question });
            }
          });
        }
        return user;
      });
      // console.log('getInitialPasswords - exportSource', exportSource);
      this.setState({ exportSource, exportColumns });
    } catch (error) {
      console.error('getInitialPasswords', error);
    }
  };

  isExportReady = () => !_.isEmpty(this.state.exportSource);

  prevPage() {
    if (this.state.startIndex === 0) {
      return;
    }
    this.setState({
      startIndex: this.state.startIndex - 100,
      endIndex: Math.ceil(this.state.endIndex / 100) * 100 - 100,
    });
  }

  nextPage() {
    if (this.state.startIndex + 100 >= this.props.users.length) {
      return;
    }
    this.setState({
      startIndex: this.state.startIndex + 100,
      endIndex: Math.ceil(this.state.endIndex / 100) * 100 + 100,
    });
  }

  handleChange(event) {
    var stateChange = {
      startIndex: 0,
      endIndex: 100,
    };
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  getTypeTitle(user) {
    const typeObject = _.find(this.props.userTypes, (t) => {
      return t.typeName === user.type && t.site === user.site;
    });
    if (typeObject) {
      return typeObject.displayName;
    }
    if (user.type) {
      if (user.type === 'FORMKIOSK') {
        return CONST_STRINGS.KIOSK_FORM_LOCKOUT;
      }
      if (user.type === 'SIGNINKIOSK') {
        return CONST_STRINGS.KIOSK_FORM_SIGNIN;
      }
      return user.type;
    }
    return '';
  }

  getSiteTitle(user) {
    const siteObject = _.find(this.state.sites, (t) => {
      return t.Id === user.site;
    });
    if (siteObject) {
      return siteObject.siteName;
    }
    return user.site;
  }

  getPaginationText() {
    const source = this.getSource();
    return `${Math.min(this.state.startIndex + 1, source.length)}-${Math.min(this.state.endIndex, source.length)}/${source.length}`;
  }

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  getBirthday(date, format = 'DD MMM YYYY') {
    if (_.isUndefined(date) || _.isEmpty(date)) return null;
    return moment(date, 'YYYY-MM-DD').format(format);
  }

  getFormattedDate(date, format = 'D MMM YYYY') {
    return date ? moment.utc(date).local().format(format) : '';
  }

  getSource() {
    let source = this.props.users;
    if (!_.isEmpty(this.state.search)) {
      let lowSearch = this.state.search.toLowerCase();
      if (lowSearch.indexOf('04') === 0) {
        lowSearch = lowSearch.substring(1); // account for phone numbers stored in +61 format
      }
      source = _.filter(source, (ev, i) => {
        if (!ev.displayName) {
          return false;
        }
        const type = this.getTypeTitle(ev);
        if (!type) {
          return false;
        }
        return (
          ev.displayName.toLowerCase().indexOf(lowSearch) > -1 ||
          type.toLowerCase().indexOf(lowSearch) > -1 ||
          (!_.isEmpty(ev.email) && ev.email.toLowerCase().indexOf(lowSearch) > -1) ||
          (!_.isEmpty(ev.phoneNumber) && ev.phoneNumber.toLowerCase().indexOf(lowSearch) > -1)
        );
      });
    }
    return source;
  }

  sortSource(source) {
    let result = _.sortBy(source, this.state.sortColumn);

    if (this.state.sortColumn === 'type') {
      result = _.sortBy(result, (user) => {
        return this.getTypeTitle(user);
      });
    }
    if (this.state.sortColumn === 'unit') {
      result = _.sortBy(result, (user) => {
        return parseInt(user.unit, 10);
      });
    }
    if (this.state.sortColumn === 'inviteCode') {
      result = _.sortBy(result, (user) => {
        return !user.invitingUser;
      });
    }
    if (this.state.sortColumn === 'LastAppUse') {
      result = _.sortBy(result, (user) => {
        if (!user.LastAppUse) {
          return Number.MAX_SAFE_INTEGER;
        }
        return Number.MAX_SAFE_INTEGER - user.LastAppUse;
      });
    }

    if (this.state.sortDesc) {
      result.reverse();
    }
    return _.filter(result, (ev, i) => {
      return i >= this.state.startIndex && i < this.state.endIndex;
    });
  }

  onOpenExportCsv = () => {
    if (!this.isExportReady()) return;
    this.setState({ exportCsvOpen: true });
  };

  onCloseExportCsv = () => {
    this.setState({ exportCsvOpen: false });
  };

  renderUsers(source) {
    return source.map((user, index) => {
      if (user != null) {
        const editLink = `/usershub/${isKiosk(user.type) ? 'kiosk' : 'user'}/${encodeURIComponent(user.Id)}`;
        return (
          <tr key={index}>
            <td className="table-TitleColumn" style={{ paddingLeft: 16 }}>
              {validateAccess(this.props.auth.site, 'userManagement', this.props.auth) ? (
                <Link to={editLink}>{user.displayName}</Link>
              ) : (
                <span>{user.displayName}</span>
              )}
            </td>
            <td>{this.getTypeTitle(user)}</td>
            <td>{this.getBirthday(user.birthday)}</td>
            {this.props.auth.site === 'hq' && <td>{this.getSiteTitle(user)}</td>}
            <td>{user.unit}</td>
            <td>{this.getFormattedDate(user.LastAppUse)}</td>
            {requiresUserTermsAndConditions && (
              <td>
                {user.onBoardingSeen ? 'Yes' : 'No'}
                {user.onBoardingSeenTime && (
                  <span>
                    <br />
                    {moment.utc(user.onBoardingSeenTime).local().format('D MMM YYYY')}
                  </span>
                )}
              </td>
            )}
            <td>
              {user.invitingUser ? 'Yes' : 'No'}
              {user.invitingUser && <span> from {user.invitingUser.displayName}</span>}
            </td>
            <td>{user.isMFAEnabled ? 'Yes' : 'No'}</td>
            <td className="table-options">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {validateAccess(this.props.auth.site, 'userManagement', this.props.auth) && (
                  <Link to={editLink}>
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name="pencil" />
                  </Link>
                )}
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  renderSort(col) {
    if (col !== this.state.sortColumn) {
      return null;
    }
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  sortIsActive(col) {
    if (col !== this.state.sortColumn) {
      return '';
    }
    return ' table--columnActive';
  }

  renderView(source) {
    if (_.isEmpty(source)) {
      return this.renderEmpty();
    }
    return (
      <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
        <thead>
          <tr>
            <th
              className={`${this.sortIsActive('displayName')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.sortByCol('displayName');
              }}
            >
              User name{this.renderSort('displayName')}
            </th>
            <th
              className={`${this.sortIsActive('type')}`}
              style={{ cursor: 'pointer', minWidth: 100 }}
              onClick={() => {
                this.sortByCol('type');
              }}
            >
              Type{this.renderSort('type')}
            </th>
            <th style={{ minWidth: 100 }}>Birthday</th>
            {this.props.auth.site === 'hq' && (
              <th
                className={`${this.sortIsActive('site')}`}
                style={{ cursor: 'pointer', minWidth: 100 }}
                onClick={() => {
                  this.sortByCol('site');
                }}
              >
                Site{this.renderSort('site')}
              </th>
            )}
            <th
              className={`${this.sortIsActive('unit')}`}
              style={{ cursor: 'pointer', minWidth: 100 }}
              onClick={() => {
                this.sortByCol('unit');
              }}
            >
              Address{this.renderSort('unit')}
            </th>
            <th
              className={`${this.sortIsActive('LastAppUse')}`}
              style={{ cursor: 'pointer', minWidth: 100 }}
              onClick={() => {
                this.sortByCol('LastAppUse');
              }}
            >
              Last Activity in App{this.renderSort('LastAppUse')}
            </th>
            {requiresUserTermsAndConditions && (
              <th
                className={`${this.sortIsActive('onBoardingSeen')}`}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.sortByCol('onBoardingSeen');
                }}
              >
                Accepted Terms?{this.renderSort('onBoardingSeen')}
              </th>
            )}
            <th
              className={`${this.sortIsActive('inviteCode')}`}
              style={{ cursor: 'pointer', minWidth: 100 }}
              onClick={() => {
                this.sortByCol('inviteCode');
              }}
            >
              Via Invite Code?{this.renderSort('inviteCode')}
            </th>
            <th
              className={`${this.sortIsActive('isMFAEnabled')}`}
              style={{ cursor: 'pointer', minWidth: 50 }}
              onClick={() => {
                this.sortByCol('isMFAEnabled');
              }}
            >
              MFA{this.renderSort('isMFAEnabled')}
            </th>
            <th style={{ width: 70 }} />
          </tr>
        </thead>
        <tbody>
          {/* Render Current Visitors here! */}
          {this.renderUsers(source)}
        </tbody>
      </Table>
    );
  }

  renderEmpty() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <Text type="h3">There are no users</Text>
        </div>
      </div>
    );
  }

  render() {
    const { search, exportCsvOpen, exportSource } = this.state;
    let source = this.getSource();
    const sourceLength = source.length;
    source = this.sortSource(source);

    return (
      <div style={{ minWidth: '100%' }}>
        <Form>
          <Row className="show-grid">
            <Col xs={6}>
              <InputGroup
                id={`search`}
                type="text"
                label="Search"
                placeholder="Search users"
                value={search}
                onChange={(e) => this.handleChange(e)}
                autoComplete={false}
              />
            </Col>
            <Col xs={6} className="flex flex-end">
              {validateAccess(this.props.auth.site, 'userManagement', this.props.auth) && (
                <div className="userTypesButtonContainer">
                  <Button inline buttonType="primaryAction" onClick={this.onOpenExportCsv} isActive={this.isExportReady()}>
                    <div className="buttonInner">
                      <FontAwesome name="file-code-o" style={{ fontSize: 19 }} />
                      <div className="text">Export CSV</div>
                    </div>
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          {source.length > 0 && (
            <Row className="show-grid">
              <Col xs={6} className="paginationContainer">
                <FontAwesome className="pagination__left" name="chevron-left" onClick={this.prevPage.bind(this)} />
                <p className="pagination__text">{this.getPaginationText()}</p>
                <FontAwesome className="pagination__right" name="chevron-right" onClick={this.nextPage.bind(this)} />
              </Col>
            </Row>
          )}
        </Form>
        {this.renderView(source)}
        {sourceLength > 100 && (
          <Form>
            <Row className="show-grid">
              <Col xs={6} className="paginationContainer">
                <FontAwesome className="pagination__left" name="chevron-left" onClick={this.prevPage.bind(this)} />
                <p className="pagination__text">{this.getPaginationText()}</p>
                <FontAwesome className="pagination__right" name="chevron-right" onClick={this.nextPage.bind(this)} />
              </Col>
            </Row>
          </Form>
        )}
        {exportCsvOpen ? (
          <ExportCsvPopup onClose={this.onCloseExportCsv} columns={this.state.exportColumns} source={exportSource} filename="users.csv" />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, { usersLoaded })(withRouter(Users));
