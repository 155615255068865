import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { alertActions, newsletterActions, infoActions, eventActions, typeActions, authActions, marketActions } from '../../webapi';
import { getLogo, getUrlParams } from '../../helper';
import { setAuthUser } from '../../actions';
import { Auth } from 'aws-amplify';

class TV extends Component {
  constructor(props) {
    super(props);

    const params = getUrlParams();

    this.state = {
      loadingSiteInfo: true,
      timestamp: '',
      alerts: [],
      news: [],
      announcements: [],
      nonFeaturedEvents: [],
      featuredEvents: [],
      events: [],
      eventIndex: 0,
      offers: [],
      offerIndex: 0,
      displayedAnnouncements: 0,
      forceRotate: params.forceRotate,
    };
  }

  componentDidMount() {
    this.getData();
    this.getSite();
    this.refreshTimestamp();

    Auth.currentSession()
      .then((data) => {
        // all good
      })
      .catch((err) => {
        this.login();
      });
  }

  componentWillUnmount() {
    clearTimeout(this.timestampTimeout);
    clearTimeout(this.dataTimeout);
    clearTimeout(this.eventChangeTimeout);
  }

  toLocal(time) {
    const params = getUrlParams();
    if (params['tz']) {
      return time.utcOffset(parseInt(params['tz'], 10));
    }
    return time.local();
  }

  login() {
    const params = getUrlParams();
    const email = params['user'];
    const password = params['pw'];

    Auth.signIn(email.toLowerCase(), password)
      .then((result) => {
        const userId = result.username;

        authActions
          .checkUserAuth(userId)
          .then((res) => {
            authActions
              .getUserSignin()
              .then((userRes) => {
                if (res.data.type != null) {
                  this.props.setAuthUser(res.data.type, { ...userRes.data, uid: userRes.data.Id }, res.data.site);
                  setTimeout(() => {
                    this.getSite();
                    this.getData();
                  }, 1000);
                } else {
                  this.props.setAuthUser(null, null);
                }
              })
              .catch((err) => {});
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }

  refreshTimestamp() {
    this.setState({
      timestamp: this.toLocal(moment()).format('dddd, D MMMM YYYY • h:mm a'),
    });
    this.timestampTimeout = setTimeout(() => {
      this.refreshTimestamp();
    }, 10000);
  }

  changeEvent() {
    const { eventIndex, offers, offerIndex } = this.state;
    const maxIndex = offers ? offers.length - 1 : 0;
    this.setState({
      eventIndex: eventIndex + 1,
      offerIndex: offerIndex >= maxIndex ? 0 : offerIndex + 1,
    });

    this.eventChangeTimeout = setTimeout(() => {
      this.changeEvent();
    }, 8000);
  }

  getSite() {
    if (!this.props.auth || !this.props.auth.site) {
      // should be a catch is site isn't present or has expired from cache
      return this.login();
    }
    typeActions.getSite(this.props.auth.site).then((res) => {
      this.setState({
        loadingSiteInfo: false,
        site: res.data.siteName,
        siteBranding: res.data.Branding,
      });
    });
  }

  getData() {
    this.getAlerts();
    this.getNews();
    this.getEvents();
    this.getWeather();
    this.getOffers();

    this.dataTimeout = setTimeout(() => {
      this.getData();
    }, 600000);
  }

  getOffers() {
    marketActions.getOffers(this.props.auth.site, true).then((res) => {
      this.setState({
        offers: res.data,
      });
    });
  }

  getNews() {
    newsletterActions.getRecent(this.props.auth.site, 10, null, 'true', 'true').then((res) => {
      const news = res.data.map((v) => {
        const date = this.toLocal(moment.utc(v.Timestamp));
        let displayTime = date.format('dddd D MMMM');
        if (date.isSame(moment(), 'date')) {
          displayTime = 'Today';
        }
        return {
          Text: v.Title,
          DisplayTime: displayTime,
          TimeSort: date.valueOf(),
        };
      });
      this.setState({
        news,
        announcements: _.sortBy([...news, ...this.state.alerts], 'TimeSort').reverse(),
        displayedAnnouncements: 0,
      });
      setTimeout(() => {
        this.checkDisplayAnnouncement();
      }, 100);
    });
  }

  getAlerts() {
    alertActions.getTVAlerts(this.props.auth.site).then((res) => {
      const alerts = res.data.map((v) => {
        const date = this.toLocal(moment.unix(v.Changed));
        let displayTime = date.format('dddd D MMMM');
        if (date.isSame(moment(), 'date')) {
          displayTime = 'Today';
        }
        return {
          Text: v.Title,
          DisplayTime: displayTime,
          TimeSort: date.valueOf(),
        };
      });
      this.setState({
        alerts,
        announcements: _.sortBy([...alerts, ...this.state.news], 'TimeSort').reverse(),
        displayedAnnouncements: 0,
      });
      setTimeout(() => {
        this.checkDisplayAnnouncement();
      }, 100);
    });
  }

  getWeather() {
    infoActions.getCurrentWeather(this.props.auth.site).then((res) => {
      this.setState({
        weather: res.data,
      });
    });
  }

  getEvents() {
    const startOfDay = moment().startOf('d');
    eventActions.getUpcomingEventReps(this.props.auth.site, startOfDay.unix(), 10).then((res) => {
      const featuredEvents = _.filter(res.data, (e) => {
        return e.IsFeatured;
      });
      const nonFeaturedEvents = _.filter(res.data, (e) => {
        return _.isEmpty(featuredEvents) || !e.IsFeatured || e !== featuredEvents[0];
      });
      //TODO change nonFeaturedEvents once we add scrolling
      //_.filter(res.data, (e) => { return !e.IsFeatured; });
      this.setState({
        events: res.data,
        nonFeaturedEvents,
        featuredEvents,
      });

      clearTimeout(this.eventChangeTimeout);
      this.eventChangeTimeout = setTimeout(() => {
        this.changeEvent();
      }, 12000);
    });
  }

  getEvent(i) {
    if (i === 0 && !_.isEmpty(this.state.featuredEvents)) {
      // split into featured events
      return this.state.featuredEvents[this.state.eventIndex % this.state.featuredEvents.length];
    } else if (!_.isEmpty(this.state.featuredEvents) && this.state.nonFeaturedEvents.length > 3) {
      return this.state.nonFeaturedEvents[(this.state.eventIndex + i) % this.state.nonFeaturedEvents.length];
    }
    return this.state.events[(this.state.eventIndex + i) % this.state.events.length];
  }

  getEventTime(ev) {
    return this.toLocal(moment.unix(ev.Unix));
  }

  checkDisplayAnnouncement() {
    const elem = document.getElementById('announcements');
    if (elem.clientHeight < elem.scrollHeight) {
      // it is now overflowing
      this.setState({
        displayedAnnouncements: this.state.displayedAnnouncements - 1,
      });
      return;
    }
    if (this.state.displayedAnnouncements === this.state.announcements.length) {
      return;
    }
    this.setState({
      displayedAnnouncements: this.state.displayedAnnouncements + 1,
    });
    setTimeout(() => {
      this.checkDisplayAnnouncement();
    }, 100);
  }

  renderAnnouncement(a, i, bypassDisplayCheck) {
    if (!bypassDisplayCheck && i + 1 > this.state.displayedAnnouncements) {
      return null;
    }
    return (
      <div className="announcementitem" key={i}>
        <h5 className="announcetitle">{a.Text}</h5>
        <p className="announcedatetime">{a.DisplayTime}</p>
      </div>
    );
  }

  renderAnnouncements() {
    const { offers, offerIndex } = this.state;
    const hasOffers = offers && offers.length > 0;
    return (
      <div className="tvannouncements">
        <h2 className="headingtext announcementhead">Announcements</h2>
        <div id="announcements" className="announcementcontent" style={{ maxHeight: hasOffers && 800 }}>
          {this.state.announcements.map((a, i) => this.renderAnnouncement(a, i))}
        </div>
        {hasOffers && (
          <div id="offers" className="announcementOffer" style={{ backgroundImage: `url(${this.state.offers[offerIndex].TVModeLogo})` }} />
        )}
      </div>
    );
  }

  renderAnnouncementsPortrait() {
    const { offers, offerIndex } = this.state;
    const hasOffers = offers && offers.length > 0;
    return (
      <div className="tvannouncementsPortrait">
        <h2 className="headingtext announcementhead">Announcements</h2>
        <div id="announcements" className="announcementcontent">
          {this.state.announcements.map((a, i) => {
            if (hasOffers && i >= 6) return null;
            return this.renderAnnouncement(a, i, true);
          })}
        </div>
        {hasOffers && (
          <div id="offers" className="announcementOffer" style={{ backgroundImage: `url(${this.state.offers[offerIndex].TVModeLogo})` }} />
        )}
      </div>
    );
  }

  renderEvent(i) {
    if (!this.state.events || this.state.events.length < i) {
      return null;
    }
    const ev = this.getEvent(i);
    const date = this.getEventTime(ev);
    const isToday = true;
    const dateText = date.calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[Yesterday]',
      lastWeek: '[Last] dddd',
      sameElse: 'MMMM',
    });
    /* const isToday = date.format('D-M-Y') === moment().format('D-M-Y'); */
    return (
      <div className="eventcontainer">
        <h6 className="smallheading" style={{ visibility: isToday ? 'visible' : 'hidden' }}>
          {dateText}
        </h6>
        <div className="eventcard">
          <div className="eventcardimg">
            <img alt="" src={ev.Image} className="eventcardimage" style={{ backgroundColor: ev.ImageColour }} />
          </div>
          <h2 className="cardTitle truncate">{ev.Title}</h2>
          <p className="cardText truncate">{date.format('ddd, D MMM • h:mm a')}</p>
        </div>
      </div>
    );
  }

  renderEventPortrait(i) {
    if (!this.state.events || this.state.events.length < i) {
      return null;
    }
    const ev = this.getEvent(i);
    const date = this.getEventTime(ev);
    const isToday = true;
    const dateText = date.calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[Yesterday]',
      lastWeek: '[Last] dddd',
      sameElse: 'MMMM',
    });
    /* const isToday = date.format('D-M-Y') === moment().format('D-M-Y'); */
    return (
      <div className="eventcontainer">
        <h6 className="smallheading" style={{ visibility: isToday ? 'visible' : 'hidden' }}>
          {dateText}
        </h6>
        <div className="eventcard">
          <div className="eventcardimg">
            <img alt="" src={ev.Image} className="eventcardimage" style={{ backgroundColor: ev.ImageColour }} />
          </div>
          <h2 className="cardTitle truncate">{ev.Title}</h2>
          <p className="cardText truncate">{date.format('ddd, D MMM • h:mm a')}</p>
        </div>
      </div>
    );
  }

  renderFeatured() {
    const featuredEvent = this.getEvent(0);
    if (!featuredEvent) {
      return (
        <div className="tvfeatured">
          <div className="tvfeaturedinner">
            <div className="tvfeaturedcontent"></div>
          </div>
        </div>
      );
    }
    return (
      <div className="tvfeatured" style={{ backgroundImage: `url(${featuredEvent.Image})`, backgroundColor: featuredEvent.ImageColour }}>
        <div className="tvfeaturedinner">
          <div className="tvfeaturedcontent">
            {featuredEvent.IsFeatured && <h6 className="smallheading text-white">Featured Event</h6>}
            <h2 className="headingtext text-white">
              <b>{featuredEvent.Title}</b>
            </h2>
            <h5 className="subtitletext text-white">{this.getEventTime(featuredEvent).format('ddd, D MMM • h:mm a')}</h5>
            {/* <div className="featuredhighlight">
                        <h6 className="featuredtext"><b>Book your spot now through our mobile app!</b></h6>
                    </div> */}
          </div>
        </div>
      </div>
    );
  }

  renderPortrait(weatherColour) {
    return (
      <div className="tvscreen">
        <div className="tvheader">
          <div className="logocontainer">
            {!this.state.loadingSiteInfo && <img alt="Logo" src={getLogo(this.state.siteBranding)} className="logoimage" />}
          </div>
          <div className="datetimecontainer">
            <h4 className="datetimetext">{this.state.timestamp}</h4>
          </div>
        </div>

        <div className="tvbodyPortrait">
          <div className="tvcontent">
            <div className="tvsubcontent">
              <div className="tvtitle">
                <h2 className="headingtextbold">Welcome{this.state.site && ` to ${this.state.site}`}</h2>

                <div className="weatherbox">
                  <h6 className="weathertitle">Weather</h6>
                  <div
                    className="weathercontent"
                    style={{
                      backgroundColor: weatherColour,
                    }}
                  >
                    {this.state.weather && (
                      <img
                        className="weathericon"
                        alt=""
                        src={`https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/weather/${this.state.weather.Weather.icon}.png`}
                      ></img>
                    )}
                  </div>

                  {this.state.weather && (
                    <h4 className="weathercity">
                      <b>{this.state.weather.Name}</b>
                    </h4>
                  )}
                  {this.state.weather && (
                    <p className="weatherinfo">{`${Math.round(this.state.weather.Temps.temp)}°C • ${this.state.weather.Weather.main}`}</p>
                  )}
                </div>
              </div>
              {this.renderFeatured()}
            </div>
            <div className="tvevents">
              <div className="eventtitle">
                <h2 className="headingtext">Events</h2>
              </div>
              <div className="eventlist">
                <div className="grid-row">
                  {this.renderEventPortrait(1)}
                  {this.renderEventPortrait(3)}
                </div>
                <div className="grid-row">
                  {this.renderEventPortrait(2)}
                  {this.renderEventPortrait(4)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderAnnouncementsPortrait()}
      </div>
    );
  }

  render() {
    const weatherColour = this.state.weather && this.state.weather.Weather.icon.substr(-1) === 'n' ? '#003159' : '#89c4f4';
    if (window.screen.width < window.screen.height) {
      return this.renderPortrait(weatherColour);
    }
    if (this.state.forceRotate) {
      return (
        <div className={`tvscreen-forceRotate ${this.state.forceRotate === '270' ? 'tvscreen-forceRotate270' : ''}`}>
          {this.renderPortrait(weatherColour)}
        </div>
      );
    }
    return (
      /* TV Mode Landscape */

      <div className="tvscreen">
        <div className="tvheader">
          <div className="logocontainer">
            {!this.state.loadingSiteInfo && <img alt="Logo" src={getLogo(this.state.siteBranding)} className="logoimage" />}
          </div>
          <div className="datetimecontainer">
            <h4 className="datetimetext">{this.state.timestamp}</h4>
          </div>
        </div>

        <div className="tvbody">
          <div className="tvcontent">
            <div className="tvsubcontent">
              <div className="tvtitle">
                <h2 className="headingtextbold">Welcome{this.state.site && ` to ${this.state.site}`}</h2>

                <div className="weatherbox">
                  <h6 className="weathertitle">Weather</h6>
                  <div
                    className="weathercontent"
                    style={{
                      backgroundColor: weatherColour,
                    }}
                  >
                    {this.state.weather && (
                      <img
                        className="weathericon"
                        alt=""
                        src={`https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/weather/${this.state.weather.Weather.icon}.png`}
                      ></img>
                    )}
                  </div>

                  {this.state.weather && (
                    <h4 className="weathercity">
                      <b>{this.state.weather.Name}</b>
                    </h4>
                  )}
                  {this.state.weather && (
                    <p className="weatherinfo">{`${Math.round(this.state.weather.Temps.temp)}°C • ${this.state.weather.Weather.main}`}</p>
                  )}
                </div>
              </div>
              {this.renderFeatured()}
            </div>

            <div className="tvevents">
              <div className="eventtitle">
                <h2 className="headingtext">Events</h2>
              </div>
              <div className="eventlist">
                {this.renderEvent(1)}
                {this.renderEvent(2)}
                {this.renderEvent(3)}
                {this.renderEvent(4)}
              </div>
            </div>
          </div>

          {this.renderAnnouncements()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { setAuthUser })(TV);
