import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import FontAwesome from 'react-fontawesome';
import { SVGIcon } from '../../../components';
import { COLOUR_DUSK } from '../../../js';

class DraggableWidget extends Component {
  renderContent() {
    if (this.props.content) {
      if (typeof this.props.content === 'function') {
        return this.props.content();
      }
      return this.props.content;
    }
    return (
      <div className="fp2_widget_content">
        <div className="fp2_widget_silhouette fp2_widget_silhouette-general"></div>
        <div className="fp2_widget_silhouette fp2_widget_silhouette-general"></div>
        <div className="fp2_widget_silhouette fp2_widget_silhouette-general"></div>
      </div>
    );
  }

  renderRemove() {
    if (!this.props.onRemove) {
      return null;
    }
    return (
      <div className="fp2_widget_remove" onClick={this.props.onRemove}>
        <SVGIcon className="fp2_widget_remove_icon" icon="close" colour={COLOUR_DUSK} />
      </div>
    );
  }

  renderTitle() {
    if (this.props.onChangeTitle) {
      return (
        <input
          type="text"
          className="fp2_widget_title fp2_widget_title-input"
          value={this.props.title}
          onChange={(e) => this.props.onChangeTitle(e.target.value)}
        />
      );
    }
    return <p className="fp2_widget_title">{this.props.title}</p>;
  }

  renderInfoButton() {
    if (this.props.selected || !this.props.onClickInfo) {
      return null;
    }
    return (
      <div className="fp2_widget_info" onClick={this.props.onClickInfo}>
        <FontAwesome className="fp2_widget_info_icon" name="info" />
      </div>
    );
  }

  render() {
    return (
      <Draggable draggableId={this.props.widget} index={this.props.index}>
        {(provided, snapshot) => (
          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            <div
              className={`fp2_widget
              ${this.props.selected ? ' fp2_widget-selected' : ''}
              ${snapshot.isDragging ? ' fp2_widget-dragging' : ''}`}
              onMouseEnter={this.props.onMouseEnter}
              onMouseLeave={this.props.onMouseLeave}
            >
              {this.renderRemove()}
              <div className="fp2_widget_card">
                <div className="fp2_widget_draghandle">
                  <FontAwesome className="fp2_widget_draghandle_icon" name="ellipsis-v" />
                  <FontAwesome className="fp2_widget_draghandle_icon" name="ellipsis-v" />
                </div>

                {this.renderTitle()}
                {/* {this.renderInfoButton()} */}
                {this.props.large ? (
                  <div>
                    {this.renderContent()}
                    {this.renderContent()}
                    {this.renderContent()}
                  </div>
                ) : (
                  this.renderContent()
                )}
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

export default DraggableWidget;
