import _ from 'lodash';
import { JOBS_LOADED, JOBS_PURGE, JOBS_LOADING, TICKETS_LOADED } from './types';
import { maintenanceActions } from '../webapi';
import { readStorageWithCookie } from '../helper';

export const jobsUpdate = (site, isdashboard) => {
  return (dispatch) => {
    if (isdashboard) {
      dispatch({
        type: JOBS_LOADING,
      });
    }
    maintenanceActions.getJobs(site).then((res) => {
      const currentSite = readStorageWithCookie('site');
      if (res.data != null && !_.isEmpty(res.data) && res.data[0].site === currentSite) {
        dispatch({
          type: JOBS_LOADED,
          payload: res.data,
        });
      } else {
        dispatch({
          type: JOBS_LOADED,
          payload: [],
        });
      }
    });
  };
};

export const clearJobs = () => {
  return {
    type: JOBS_PURGE,
  };
};

export const ticketsLoaded = (tickets) => {
  return {
    type: TICKETS_LOADED,
    payload: tickets,
  };
};

export const ticketsUpdate = (site) => {
  return (dispatch) => {
    maintenanceActions.getTickets(site).then((res) => {
      if (res.data != null) {
        dispatch({
          type: TICKETS_LOADED,
          payload: res.data,
        });
      }
    });
  };
};
